import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
/* import __COLOCATED_TEMPLATE__ from './edit-category-general.hbs'; */
import { action } from "@ember/object";
import { not } from "@ember/object/computed";
import { cancel } from "@ember/runloop";
import { isEmpty } from "@ember/utils";
import { buildCategoryPanel } from "discourse/components/edit-category-panel";
import { categoryBadgeHTML } from "discourse/helpers/category-link";
import Category from "discourse/models/category";
import getURL from "discourse-common/lib/get-url";
import discourseLater from "discourse-common/lib/later";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./edit-category-general.hbs";
import { setComponentTemplate } from "@ember/component";
export default setComponentTemplate(TEMPLATE, buildCategoryPanel("general", (_dec = discourseComputed("category.id", "category.color"), _dec2 = discourseComputed("category.parent_category_id", "category.name", "category.color", "category.text_color"), _dec3 = discourseComputed("category.id"), _dec4 = discourseComputed("category.isUncategorizedCategory", "category.id", "category.topic_url"), (_obj = {
  init() {
    this._super(...arguments);
    this.foregroundColors = ["FFFFFF", "000000"];
  },
  didInsertElement() {
    this._super(...arguments);
    this._focusCategoryName();
  },
  willDestroyElement() {
    this._super(...arguments);
    this._laterFocus && cancel(this._laterFocus);
  },
  canSelectParentCategory: not("category.isUncategorizedCategory"),
  uncategorizedSiteSettingLink: getURL("/admin/site_settings/category/all_results?filter=allow_uncategorized_topics"),
  customizeTextContentLink: getURL("/admin/customize/site_texts?q=uncategorized"),
  backgroundColors() {
    const categories = this.site.get("categoriesList");
    return this.siteSettings.category_colors.split("|").map(function (i) {
      return i.toUpperCase();
    }).concat(categories.map(function (c) {
      return c.color.toUpperCase();
    })).uniq();
  },
  usedBackgroundColors(categoryId, categoryColor) {
    const categories = this.site.get("categoriesList");

    // If editing a category, don't include its color:
    return categories.map(function (c) {
      return categoryId && categoryColor.toUpperCase() === c.color.toUpperCase() ? null : c.color.toUpperCase();
    }, this).compact();
  },
  parentCategories() {
    return this.site.get("categoriesList").filter(c => c.level + 1 < this.siteSettings.max_category_nesting);
  },
  categoryBadgePreview(parentCategoryId, name, color, textColor) {
    const category = this.category;
    const c = Category.create({
      name,
      color,
      id: category.id,
      text_color: textColor,
      parent_category_id: parseInt(parentCategoryId, 10),
      read_restricted: category.get("read_restricted")
    });
    return categoryBadgeHTML(c, {
      link: false,
      previewColor: true
    });
  },
  subCategories(categoryId) {
    if (isEmpty(categoryId)) {
      return null;
    }
    return Category.list().filterBy("parent_category_id", categoryId);
  },
  showDescription(isUncategorizedCategory, categoryId, topicUrl) {
    return !isUncategorizedCategory && categoryId && topicUrl;
  },
  showCategoryTopic() {
    window.open(this.get("category.topic_url"), "_blank").focus();
    return false;
  },
  _focusCategoryName() {
    this._laterFocus = discourseLater(() => {
      const categoryName = this.element.querySelector(".category-name");
      categoryName && categoryName.focus();
    }, 25);
  }
}, (_applyDecoratedDescriptor(_obj, "backgroundColors", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "backgroundColors"), _obj), _applyDecoratedDescriptor(_obj, "usedBackgroundColors", [_dec], Object.getOwnPropertyDescriptor(_obj, "usedBackgroundColors"), _obj), _applyDecoratedDescriptor(_obj, "parentCategories", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "parentCategories"), _obj), _applyDecoratedDescriptor(_obj, "categoryBadgePreview", [_dec2], Object.getOwnPropertyDescriptor(_obj, "categoryBadgePreview"), _obj), _applyDecoratedDescriptor(_obj, "subCategories", [_dec3], Object.getOwnPropertyDescriptor(_obj, "subCategories"), _obj), _applyDecoratedDescriptor(_obj, "showDescription", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showDescription"), _obj), _applyDecoratedDescriptor(_obj, "showCategoryTopic", [action], Object.getOwnPropertyDescriptor(_obj, "showCategoryTopic"), _obj)), _obj))));