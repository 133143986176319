import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <p>{{i18n "topics.bulk.choose_new_category"}}</p>

<p>
  <CategoryChooser
    @value={{this.categoryId}}
    @onChange={{fn (mut this.categoryId)}}
  />
</p>

<ConditionalLoadingSpinner @condition={{@loading}}>
  <DButton
    @action={{this.changeCategory}}
    @label="topics.bulk.change_category"
  />
</ConditionalLoadingSpinner>
*/
{
  "id": "9NV4jf7u",
  "block": "[[[10,2],[12],[1,[28,[35,0],[\"topics.bulk.choose_new_category\"],null]],[13],[1,\"\\n\\n\"],[10,2],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@value\",\"@onChange\"],[[30,0,[\"categoryId\"]],[28,[37,2],[[28,[37,3],[[30,0,[\"categoryId\"]]],null]],null]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@condition\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,5],null,[[\"@action\",\"@label\"],[[30,0,[\"changeCategory\"]],\"topics.bulk.change_category\"]],null],[1,\"\\n\"]],[]]]]]],[\"@loading\"],false,[\"i18n\",\"category-chooser\",\"fn\",\"mut\",\"conditional-loading-spinner\",\"d-button\"]]",
  "moduleName": "discourse/components/bulk-actions/change-category.hbs",
  "isStrictMode": false
});