import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="emoji-uploader form-horizontal">
  <div class="control-group">
    <span class="label">
      {{i18n "admin.emoji.name"}}
    </span>
    <div class="input">
      <Input
        id="emoji-name"
        name="name"
        placeholder={{i18n "admin.emoji.name"}}
        @value={{readonly this.name}}
        {{on "input" (with-event-value (fn (mut this.name)))}}
      />
    </div>
  </div>
  <div class="control-group">
    <span class="label">
      {{i18n "admin.emoji.group"}}
    </span>
    <div class="input">
      <ComboBox
        @name="group"
        @id="emoji-group-selector"
        @value={{this.group}}
        @content={{this.newEmojiGroups}}
        @onChange={{action "createEmojiGroup"}}
        @valueProperty={{null}}
        @nameProperty={{null}}
        @options={{hash allowAny=true}}
      />
    </div>
  </div>
  <div class="control-group">
    <div class="input">
      <input
        class="hidden-upload-field"
        disabled={{this.uploading}}
        type="file"
        multiple="true"
        accept=".png,.gif"
      />
      <DButton
        @translatedLabel={{this.buttonLabel}}
        @icon="plus"
        @action={{this.chooseFiles}}
        @disabled={{this.uploading}}
        class="btn-primary"
      />
    </div>
  </div>
</div>
*/
{
  "id": "A3LUEX6L",
  "block": "[[[10,0],[14,0,\"emoji-uploader form-horizontal\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"label\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"admin.emoji.name\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"input\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,1,\"emoji-name\"],[24,3,\"name\"],[16,\"placeholder\",[28,[37,0],[\"admin.emoji.name\"],null]],[4,[38,3],[\"input\",[28,[37,4],[[28,[37,5],[[28,[37,6],[[30,0,[\"name\"]]],null]],null]],null]],null]],[[\"@value\"],[[28,[37,2],[[30,0,[\"name\"]]],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"label\"],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"admin.emoji.group\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"input\"],[12],[1,\"\\n      \"],[8,[39,7],null,[[\"@name\",\"@id\",\"@value\",\"@content\",\"@onChange\",\"@valueProperty\",\"@nameProperty\",\"@options\"],[\"group\",\"emoji-group-selector\",[30,0,[\"group\"]],[30,0,[\"newEmojiGroups\"]],[28,[37,8],[[30,0],\"createEmojiGroup\"],null],null,null,[28,[37,9],null,[[\"allowAny\"],[true]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"input\"],[12],[1,\"\\n      \"],[10,\"input\"],[14,0,\"hidden-upload-field\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[14,\"multiple\",\"true\"],[14,\"accept\",\".png,.gif\"],[14,4,\"file\"],[12],[13],[1,\"\\n      \"],[8,[39,10],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@icon\",\"@action\",\"@disabled\"],[[30,0,[\"buttonLabel\"]],\"plus\",[30,0,[\"chooseFiles\"]],[30,0,[\"uploading\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"input\",\"readonly\",\"on\",\"with-event-value\",\"fn\",\"mut\",\"combo-box\",\"action\",\"hash\",\"d-button\"]]",
  "moduleName": "discourse/components/emoji-uploader.hbs",
  "isStrictMode": false
});