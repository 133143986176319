import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "fullscreen_table.view_table"}}
  @closeModal={{@closeModal}}
  class="fullscreen-table-modal -max"
>
  <:body>
    {{@model.tableHtml}}
  </:body>
</DModal>
*/
{
  "id": "XUrxWMpj",
  "block": "[[[8,[39,0],[[24,0,\"fullscreen-table-modal -max\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"fullscreen_table.view_table\"],null],[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[1,[30,2,[\"tableHtml\"]]],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\"]]",
  "moduleName": "discourse/components/modal/fullscreen-table.hbs",
  "isStrictMode": false
});