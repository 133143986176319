import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div id="simple-container">
  <div class="account-created">
    {{outlet}}
  </div>
</div>
*/
{
  "id": "VPE1a6+E",
  "block": "[[[10,0],[14,1,\"simple-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"account-created\"],[12],[1,\"\\n    \"],[46,[28,[37,1],null,null],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"component\",\"-outlet\"]]",
  "moduleName": "discourse/templates/account-created.hbs",
  "isStrictMode": false
});