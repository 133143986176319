import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import Service, { service } from "@ember/service";
import { focusSearchInput } from "discourse/components/search-menu";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
let Search = disableImplicitInjections(_class = (_class2 = class Search extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "appEvents", _descriptor, this);
    _initializerDefineProperty(this, "activeGlobalSearchTerm", _descriptor2, this);
    _initializerDefineProperty(this, "searchContext", _descriptor3, this);
    _initializerDefineProperty(this, "highlightTerm", _descriptor4, this);
    _initializerDefineProperty(this, "inTopicContext", _descriptor5, this);
    _initializerDefineProperty(this, "visible", _descriptor6, this);
    _initializerDefineProperty(this, "results", _descriptor7, this);
    _initializerDefineProperty(this, "noResults", _descriptor8, this);
    // only relative for the widget search menu
    _defineProperty(this, "searchContextEnabled", false);
  }
  // checkbox to scope search

  get contextType() {
    return this.searchContext?.type || null;
  }

  // The need to navigate with the keyboard creates a lot shared logic
  // between multiple components
  //
  // - SearchTerm
  // - Results::AssistantItem
  // - Results::Types
  // - Results::MoreLink
  // - Results::RecentSearches
  //
  // To minimize the duplicate logic we will create a shared action here
  // that can be reused across all of the components
  handleResultInsertion(e) {
    if (e.keyCode === 65 /* a or A */) {
      // add a link and focus composer if open
      if (document.querySelector("#reply-control.open")) {
        this.appEvents.trigger("composer:insert-text", document.activeElement.href, {
          ensureSpace: true
        });
        this.appEvents.trigger("header:keyboard-trigger", {
          type: "search"
        });
        document.querySelector("#reply-control.open textarea").focus();
        e.stopPropagation();
        e.preventDefault();
        return false;
      }
    }
  }
  handleArrowUpOrDown(e) {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      let focused = e.target.closest(".search-menu") ? e.target : null;
      if (!focused) {
        return;
      }
      let links = document.querySelectorAll(".search-menu .results a");
      let results = document.querySelectorAll(".search-menu .results .search-link");
      if (!results.length) {
        return;
      }
      let prevResult;
      let result;
      links.forEach(item => {
        if (item.classList.contains("search-link")) {
          prevResult = item;
        }
        if (item === focused) {
          result = prevResult;
        }
      });
      let index = -1;
      if (result) {
        index = Array.prototype.indexOf.call(results, result);
      }
      if (index === -1 && e.key === "ArrowDown") {
        // change focus from the search input to the first result item
        const firstResult = results[0] || links[0];
        firstResult.focus();
      } else if (index === 0 && e.key === "ArrowUp") {
        focusSearchInput();
      } else if (index > -1) {
        // change focus to the next result item if present
        index += e.key === "ArrowDown" ? 1 : -1;
        if (index >= 0 && index < results.length) {
          results[index].focus();
        }
      }
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "activeGlobalSearchTerm", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "";
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "searchContext", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "highlightTerm", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "inTopicContext", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "visible", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "results", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return {};
  }
}), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "noResults", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class2.prototype, "handleResultInsertion", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleResultInsertion"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleArrowUpOrDown", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleArrowUpOrDown"), _class2.prototype)), _class2)) || _class;
export { Search as default };