import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.showPWAInstallBanner}}
  <div class="row">
    <div class="pwa-install-banner alert alert-info">
      <span>
        <DiscourseLinkedText
          @action={{action "turnOn"}}
          @text="pwa.install_banner"
          @textParams={{hash title=this.siteSettings.title}}
        />
      </span>
      <DButton
        @icon="times"
        @action={{action "dismiss"}}
        @title="banner.close"
        class="btn-transparent close"
      />
    </div>
  </div>
{{/if}}
*/
{
  "id": "pgc/IdtF",
  "block": "[[[41,[30,0,[\"showPWAInstallBanner\"]],[[[1,\"  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"pwa-install-banner alert alert-info\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@action\",\"@text\",\"@textParams\"],[[28,[37,2],[[30,0],\"turnOn\"],null],\"pwa.install_banner\",[28,[37,3],null,[[\"title\"],[[30,0,[\"siteSettings\",\"title\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,4],[[24,0,\"btn-transparent close\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[28,[37,2],[[30,0],\"dismiss\"],null],\"banner.close\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"discourse-linked-text\",\"action\",\"hash\",\"d-button\"]]",
  "moduleName": "discourse/components/pwa-install-banner.hbs",
  "isStrictMode": false
});