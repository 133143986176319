import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './user-badge.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./user-badge.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("count"), _dec2 = discourseComputed("badge", "user"), (_obj = {
  tagName: "",
  showGrantCount(count) {
    return count && count > 1;
  },
  badgeUrl() {
    // NOTE: I tried using a link-to helper here but the queryParams mean it fails
    let username = this.get("user.username_lower") || "";
    username = username !== "" ? "?username=" + username : "";
    return this.get("badge.url") + username;
  }
}, (_applyDecoratedDescriptor(_obj, "showGrantCount", [_dec], Object.getOwnPropertyDescriptor(_obj, "showGrantCount"), _obj), _applyDecoratedDescriptor(_obj, "badgeUrl", [_dec2], Object.getOwnPropertyDescriptor(_obj, "badgeUrl"), _obj)), _obj))));