import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <a
  class="clear-search"
  aria-label="clear_input"
  title={{i18n "search.clear_search"}}
  href
  {{on "click" @clearSearch}}
>
  {{d-icon "times"}}
</a>
*/
{
  "id": "BPWXsITk",
  "block": "[[[11,3],[24,0,\"clear-search\"],[24,\"aria-label\",\"clear_input\"],[16,\"title\",[28,[37,0],[\"search.clear_search\"],null]],[24,6,\"\"],[4,[38,1],[\"click\",[30,1]],null],[12],[1,\"\\n  \"],[1,[28,[35,2],[\"times\"],null]],[1,\"\\n\"],[13]],[\"@clearSearch\"],false,[\"i18n\",\"on\",\"d-icon\"]]",
  "moduleName": "discourse/components/search-menu/clear-button.hbs",
  "isStrictMode": false
});