import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @closeModal={{@closeModal}}
  @title={{i18n "topic.progress.jump_prompt_long"}}
  class="jump-to-post-modal"
>
  <:body>
    <div class="jump-to-post-form">
      <div class="jump-to-post-control">
        <span class="index">#</span>
        <Input
          @value={{this.postNumber}}
          @type="number"
          autofocus="true"
          id="post-jump"
        />
        <span class="input-hint-text post-number">
          {{i18n "topic.progress.jump_prompt_of" count=this.filteredPostsCount}}
        </span>
      </div>

      <div class="separator">
        <span class="text">
          {{i18n "topic.progress.jump_prompt_or"}}
        </span>
        <hr class="right" />
      </div>

      <div class="jump-to-date-control">
        <span class="input-hint-text post-date">
          {{i18n "topic.progress.jump_prompt_to_date"}}
        </span>
        <DatePicker
          @value={{this.postDate}}
          @defaultDate="YYYY-MM-DD"
          id="post-date"
          class="date-input"
        />
      </div>
    </div>
  </:body>

  <:footer>
    <DButton
      @action={{this.jump}}
      @label="composer.modal_ok"
      type="submit"
      class="btn-primary"
    />
    <DModalCancel @close={{@closeModal}} />
  </:footer>
</DModal>
*/
{
  "id": "Wyk0cwXj",
  "block": "[[[8,[39,0],[[24,0,\"jump-to-post-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"topic.progress.jump_prompt_long\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"jump-to-post-form\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"jump-to-post-control\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"index\"],[12],[1,\"#\"],[13],[1,\"\\n        \"],[8,[39,2],[[24,\"autofocus\",\"true\"],[24,1,\"post-jump\"]],[[\"@value\",\"@type\"],[[30,0,[\"postNumber\"]],\"number\"]],null],[1,\"\\n        \"],[10,1],[14,0,\"input-hint-text post-number\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"topic.progress.jump_prompt_of\"],[[\"count\"],[[30,0,[\"filteredPostsCount\"]]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"separator\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"text\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"topic.progress.jump_prompt_or\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,\"hr\"],[14,0,\"right\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"jump-to-date-control\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"input-hint-text post-date\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[\"topic.progress.jump_prompt_to_date\"],null]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,3],[[24,1,\"post-date\"],[24,0,\"date-input\"]],[[\"@value\",\"@defaultDate\"],[[30,0,[\"postDate\"]],\"YYYY-MM-DD\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn-primary\"],[24,4,\"submit\"]],[[\"@action\",\"@label\"],[[30,0,[\"jump\"]],\"composer.modal_ok\"]],null],[1,\"\\n    \"],[8,[39,5],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"input\",\"date-picker\",\"d-button\",\"d-modal-cancel\"]]",
  "moduleName": "discourse/components/modal/jump-to-post.hbs",
  "isStrictMode": false
});