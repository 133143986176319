import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#unless this.hidden}}
  <div class="topic-navigation-popup">
    <DButton @action={{this.close}} @icon="times" class="close btn-flat" />
    {{yield}}
  </div>
{{/unless}}
*/
{
  "id": "KdX53xZU",
  "block": "[[[41,[51,[30,0,[\"hidden\"]]],[[[1,\"  \"],[10,0],[14,0,\"topic-navigation-popup\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"close btn-flat\"]],[[\"@action\",\"@icon\"],[[30,0,[\"close\"]],\"times\"]],null],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"unless\",\"d-button\",\"yield\"]]",
  "moduleName": "discourse/components/topic-navigation-popup.hbs",
  "isStrictMode": false
});