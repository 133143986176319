import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <ComboBox
  @value={{this.time}}
  @content={{this.timeOptions}}
  @onChange={{action "onChangeTime"}}
  @options={{hash
    translatedNone="--:--"
    allowAny=true
    filterable=false
    autoInsertNoneItem=false
    translatedFilterPlaceholder="--:--"
  }}
/>
*/
{
  "id": "DcLZyXCT",
  "block": "[[[8,[39,0],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,0,[\"time\"]],[30,0,[\"timeOptions\"]],[28,[37,1],[[30,0],\"onChangeTime\"],null],[28,[37,2],null,[[\"translatedNone\",\"allowAny\",\"filterable\",\"autoInsertNoneItem\",\"translatedFilterPlaceholder\"],[\"--:--\",true,false,false,\"--:--\"]]]]],null]],[],false,[\"combo-box\",\"action\",\"hash\"]]",
  "moduleName": "discourse/components/time-input.hbs",
  "isStrictMode": false
});