import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.canJoinGroup}}
  <DButton
    @action={{action "joinGroup"}}
    @icon="user-plus"
    @label="groups.join"
    @disabled={{this.updatingMembership}}
    class="btn-default group-index-join"
  />
{{else if this.canLeaveGroup}}
  <DButton
    @action={{action "leaveGroup"}}
    @icon="user-times"
    @label="groups.leave"
    @disabled={{this.updatingMembership}}
    class="btn-danger group-index-leave"
  />
{{else if this.canRequestMembership}}
  <DButton
    @action={{action "showRequestMembershipForm"}}
    @disabled={{this.loading}}
    @icon="user-plus"
    @label="groups.request"
    class="btn-default group-index-request"
  />
{{else}}
  {{yield}}
{{/if}}
*/
{
  "id": "vF0M0d39",
  "block": "[[[41,[30,0,[\"canJoinGroup\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn-default group-index-join\"]],[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[28,[37,2],[[30,0],\"joinGroup\"],null],\"user-plus\",\"groups.join\",[30,0,[\"updatingMembership\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"canLeaveGroup\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn-danger group-index-leave\"]],[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[28,[37,2],[[30,0],\"leaveGroup\"],null],\"user-times\",\"groups.leave\",[30,0,[\"updatingMembership\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"canRequestMembership\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn-default group-index-request\"]],[[\"@action\",\"@disabled\",\"@icon\",\"@label\"],[[28,[37,2],[[30,0],\"showRequestMembershipForm\"],null],[30,0,[\"loading\"]],\"user-plus\",\"groups.request\"]],null],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[]]]],[]]]],[\"&default\"],false,[\"if\",\"d-button\",\"action\",\"yield\"]]",
  "moduleName": "discourse/components/group-membership-button.hbs",
  "isStrictMode": false
});