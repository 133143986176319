import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{d-icon this.icon class="fa-stack-1x"}}

{{#if this.disabled}}
  {{d-icon "ban" class="fa-stack-2x"}}
{{/if}}
*/
{
  "id": "Yu+SPuoe",
  "block": "[[[1,[28,[35,0],[[30,0,[\"icon\"]]],[[\"class\"],[\"fa-stack-1x\"]]]],[1,\"\\n\\n\"],[41,[30,0,[\"disabled\"]],[[[1,\"  \"],[1,[28,[35,0],[\"ban\"],[[\"class\"],[\"fa-stack-2x\"]]]],[1,\"\\n\"]],[]],null]],[],false,[\"d-icon\",\"if\"]]",
  "moduleName": "discourse/components/disabled-icon.hbs",
  "isStrictMode": false
});