/* import __COLOCATED_TEMPLATE__ from './reviewable-field-tags.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import TEMPLATE from "./reviewable-field-tags.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend({
  actions: {
    onChange(tags) {
      this.set("value", tags);
      this.valueChanged && this.valueChanged({
        target: {
          value: tags
        }
      });
    }
  }
}));