import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.model.canLoadMore}}
  {{hide-application-footer}}
{{/if}}

<UserStream @stream={{this.model}} />
*/
{
  "id": "uPuLwePd",
  "block": "[[[41,[30,0,[\"model\",\"canLoadMore\"]],[[[1,\"  \"],[1,[34,1]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,2],null,[[\"@stream\"],[[30,0,[\"model\"]]]],null]],[],false,[\"if\",\"hide-application-footer\",\"user-stream\"]]",
  "moduleName": "discourse/templates/user/posts.hbs",
  "isStrictMode": false
});