import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Input
  @value={{this.value}}
  class="reviewable-input-text"
  {{on "change" this.valueChanged}}
/>
*/
{
  "id": "tBQ611mt",
  "block": "[[[8,[39,0],[[24,0,\"reviewable-input-text\"],[4,[38,1],[\"change\",[30,0,[\"valueChanged\"]]],null]],[[\"@value\"],[[30,0,[\"value\"]]]],null]],[],false,[\"input\",\"on\"]]",
  "moduleName": "discourse/components/reviewable-field-text.hbs",
  "isStrictMode": false
});