import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n @model.title}}
  @closeModal={{@closeModal}}
  @bodyClass="invite modal-panel"
  class="add-pm-participants"
>
  <:body>
    <InvitePanel
      @inviteModel={{@model.inviteModel}}
      @closeModal={{@closeModal}}
    />
  </:body>
</DModal>
*/
{
  "id": "snRFXF4N",
  "block": "[[[8,[39,0],[[24,0,\"add-pm-participants\"]],[[\"@title\",\"@closeModal\",\"@bodyClass\"],[[28,[37,1],[[30,1,[\"title\"]]],null],[30,2],\"invite modal-panel\"]],[[\"body\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@inviteModel\",\"@closeModal\"],[[30,1,[\"inviteModel\"]],[30,2]]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"invite-panel\"]]",
  "moduleName": "discourse/components/modal/add-pm-participants.hbs",
  "isStrictMode": false
});