import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "copy_codeblock.view_code"}}
  @closeModal={{this.closeModal}}
  {{did-insert this.applyCodeblockButtons}}
  class="fullscreen-code-modal -max"
>
  <:body>
    <pre>
      <code class={{@model.codeClasses}}>{{@model.code}}</code>
    </pre>
  </:body>
</DModal>
*/
{
  "id": "OxotvjRw",
  "block": "[[[8,[39,0],[[24,0,\"fullscreen-code-modal -max\"],[4,[38,2],[[30,0,[\"applyCodeblockButtons\"]]],null]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"copy_codeblock.view_code\"],null],[30,0,[\"closeModal\"]]]],[[\"body\"],[[[[1,\"\\n    \"],[10,\"pre\"],[12],[1,\"      \"],[10,\"code\"],[15,0,[30,1,[\"codeClasses\"]]],[12],[1,[30,1,[\"code\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@model\"],false,[\"d-modal\",\"i18n\",\"did-insert\"]]",
  "moduleName": "discourse/components/modal/fullscreen-code.hbs",
  "isStrictMode": false
});