import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{i18n "user.second_factor.delete_confirm_header"}}

<ul>
  {{#each @model.totps as |totp|}}
    <li>{{totp.name}}</li>
  {{/each}}

  {{#each @model.security_keys as |sk|}}
    <li>{{sk.name}}</li>
  {{/each}}

  {{#if this.currentUser.second_factor_backup_enabled}}
    <li>{{i18n "user.second_factor_backup.title"}}</li>
  {{/if}}
</ul>

<p>
  {{html-safe
    (i18n
      "user.second_factor.delete_confirm_instruction"
      confirm=this.disabledString
    )
  }}
</p>

<TextField
  @value={{this.confirmPhraseInput}}
  {{on "input" this.onConfirmPhraseInput}}
  @id="confirm-phrase"
  @autocorrect="off"
  @autocapitalize="off"
/>
*/
{
  "id": "5+JmmGB3",
  "block": "[[[1,[28,[35,0],[\"user.second_factor.delete_confirm_header\"],null]],[1,\"\\n\\n\"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"totps\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[1,[30,2,[\"name\"]]],[13],[1,\"\\n\"]],[2]],null],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1,[\"security_keys\"]]],null]],null],null,[[[1,\"    \"],[10,\"li\"],[12],[1,[30,3,[\"name\"]]],[13],[1,\"\\n\"]],[3]],null],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"second_factor_backup_enabled\"]],[[[1,\"    \"],[10,\"li\"],[12],[1,[28,[35,0],[\"user.second_factor_backup.title\"],null]],[13],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\\n\"],[10,2],[12],[1,\"\\n  \"],[1,[28,[35,4],[[28,[37,0],[\"user.second_factor.delete_confirm_instruction\"],[[\"confirm\"],[[30,0,[\"disabledString\"]]]]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,5],[[4,[38,6],[\"input\",[30,0,[\"onConfirmPhraseInput\"]]],null]],[[\"@value\",\"@id\",\"@autocorrect\",\"@autocapitalize\"],[[30,0,[\"confirmPhraseInput\"]],\"confirm-phrase\",\"off\",\"off\"]],null]],[\"@model\",\"totp\",\"sk\"],false,[\"i18n\",\"each\",\"-track-array\",\"if\",\"html-safe\",\"text-field\",\"on\"]]",
  "moduleName": "discourse/components/dialog-messages/second-factor-confirm-phrase.hbs",
  "isStrictMode": false
});