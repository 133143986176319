import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <a class="tag-groups--back" href={{get-url "/tags"}}>
  {{d-icon "chevron-left"}}
  <span>{{i18n "tagging.groups.back_btn"}}</span>
</a>

<div class="container tag-groups-container">
  <h2>{{i18n "tagging.groups.title"}}</h2>

  {{#if this.siteSettings.tagging_enabled}}
    {{#if this.model}}
      <div class="tag-groups-sidebar content-list">
        <ul>
          {{#each this.model as |tagGroup|}}
            <li>
              <LinkTo @route="tagGroups.edit" @model={{tagGroup}}>
                {{tagGroup.name}}
              </LinkTo>
            </li>
          {{/each}}
        </ul>

        <DButton
          @action={{action "newTagGroup"}}
          @icon="plus"
          @label="tagging.groups.new"
          class="btn-default"
        />
      </div>
    {{/if}}

    {{outlet}}
  {{else}}
    <div class="tag-group-content">
      <div class="alert info">{{i18n "tagging.groups.disabled"}}</div>
    </div>
  {{/if}}
</div>
*/
{
  "id": "2ZXsjfTK",
  "block": "[[[10,3],[14,0,\"tag-groups--back\"],[15,6,[28,[37,0],[\"/tags\"],null]],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"chevron-left\"],null]],[1,\"\\n  \"],[10,1],[12],[1,[28,[35,2],[\"tagging.groups.back_btn\"],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"container tag-groups-container\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,2],[\"tagging.groups.title\"],null]],[13],[1,\"\\n\\n\"],[41,[30,0,[\"siteSettings\",\"tagging_enabled\"]],[[[41,[30,0,[\"model\"]],[[[1,\"      \"],[10,0],[14,0,\"tag-groups-sidebar content-list\"],[12],[1,\"\\n        \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,\"\\n              \"],[8,[39,6],null,[[\"@route\",\"@model\"],[\"tagGroups.edit\",[30,1]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[30,1,[\"name\"]]],[1,\"\\n              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\\n        \"],[8,[39,7],[[24,0,\"btn-default\"]],[[\"@action\",\"@icon\",\"@label\"],[[28,[37,8],[[30,0],\"newTagGroup\"],null],\"plus\",\"tagging.groups.new\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"tag-group-content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"alert info\"],[12],[1,[28,[35,2],[\"tagging.groups.disabled\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"tagGroup\"],false,[\"get-url\",\"d-icon\",\"i18n\",\"if\",\"each\",\"-track-array\",\"link-to\",\"d-button\",\"action\",\"component\",\"-outlet\"]]",
  "moduleName": "discourse/templates/tag-groups.hbs",
  "isStrictMode": false
});