import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#each this.filteredResultTypes as |resultType|}}
  <div class={{resultType.componentName}}>
    <PluginOutlet
      @name="search-menu-results-type-top"
      @outletArgs={{hash resultType=resultType}}
    />
    <ul
      class="list"
      aria-label={{concat (i18n "search.results") " " resultType.type}}
    >
      {{#each resultType.results as |result|}}
        {{! template-lint-disable no-pointer-down-event-binding }}
        {{! template-lint-disable no-invalid-interactive }}
        <li
          class="item"
          {{on
            "keydown"
            (fn this.onKeydown (hash resultType=resultType result=result))
          }}
        >
          <a
            href={{or result.url result.path}}
            {{on
              "click"
              (fn this.onClick (hash resultType=resultType result=result))
            }}
            class="search-link"
          >
            <resultType.component
              @result={{result}}
              @displayNameWithUser={{@displayNameWithUser}}
            />
          </a>
        </li>
      {{/each}}
    </ul>
  </div>
{{/each}}
*/
{
  "id": "9tCylsKy",
  "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"filteredResultTypes\"]]],null]],null],null,[[[1,\"  \"],[10,0],[15,0,[30,1,[\"componentName\"]]],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@outletArgs\"],[\"search-menu-results-type-top\",[28,[37,3],null,[[\"resultType\"],[[30,1]]]]]],null],[1,\"\\n    \"],[10,\"ul\"],[14,0,\"list\"],[15,\"aria-label\",[28,[37,4],[[28,[37,5],[\"search.results\"],null],\" \",[30,1,[\"type\"]]],null]],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"results\"]]],null]],null],null,[[[1,\"        \"],[11,\"li\"],[24,0,\"item\"],[4,[38,6],[\"keydown\",[28,[37,7],[[30,0,[\"onKeydown\"]],[28,[37,3],null,[[\"resultType\",\"result\"],[[30,1],[30,2]]]]],null]],null],[12],[1,\"\\n          \"],[11,3],[16,6,[28,[37,8],[[30,2,[\"url\"]],[30,2,[\"path\"]]],null]],[24,0,\"search-link\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"onClick\"]],[28,[37,3],null,[[\"resultType\",\"result\"],[[30,1],[30,2]]]]],null]],null],[12],[1,\"\\n            \"],[8,[30,1,[\"component\"]],null,[[\"@result\",\"@displayNameWithUser\"],[[30,2],[30,3]]],null],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[2]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null]],[\"resultType\",\"result\",\"@displayNameWithUser\"],false,[\"each\",\"-track-array\",\"plugin-outlet\",\"hash\",\"concat\",\"i18n\",\"on\",\"fn\",\"or\"]]",
  "moduleName": "discourse/components/search-menu/results/types.hbs",
  "isStrictMode": false
});