import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "keyboard_shortcuts_help.title"}}
  @closeModal={{@closeModal}}
  class="keyboard-shortcuts-modal -max"
>
  <:body>
    <div id="keyboard-shortcuts-help">
      <div class="keyboard-shortcuts-help__container">
        <span tabindex="0"></span>
        {{! A11Y, allows keyboard users to scroll modal body }}
        {{#each-in this.shortcuts as |category shortcutCategory|}}
          <section
            class="shortcut-category span-{{shortcutCategory.count}}
              shortcut-category-{{category}}"
          >
            <h4>{{i18n
                (concat "keyboard_shortcuts_help." category ".title")
              }}</h4>
            <ul>
              {{#each-in shortcutCategory.shortcuts as |name shortcut|}}
                <li>{{html-safe shortcut}}</li>
              {{/each-in}}
            </ul>
          </section>
        {{/each-in}}
      </div>
    </div>
  </:body>
</DModal>
*/
{
  "id": "zcdyzzd9",
  "block": "[[[8,[39,0],[[24,0,\"keyboard-shortcuts-modal -max\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"keyboard_shortcuts_help.title\"],null],[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[14,1,\"keyboard-shortcuts-help\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"keyboard-shortcuts-help__container\"],[12],[1,\"\\n        \"],[10,1],[14,\"tabindex\",\"0\"],[12],[13],[1,\"\\n\"],[42,[28,[37,3],[[30,0,[\"shortcuts\"]]],null],null,[[[1,\"          \"],[10,\"section\"],[15,0,[29,[\"shortcut-category span-\",[30,2,[\"count\"]],\"\\n              shortcut-category-\",[30,3]]]],[12],[1,\"\\n            \"],[10,\"h4\"],[12],[1,[28,[35,1],[[28,[37,4],[\"keyboard_shortcuts_help.\",[30,3],\".title\"],null]],null]],[13],[1,\"\\n            \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[30,2,[\"shortcuts\"]]],null],null,[[[1,\"                \"],[10,\"li\"],[12],[1,[28,[35,5],[[30,4]],null]],[13],[1,\"\\n\"]],[4,5]],null],[1,\"            \"],[13],[1,\"\\n          \"],[13],[1,\"\\n\"]],[2,3]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"shortcutCategory\",\"category\",\"shortcut\",\"name\"],false,[\"d-modal\",\"i18n\",\"each\",\"-each-in\",\"concat\",\"html-safe\"]]",
  "moduleName": "discourse/components/modal/keyboard-shortcuts-help.hbs",
  "isStrictMode": false
});