import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.showInput}}
  {{yield}}
{{else}}
  <a href>
    {{#if this.key}}
      {{i18n this.key}}
    {{/if}}
    {{#if this.icon}}
      {{d-icon this.icon}}
    {{/if}}
  </a>
{{/if}}
*/
{
  "id": "O5u4qHpi",
  "block": "[[[41,[30,0,[\"showInput\"]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,3],[14,6,\"\"],[12],[1,\"\\n\"],[41,[30,0,[\"key\"]],[[[1,\"      \"],[1,[28,[35,2],[[30,0,[\"key\"]]],null]],[1,\"\\n\"]],[]],null],[41,[30,0,[\"icon\"]],[[[1,\"      \"],[1,[28,[35,3],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"yield\",\"i18n\",\"d-icon\"]]",
  "moduleName": "discourse/components/link-to-input.hbs",
  "isStrictMode": false
});