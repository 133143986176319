import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
import Controller from "@ember/controller";
import { and } from "@ember/object/computed";
import { service } from "@ember/service";
import { underscore } from "@ember/string";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { NotificationLevels } from "discourse/lib/notification-levels";
import DiscourseURL from "discourse/lib/url";
import Category from "discourse/models/category";
import PermissionType from "discourse/models/permission-type";
import discourseComputed, { on } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend((_dec = on("init"), _dec2 = discourseComputed("saving", "model.name", "model.color", "deleting"), _dec3 = discourseComputed("saving", "deleting"), _dec4 = discourseComputed("name"), _dec5 = discourseComputed("saving", "model.id"), _dec6 = discourseComputed("model.id", "model.name"), _dec7 = discourseComputed("selectedTab"), (_obj = {
  dialog: service(),
  site: service(),
  router: service(),
  selectedTab: "general",
  saving: false,
  deleting: false,
  panels: null,
  showTooltip: false,
  createdCategory: false,
  expandedMenu: false,
  parentParams: null,
  showDeleteReason: and("showTooltip", "model.cannot_delete_reason"),
  _initPanels() {
    this.setProperties({
      panels: [],
      validators: []
    });
  },
  disabled(saving, name, color, deleting) {
    if (saving || deleting) {
      return true;
    }
    if (!name) {
      return true;
    }
    if (!color) {
      return true;
    }
    return false;
  },
  deleteDisabled(saving, deleting) {
    return deleting || saving || false;
  },
  categoryName(name) {
    name = name || "";
    return name.trim().length > 0 ? name : I18n.t("preview");
  },
  saveLabel(saving, id) {
    if (saving) {
      return "saving";
    }
    return id ? "category.save" : "category.create";
  },
  title(id, name) {
    return id ? I18n.t("category.edit_dialog_title", {
      categoryName: name
    }) : I18n.t("category.create");
  },
  selectedTabTitle(tab) {
    return I18n.t(`category.${underscore(tab)}`);
  },
  actions: {
    registerValidator(validator) {
      this.validators.push(validator);
    },
    saveCategory() {
      if (this.validators.some(validator => validator())) {
        return;
      }
      this.set("saving", true);
      this.model.save().then(result => {
        if (!this.model.id) {
          this.model.setProperties({
            slug: result.category.slug,
            id: result.category.id,
            can_edit: result.category.can_edit,
            permission: PermissionType.FULL,
            notification_level: NotificationLevels.REGULAR
          });
          this.site.updateCategory(this.model);
          this.router.transitionTo("editCategory", Category.slugFor(this.model));
        }
      }).catch(error => {
        popupAjaxError(error);
        this.model.set("parent_category_id", undefined);
      }).finally(() => {
        this.set("saving", false);
      });
    },
    deleteCategory() {
      this.set("deleting", true);
      this.dialog.yesNoConfirm({
        message: I18n.t("category.delete_confirm"),
        didConfirm: () => {
          this.model.destroy().then(() => {
            this.router.transitionTo("discovery.categories");
          }).catch(() => {
            this.displayErrors([I18n.t("category.delete_error")]);
          }).finally(() => {
            this.set("deleting", false);
          });
        },
        didCancel: () => this.set("deleting", false)
      });
    },
    toggleDeleteTooltip() {
      this.toggleProperty("showTooltip");
    },
    goBack() {
      DiscourseURL.routeTo(this.model.url);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "_initPanels", [_dec], Object.getOwnPropertyDescriptor(_obj, "_initPanels"), _obj), _applyDecoratedDescriptor(_obj, "disabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "disabled"), _obj), _applyDecoratedDescriptor(_obj, "deleteDisabled", [_dec3], Object.getOwnPropertyDescriptor(_obj, "deleteDisabled"), _obj), _applyDecoratedDescriptor(_obj, "categoryName", [_dec4], Object.getOwnPropertyDescriptor(_obj, "categoryName"), _obj), _applyDecoratedDescriptor(_obj, "saveLabel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "saveLabel"), _obj), _applyDecoratedDescriptor(_obj, "title", [_dec6], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "selectedTabTitle", [_dec7], Object.getOwnPropertyDescriptor(_obj, "selectedTabTitle"), _obj)), _obj)));