import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _class;
import { computed } from "@ember/object";
import RestModel from "discourse/models/rest";
import { getAbsoluteURL } from "discourse-common/lib/get-url";
let PublishedPage = (_dec = computed("slug"), (_class = class PublishedPage extends RestModel {
  get url() {
    return getAbsoluteURL(`/pub/${this.slug}`);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "url", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "url"), _class.prototype)), _class));
export { PublishedPage as default };