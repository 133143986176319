import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
import { computed } from "@ember/object";
import I18n from "discourse-i18n";
let TrustLevel = (_class = class TrustLevel {
  constructor(id, key) {
    this.id = id;
    this._key = key;
  }
  get name() {
    return I18n.t(`trust_levels.names.${this._key}`);
  }
  get detailedName() {
    return I18n.t("trust_levels.detailed_name", {
      level: this.id,
      name: this.name
    });
  }
}, (_applyDecoratedDescriptor(_class.prototype, "name", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "name"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "detailedName", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "detailedName"), _class.prototype)), _class);
export { TrustLevel as default };