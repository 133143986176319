import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import Service from "@ember/service";
import KeyValueStore from "discourse/lib/key-value-store";
export default class SearchPreferencesManager extends Service {
  constructor() {
    super(...arguments);
    _defineProperty(this, "STORE_NAMESPACE", "discourse_search_preferences_manager_");
    _defineProperty(this, "store", new KeyValueStore(this.STORE_NAMESPACE));
  }
  get sortOrder() {
    return this.store.getObject("sortOrder");
  }
  set sortOrder(value) {
    this.store.setObject({
      key: "sortOrder",
      value
    });
  }
}