import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _class2, _descriptor;
import { tracked } from "@glimmer/tracking";
import { cancel } from "@ember/runloop";
import Service from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import discourseDebounce from "discourse-common/lib/debounce";
import { bind } from "discourse-common/utils/decorators";
const CONNECTIVITY_ERROR_CLASS = "network-disconnected";
let NetworkConnectivity = disableImplicitInjections(_class = (_class2 = class NetworkConnectivity extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "connected", _descriptor, this);
    window.addEventListener("offline", () => {
      this.setConnectivity(false);
      this.startTimerToCheckNavigator();
    });
    window.addEventListener("online", this.pingServerAndSetConnectivity);
    window.addEventListener("visibilitychange", this.onFocus);
    if (!navigator.onLine) {
      this.pingServerAndSetConnectivity();
    }
  }
  onFocus() {
    if (!this.connected && document.visibilityState === "visible") {
      this.pingServerAndSetConnectivity();
    }
  }
  async pingServerAndSetConnectivity() {
    try {
      let response = await ajax("/srv/status", {
        dataType: "text"
      });
      if (response === "ok") {
        cancel(this._timer);
        this.setConnectivity(true);
      } else {
        throw "disconnected";
      }
    } catch {
      // Either the request didn't go out at all or the response wasn't "ok". Both are failures.
      // Start the timer to check every second if `navigator.onLine` comes back online in the event that
      // we miss the `online` event firing
      this.startTimerToCheckNavigator();
    }
  }
  startTimerToCheckNavigator() {
    cancel(this._timer);
    this._timer = discourseDebounce(this, this.checkNavigatorOnline, 1000);
  }
  checkNavigatorOnline() {
    if (navigator.onLine) {
      this.pingServerAndSetConnectivity();
    } else {
      this.startTimerToCheckNavigator();
    }
  }
  setConnectivity(connected) {
    this.connected = connected;
    document.documentElement.classList.toggle(CONNECTIVITY_ERROR_CLASS, !connected);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "connected", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _applyDecoratedDescriptor(_class2.prototype, "onFocus", [bind], Object.getOwnPropertyDescriptor(_class2.prototype, "onFocus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "pingServerAndSetConnectivity", [bind], Object.getOwnPropertyDescriptor(_class2.prototype, "pingServerAndSetConnectivity"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "startTimerToCheckNavigator", [bind], Object.getOwnPropertyDescriptor(_class2.prototype, "startTimerToCheckNavigator"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "checkNavigatorOnline", [bind], Object.getOwnPropertyDescriptor(_class2.prototype, "checkNavigatorOnline"), _class2.prototype)), _class2)) || _class;
export { NetworkConnectivity as default };