import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _class, _descriptor, _descriptor2;
import { equal, not } from "@ember/object/computed";
import RestModel from "discourse/models/rest";
export const MAX_MESSAGE_LENGTH = 500;
let PostActionType = (_dec = not("is_custom_flag"), _dec2 = equal("name_key", "illegal"), (_class = class PostActionType extends RestModel {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "notCustomFlag", _descriptor, this);
    _initializerDefineProperty(this, "isIllegal", _descriptor2, this);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notCustomFlag", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isIllegal", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class));
export { PostActionType as default };