import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './create-invite-uploader.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import UppyUploadMixin from "discourse/mixins/uppy-upload";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./create-invite-uploader.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(UppyUploadMixin, (_dec = discourseComputed("filesAwaitingUpload", "uploading"), (_obj = {
  id: "create-invite-uploader",
  tagName: "div",
  type: "csv",
  autoStartUploads: false,
  uploadUrl: "/invites/upload_csv",
  preventDirectS3Uploads: true,
  fileInputSelector: "#csv-file",
  validateUploadedFilesOptions() {
    return {
      bypassNewUserRestriction: true,
      csvOnly: true
    };
  },
  submitDisabled(filesAwaitingUpload, uploading) {
    return !filesAwaitingUpload || uploading;
  },
  uploadDone() {
    this.set("uploaded", true);
  },
  startUpload() {
    this._startUpload();
  },
  setElement(element) {
    this.set("fileInputEl", element);
    this._initialize();
  }
}, (_applyDecoratedDescriptor(_obj, "submitDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "submitDisabled"), _obj), _applyDecoratedDescriptor(_obj, "startUpload", [action], Object.getOwnPropertyDescriptor(_obj, "startUpload"), _obj), _applyDecoratedDescriptor(_obj, "setElement", [action], Object.getOwnPropertyDescriptor(_obj, "setElement"), _obj)), _obj))));