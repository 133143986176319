import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <CategoriesOnly @categories={{this.categories}} @showTopics="true" />
*/
{
  "id": "Ee+adfCO",
  "block": "[[[8,[39,0],null,[[\"@categories\",\"@showTopics\"],[[30,0,[\"categories\"]],\"true\"]],null]],[],false,[\"categories-only\"]]",
  "moduleName": "discourse/components/categories-with-featured-topics.hbs",
  "isStrictMode": false
});