import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
import { tracked } from "@glimmer/tracking";
import { INBOX, NEW, UNREAD } from "discourse/components/sidebar/user/messages-section";
let MessageSectionLink = (_class = class MessageSectionLink {
  constructor(_ref) {
    let {
      group,
      currentUser,
      type,
      pmTopicTrackingState
    } = _ref;
    _initializerDefineProperty(this, "shouldDisplay", _descriptor, this);
    _initializerDefineProperty(this, "count", _descriptor2, this);
    this.group = group;
    this.currentUser = currentUser;
    this.type = type;
    this.pmTopicTrackingState = pmTopicTrackingState;
  }
  refreshCount() {
    this._refreshCount();
  }
  _refreshCount() {
    if (this.shouldDisplay && this._shouldTrack) {
      this.count = this.pmTopicTrackingState.lookupCount(this.type, {
        inboxFilter: this.group ? "group" : "user",
        groupName: this.group?.name
      });
    }
  }
  set setDisplayState(value) {
    const changed = this.shouldDisplay !== value;
    this.shouldDisplay = value;
    if (changed) {
      this._refreshCount();
    }
  }
  get inboxFilter() {
    throw "not implemented";
  }
  expand() {
    if (this._isInbox) {
      return;
    }
    this.setDisplayState = true;
  }
  collapse() {
    if (this._isInbox) {
      return;
    }
    this.setDisplayState = false;
  }

  // eslint-disable-next-line no-unused-vars
  pageChanged(_ref2) {
    let {
      currentRouteName,
      currentRouteParams,
      privateMessageTopic
    } = _ref2;
    throw "not implemented";
  }
  get _isInbox() {
    return this.type === INBOX;
  }
  get _shouldTrack() {
    return this.type === NEW || this.type === UNREAD;
  }
  get prefixType() {
    if (this._isInbox) {
      return "icon";
    }
  }
  get prefixValue() {
    if (this._isInbox) {
      return "inbox";
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "shouldDisplay", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this._isInbox;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "count", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
})), _class);
export { MessageSectionLink as default };