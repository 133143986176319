import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './delete-topic-confirm.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import I18n from "discourse-i18n";

// Modal that displays confirmation text when user deletes a topic
// The modal will display only if the topic exceeds a certain amount of views
import TEMPLATE from "./delete-topic-confirm.hbs";
import { setComponentTemplate } from "@ember/component";
let DeleteTopicConfirm = (_class = class DeleteTopicConfirm extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    _initializerDefineProperty(this, "deletingTopic", _descriptor3, this);
    _initializerDefineProperty(this, "flash", _descriptor4, this);
  }
  async deleteTopic() {
    try {
      this.deletingTopic = true;
      await this.args.model.topic.destroy(this.currentUser);
      this.args.closeModal();
    } catch {
      this.flash = I18n.t("post.controls.delete_topic_error");
      this.deletingTopic = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "deletingTopic", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "deleteTopic", [action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteTopic"), _class.prototype)), _class);
export { DeleteTopicConfirm as default };
setComponentTemplate(TEMPLATE, DeleteTopicConfirm);