import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
import $ from "jquery";
import TextField from "discourse/components/text-field";
import { applySearchAutocomplete } from "discourse/lib/search";
import discourseComputed, { on } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default TextField.extend((_dec = discourseComputed("searchService.searchContextEnabled"), _dec2 = on("didInsertElement"), (_obj = {
  autocomplete: "off",
  placeholder(searchContextEnabled) {
    return searchContextEnabled ? "" : I18n.t("search.full_page_title");
  },
  becomeFocused() {
    const $searchInput = $(this.element);
    applySearchAutocomplete($searchInput, this.siteSettings);
    if (!this.hasAutofocus) {
      return;
    }
    // iOS is crazy, without this we will not be
    // at the top of the page
    $(window).scrollTop(0);
    $searchInput.focus();
  }
}, (_applyDecoratedDescriptor(_obj, "placeholder", [_dec], Object.getOwnPropertyDescriptor(_obj, "placeholder"), _obj), _applyDecoratedDescriptor(_obj, "becomeFocused", [_dec2], Object.getOwnPropertyDescriptor(_obj, "becomeFocused"), _obj)), _obj)));