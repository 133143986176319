import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "pause_notifications.title"}}
  @closeModal={{@closeModal}}
  @flash={{this.flash}}
  class="do-not-disturb-modal"
>
  <:body>
    <TapTileGrid as |grid|>
      <TapTile
        @tileId="30"
        @activeTile={{grid.activeTile}}
        @onChange={{this.saveDuration}}
        class="do-not-disturb-tile"
      >
        {{i18n "pause_notifications.options.half_hour"}}
      </TapTile>
      <TapTile
        @tileId="60"
        @activeTile={{grid.activeTile}}
        @onChange={{this.saveDuration}}
        class="do-not-disturb-tile"
      >
        {{i18n "pause_notifications.options.one_hour"}}
      </TapTile>
      <TapTile
        @tileId="120"
        @activeTile={{grid.activeTile}}
        @onChange={{this.saveDuration}}
        class="do-not-disturb-tile"
      >
        {{i18n "pause_notifications.options.two_hours"}}
      </TapTile>
      <TapTile
        @tileId="tomorrow"
        @activeTile={{grid.activeTile}}
        @onChange={{this.saveDuration}}
        class="do-not-disturb-tile"
      >
        {{i18n "pause_notifications.options.tomorrow"}}
      </TapTile>
    </TapTileGrid>

    <DButton
      @action={{this.navigateToNotificationSchedule}}
      @label="pause_notifications.set_schedule"
    />
  </:body>
</DModal>
*/
{
  "id": "NQMmRMMW",
  "block": "[[[8,[39,0],[[24,0,\"do-not-disturb-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[[28,[37,1],[\"pause_notifications.title\"],null],[30,1],[30,0,[\"flash\"]]]],[[\"body\"],[[[[1,\"\\n    \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],[[24,0,\"do-not-disturb-tile\"]],[[\"@tileId\",\"@activeTile\",\"@onChange\"],[\"30\",[30,2,[\"activeTile\"]],[30,0,[\"saveDuration\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"pause_notifications.options.half_hour\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,3],[[24,0,\"do-not-disturb-tile\"]],[[\"@tileId\",\"@activeTile\",\"@onChange\"],[\"60\",[30,2,[\"activeTile\"]],[30,0,[\"saveDuration\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"pause_notifications.options.one_hour\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,3],[[24,0,\"do-not-disturb-tile\"]],[[\"@tileId\",\"@activeTile\",\"@onChange\"],[\"120\",[30,2,[\"activeTile\"]],[30,0,[\"saveDuration\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"pause_notifications.options.two_hours\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n      \"],[8,[39,3],[[24,0,\"do-not-disturb-tile\"]],[[\"@tileId\",\"@activeTile\",\"@onChange\"],[\"tomorrow\",[30,2,[\"activeTile\"]],[30,0,[\"saveDuration\"]]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,1],[\"pause_notifications.options.tomorrow\"],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"]],[2]]]]],[1,\"\\n\\n    \"],[8,[39,4],null,[[\"@action\",\"@label\"],[[30,0,[\"navigateToNotificationSchedule\"]],\"pause_notifications.set_schedule\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"grid\"],false,[\"d-modal\",\"i18n\",\"tap-tile-grid\",\"tap-tile\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/do-not-disturb.hbs",
  "isStrictMode": false
});