import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
import { tracked } from "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import Controller, { inject as controller } from "@ember/controller";
import { action } from "@ember/object";
import { alias, and, equal, readOnly } from "@ember/object/computed";
import { service } from "@ember/service";
import DiscourseURL from "discourse/lib/url";
import I18n from "discourse-i18n";
import esc from "../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
const customUserNavMessagesDropdownRows = [];
export function registerCustomUserNavMessagesDropdownRow(routeName, name, icon) {
  customUserNavMessagesDropdownRows.push({
    routeName,
    name,
    icon
  });
}
export function resetCustomUserNavMessagesDropdownRows() {
  customUserNavMessagesDropdownRows.length = 0;
}
let _class = (_dec = alias("group.name"), _dec2 = and("user.viewingSelf", "currentUser.can_send_private_messages"), _dec3 = equal("currentParentRouteName", "userPrivateMessages.group"), _dec4 = readOnly("user.viewingSelf"), _dec5 = readOnly("router.currentRoute.parent.name"), _dec6 = readOnly("site.can_tag_pms"), (_class2 = class _class2 extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "user", _descriptor2, this);
    _initializerDefineProperty(this, "userTopicsList", _descriptor3, this);
    _initializerDefineProperty(this, "group", _descriptor4, this);
    _initializerDefineProperty(this, "tagId", _descriptor5, this);
    _initializerDefineProperty(this, "groupFilter", _descriptor6, this);
    _initializerDefineProperty(this, "showNewPM", _descriptor7, this);
    _initializerDefineProperty(this, "isGroup", _descriptor8, this);
    _initializerDefineProperty(this, "viewingSelf", _descriptor9, this);
    _initializerDefineProperty(this, "currentParentRouteName", _descriptor10, this);
    _initializerDefineProperty(this, "pmTaggingEnabled", _descriptor11, this);
  }
  get bulkSelectHelper() {
    this.userTopicsList.bulkSelectHelper;
  }
  get messagesDropdownValue() {
    let value;
    const currentURL = this.router.currentURL.toLowerCase();
    for (let i = this.messagesDropdownContent.length - 1; i >= 0; i--) {
      const row = this.messagesDropdownContent[i];
      if (currentURL.includes(row.id.toLowerCase().replace(this.router.rootURL, "/"))) {
        value = row.id;
        break;
      }
    }
    return value;
  }
  get messagesDropdownContent() {
    const usernameLower = this.model.username_lower;
    const content = [{
      id: this.router.urlFor("userPrivateMessages.user", usernameLower),
      name: I18n.t("user.messages.inbox")
    }];
    this.model.groupsWithMessages.forEach(group => {
      content.push({
        id: this.router.urlFor("userPrivateMessages.group", usernameLower, group.name),
        name: group.name,
        icon: "inbox"
      });
    });
    if (this.pmTaggingEnabled) {
      content.push({
        id: this.router.urlFor("userPrivateMessages.tags", usernameLower),
        name: I18n.t("user.messages.tags"),
        icon: "tags"
      });
    }
    customUserNavMessagesDropdownRows.forEach(row => {
      content.push({
        id: this.router.urlFor(row.routeName, usernameLower),
        name: row.name,
        icon: row.icon
      });
    });
    return content;
  }
  onMessagesDropdownChange(item) {
    return DiscourseURL.routeTo(item);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "user", [controller], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "userTopicsList", [controller], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "group", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "tagId", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "groupFilter", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "showNewPM", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class2.prototype, "isGroup", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class2.prototype, "viewingSelf", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class2.prototype, "currentParentRouteName", [_dec5], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class2.prototype, "pmTaggingEnabled", [_dec6], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "messagesDropdownContent", [cached], Object.getOwnPropertyDescriptor(_class2.prototype, "messagesDropdownContent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onMessagesDropdownChange", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "onMessagesDropdownChange"), _class2.prototype)), _class2));
export { _class as default };