import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
/* import __COLOCATED_TEMPLATE__ from './group-post.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { propertyEqual } from "discourse/lib/computed";
import { prioritizeNameInUx } from "discourse/lib/settings";
import { userPath } from "discourse/lib/url";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./group-post.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("post.url"), _dec2 = discourseComputed("post.user"), _dec3 = discourseComputed("post.user"), _dec4 = discourseComputed("post.user.username"), _dec5 = discourseComputed("post.title", "post.post_number"), (_obj = {
  classNameBindings: [":user-stream-item", ":item", "moderatorAction", "primaryGroup"],
  postUrl(url) {
    return getURL(url);
  },
  moderatorAction: propertyEqual("post.post_type", "site.post_types.moderator_action"),
  name(postUser) {
    if (prioritizeNameInUx(postUser.name)) {
      return postUser.name;
    }
    return postUser.username;
  },
  primaryGroup(postUser) {
    if (postUser.primary_group_name) {
      return `group-${postUser.primary_group_name}`;
    }
  },
  userUrl(username) {
    return userPath(username.toLowerCase());
  },
  titleAriaLabel(title, postNumber) {
    return I18n.t("groups.aria_post_number", {
      postNumber,
      title
    });
  }
}, (_applyDecoratedDescriptor(_obj, "postUrl", [_dec], Object.getOwnPropertyDescriptor(_obj, "postUrl"), _obj), _applyDecoratedDescriptor(_obj, "name", [_dec2], Object.getOwnPropertyDescriptor(_obj, "name"), _obj), _applyDecoratedDescriptor(_obj, "primaryGroup", [_dec3], Object.getOwnPropertyDescriptor(_obj, "primaryGroup"), _obj), _applyDecoratedDescriptor(_obj, "userUrl", [_dec4], Object.getOwnPropertyDescriptor(_obj, "userUrl"), _obj), _applyDecoratedDescriptor(_obj, "titleAriaLabel", [_dec5], Object.getOwnPropertyDescriptor(_obj, "titleAriaLabel"), _obj)), _obj))));