import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{i18n "search.post_format" @result}}
<SearchMenu::Results::Blurb @result={{@result}} />
*/
{
  "id": "b0ku8qNe",
  "block": "[[[1,[28,[35,0],[\"search.post_format\",[30,1]],null]],[1,\"\\n\"],[8,[39,1],null,[[\"@result\"],[[30,1]]],null]],[\"@result\"],false,[\"i18n\",\"search-menu/results/blurb\"]]",
  "moduleName": "discourse/components/search-menu/results/type/post.hbs",
  "isStrictMode": false
});