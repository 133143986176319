import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  class={{concat-class "char-counter" (if (gt @value.length @max) "exceeded")}}
  ...attributes
>
  {{yield}}
  <small class="char-counter__ratio">
    {{@value.length}}/{{@max}}
  </small>
  <span aria-live="polite" class="sr-only">
    {{if (gt @value.length @max) (i18n "char_counter.exceeded")}}
  </span>
</div>
*/
{
  "id": "V+rYmbch",
  "block": "[[[11,0],[16,0,[28,[37,0],[\"char-counter\",[52,[28,[37,2],[[30,1,[\"length\"]],[30,2]],null],\"exceeded\"]],null]],[17,3],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n  \"],[10,\"small\"],[14,0,\"char-counter__ratio\"],[12],[1,\"\\n    \"],[1,[30,1,[\"length\"]]],[1,\"/\"],[1,[30,2]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,\"aria-live\",\"polite\"],[14,0,\"sr-only\"],[12],[1,\"\\n    \"],[1,[52,[28,[37,2],[[30,1,[\"length\"]],[30,2]],null],[28,[37,4],[\"char_counter.exceeded\"],null]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@value\",\"@max\",\"&attrs\",\"&default\"],false,[\"concat-class\",\"if\",\"gt\",\"yield\",\"i18n\"]]",
  "moduleName": "discourse/components/char-counter.hbs",
  "isStrictMode": false
});