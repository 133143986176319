import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
import Controller from "@ember/controller";
import { equal } from "@ember/object/computed";
import { popupAjaxError } from "discourse/lib/ajax-error";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
const EMAIL_LEVELS = {
  ALWAYS: 0,
  ONLY_WHEN_AWAY: 1,
  NEVER: 2
};
export default Controller.extend((_dec = discourseComputed(), _dec2 = discourseComputed(), _dec3 = discourseComputed(), (_obj = {
  subpageTitle: I18n.t("user.preferences_nav.emails"),
  emailMessagesLevelAway: equal("model.user_option.email_messages_level", EMAIL_LEVELS.ONLY_WHEN_AWAY),
  emailLevelAway: equal("model.user_option.email_level", EMAIL_LEVELS.ONLY_WHEN_AWAY),
  init() {
    this._super(...arguments);
    this.saveAttrNames = ["email_level", "email_messages_level", "mailing_list_mode", "mailing_list_mode_frequency", "email_digests", "email_in_reply_to", "email_previous_replies", "digest_after_minutes", "include_tl0_in_digests"];
    this.previousRepliesOptions = [{
      name: I18n.t("user.email_previous_replies.always"),
      value: 0
    }, {
      name: I18n.t("user.email_previous_replies.unless_emailed"),
      value: 1
    }, {
      name: I18n.t("user.email_previous_replies.never"),
      value: 2
    }];
    this.emailLevelOptions = [{
      name: I18n.t("user.email_level.always"),
      value: EMAIL_LEVELS.ALWAYS
    }, {
      name: I18n.t("user.email_level.only_when_away"),
      value: EMAIL_LEVELS.ONLY_WHEN_AWAY
    }, {
      name: I18n.t("user.email_level.never"),
      value: EMAIL_LEVELS.NEVER
    }];
    this.digestFrequencies = [{
      name: I18n.t("user.email_digests.every_30_minutes"),
      value: 30
    }, {
      name: I18n.t("user.email_digests.every_hour"),
      value: 60
    }, {
      name: I18n.t("user.email_digests.daily"),
      value: 1440
    }, {
      name: I18n.t("user.email_digests.weekly"),
      value: 10080
    }, {
      name: I18n.t("user.email_digests.every_month"),
      value: 43200
    }, {
      name: I18n.t("user.email_digests.every_six_months"),
      value: 259200
    }];
  },
  frequencyEstimate() {
    let estimate = this.get("model.mailing_list_posts_per_day");
    if (!estimate || estimate < 2) {
      return I18n.t("user.mailing_list_mode.few_per_day");
    } else {
      return I18n.t("user.mailing_list_mode.many_per_day", {
        dailyEmailEstimate: estimate
      });
    }
  },
  mailingListModeOptions() {
    return [{
      name: this.frequencyEstimate,
      value: 1
    }, {
      name: I18n.t("user.mailing_list_mode.individual_no_echo"),
      value: 2
    }];
  },
  emailFrequencyInstructions() {
    return this.siteSettings.email_time_window_mins ? I18n.t("user.email.frequency", {
      count: this.siteSettings.email_time_window_mins
    }) : null;
  },
  actions: {
    save() {
      this.set("saved", false);
      return this.model.save(this.saveAttrNames).then(() => {
        this.set("saved", true);
      }).catch(popupAjaxError);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "frequencyEstimate", [_dec], Object.getOwnPropertyDescriptor(_obj, "frequencyEstimate"), _obj), _applyDecoratedDescriptor(_obj, "mailingListModeOptions", [_dec2], Object.getOwnPropertyDescriptor(_obj, "mailingListModeOptions"), _obj), _applyDecoratedDescriptor(_obj, "emailFrequencyInstructions", [_dec3], Object.getOwnPropertyDescriptor(_obj, "emailFrequencyInstructions"), _obj)), _obj)));