import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "user.second_factor.edit_title"}}
  @closeModal={{@closeModal}}
>
  <:body>
    <div class="input-group">
      <label for="authenticator-name">{{i18n
          "user.second_factor.edit_description"
        }}</label>
      <Input
        name="authenticator-name"
        maxlength={{this.maxSecondFactorNameLength}}
        @type="text"
        @value={{@model.secondFactor.name}}
      />
    </div>
  </:body>
  <:footer>
    <DButton
      @action={{this.editSecondFactor}}
      class="btn-primary"
      @label="user.second_factor.save"
    />
  </:footer>
</DModal>
*/
{
  "id": "L7zSO/yj",
  "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"user.second_factor.edit_title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"authenticator-name\"],[12],[1,[28,[35,1],[\"user.second_factor.edit_description\"],null]],[13],[1,\"\\n      \"],[8,[39,2],[[24,3,\"authenticator-name\"],[16,\"maxlength\",[30,0,[\"maxSecondFactorNameLength\"]]]],[[\"@type\",\"@value\"],[\"text\",[30,2,[\"secondFactor\",\"name\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"editSecondFactor\"]],\"user.second_factor.save\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"input\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/second-factor-edit.hbs",
  "isStrictMode": false
});