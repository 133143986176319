import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <label for="choose-message-title">{{i18n "choose_message.title.search"}}</label>

<TextField
  @value={{this.messageTitle}}
  @placeholderKey="choose_message.title.placeholder"
  @id="choose-message-title"
/>

{{#if this.loading}}
  <p>{{i18n "loading"}}</p>
{{else}}
  {{#if this.noResults}}
    <p>{{i18n "choose_message.none_found"}}</p>
  {{else}}
    {{#each this.messages as |m|}}
      <div class="controls existing-message">
        <label class="radio">
          <input
            id="choose-message-{{m.id}}"
            {{on "click" (fn this.chooseMessage m)}}
            type="radio"
            name="choose_message_id"
          />
          <span class="message-title">
            {{m.title}}
          </span>
        </label>
      </div>
    {{/each}}
  {{/if}}
{{/if}}
*/
{
  "id": "vpQ4YaL5",
  "block": "[[[10,\"label\"],[14,\"for\",\"choose-message-title\"],[12],[1,[28,[35,0],[\"choose_message.title.search\"],null]],[13],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@value\",\"@placeholderKey\",\"@id\"],[[30,0,[\"messageTitle\"]],\"choose_message.title.placeholder\",\"choose-message-title\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"loading\"]],[[[1,\"  \"],[10,2],[12],[1,[28,[35,0],[\"loading\"],null]],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"noResults\"]],[[[1,\"    \"],[10,2],[12],[1,[28,[35,0],[\"choose_message.none_found\"],null]],[13],[1,\"\\n\"]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"messages\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"controls existing-message\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"radio\"],[12],[1,\"\\n          \"],[11,\"input\"],[16,1,[29,[\"choose-message-\",[30,1,[\"id\"]]]]],[24,3,\"choose_message_id\"],[24,4,\"radio\"],[4,[38,5],[\"click\",[28,[37,6],[[30,0,[\"chooseMessage\"]],[30,1]],null]],null],[12],[13],[1,\"\\n          \"],[10,1],[14,0,\"message-title\"],[12],[1,\"\\n            \"],[1,[30,1,[\"title\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null]],[]]]],[]]]],[\"m\"],false,[\"i18n\",\"text-field\",\"if\",\"each\",\"-track-array\",\"on\",\"fn\"]]",
  "moduleName": "discourse/components/choose-message.hbs",
  "isStrictMode": false
});