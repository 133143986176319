import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <a
  href
  {{on "click" this.select}}
  class={{if this.active "active"}}
>{{this.title}}</a>
*/
{
  "id": "KoewHw43",
  "block": "[[[11,3],[24,6,\"\"],[16,0,[52,[30,0,[\"active\"]],\"active\"]],[4,[38,1],[\"click\",[30,0,[\"select\"]]],null],[12],[1,[30,0,[\"title\"]]],[13]],[],false,[\"if\",\"on\"]]",
  "moduleName": "discourse/components/edit-category-tab.hbs",
  "isStrictMode": false
});