import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if @items}}
  <ul>
    {{#each @items as |item|}}
      {{yield item}}
    {{/each}}
  </ul>
  {{#if this.hasMore}}
    <p>
      <LinkTo
        @route={{concat "userActivity." @type}}
        @model={{@user}}
        class="more"
      >
        {{i18n (concat "user.summary.more_" @type)}}
      </LinkTo>
    </p>
  {{/if}}
{{else}}
  <p>{{i18n (concat "user.summary.no_" @type)}}</p>
{{/if}}
*/
{
  "id": "0A+RIA2U",
  "block": "[[[41,[30,1],[[[1,\"  \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,1]],null]],null],null,[[[1,\"      \"],[18,5,[[30,2]]],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,0,[\"hasMore\"]],[[[1,\"    \"],[10,2],[12],[1,\"\\n      \"],[8,[39,4],[[24,0,\"more\"]],[[\"@route\",\"@model\"],[[28,[37,5],[\"userActivity.\",[30,3]],null],[30,4]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[35,6],[[28,[37,5],[\"user.summary.more_\",[30,3]],null]],null]],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]],[[[1,\"  \"],[10,2],[12],[1,[28,[35,6],[[28,[37,5],[\"user.summary.no_\",[30,3]],null]],null]],[13],[1,\"\\n\"]],[]]]],[\"@items\",\"item\",\"@type\",\"@user\",\"&default\"],false,[\"if\",\"each\",\"-track-array\",\"yield\",\"link-to\",\"concat\",\"i18n\"]]",
  "moduleName": "discourse/components/user-summary-topics-list.hbs",
  "isStrictMode": false
});