import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{this.tagRecord.id}}
*/
{
  "id": "MDll+Ehk",
  "block": "[[[1,[30,0,[\"tagRecord\",\"id\"]]]],[],false,[]]",
  "moduleName": "discourse/components/discourse-tag-bound.hbs",
  "isStrictMode": false
});