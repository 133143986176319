import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <tr class="reviewable-score">
  <td class="user">
    <UserLink @user={{this.rs.user}}>
      {{avatar this.rs.user imageSize="tiny"}}
      {{this.rs.user.username}}
    </UserLink>
  </td>

  <td>
    {{format-date this.rs.created_at format="tiny"}}
  </td>

  <td>
    {{d-icon this.rs.score_type.icon}}
    {{this.title}}
  </td>

  {{#if this.showStatus}}
    <td class="reviewed-by">
      {{#if this.rs.reviewed_by}}
        <UserLink @user={{this.rs.reviewed_by}}>
          {{avatar this.rs.reviewed_by imageSize="tiny"}}
          {{this.rs.reviewed_by.username}}
        </UserLink>
      {{else}}
        &mdash;
      {{/if}}
    </td>

    <td>
      {{#if this.rs.reviewed_by}}
        {{format-date this.rs.reviewed_at format="tiny"}}
      {{/if}}
    </td>

    <td>
      {{reviewable-status this.rs.status this.reviewable.type}}
    </td>

  {{else}}
    <td colspan="4"></td>
  {{/if}}
</tr>
*/
{
  "id": "lxHMsd8I",
  "block": "[[[10,\"tr\"],[14,0,\"reviewable-score\"],[12],[1,\"\\n  \"],[10,\"td\"],[14,0,\"user\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@user\"],[[30,0,[\"rs\",\"user\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[1,[28,[35,1],[[30,0,[\"rs\",\"user\"]]],[[\"imageSize\"],[\"tiny\"]]]],[1,\"\\n      \"],[1,[30,0,[\"rs\",\"user\",\"username\"]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"rs\",\"created_at\"]]],[[\"format\"],[\"tiny\"]]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,\"td\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[[30,0,[\"rs\",\"score_type\",\"icon\"]]],null]],[1,\"\\n    \"],[1,[30,0,[\"title\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showStatus\"]],[[[1,\"    \"],[10,\"td\"],[14,0,\"reviewed-by\"],[12],[1,\"\\n\"],[41,[30,0,[\"rs\",\"reviewed_by\"]],[[[1,\"        \"],[8,[39,0],null,[[\"@user\"],[[30,0,[\"rs\",\"reviewed_by\"]]]],[[\"default\"],[[[[1,\"\\n          \"],[1,[28,[35,1],[[30,0,[\"rs\",\"reviewed_by\"]]],[[\"imageSize\"],[\"tiny\"]]]],[1,\"\\n          \"],[1,[30,0,[\"rs\",\"reviewed_by\",\"username\"]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"        —\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n\"],[41,[30,0,[\"rs\",\"reviewed_by\"]],[[[1,\"        \"],[1,[28,[35,2],[[30,0,[\"rs\",\"reviewed_at\"]]],[[\"format\"],[\"tiny\"]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,\"td\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[[30,0,[\"rs\",\"status\"]],[30,0,[\"reviewable\",\"type\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n\"]],[]],[[[1,\"    \"],[10,\"td\"],[14,\"colspan\",\"4\"],[12],[13],[1,\"\\n\"]],[]]],[13]],[],false,[\"user-link\",\"avatar\",\"format-date\",\"d-icon\",\"if\",\"reviewable-status\"]]",
  "moduleName": "discourse/components/reviewable-score.hbs",
  "isStrictMode": false
});