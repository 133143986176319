import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#unless this.hideProgress}}
  {{yield}}
{{/unless}}

{{#if this.showBackButton}}
  <div class="progress-back-container">
    <DButton
      @label="topic.timeline.back"
      @action={{this.goBack}}
      @icon="arrow-down"
      class="btn-primary progress-back"
    />
  </div>
{{/if}}

<nav
  title={{i18n "topic.progress.title"}}
  aria-label={{i18n "topic.progress.title"}}
  class={{if this.hideProgress "hidden"}}
  id="topic-progress"
  style={{html-safe this.progressStyle}}
>
  <div class="nums">
    <h4>{{this.progressPosition}}</h4>
    <span class={{if this.hugeNumberOfPosts "hidden"}}>
      <span>/</span>
      <h4>{{this.postStream.filteredPostsCount}}</h4>
    </span>
  </div>
  <div class="bg"></div>
</nav>

<PluginOutlet @name="after-topic-progress" @connectorTagName="div" />
*/
{
  "id": "I/HtEhcg",
  "block": "[[[41,[51,[30,0,[\"hideProgress\"]]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showBackButton\"]],[[[1,\"  \"],[10,0],[14,0,\"progress-back-container\"],[12],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary progress-back\"]],[[\"@label\",\"@action\",\"@icon\"],[\"topic.timeline.back\",[30,0,[\"goBack\"]],\"arrow-down\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,\"nav\"],[15,\"title\",[28,[37,4],[\"topic.progress.title\"],null]],[15,\"aria-label\",[28,[37,4],[\"topic.progress.title\"],null]],[15,0,[52,[30,0,[\"hideProgress\"]],\"hidden\"]],[14,1,\"topic-progress\"],[15,5,[28,[37,5],[[30,0,[\"progressStyle\"]]],null]],[12],[1,\"\\n  \"],[10,0],[14,0,\"nums\"],[12],[1,\"\\n    \"],[10,\"h4\"],[12],[1,[30,0,[\"progressPosition\"]]],[13],[1,\"\\n    \"],[10,1],[15,0,[52,[30,0,[\"hugeNumberOfPosts\"]],\"hidden\"]],[12],[1,\"\\n      \"],[10,1],[12],[1,\"/\"],[13],[1,\"\\n      \"],[10,\"h4\"],[12],[1,[30,0,[\"postStream\",\"filteredPostsCount\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"bg\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,6],null,[[\"@name\",\"@connectorTagName\"],[\"after-topic-progress\",\"div\"]],null]],[\"&default\"],false,[\"unless\",\"yield\",\"if\",\"d-button\",\"i18n\",\"html-safe\",\"plugin-outlet\"]]",
  "moduleName": "discourse/components/topic-progress.hbs",
  "isStrictMode": false
});