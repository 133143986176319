import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <span class="topic">
  <span class="first-line">
    <TopicStatus @topic={{@result.topic}} @disableActions={{true}} />
    <span class="topic-title" data-topic-id={{@result.topic.id}}>
      {{#if
        (and
          this.siteSettings.use_pg_headlines_for_excerpt
          @result.topic_title_headline
        )
      }}
        <a href={{if @withTopicUrl @result.url}}>
          {{replace-emoji (html-safe @result.topic_title_headline)}}
        </a>
      {{else}}
        <a href={{if @withTopicUrl @result.url}}>
          <SearchMenu::HighlightedSearch @string={{@result.topic.fancyTitle}} />
        </a>
      {{/if}}
      <PluginOutlet
        @name="search-menu-results-topic-title-suffix"
        @outletArgs={{hash topic=@result.topic}}
      />
    </span>
  </span>
  <span class="second-line">
    {{category-link @result.topic.category link=(if @withTopicUrl true false)}}
    {{#if this.siteSettings.tagging_enabled}}
      {{discourse-tags @result.topic tagName="span"}}
    {{/if}}
  </span>
</span>
<SearchMenu::Results::Blurb @result={{@result}} />
*/
{
  "id": "Xnn+fHpm",
  "block": "[[[10,1],[14,0,\"topic\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"first-line\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@topic\",\"@disableActions\"],[[30,1,[\"topic\"]],true]],null],[1,\"\\n    \"],[10,1],[14,0,\"topic-title\"],[15,\"data-topic-id\",[30,1,[\"topic\",\"id\"]]],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,0,[\"siteSettings\",\"use_pg_headlines_for_excerpt\"]],[30,1,[\"topic_title_headline\"]]],null],[[[1,\"        \"],[10,3],[15,6,[52,[30,2],[30,1,[\"url\"]]]],[12],[1,\"\\n          \"],[1,[28,[35,3],[[28,[37,4],[[30,1,[\"topic_title_headline\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,3],[15,6,[52,[30,2],[30,1,[\"url\"]]]],[12],[1,\"\\n          \"],[8,[39,5],null,[[\"@string\"],[[30,1,[\"topic\",\"fancyTitle\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"      \"],[8,[39,6],null,[[\"@name\",\"@outletArgs\"],[\"search-menu-results-topic-title-suffix\",[28,[37,7],null,[[\"topic\"],[[30,1,[\"topic\"]]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"second-line\"],[12],[1,\"\\n    \"],[1,[28,[35,8],[[30,1,[\"topic\",\"category\"]]],[[\"link\"],[[52,[30,2],true,false]]]]],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"tagging_enabled\"]],[[[1,\"      \"],[1,[28,[35,9],[[30,1,[\"topic\"]]],[[\"tagName\"],[\"span\"]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,10],null,[[\"@result\"],[[30,1]]],null]],[\"@result\",\"@withTopicUrl\"],false,[\"topic-status\",\"if\",\"and\",\"replace-emoji\",\"html-safe\",\"search-menu/highlighted-search\",\"plugin-outlet\",\"hash\",\"category-link\",\"discourse-tags\",\"search-menu/results/blurb\"]]",
  "moduleName": "discourse/components/search-menu/results/type/topic.hbs",
  "isStrictMode": false
});