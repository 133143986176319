import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="reviewable-settings">
  <h4>{{i18n "review.settings.priorities.title"}}</h4>

  {{#each this.scoreTypes as |rst|}}
    <div class="reviewable-score-type">
      <div class="title">{{rst.title}}</div>
      <div class="field">
        <ComboBox
          @value={{rst.reviewable_priority}}
          @content={{this.settings.reviewable_priorities}}
          @onChange={{fn (mut rst.reviewable_priority)}}
        />
      </div>
    </div>
  {{/each}}

  <div class="reviewable-score-type">
    <div class="title"></div>
    <div class="field">
      <DButton
        @icon="check"
        @label="review.settings.save_changes"
        @action={{action "save"}}
        @disabled={{this.saving}}
        class="btn-primary save-settings"
      />

      {{#if this.saved}}
        <span class="saved">{{i18n "review.settings.saved"}}</span>
      {{/if}}
    </div>
  </div>
</div>
*/
{
  "id": "wDixO59S",
  "block": "[[[10,0],[14,0,\"reviewable-settings\"],[12],[1,\"\\n  \"],[10,\"h4\"],[12],[1,[28,[35,0],[\"review.settings.priorities.title\"],null]],[13],[1,\"\\n\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"scoreTypes\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"reviewable-score-type\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n      \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n        \"],[8,[39,3],null,[[\"@value\",\"@content\",\"@onChange\"],[[30,1,[\"reviewable_priority\"]],[30,0,[\"settings\",\"reviewable_priorities\"]],[28,[37,4],[[28,[37,5],[[30,1,[\"reviewable_priority\"]]],null]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n  \"],[10,0],[14,0,\"reviewable-score-type\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"title\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"field\"],[12],[1,\"\\n      \"],[8,[39,6],[[24,0,\"btn-primary save-settings\"]],[[\"@icon\",\"@label\",\"@action\",\"@disabled\"],[\"check\",\"review.settings.save_changes\",[28,[37,7],[[30,0],\"save\"],null],[30,0,[\"saving\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"saved\"]],[[[1,\"        \"],[10,1],[14,0,\"saved\"],[12],[1,[28,[35,0],[\"review.settings.saved\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"rst\"],false,[\"i18n\",\"each\",\"-track-array\",\"combo-box\",\"fn\",\"mut\",\"d-button\",\"action\",\"if\"]]",
  "moduleName": "discourse/templates/review-settings.hbs",
  "isStrictMode": false
});