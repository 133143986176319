import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.shouldShow}}
  <div class="row">
    <div class="alert alert-info category-read-only-banner">
      {{html-safe this.category.read_only_banner}}
    </div>
  </div>
{{/if}}
*/
{
  "id": "/z0WbsYv",
  "block": "[[[41,[30,0,[\"shouldShow\"]],[[[1,\"  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"alert alert-info category-read-only-banner\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[[30,0,[\"category\",\"read_only_banner\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"html-safe\"]]",
  "moduleName": "discourse/components/category-read-only-banner.hbs",
  "isStrictMode": false
});