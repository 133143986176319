import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
/* import __COLOCATED_TEMPLATE__ from './group-imap-email-settings.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import EmberObject, { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import emailProviderDefaultSettings from "discourse/lib/email-provider-default-settings";
import discourseComputed, { on } from "discourse-common/utils/decorators";
import TEMPLATE from "./group-imap-email-settings.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("group.email_username", "group.email_password", "form.imap_server", "form.imap_port"), _dec2 = discourseComputed("group.imap_mailboxes"), _dec3 = discourseComputed("group.imap_mailbox_name", "mailboxes.length"), _dec4 = on("init"), (_obj = {
  tagName: "",
  form: null,
  missingSettings(email_username, email_password, imap_server, imap_port) {
    return [email_username, email_password, imap_server, imap_port].some(value => isEmpty(value));
  },
  mailboxes(imapMailboxes) {
    if (!imapMailboxes) {
      return [];
    }
    return imapMailboxes.map(mailbox => ({
      name: mailbox,
      value: mailbox
    }));
  },
  mailboxSelected(mailboxName, mailboxesSize) {
    return mailboxesSize === 0 || !isEmpty(mailboxName);
  },
  resetSettingsValid() {
    this.set("imapSettingsValid", false);
  },
  _fillForm() {
    this.set("form", EmberObject.create({
      imap_server: this.group.imap_server,
      imap_port: (this.group.imap_port || "").toString(),
      imap_ssl: this.group.imap_ssl
    }));
  },
  prefillSettings(provider, event) {
    event?.preventDefault();
    this.form.setProperties(emailProviderDefaultSettings(provider, "imap"));
  },
  testImapSettings() {
    const settings = {
      host: this.form.imap_server,
      port: this.form.imap_port,
      ssl: this.form.imap_ssl,
      username: this.group.email_username,
      password: this.group.email_password
    };
    this.set("testingSettings", true);
    this.set("imapSettingsValid", false);
    return ajax(`/groups/${this.group.id}/test_email_settings`, {
      type: "POST",
      data: Object.assign(settings, {
        protocol: "imap"
      })
    }).then(() => {
      this.set("imapSettingsValid", true);
      this.group.setProperties({
        imap_server: this.form.imap_server,
        imap_port: this.form.imap_port,
        imap_ssl: this.form.imap_ssl
      });
    }).catch(popupAjaxError).finally(() => this.set("testingSettings", false));
  }
}, (_applyDecoratedDescriptor(_obj, "missingSettings", [_dec], Object.getOwnPropertyDescriptor(_obj, "missingSettings"), _obj), _applyDecoratedDescriptor(_obj, "mailboxes", [_dec2], Object.getOwnPropertyDescriptor(_obj, "mailboxes"), _obj), _applyDecoratedDescriptor(_obj, "mailboxSelected", [_dec3], Object.getOwnPropertyDescriptor(_obj, "mailboxSelected"), _obj), _applyDecoratedDescriptor(_obj, "resetSettingsValid", [action], Object.getOwnPropertyDescriptor(_obj, "resetSettingsValid"), _obj), _applyDecoratedDescriptor(_obj, "_fillForm", [_dec4], Object.getOwnPropertyDescriptor(_obj, "_fillForm"), _obj), _applyDecoratedDescriptor(_obj, "prefillSettings", [action], Object.getOwnPropertyDescriptor(_obj, "prefillSettings"), _obj), _applyDecoratedDescriptor(_obj, "testImapSettings", [action], Object.getOwnPropertyDescriptor(_obj, "testImapSettings"), _obj)), _obj))));