import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="directory-table-container">
  <div class="directory-table-top-scroll" {{on "scroll" this.onTopScroll}}>
    <div class="directory-table-top-scroll-fake-content"></div>
  </div>
  <div
    class={{concat-class "directory-table" @className}}
    role="table"
    aria-label={{@ariaLabel}}
    style={{@style}}
    {{did-insert this.checkScroll}}
    {{did-update this.checkScroll @updates}}
    {{on-resize this.checkScroll}}
    {{on "scroll" this.onBottomScroll}}
  >
    <div class="directory-table__header">
      {{yield to="header"}}
    </div>
    <div class="directory-table__body">
      {{yield to="body"}}
    </div>
  </div>
</div>
*/
{
  "id": "8n0K9JK6",
  "block": "[[[10,0],[14,0,\"directory-table-container\"],[12],[1,\"\\n  \"],[11,0],[24,0,\"directory-table-top-scroll\"],[4,[38,0],[\"scroll\",[30,0,[\"onTopScroll\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"directory-table-top-scroll-fake-content\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[11,0],[16,0,[28,[37,1],[\"directory-table\",[30,1]],null]],[24,\"role\",\"table\"],[16,\"aria-label\",[30,2]],[16,5,[30,3]],[4,[38,2],[[30,0,[\"checkScroll\"]]],null],[4,[38,3],[[30,0,[\"checkScroll\"]],[30,4]],null],[4,[38,4],[[30,0,[\"checkScroll\"]]],null],[4,[38,0],[\"scroll\",[30,0,[\"onBottomScroll\"]]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"directory-table__header\"],[12],[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"directory-table__body\"],[12],[1,\"\\n      \"],[18,6,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@className\",\"@ariaLabel\",\"@style\",\"@updates\",\"&header\",\"&body\"],false,[\"on\",\"concat-class\",\"did-insert\",\"did-update\",\"on-resize\",\"yield\"]]",
  "moduleName": "discourse/components/responsive-table.hbs",
  "isStrictMode": false
});