import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="composer-controls">
  <span>
    <PluginOutlet @name="before-composer-toggles" @connectorTagName="div" />
  </span>

  {{#if this.site.mobileView}}
    <DButton
      @icon="bars"
      @action={{this.toggleToolbar}}
      @title={{this.toggleToolbarTitle}}
      @preventFocus={{true}}
      class="btn-flat toggle-toolbar btn-mini-toggle"
    />
  {{/if}}

  {{#if this.showFullScreenButton}}
    <DButton
      @icon={{this.fullscreenIcon}}
      @action={{this.toggleFullscreen}}
      @title={{this.fullscreenTitle}}
      class="btn-transparent toggle-fullscreen btn-mini-toggle"
    />
  {{/if}}

  <DButton
    @icon={{this.toggleIcon}}
    @action={{this.toggleComposer}}
    @title={{this.toggleTitle}}
    class="btn-transparent toggler toggle-minimize btn-mini-toggle"
  />
</div>
*/
{
  "id": "BTQ9GQoO",
  "block": "[[[10,0],[14,0,\"composer-controls\"],[12],[1,\"\\n  \"],[10,1],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@name\",\"@connectorTagName\"],[\"before-composer-toggles\",\"div\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"    \"],[8,[39,2],[[24,0,\"btn-flat toggle-toolbar btn-mini-toggle\"]],[[\"@icon\",\"@action\",\"@title\",\"@preventFocus\"],[\"bars\",[30,0,[\"toggleToolbar\"]],[30,0,[\"toggleToolbarTitle\"]],true]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showFullScreenButton\"]],[[[1,\"    \"],[8,[39,2],[[24,0,\"btn-transparent toggle-fullscreen btn-mini-toggle\"]],[[\"@icon\",\"@action\",\"@title\"],[[30,0,[\"fullscreenIcon\"]],[30,0,[\"toggleFullscreen\"]],[30,0,[\"fullscreenTitle\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,2],[[24,0,\"btn-transparent toggler toggle-minimize btn-mini-toggle\"]],[[\"@icon\",\"@action\",\"@title\"],[[30,0,[\"toggleIcon\"]],[30,0,[\"toggleComposer\"]],[30,0,[\"toggleTitle\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"plugin-outlet\",\"if\",\"d-button\"]]",
  "moduleName": "discourse/components/composer-toggles.hbs",
  "isStrictMode": false
});