import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
/* import __COLOCATED_TEMPLATE__ from './reject-reason-reviewable.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import TEMPLATE from "./reject-reason-reviewable.hbs";
import { setComponentTemplate } from "@ember/component";
let RejectReasonReviewableModal = (_class = class RejectReasonReviewableModal extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "rejectReason", void 0);
    _defineProperty(this, "sendEmail", false);
  }
  async perform() {
    this.args.model.reviewable.setProperties({
      rejectReason: this.rejectReason,
      sendEmail: this.sendEmail
    });
    this.args.closeModal();
    await this.args.model.performConfirmed(this.args.model.action);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "perform", [action], Object.getOwnPropertyDescriptor(_class.prototype, "perform"), _class.prototype)), _class);
export { RejectReasonReviewableModal as default };
setComponentTemplate(TEMPLATE, RejectReasonReviewableModal);