/* import __COLOCATED_TEMPLATE__ from './generated-invite-link.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import TEMPLATE from "./generated-invite-link.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend({
  didInsertElement() {
    this._super(...arguments);
    const invite = this.element.querySelector(".invite-link-input");
    invite.focus();
    invite.select();
  }
}));