import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import Helper from "@ember/component/helper";
import { service } from "@ember/service";
let HideApplicationFooter = (_class = class HideApplicationFooter extends Helper {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "footer", _descriptor, this);
    this.footer.registerHider(this);
  }
  compute() {}
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "footer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { HideApplicationFooter as default };