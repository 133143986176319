import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
/* import __COLOCATED_TEMPLATE__ from './dismiss-notification-confirmation.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import TEMPLATE from "./dismiss-notification-confirmation.hbs";
import { setComponentTemplate } from "@ember/component";
let DismissNotificationConfirmation = (_class = class DismissNotificationConfirmation extends Component {
  dismiss() {
    this.args.model?.dismissNotifications?.();
    this.args.closeModal();
  }
}, (_applyDecoratedDescriptor(_class.prototype, "dismiss", [action], Object.getOwnPropertyDescriptor(_class.prototype, "dismiss"), _class.prototype)), _class);
export { DismissNotificationConfirmation as default };
setComponentTemplate(TEMPLATE, DismissNotificationConfirmation);