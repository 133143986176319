import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "groups.default_notifications.modal_title"}}
  @closeModal={{@closeModal}}
>
  <:body>
    {{i18n "groups.default_notifications.modal_description" count=@model.count}}
  </:body>
  <:footer>
    <DButton
      @action={{this.updateExistingUsers}}
      @label="groups.default_notifications.modal_yes"
      class="btn-primary"
    />
    <DButton
      @action={{this.cancel}}
      @label="groups.default_notifications.modal_no"
    />
  </:footer>
</DModal>
*/
{
  "id": "SeBdrviZ",
  "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"groups.default_notifications.modal_title\"],null],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[\"groups.default_notifications.modal_description\"],[[\"count\"],[[30,2,[\"count\"]]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"updateExistingUsers\"]],\"groups.default_notifications.modal_yes\"]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@action\",\"@label\"],[[30,0,[\"cancel\"]],\"groups.default_notifications.modal_no\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/group-default-notifications.hbs",
  "isStrictMode": false
});