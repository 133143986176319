import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{! prettier-ignore }}
<svg class="fa d-icon d-icon-custom-google-oauth2 svg-icon" viewBox="0 0 48 48"><defs><path id="google-icon-a" d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z"/></defs><clipPath id="google-icon-b"><use href="#google-icon-a" overflow="visible"/></clipPath><path clip-path="url(#google-icon-b)" fill="#FBBC05" d="M0 37V11l17 13z"/><path clip-path="url(#google-icon-b)" fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z"/><path clip-path="url(#google-icon-b)" fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z"/><path clip-path="url(#google-icon-b)" fill="#4285F4" d="M48 48L17 24l-4-3 35-10z"/></svg>
*/
{
  "id": "di1pFoLw",
  "block": "[[[10,\"svg\"],[14,0,\"fa d-icon d-icon-custom-google-oauth2 svg-icon\"],[14,\"viewBox\",\"0 0 48 48\"],[12],[10,\"defs\"],[12],[10,\"path\"],[14,1,\"google-icon-a\"],[14,\"d\",\"M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z\"],[12],[13],[13],[10,\"clipPath\"],[14,1,\"google-icon-b\"],[12],[10,\"use\"],[14,6,\"#google-icon-a\"],[14,\"overflow\",\"visible\"],[12],[13],[13],[10,\"path\"],[14,\"clip-path\",\"url(#google-icon-b)\"],[14,\"fill\",\"#FBBC05\"],[14,\"d\",\"M0 37V11l17 13z\"],[12],[13],[10,\"path\"],[14,\"clip-path\",\"url(#google-icon-b)\"],[14,\"fill\",\"#EA4335\"],[14,\"d\",\"M0 11l17 13 7-6.1L48 14V0H0z\"],[12],[13],[10,\"path\"],[14,\"clip-path\",\"url(#google-icon-b)\"],[14,\"fill\",\"#34A853\"],[14,\"d\",\"M0 37l30-23 7.9 1L48 0v48H0z\"],[12],[13],[10,\"path\"],[14,\"clip-path\",\"url(#google-icon-b)\"],[14,\"fill\",\"#4285F4\"],[14,\"d\",\"M48 48L17 24l-4-3 35-10z\"],[12],[13],[13]],[],false,[]]",
  "moduleName": "discourse/components/google-icon.hbs",
  "isStrictMode": false
});