import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './top-period-buttons.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./top-period-buttons.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("period"), (_obj = {
  classNames: ["top-title-buttons"],
  periods(period) {
    return this.site.get("periods").filter(p => p !== period);
  },
  changePeriod(p) {
    this.action(p);
  }
}, (_applyDecoratedDescriptor(_obj, "periods", [_dec], Object.getOwnPropertyDescriptor(_obj, "periods"), _obj), _applyDecoratedDescriptor(_obj, "changePeriod", [action], Object.getOwnPropertyDescriptor(_obj, "changePeriod"), _obj)), _obj))));