import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="user-status-picker-wrap">
  <div
    class="emoji-picker-anchor user-status-picker
      {{if this.isFocused 'focused'}}"
  >
    <button
      type="button"
      class="btn-emoji btn-transparent"
      onclick={{this.toggleEmojiPicker}}
      {{on "focus" this.focus}}
      {{on "blur" this.blur}}
    >
      {{#if @status.emoji}}
        {{html-safe this.emojiHtml}}
      {{else}}
        {{d-icon "discourse-emojis"}}
      {{/if}}
    </button>
    <Input
      class="user-status-description"
      @value={{@status.description}}
      maxlength="100"
      placeholder={{i18n "user_status.what_are_you_doing"}}
      {{on "input" this.setDefaultEmoji}}
      {{on "focus" this.focus}}
      {{on "blur" this.blur}}
    />
  </div>
</div>
<EmojiPicker
  @isActive={{this.emojiPickerIsActive}}
  @emojiSelected={{this.emojiSelected}}
  @onEmojiPickerClose={{this.onEmojiPickerOutsideClick}}
  @placement="bottom"
/>
*/
{
  "id": "J5uzuxel",
  "block": "[[[10,0],[14,0,\"user-status-picker-wrap\"],[12],[1,\"\\n  \"],[10,0],[15,0,[29,[\"emoji-picker-anchor user-status-picker\\n      \",[52,[30,0,[\"isFocused\"]],\"focused\"]]]],[12],[1,\"\\n    \"],[11,\"button\"],[24,0,\"btn-emoji btn-transparent\"],[16,\"onclick\",[30,0,[\"toggleEmojiPicker\"]]],[24,4,\"button\"],[4,[38,1],[\"focus\",[30,0,[\"focus\"]]],null],[4,[38,1],[\"blur\",[30,0,[\"blur\"]]],null],[12],[1,\"\\n\"],[41,[30,1,[\"emoji\"]],[[[1,\"        \"],[1,[28,[35,2],[[30,0,[\"emojiHtml\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,3],[\"discourse-emojis\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[8,[39,4],[[24,0,\"user-status-description\"],[24,\"maxlength\",\"100\"],[16,\"placeholder\",[28,[37,5],[\"user_status.what_are_you_doing\"],null]],[4,[38,1],[\"input\",[30,0,[\"setDefaultEmoji\"]]],null],[4,[38,1],[\"focus\",[30,0,[\"focus\"]]],null],[4,[38,1],[\"blur\",[30,0,[\"blur\"]]],null]],[[\"@value\"],[[30,1,[\"description\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[8,[39,6],null,[[\"@isActive\",\"@emojiSelected\",\"@onEmojiPickerClose\",\"@placement\"],[[30,0,[\"emojiPickerIsActive\"]],[30,0,[\"emojiSelected\"]],[30,0,[\"onEmojiPickerOutsideClick\"]],\"bottom\"]],null]],[\"@status\"],false,[\"if\",\"on\",\"html-safe\",\"d-icon\",\"input\",\"i18n\",\"emoji-picker\"]]",
  "moduleName": "discourse/components/user-status-picker.hbs",
  "isStrictMode": false
});