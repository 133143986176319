import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{body-class "static-login"}}

<section class="container">
  <div class="contents clearfix body-page">
    <div class="login-welcome">
      <PluginOutlet @name="above-login" @outletArgs={{hash model=this.model}} />
      <PluginOutlet @name="above-static" />

      <div class="login-content">
        {{html-safe this.model.html}}
      </div>

      <PluginOutlet @name="below-static" />
      <PluginOutlet @name="below-login" @outletArgs={{hash model=this.model}} />

      <div class="body-page-button-container">
        {{#if this.application.canSignUp}}
          <DButton
            @action={{route-action "showCreateAccount"}}
            @label="sign_up"
            class="btn-primary sign-up-button"
          />
        {{/if}}

        <DButton
          @action={{route-action "showLogin"}}
          @icon="user"
          @label="log_in"
          class="btn-primary login-button"
        />
      </div>
    </div>
  </div>
</section>
*/
{
  "id": "RexmQ7m9",
  "block": "[[[1,[28,[35,0],[\"static-login\"],null]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"contents clearfix body-page\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"login-welcome\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@outletArgs\"],[\"above-login\",[28,[37,2],null,[[\"model\"],[[30,0,[\"model\"]]]]]]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\"],[\"above-static\"]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"login-content\"],[12],[1,\"\\n        \"],[1,[28,[35,3],[[30,0,[\"model\",\"html\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[39,1],null,[[\"@name\"],[\"below-static\"]],null],[1,\"\\n      \"],[8,[39,1],null,[[\"@name\",\"@outletArgs\"],[\"below-login\",[28,[37,2],null,[[\"model\"],[[30,0,[\"model\"]]]]]]],null],[1,\"\\n\\n      \"],[10,0],[14,0,\"body-page-button-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"application\",\"canSignUp\"]],[[[1,\"          \"],[8,[39,5],[[24,0,\"btn-primary sign-up-button\"]],[[\"@action\",\"@label\"],[[28,[37,6],[\"showCreateAccount\"],null],\"sign_up\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[39,5],[[24,0,\"btn-primary login-button\"]],[[\"@action\",\"@icon\",\"@label\"],[[28,[37,6],[\"showLogin\"],null],\"user\",\"log_in\"]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"body-class\",\"plugin-outlet\",\"hash\",\"html-safe\",\"if\",\"d-button\",\"route-action\"]]",
  "moduleName": "discourse/templates/login.hbs",
  "isStrictMode": false
});