import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import Component from "@ember/component";
import $ from "jquery";
import discourseComputed from "discourse-common/utils/decorators";
export default Component.extend((_dec = discourseComputed("value", "selection"), (_obj = {
  tagName: "input",
  type: "radio",
  attributeBindings: ["name", "type", "value", "checked:checked", "disabled:disabled"],
  click() {
    const value = $(this.element).val();
    if (this.onChange) {
      this.onChange(value);
    } else {
      if (this.selection === value) {
        this.set("selection", undefined);
      }
      this.set("selection", value);
    }
  },
  checked(value, selection) {
    return value === selection;
  }
}, (_applyDecoratedDescriptor(_obj, "checked", [_dec], Object.getOwnPropertyDescriptor(_obj, "checked"), _obj)), _obj)));