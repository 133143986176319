import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _dec, _dec2, _dec3, _dec4, _class2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _NavItem;
import { tracked } from "@glimmer/tracking";
import EmberObject from "@ember/object";
import { dependentKeyCompat } from "@ember/object/compat";
import { reads } from "@ember/object/computed";
import { service } from "@ember/service";
import { emojiUnescape } from "discourse/lib/text";
import { hasTrackedFilter, isTrackedTopic } from "discourse/lib/topic-list-tracked-filter";
import Category from "discourse/models/category";
import Site from "discourse/models/site";
import User from "discourse/models/user";
import deprecated from "discourse-common/lib/deprecated";
import { getOwnerWithFallback } from "discourse-common/lib/get-owner";
import getURL from "discourse-common/lib/get-url";
import { deepMerge } from "discourse-common/lib/object";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
let NavItem = (_dec = reads("name"), _dec2 = discourseComputed("filterType", "category", "noSubcategories", "tagId"), _dec3 = discourseComputed("name", "category", "noSubcategories"), _dec4 = discourseComputed("name", "category", "tagId", "noSubcategories", "currentRouteQueryParams", "topicTrackingState.messageCount"), (_class2 = (_NavItem = class NavItem extends EmberObject {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor3, this);
    _initializerDefineProperty(this, "name", _descriptor4, this);
    _initializerDefineProperty(this, "filterType", _descriptor5, this);
    _initializerDefineProperty(this, "_title", _descriptor6, this);
    _initializerDefineProperty(this, "_displayName", _descriptor7, this);
  }
  static pathFor(filterType, context) {
    let path = getURL("");
    let includesCategoryContext = false;
    let includesTagContext = false;
    if (filterType === "categories") {
      path += "/categories";
      return path;
    }
    if (context.tagId && Site.currentProp("filters").includes(filterType)) {
      includesTagContext = true;
      if (context.category) {
        path += "/tags";
      } else {
        path += "/tag";
      }
    }
    if (context.category) {
      includesCategoryContext = true;
      path += `/c/${Category.slugFor(context.category)}/${context.category.id}`;
      if (context.noSubcategories) {
        path += "/none";
      }
    }
    if (includesTagContext) {
      path += `/${context.tagId}`;
    }
    if (includesTagContext || includesCategoryContext) {
      path += "/l";
    }
    path += `/${filterType}`;

    // In the case of top, the nav item doesn't include a period because the
    // period has its own selector just below

    return path;
  }

  // Create a nav item given a filterType. It returns null if there is not
  // valid nav item. The name is a historical artifact.
  static fromText(filterType, opts) {
    const anonymous = !User.current();
    opts = opts || {};
    if (anonymous) {
      const topMenuItems = Site.currentProp("anonymous_top_menu_items");
      if (!topMenuItems || !topMenuItems.includes(filterType)) {
        return null;
      }
    }
    if (!Category.list() && filterType === "categories") {
      return null;
    }
    if (!Site.currentProp("top_menu_items").includes(filterType)) {
      return null;
    }
    let args = {
      name: filterType,
      hasIcon: filterType === "unread"
    };
    if (opts.category) {
      args.category = opts.category;
    }
    if (opts.tagId) {
      args.tagId = opts.tagId;
    }
    if (opts.currentRouteQueryParams) {
      args.currentRouteQueryParams = opts.currentRouteQueryParams;
    }
    if (opts.noSubcategories) {
      args.noSubcategories = true;
    }
    NavItem.extraArgsCallbacks.forEach(cb => deepMerge(args, cb.call(this, filterType, opts)));
    let store = getOwnerWithFallback(this).lookup("service:store");
    return store.createRecord("nav-item", args);
  }
  static buildList(category, args) {
    args = args || {};
    if (category) {
      args.category = category;
    }
    if (!args.siteSettings) {
      deprecated("You must supply `buildList` with a `siteSettings` object", {
        since: "2.6.0",
        dropFrom: "2.7.0",
        id: "discourse.nav-item.built-list-site-settings"
      });
      args.siteSettings = getOwnerWithFallback(this).lookup("service:site-settings");
    }
    let items = args.siteSettings.top_menu.split("|");
    const user = getOwnerWithFallback(this).lookup("service:current-user");
    if (user?.new_new_view_enabled) {
      items = items.reject(item => item === "unread");
    }
    const filterType = (args.filterMode || "").split("/").pop();
    if (!items.some(i => filterType === i)) {
      items.push(filterType);
    }
    items = items.map(i => NavItem.fromText(i, args)).filter(i => {
      if (i === null) {
        return false;
      }
      if ((category || args.skipCategoriesNavItem) && i.name.startsWith("categor")) {
        return false;
      }
      return true;
    });
    const context = {
      category: args.category,
      tagId: args.tagId,
      noSubcategories: args.noSubcategories
    };
    const extraItems = NavItem.extraNavItemDescriptors.map(descriptor => ExtraNavItem.create(deepMerge({}, context, descriptor))).filter(item => {
      if (!item.customFilter) {
        return true;
      }
      return item.customFilter(category, args);
    });
    let forceActive = false;
    extraItems.forEach(item => {
      if (item.init) {
        item.init(item, category, args);
      }
      if (item.href) {
        item.href = getURL(item.href);
      }
      const before = item.before;
      if (before) {
        let i = 0;
        for (i = 0; i < items.length; i++) {
          if (items[i].name === before) {
            break;
          }
        }
        items.splice(i, 0, item);
      } else {
        items.push(item);
      }
      if (item.customHref) {
        item.set("href", item.customHref(category, args));
      }
      if (item.forceActive && item.forceActive(category, args)) {
        item.active = true;
        forceActive = true;
      } else {
        item.active = undefined;
      }
    });
    if (forceActive) {
      items.forEach(i => {
        if (i.active === undefined) {
          i.active = false;
        }
      });
    }
    return items;
  }
  get title() {
    if (this._title) {
      return this._title;
    }
    return I18n.t("filters." + this.name.replace("/", ".") + ".help", {});
  }
  set title(value) {
    this._title = value;
  }
  get displayName() {
    if (this._displayName) {
      return this._displayName;
    }
    let count = this.count || 0;
    if (this.name === "latest" && (Site.currentProp("desktopView") || this.tagId !== undefined)) {
      count = 0;
    }
    let extra = {
      count
    };
    const titleKey = count === 0 ? ".title" : ".title_with_count";
    return emojiUnescape(I18n.t(`filters.${this.name.replace("/", ".") + titleKey}`, extra));
  }
  set displayName(value) {
    this._displayName = value;
  }
  href(filterType, category, noSubcategories, tagId) {
    let customHref = null;
    NavItem.customNavItemHrefs.forEach(function (cb) {
      customHref = cb.call(this, this);
      if (customHref) {
        return false;
      }
    }, this);
    if (customHref) {
      return getURL(customHref);
    }
    const context = {
      category,
      noSubcategories,
      tagId
    };
    return NavItem.pathFor(filterType, context);
  }
  filterMode(name, category, noSubcategories) {
    let mode = "";
    if (category) {
      mode += "c/";
      mode += Category.slugFor(category);
      if (noSubcategories) {
        mode += "/none";
      }
      mode += "/l/";
    }
    return mode + name.replace(" ", "-");
  }
  count(name, category, tagId, noSubcategories, currentRouteQueryParams) {
    const state = this.topicTrackingState;
    if (state) {
      return state.lookupCount({
        type: name,
        category,
        tagId,
        noSubcategories,
        customFilterFn: hasTrackedFilter(currentRouteQueryParams) ? isTrackedTopic : undefined
      });
    }
  }
}, _defineProperty(_NavItem, "extraArgsCallbacks", []), _defineProperty(_NavItem, "customNavItemHrefs", []), _defineProperty(_NavItem, "extraNavItemDescriptors", []), _NavItem), (_descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "name", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class2.prototype, "filterType", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class2.prototype, "_title", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class2.prototype, "_displayName", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "title", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "title"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "displayName", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "displayName"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "href", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "href"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "filterMode", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "filterMode"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "count", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "count"), _class2.prototype)), _class2));
export { NavItem as default };
export let ExtraNavItem = (_class = class ExtraNavItem extends NavItem {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "href", _descriptor, this);
    _initializerDefineProperty(this, "count", _descriptor2, this);
    _defineProperty(this, "customFilter", null);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "href", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "count", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
})), _class);
export function extraNavItemProperties(cb) {
  NavItem.extraArgsCallbacks.push(cb);
}
export function customNavItemHref(cb) {
  NavItem.customNavItemHrefs.push(cb);
}
export function clearNavItems() {
  NavItem.customNavItemHrefs.clear();
  NavItem.extraArgsCallbacks.clear();
  NavItem.extraNavItemDescriptors.clear();
}
export function addNavItem(item) {
  NavItem.extraNavItemDescriptors.push(item);
}