import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <p>{{i18n "user.invited.link_generated"}}</p>
<p>
  <input value={{this.link}} class="invite-link-input" type="text" />
</p>
{{#if this.email}}
  <p>{{i18n "user.invited.valid_for" email=this.email}}</p>
{{/if}}
*/
{
  "id": "cN5BYJjK",
  "block": "[[[10,2],[12],[1,[28,[35,0],[\"user.invited.link_generated\"],null]],[13],[1,\"\\n\"],[10,2],[12],[1,\"\\n  \"],[10,\"input\"],[15,2,[30,0,[\"link\"]]],[14,0,\"invite-link-input\"],[14,4,\"text\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"email\"]],[[[1,\"  \"],[10,2],[12],[1,[28,[35,0],[\"user.invited.valid_for\"],[[\"email\"],[[30,0,[\"email\"]]]]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"i18n\",\"if\"]]",
  "moduleName": "discourse/components/generated-invite-link.hbs",
  "isStrictMode": false
});