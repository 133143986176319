import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _class2, _descriptor, _descriptor2;
import Service, { service } from "@ember/service";
import { Promise } from "rsvp";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import { fileToImageData } from "discourse/lib/media-optimization-utils";
import { getAbsoluteURL, getURLWithCDN } from "discourse-common/lib/get-url";

/**
 * This worker follows a particular promise/callback flow to ensure
 * that the media-optimization-worker is installed and has its libraries
 * loaded before optimizations can happen. The flow:
 *
 * 1. optimizeImage called
 * 2. worker initialized and started
 * 3. message handlers for worker registered
 * 4. "install" message posted to worker
 * 5. "installed" message received from worker
 * 6. optimizeImage continues, posting "compress" message to worker
 *
 * When the worker is being installed, all other calls to optimizeImage
 * will wait for the "installed" message to be handled before continuing
 * with any image optimization work.
 */
let MediaOptimizationWorkerService = disableImplicitInjections(_class = (_class2 = class MediaOptimizationWorkerService extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "appEvents", _descriptor, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor2, this);
    _defineProperty(this, "worker", null);
    _defineProperty(this, "workerUrl", getAbsoluteURL("/javascripts/media-optimization-worker.js"));
    _defineProperty(this, "currentComposerUploadData", null);
    _defineProperty(this, "promiseResolvers", null);
  }
  async optimizeImage(data) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    this.promiseResolvers = this.promiseResolvers || {};
    this.stopWorkerOnError = opts.hasOwnProperty("stopWorkerOnError") ? opts.stopWorkerOnError : true;
    let file = data;
    if (!/(\.|\/)(jpe?g|png)$/i.test(file.type)) {
      return Promise.resolve();
    }
    if (file.size < this.siteSettings.composer_media_optimization_image_bytes_optimization_threshold) {
      this.logIfDebug(`The file ${file.name} was less than the image optimization bytes threshold (${this.siteSettings.composer_media_optimization_image_bytes_optimization_threshold} bytes), skipping.`, file);
      return Promise.resolve();
    }
    await this.ensureAvailableWorker();

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      this.logIfDebug(`Transforming ${file.name}`);
      this.currentComposerUploadData = data;
      this.promiseResolvers[file.id] = resolve;
      let imageData;
      try {
        imageData = await fileToImageData(file.data);
      } catch (error) {
        this.logIfDebug(error);
        return resolve();
      }
      this.worker.postMessage({
        type: "compress",
        fileId: file.id,
        file: imageData.data.buffer,
        fileName: file.name,
        width: imageData.width,
        height: imageData.height,
        settings: {
          resize_threshold: this.siteSettings.composer_media_optimization_image_resize_dimensions_threshold,
          resize_target: this.siteSettings.composer_media_optimization_image_resize_width_target,
          resize_pre_multiply: this.siteSettings.composer_media_optimization_image_resize_pre_multiply,
          resize_linear_rgb: this.siteSettings.composer_media_optimization_image_resize_linear_rgb,
          encode_quality: this.siteSettings.composer_media_optimization_image_encode_quality,
          debug_mode: this.siteSettings.composer_media_optimization_debug_mode
        }
      }, [imageData.data.buffer]);
    });
  }
  async ensureAvailableWorker() {
    if (this.worker && this.workerInstalled) {
      return Promise.resolve();
    }
    if (this.installPromise) {
      return this.installPromise;
    }
    return this.install();
  }
  async install() {
    this.installPromise = new Promise((resolve, reject) => {
      this.afterInstalled = resolve;
      this.failedInstall = reject;
      this.logIfDebug("Installing worker.");
      this.startWorker();
      this.registerMessageHandler();
      this.worker.postMessage({
        type: "install",
        settings: {
          mozjpeg_script: getURLWithCDN("/javascripts/squoosh/mozjpeg_enc.js"),
          mozjpeg_wasm: getURLWithCDN("/javascripts/squoosh/mozjpeg_enc.wasm"),
          resize_script: getURLWithCDN("/javascripts/squoosh/squoosh_resize.js"),
          resize_wasm: getURLWithCDN("/javascripts/squoosh/squoosh_resize_bg.wasm")
        }
      });
      this.appEvents.on("composer:closed", this, "stopWorker");
    });
    return this.installPromise;
  }
  startWorker() {
    this.logIfDebug("Starting media-optimization-worker");
    this.worker = new Worker(this.workerUrl); // TODO come up with a workaround for FF that lacks type: module support
  }
  stopWorker() {
    if (this.worker) {
      this.logIfDebug("Stopping media-optimization-worker...");
      this.workerInstalled = false;
      this.worker.terminate();
      this.worker = null;
    }
  }
  registerMessageHandler() {
    this.worker.onmessage = e => {
      switch (e.data.type) {
        case "file":
          let optimizedFile = new File([e.data.file], e.data.fileName, {
            type: "image/jpeg"
          });
          this.logIfDebug(`Finished optimization of ${optimizedFile.name} new size: ${optimizedFile.size}.`);
          this.promiseResolvers[e.data.fileId](optimizedFile);
          break;
        case "error":
          this.logIfDebug(`Handling error message from image optimization for ${e.data.fileName}.`);
          if (this.stopWorkerOnError) {
            this.stopWorker();
          }
          this.promiseResolvers[e.data.fileId]();
          break;
        case "installed":
          this.logIfDebug("Worker installed.");
          this.workerInstalled = true;
          this.afterInstalled();
          this.cleanupInstallPromises();
          break;
        case "installFailed":
          this.logIfDebug("Worker failed to install.");
          this.failedInstall(e.data.errorMessage);
          this.cleanupInstallPromises();
          break;
        default:
          this.logIfDebug(`Sorry, we are out of ${e}.`);
      }
    };
  }
  cleanupInstallPromises() {
    this.afterInstalled = null;
    this.failedInstall = null;
    this.installPromise = null;
  }
  logIfDebug() {
    if (this.siteSettings.composer_media_optimization_debug_mode) {
      // eslint-disable-next-line no-console
      console.log(...arguments);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class2)) || _class;
export { MediaOptimizationWorkerService as default };