import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <UserStreamItem @item={{@post}} />
*/
{
  "id": "QxNva+5p",
  "block": "[[[8,[39,0],null,[[\"@item\"],[[30,1]]],null]],[\"@post\"],false,[\"user-stream-item\"]]",
  "moduleName": "discourse/components/pending-post.hbs",
  "isStrictMode": false
});