import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.unreadTopicsCount}}
  <a
    href={{this.category.unreadUrl}}
    title={{i18n "topic.unread_topics" count=this.unreadTopicsCount}}
    class="badge new-posts badge-notification"
  >{{i18n
      "filters.unread.lower_title_with_count"
      count=this.unreadTopicsCount
    }}</a>
{{/if}}
{{#if this.newTopicsCount}}
  <a
    href={{this.category.newUrl}}
    title={{i18n "topic.new_topics" count=this.newTopicsCount}}
    class="badge new-posts badge-notification"
  >{{i18n "filters.new.lower_title_with_count" count=this.newTopicsCount}}</a>
{{/if}}
*/
{
  "id": "1dGC4P7h",
  "block": "[[[41,[30,0,[\"unreadTopicsCount\"]],[[[1,\"  \"],[10,3],[15,6,[30,0,[\"category\",\"unreadUrl\"]]],[15,\"title\",[28,[37,1],[\"topic.unread_topics\"],[[\"count\"],[[30,0,[\"unreadTopicsCount\"]]]]]],[14,0,\"badge new-posts badge-notification\"],[12],[1,[28,[35,1],[\"filters.unread.lower_title_with_count\"],[[\"count\"],[[30,0,[\"unreadTopicsCount\"]]]]]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"newTopicsCount\"]],[[[1,\"  \"],[10,3],[15,6,[30,0,[\"category\",\"newUrl\"]]],[15,\"title\",[28,[37,1],[\"topic.new_topics\"],[[\"count\"],[[30,0,[\"newTopicsCount\"]]]]]],[14,0,\"badge new-posts badge-notification\"],[12],[1,[28,[35,1],[\"filters.new.lower_title_with_count\"],[[\"count\"],[[30,0,[\"newTopicsCount\"]]]]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\"]]",
  "moduleName": "discourse/components/category-unread.hbs",
  "isStrictMode": false
});