import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
/* import __COLOCATED_TEMPLATE__ from './flag-action-type.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { and, equal, not } from "@ember/object/computed";
import { MAX_MESSAGE_LENGTH } from "discourse/models/post-action-type";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./flag-action-type.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("flag.name_key"), _dec2 = discourseComputed("flag.name_key"), _dec3 = discourseComputed("flag.name", "flag.name_key", "username"), _dec4 = discourseComputed("flag", "selectedFlag"), _dec5 = discourseComputed("flag.description", "flag.short_description"), _dec6 = discourseComputed("message.length"), _dec7 = discourseComputed("message.length"), (_obj = {
  tagName: "",
  wrapperClassNames(nameKey) {
    return `flag-action-type ${nameKey}`;
  },
  customPlaceholder(nameKey) {
    return I18n.t("flagging.custom_placeholder_" + nameKey);
  },
  formattedName(name, nameKey, username) {
    if (["notify_user", "notify_moderators"].includes(nameKey)) {
      return name.replace(/{{username}}|%{username}/, username);
    } else {
      return I18n.t("flagging.formatted_name." + nameKey);
    }
  },
  selected(flag, selectedFlag) {
    return flag === selectedFlag;
  },
  showMessageInput: and("flag.is_custom_flag", "selected"),
  showConfirmation: and("flag.isIllegal", "selected"),
  showDescription: not("showMessageInput"),
  isNotifyUser: equal("flag.name_key", "notify_user"),
  description(long_description, short_description) {
    return this.site.mobileView ? short_description : long_description;
  },
  customMessageLengthClasses(messageLength) {
    return messageLength < this.siteSettings.min_personal_message_post_length ? "too-short" : "ok";
  },
  customMessageLength(messageLength) {
    const len = messageLength || 0;
    const minLen = this.siteSettings.min_personal_message_post_length;
    if (len === 0) {
      return I18n.t("flagging.custom_message.at_least", {
        count: minLen
      });
    } else if (len < minLen) {
      return I18n.t("flagging.custom_message.more", {
        count: minLen - len
      });
    } else {
      return I18n.t("flagging.custom_message.left", {
        count: MAX_MESSAGE_LENGTH - len
      });
    }
  },
  actions: {
    changePostActionType(at) {
      this.changePostActionType(at);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "wrapperClassNames", [_dec], Object.getOwnPropertyDescriptor(_obj, "wrapperClassNames"), _obj), _applyDecoratedDescriptor(_obj, "customPlaceholder", [_dec2], Object.getOwnPropertyDescriptor(_obj, "customPlaceholder"), _obj), _applyDecoratedDescriptor(_obj, "formattedName", [_dec3], Object.getOwnPropertyDescriptor(_obj, "formattedName"), _obj), _applyDecoratedDescriptor(_obj, "selected", [_dec4], Object.getOwnPropertyDescriptor(_obj, "selected"), _obj), _applyDecoratedDescriptor(_obj, "description", [_dec5], Object.getOwnPropertyDescriptor(_obj, "description"), _obj), _applyDecoratedDescriptor(_obj, "customMessageLengthClasses", [_dec6], Object.getOwnPropertyDescriptor(_obj, "customMessageLengthClasses"), _obj), _applyDecoratedDescriptor(_obj, "customMessageLength", [_dec7], Object.getOwnPropertyDescriptor(_obj, "customMessageLength"), _obj)), _obj))));