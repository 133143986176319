import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.currentUser.recent_searches}}
  <div class="search-menu-recent">
    <div class="heading">
      <h4>{{i18n "search.recent"}}</h4>
      <FlatButton
        @title="search.clear_recent"
        @icon="times"
        @action={{this.clearRecent}}
        class="clear-recent-searches"
      />
    </div>

    {{#each this.currentUser.recent_searches as |slug|}}
      <SearchMenu::Results::AssistantItem
        @icon="history"
        @label={{slug}}
        @slug={{slug}}
        @closeSearchMenu={{@closeSearchMenu}}
        @searchTermChanged={{@searchTermChanged}}
        @usage="recent-search"
        @concatSlug={{true}}
      />
    {{/each}}
  </div>
{{/if}}
*/
{
  "id": "+32m3A13",
  "block": "[[[41,[30,0,[\"currentUser\",\"recent_searches\"]],[[[1,\"  \"],[10,0],[14,0,\"search-menu-recent\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"heading\"],[12],[1,\"\\n      \"],[10,\"h4\"],[12],[1,[28,[35,1],[\"search.recent\"],null]],[13],[1,\"\\n      \"],[8,[39,2],[[24,0,\"clear-recent-searches\"]],[[\"@title\",\"@icon\",\"@action\"],[\"search.clear_recent\",\"times\",[30,0,[\"clearRecent\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"currentUser\",\"recent_searches\"]]],null]],null],null,[[[1,\"      \"],[8,[39,5],null,[[\"@icon\",\"@label\",\"@slug\",\"@closeSearchMenu\",\"@searchTermChanged\",\"@usage\",\"@concatSlug\"],[\"history\",[30,1],[30,1],[30,2],[30,3],\"recent-search\",true]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"slug\",\"@closeSearchMenu\",\"@searchTermChanged\"],false,[\"if\",\"i18n\",\"flat-button\",\"each\",\"-track-array\",\"search-menu/results/assistant-item\"]]",
  "moduleName": "discourse/components/search-menu/results/recent-searches.hbs",
  "isStrictMode": false
});