import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './categories-boxes-topic.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./categories-boxes-topic.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("topic.pinned", "topic.closed", "topic.archived"), (_obj = {
  tagName: "li",
  attributeBindings: ["topic.id:data-topic-id"],
  topicStatusIcon(pinned, closed, archived) {
    if (pinned) {
      return "thumbtack";
    }
    if (closed || archived) {
      return "lock";
    }
    return "far-file-alt";
  }
}, (_applyDecoratedDescriptor(_obj, "topicStatusIcon", [_dec], Object.getOwnPropertyDescriptor(_obj, "topicStatusIcon"), _obj)), _obj))));