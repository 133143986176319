import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @closeModal={{@closeModal}}
  class="feature-topic-on-profile choose-topic-modal"
  id="choosing-topic"
  @title={{i18n "user.feature_topic_on_profile.title"}}
>
  <:body>
    <ChooseTopic
      @currentTopicId={{@model.user.featured_topic.id}}
      @selectedTopicId={{this.newFeaturedTopicId}}
      @additionalFilters="status:public"
      @label="user.feature_topic_on_profile.search_label"
      @topicChangedCallback={{this.newTopicSelected}}
      @loadOnInit={{true}}
    />
  </:body>
  <:footer>
    <DButton
      @action={{this.save}}
      class="btn-primary save-featured-topic-on-profile"
      @disabled={{this.noTopicSelected}}
      @label="user.feature_topic_on_profile.save"
    />
    <DButton @action={{@closeModal}} @label="cancel" class="btn-flat" />
  </:footer>
</DModal>
*/
{
  "id": "aQKWszNB",
  "block": "[[[8,[39,0],[[24,0,\"feature-topic-on-profile choose-topic-modal\"],[24,1,\"choosing-topic\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"user.feature_topic_on_profile.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@currentTopicId\",\"@selectedTopicId\",\"@additionalFilters\",\"@label\",\"@topicChangedCallback\",\"@loadOnInit\"],[[30,2,[\"user\",\"featured_topic\",\"id\"]],[30,0,[\"newFeaturedTopicId\"]],\"status:public\",\"user.feature_topic_on_profile.search_label\",[30,0,[\"newTopicSelected\"]],true]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary save-featured-topic-on-profile\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"save\"]],[30,0,[\"noTopicSelected\"]],\"user.feature_topic_on_profile.save\"]],null],[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-flat\"]],[[\"@action\",\"@label\"],[[30,1],\"cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"choose-topic\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/feature-topic-on-profile.hbs",
  "isStrictMode": false
});