import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
import { action } from "@ember/object";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default DiscourseRoute.extend((_obj = {
  titleToken() {
    return I18n.t("groups.manage.logs.title");
  },
  model() {
    return this.modelFor("group").findLogs();
  },
  setupController(controller, model) {
    this.controllerFor("group-manage-logs").setProperties({
      model
    });
  },
  willTransition() {
    this.controllerFor("group-manage-logs").reset();
  }
}, (_applyDecoratedDescriptor(_obj, "willTransition", [action], Object.getOwnPropertyDescriptor(_obj, "willTransition"), _obj)), _obj));