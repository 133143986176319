import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <label
  class="btn"
  disabled={{this.uploading}}
  title={{i18n "admin.backups.upload.title"}}
>
  {{d-icon "upload"}}{{this.uploadButtonText}}
  <input
    class="hidden-upload-field"
    disabled={{this.uploading}}
    type="file"
    accept=".gz"
  />
</label>
*/
{
  "id": "je4ghZHh",
  "block": "[[[10,\"label\"],[14,0,\"btn\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[15,\"title\",[28,[37,0],[\"admin.backups.upload.title\"],null]],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"upload\"],null]],[1,[30,0,[\"uploadButtonText\"]]],[1,\"\\n  \"],[10,\"input\"],[14,0,\"hidden-upload-field\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[14,\"accept\",\".gz\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"d-icon\"]]",
  "moduleName": "discourse/components/uppy-backup-uploader.hbs",
  "isStrictMode": false
});