import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './convert-to-public-topic.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import I18n from "discourse-i18n";
import TEMPLATE from "./convert-to-public-topic.hbs";
import { setComponentTemplate } from "@ember/component";
let ConvertToPublicTopic = (_class = class ConvertToPublicTopic extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "appEvents", _descriptor, this);
    _initializerDefineProperty(this, "publicCategoryId", _descriptor2, this);
    _initializerDefineProperty(this, "saving", _descriptor3, this);
    _initializerDefineProperty(this, "flash", _descriptor4, this);
  }
  async makePublic() {
    try {
      this.saving = true;
      await this.args.model.topic.convertTopic("public", {
        categoryId: this.publicCategoryId
      });
      this.args.model.topic.set("archetype", "regular");
      this.args.model.topic.set("category_id", this.publicCategoryId);
      this.appEvents.trigger("header:show-topic", this.args.model.topic);
      this.saving = false;
      this.args.closeModal();
    } catch (e) {
      this.flash = I18n.t("generic_error");
      this.saving = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "publicCategoryId", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "saving", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "makePublic", [action], Object.getOwnPropertyDescriptor(_class.prototype, "makePublic"), _class.prototype)), _class);
export { ConvertToPublicTopic as default };
setComponentTemplate(TEMPLATE, ConvertToPublicTopic);