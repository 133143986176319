import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="empty-state">
  <span class="empty-state-title">
    {{i18n "user_menu.generic_no_items"}}
  </span>
</div>
*/
{
  "id": "QAj6pCPl",
  "block": "[[[10,0],[14,0,\"empty-state\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"empty-state-title\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"user_menu.generic_no_items\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
  "moduleName": "discourse/components/user-menu/items-list-empty-state.hbs",
  "isStrictMode": false
});