import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="d-lightbox__screen-reader-announcer" tabindex="-1">
  <h2
    aria-live="polite"
    aria-level="2"
    aria-atomic="true"
    class="d-lightbox__screen-reader-title"
    id={{@titleElementId}}
  >
    {{#if @currentItem.title}}
      {{i18n
        "experimental_lightbox.screen_reader_image_title"
        current=@counterIndex
        total=@totalItemCount
        title=@currentItem.title
      }}
    {{/if}}
  </h2>
</div>
*/
{
  "id": "jSaUuuX1",
  "block": "[[[10,0],[14,0,\"d-lightbox__screen-reader-announcer\"],[14,\"tabindex\",\"-1\"],[12],[1,\"\\n  \"],[10,\"h2\"],[14,\"aria-live\",\"polite\"],[14,\"aria-level\",\"2\"],[14,\"aria-atomic\",\"true\"],[14,0,\"d-lightbox__screen-reader-title\"],[15,1,[30,1]],[12],[1,\"\\n\"],[41,[30,2,[\"title\"]],[[[1,\"      \"],[1,[28,[35,1],[\"experimental_lightbox.screen_reader_image_title\"],[[\"current\",\"total\",\"title\"],[[30,3],[30,4],[30,2,[\"title\"]]]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@titleElementId\",\"@currentItem\",\"@counterIndex\",\"@totalItemCount\"],false,[\"if\",\"i18n\"]]",
  "moduleName": "discourse/components/d-lightbox/screen-reader-announcer.hbs",
  "isStrictMode": false
});