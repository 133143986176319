import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './edit-category-topic-template.hbs'; */
import { action, computed } from "@ember/object";
import { schedule } from "@ember/runloop";
import { buildCategoryPanel } from "discourse/components/edit-category-panel";
import discourseComputed, { observes } from "discourse-common/utils/decorators";
import TEMPLATE from "./edit-category-topic-template.hbs";
import { setComponentTemplate } from "@ember/component";
export default setComponentTemplate(TEMPLATE, buildCategoryPanel("topic-template", (_dec = discourseComputed("showFormTemplate"), _dec2 = observes("activeTab", "showFormTemplate"), (_obj = {
  showFormTemplate: computed("category.form_template_ids", {
    get() {
      return Boolean(this.category.form_template_ids.length);
    },
    set(key, value) {
      return value;
    }
  }),
  templateTypeToggleLabel(showFormTemplate) {
    if (showFormTemplate) {
      return "admin.form_templates.edit_category.toggle_form_template";
    }
    return "admin.form_templates.edit_category.toggle_freeform";
  },
  toggleTemplateType() {
    this.toggleProperty("showFormTemplate");
    if (!this.showFormTemplate) {
      // Clear associated form templates if switching to freeform
      this.set("category.form_template_ids", []);
    }
  },
  _activeTabChanged() {
    if (this.activeTab && !this.showFormTemplate) {
      schedule("afterRender", () => this.element.querySelector(".d-editor-input").focus());
    }
  }
}, (_applyDecoratedDescriptor(_obj, "templateTypeToggleLabel", [_dec], Object.getOwnPropertyDescriptor(_obj, "templateTypeToggleLabel"), _obj), _applyDecoratedDescriptor(_obj, "toggleTemplateType", [action], Object.getOwnPropertyDescriptor(_obj, "toggleTemplateType"), _obj), _applyDecoratedDescriptor(_obj, "_activeTabChanged", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_activeTabChanged"), _obj)), _obj))));