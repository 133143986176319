import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.isLoading}}
  <span class="title">{{this.title}}</span>
  <div class="spinner {{this.size}}"></div>
{{else}}
  {{yield}}
{{/if}}
*/
{
  "id": "liv03Qpv",
  "block": "[[[41,[30,0,[\"isLoading\"]],[[[1,\"  \"],[10,1],[14,0,\"title\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n  \"],[10,0],[15,0,[29,[\"spinner \",[30,0,[\"size\"]]]]],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"yield\"]]",
  "moduleName": "discourse/components/conditional-loading-section.hbs",
  "isStrictMode": false
});