import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.showSlowModeNotice}}
  <div class="topic-status-info">
    <h3 class="slow-mode-heading">
      <span>
        {{d-icon "hourglass-start"}}
        {{i18n "topic.slow_mode_notice.duration" duration=this.durationText}}
      </span>

      {{#if this.user.canManageTopic}}
        <DButton
          @action={{this.disableSlowMode}}
          @icon="trash-alt"
          class="slow-mode-remove"
        />
      {{/if}}
    </h3>
  </div>
{{/if}}
*/
{
  "id": "1801cP72",
  "block": "[[[41,[30,0,[\"showSlowModeNotice\"]],[[[1,\"  \"],[10,0],[14,0,\"topic-status-info\"],[12],[1,\"\\n    \"],[10,\"h3\"],[14,0,\"slow-mode-heading\"],[12],[1,\"\\n      \"],[10,1],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"hourglass-start\"],null]],[1,\"\\n        \"],[1,[28,[35,2],[\"topic.slow_mode_notice.duration\"],[[\"duration\"],[[30,0,[\"durationText\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"user\",\"canManageTopic\"]],[[[1,\"        \"],[8,[39,3],[[24,0,\"slow-mode-remove\"]],[[\"@action\",\"@icon\"],[[30,0,[\"disableSlowMode\"]],\"trash-alt\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-icon\",\"i18n\",\"d-button\"]]",
  "moduleName": "discourse/components/slow-mode-info.hbs",
  "isStrictMode": false
});