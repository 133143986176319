import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <label
  class="btn"
  disabled={{this.uploadingOrProcessing}}
  title={{i18n "admin.site_settings.uploaded_image_list.upload.title"}}
>
  {{d-icon "far-image"}}&nbsp;{{this.uploadButtonText}}
  <input
    class="hidden-upload-field"
    disabled={{this.uploading}}
    type="file"
    accept="image/*"
    multiple
  />
</label>
{{#if this.uploadingOrProcessing}}
  <span>{{i18n "upload_selector.uploading"}} {{this.uploadProgress}}%</span>
{{/if}}
*/
{
  "id": "jrOoDhht",
  "block": "[[[10,\"label\"],[14,0,\"btn\"],[15,\"disabled\",[30,0,[\"uploadingOrProcessing\"]]],[15,\"title\",[28,[37,0],[\"admin.site_settings.uploaded_image_list.upload.title\"],null]],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"far-image\"],null]],[1,\" \"],[1,[30,0,[\"uploadButtonText\"]]],[1,\"\\n  \"],[10,\"input\"],[14,0,\"hidden-upload-field\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[14,\"accept\",\"image/*\"],[14,\"multiple\",\"\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"uploadingOrProcessing\"]],[[[1,\"  \"],[10,1],[12],[1,[28,[35,0],[\"upload_selector.uploading\"],null]],[1,\" \"],[1,[30,0,[\"uploadProgress\"]]],[1,\"%\"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"i18n\",\"d-icon\",\"if\"]]",
  "moduleName": "discourse/components/images-uploader.hbs",
  "isStrictMode": false
});