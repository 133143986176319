import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
import EmberObject from "@ember/object";
import PreloadStore from "discourse/lib/preload-store";
import { bind } from "discourse-common/utils/decorators";
export default (_obj = {
  after: "message-bus",
  initialize(owner) {
    this.site = owner.lookup("service:site");
    this.messageBus = owner.lookup("service:message-bus");
    const banner = EmberObject.create(PreloadStore.get("banner") || {});
    this.site.set("banner", banner);
    this.messageBus.subscribe("/site/banner", this.onMessage);
  },
  teardown() {
    this.messageBus.unsubscribe("/site/banner", this.onMessage);
  },
  onMessage(data) {
    if (data) {
      this.site.set("banner", EmberObject.create(data));
    } else {
      this.site.set("banner", null);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "onMessage", [bind], Object.getOwnPropertyDescriptor(_obj, "onMessage"), _obj)), _obj);