import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './change-owner.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import DiscourseURL from "discourse/lib/url";
import Topic from "discourse/models/topic";
import I18n from "discourse-i18n";
import TEMPLATE from "./change-owner.hbs";
import { setComponentTemplate } from "@ember/component";
let ChangeOwnerModal = (_class = class ChangeOwnerModal extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "saving", _descriptor, this);
    _initializerDefineProperty(this, "newOwner", _descriptor2, this);
    _initializerDefineProperty(this, "flash", _descriptor3, this);
  }
  get buttonDisabled() {
    return this.saving || isEmpty(this.newOwner);
  }
  get selectedPostsUsername() {
    return this.args.model.selectedPostsUsername;
  }
  get selectedPostIds() {
    return this.args.model.selectedPostIds;
  }
  get selectedPostsCount() {
    return this.args.model.selectedPostsCount;
  }
  async changeOwnershipOfPosts() {
    this.saving = true;
    const options = {
      post_ids: this.selectedPostIds,
      username: this.newOwner
    };
    try {
      await Topic.changeOwners(this.args.model.topic.id, options);
      this.args.closeModal();
      this.args.model.deselectAll();
      if (this.args.model.multiSelect) {
        this.args.model.toggleMultiSelect();
      }
      DiscourseURL.routeTo(this.args.model.topic.url);
    } catch (error) {
      this.flash = I18n.t("topic.change_owner.error");
      this.saving = false;
    }
    return false;
  }
  async updateNewOwner(selected) {
    this.newOwner = selected.firstObject;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "saving", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "newOwner", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "changeOwnershipOfPosts", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changeOwnershipOfPosts"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateNewOwner", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateNewOwner"), _class.prototype)), _class);
export { ChangeOwnerModal as default };
setComponentTemplate(TEMPLATE, ChangeOwnerModal);