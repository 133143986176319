let d = window.define;
d("@ember/render-modifiers/modifiers/did-insert", function () {
  return esc(require("@ember/render-modifiers/modifiers/did-insert"));
});
d("@ember/render-modifiers/modifiers/did-update", function () {
  return esc(require("@ember/render-modifiers/modifiers/did-update"));
});
d("@ember/render-modifiers/modifiers/will-destroy", function () {
  return esc(require("@ember/render-modifiers/modifiers/will-destroy"));
});
import "@ember/render-modifiers/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import esc from "../../@embroider/macros/src/addon/es-compat2";