import { registerWidgetShim } from "discourse/widgets/render-glimmer";
import { createTemplateFactory } from "@ember/template-factory";
registerWidgetShim("header-user-tip-shim", "div.header-user-tip-shim", createTemplateFactory(
/*
  <UserTip @id="first_notification" @triggerSelector=".header-dropdown-toggle.current-user" @placement="bottom-end" @titleText={{i18n "user_tips.first_notification.title"}} @contentText={{i18n "user_tips.first_notification.content"}} @showSkipButton={{true}} />
*/
{
  "id": "/QmICx36",
  "block": "[[[8,[39,0],null,[[\"@id\",\"@triggerSelector\",\"@placement\",\"@titleText\",\"@contentText\",\"@showSkipButton\"],[\"first_notification\",\".header-dropdown-toggle.current-user\",\"bottom-end\",[28,[37,1],[\"user_tips.first_notification.title\"],null],[28,[37,1],[\"user_tips.first_notification.content\"],null],true]],null]],[],false,[\"user-tip\",\"i18n\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/widgets/header-user-tip-shim.js",
  "isStrictMode": false
}));