import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="d-lightbox__carousel">
  {{#if @shouldDisplayCarouselArrows}}
    <DButton
      {{on "click" @showPreviousItem passive=true capture=true}}
      @title="experimental_lightbox.buttons.previous"
      @icon={{@previousButtonIcon}}
      @ariaHidden="true"
      class="d-lightbox__carousel-previous-button btn-flat"
    />
  {{/if}}
  <div
    class="d-lightbox__carousel-items"
    tabindex="-1"
    role="list"
    aria-hidden="true"
    {{on "click" @showSelectedImage passive=true capture=true}}
    {{on "focus" @showSelectedImage passive=true capture=true}}
  >
    {{#each @items as |item|}}
      <img
        data-lightbox-carousel-item={{if
          (eq item.index @currentIndex)
          "current"
          ""
        }}
        fetchPriority="low"
        decoding="async"
        loading="lazy"
        tabindex="-1"
        src={{item.smallURL}}
        data-lightbox-item-index={{item.index}}
        style={{item.cssVars}}
        class={{concat
          "d-lightbox__carousel-item"
          (if (eq item.index @currentIndex) " is-current")
        }}
      />
    {{/each}}
  </div>
  {{#if @shouldDisplayCarouselArrows}}
    <DButton
      {{on "click" @showNextItem passive=true capture=true}}
      @title="experimental_lightbox.buttons.next"
      @icon={{@nextButtonIcon}}
      @ariaHidden="true"
      class="d-lightbox__carousel-next-button btn-flat"
    />
  {{/if}}
</div>
*/
{
  "id": "6sNCM/sw",
  "block": "[[[10,0],[14,0,\"d-lightbox__carousel\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,1],[[24,0,\"d-lightbox__carousel-previous-button btn-flat\"],[4,[38,2],[\"click\",[30,3]],[[\"passive\",\"capture\"],[true,true]]]],[[\"@title\",\"@icon\",\"@ariaHidden\"],[\"experimental_lightbox.buttons.previous\",[30,2],\"true\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[11,0],[24,0,\"d-lightbox__carousel-items\"],[24,\"tabindex\",\"-1\"],[24,\"role\",\"list\"],[24,\"aria-hidden\",\"true\"],[4,[38,2],[\"click\",[30,4]],[[\"passive\",\"capture\"],[true,true]]],[4,[38,2],[\"focus\",[30,4]],[[\"passive\",\"capture\"],[true,true]]],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,5]],null]],null],null,[[[1,\"      \"],[10,\"img\"],[15,\"data-lightbox-carousel-item\",[52,[28,[37,5],[[30,6,[\"index\"]],[30,7]],null],\"current\",\"\"]],[14,\"fetchPriority\",\"low\"],[14,\"decoding\",\"async\"],[14,\"loading\",\"lazy\"],[14,\"tabindex\",\"-1\"],[15,\"src\",[30,6,[\"smallURL\"]]],[15,\"data-lightbox-item-index\",[30,6,[\"index\"]]],[15,5,[30,6,[\"cssVars\"]]],[15,0,[28,[37,6],[\"d-lightbox__carousel-item\",[52,[28,[37,5],[[30,6,[\"index\"]],[30,7]],null],\" is-current\"]],null]],[12],[13],[1,\"\\n\"]],[6]],null],[1,\"  \"],[13],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[8,[39,1],[[24,0,\"d-lightbox__carousel-next-button btn-flat\"],[4,[38,2],[\"click\",[30,9]],[[\"passive\",\"capture\"],[true,true]]]],[[\"@title\",\"@icon\",\"@ariaHidden\"],[\"experimental_lightbox.buttons.next\",[30,8],\"true\"]],null],[1,\"\\n\"]],[]],null],[13]],[\"@shouldDisplayCarouselArrows\",\"@previousButtonIcon\",\"@showPreviousItem\",\"@showSelectedImage\",\"@items\",\"item\",\"@currentIndex\",\"@nextButtonIcon\",\"@showNextItem\"],false,[\"if\",\"d-button\",\"on\",\"each\",\"-track-array\",\"eq\",\"concat\"]]",
  "moduleName": "discourse/components/d-lightbox/carousel.hbs",
  "isStrictMode": false
});