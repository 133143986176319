import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  class="control-group notification-schedule"
  data-setting-name="user-notification-schedule"
>
  <label class="control-label">{{i18n
      "user.notification_schedule.title"
    }}</label>
  <PreferenceCheckbox
    @labelKey="user.notification_schedule.label"
    @checked={{this.model.user_notification_schedule.enabled}}
  />

  {{#if this.model.user_notification_schedule.enabled}}
    <div class="instruction">{{i18n "user.notification_schedule.tip"}}</div>

    <table class="notification-schedule-table">
      <tbody class="notification-schedule-tbody">
        {{#each this.days as |day|}}
          <UserNotificationScheduleDay
            @day={{day.day}}
            @startTimeOptions={{day.startTimeOptions}}
            @startTimeValue={{day.startTimeValue}}
            @onChangeStartTime={{day.onChangeStartTime}}
            @endTimeOptions={{day.endTimeOptions}}
            @endTimeValue={{day.endTimeValue}}
            @onChangeEndTime={{day.onChangeEndTime}}
          />
        {{/each}}
      </tbody>
    </table>
  {{/if}}
</div>
*/
{
  "id": "J5Uvtjsm",
  "block": "[[[10,0],[14,0,\"control-group notification-schedule\"],[14,\"data-setting-name\",\"user-notification-schedule\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"user.notification_schedule.title\"],null]],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@labelKey\",\"@checked\"],[\"user.notification_schedule.label\",[30,0,[\"model\",\"user_notification_schedule\",\"enabled\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"user_notification_schedule\",\"enabled\"]],[[[1,\"    \"],[10,0],[14,0,\"instruction\"],[12],[1,[28,[35,0],[\"user.notification_schedule.tip\"],null]],[13],[1,\"\\n\\n    \"],[10,\"table\"],[14,0,\"notification-schedule-table\"],[12],[1,\"\\n      \"],[10,\"tbody\"],[14,0,\"notification-schedule-tbody\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"days\"]]],null]],null],null,[[[1,\"          \"],[8,[39,5],null,[[\"@day\",\"@startTimeOptions\",\"@startTimeValue\",\"@onChangeStartTime\",\"@endTimeOptions\",\"@endTimeValue\",\"@onChangeEndTime\"],[[30,1,[\"day\"]],[30,1,[\"startTimeOptions\"]],[30,1,[\"startTimeValue\"]],[30,1,[\"onChangeStartTime\"]],[30,1,[\"endTimeOptions\"]],[30,1,[\"endTimeValue\"]],[30,1,[\"onChangeEndTime\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"day\"],false,[\"i18n\",\"preference-checkbox\",\"if\",\"each\",\"-track-array\",\"user-notification-schedule-day\"]]",
  "moduleName": "discourse/components/user-notification-schedule.hbs",
  "isStrictMode": false
});