export default function gte(left, right) {
  let {
    forceNumber = false
  } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (forceNumber) {
    if (typeof left !== "number") {
      left = Number(left);
    }
    if (typeof right !== "number") {
      right = Number(right);
    }
  }
  return left >= right;
}