import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{loading-spinner}}
{{hide-application-footer}}
*/
{
  "id": "UFkU87yJ",
  "block": "[[[1,[34,0]],[1,\"\\n\"],[1,[34,1]]],[],false,[\"loading-spinner\",\"hide-application-footer\"]]",
  "moduleName": "discourse/templates/loading.hbs",
  "isStrictMode": false
});