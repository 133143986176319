import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class2, _descriptor;
import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { popupAjaxError } from "discourse/lib/ajax-error";
import I18n from "discourse-i18n";
let _class = (_class2 = class _class2 extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "saved", _descriptor, this);
    _defineProperty(this, "subpageTitle", I18n.t("user.preferences_nav.navigation_menu"));
    _defineProperty(this, "saveAttrNames", ["sidebar_link_to_filtered_list", "sidebar_show_count_of_new_items"]);
  }
  save() {
    const initialSidebarLinkToFilteredList = this.model.sidebarLinkToFilteredList;
    const initialSidebarShowCountOfNewItems = this.model.sidebarShowCountOfNewItems;
    this.model.set("user_option.sidebar_link_to_filtered_list", this.newSidebarLinkToFilteredList);
    this.model.set("user_option.sidebar_show_count_of_new_items", this.newSidebarShowCountOfNewItems);
    this.model.save(this.saveAttrNames).then(() => {
      this.saved = true;
    }).catch(error => {
      this.model.set("user_option.sidebar_link_to_filtered_list", initialSidebarLinkToFilteredList);
      this.model.set("user_option.sidebar_show_count_of_new_items", initialSidebarShowCountOfNewItems);
      popupAjaxError(error);
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "saved", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class2.prototype, "save", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "save"), _class2.prototype)), _class2);
export { _class as default };