import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @headerClass="hidden"
  class="dismiss-notification-confirmation"
  @closeModal={{@closeModal}}
>
  <:body>
    {{@model.confirmationMessage}}
  </:body>
  <:footer>
    <DButton
      @icon="check"
      class="btn-primary"
      @action={{this.dismiss}}
      @label="notifications.dismiss_confirmation.dismiss"
    />
    <DButton
      @action={{@closeModal}}
      @label="notifications.dismiss_confirmation.cancel"
      class="btn-default"
    />
  </:footer>
</DModal>
*/
{
  "id": "uP5EhAqN",
  "block": "[[[8,[39,0],[[24,0,\"dismiss-notification-confirmation\"]],[[\"@headerClass\",\"@closeModal\"],[\"hidden\",[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[30,2,[\"confirmationMessage\"]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn-primary\"]],[[\"@icon\",\"@action\",\"@label\"],[\"check\",[30,0,[\"dismiss\"]],\"notifications.dismiss_confirmation.dismiss\"]],null],[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn-default\"]],[[\"@action\",\"@label\"],[[30,1],\"notifications.dismiss_confirmation.cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/dismiss-notification-confirmation.hbs",
  "isStrictMode": false
});