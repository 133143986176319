import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
import { bind } from "discourse-common/utils/decorators";

// Subscribe to "read-only" status change events via the Message Bus
export default (_obj = {
  after: "message-bus",
  initialize(owner) {
    this.messageBus = owner.lookup("service:message-bus");
    this.site = owner.lookup("service:site");
    this.messageBus.subscribe("/site/read-only", this.onMessage);
  },
  teardown() {
    this.messageBus.unsubscribe("/site/read-only", this.onMessage);
  },
  onMessage(enabled) {
    this.site.set("isReadOnly", enabled);
  }
}, (_applyDecoratedDescriptor(_obj, "onMessage", [bind], Object.getOwnPropertyDescriptor(_obj, "onMessage"), _obj)), _obj);