import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @bodyClass="change-timestamp"
  @closeModal={{@closeModal}}
  @flash={{this.flash}}
  @title={{i18n "topic.change_timestamp.title"}}
>
  <:body>
    <p>
      {{i18n "topic.change_timestamp.instructions"}}
    </p>
    <p class="alert alert-error {{unless this.validTimestamp 'hidden'}}">
      {{i18n "topic.change_timestamp.invalid_timestamp"}}
    </p>
    <form>
      <DatePickerPast
        @value={{readonly this.date}}
        @containerId="date-container"
        @onSelect={{fn (mut this.date)}}
      />
      <Input @type="time" @value={{this.time}} />
    </form>
    <div id="date-container"></div>
  </:body>
  <:footer>
    <DButton
      class="btn-primary"
      @disabled={{this.buttonDisabled}}
      @action={{this.changeTimestamp}}
      @label={{if this.saving "saving" "topic.change_timestamp.action"}}
    />
  </:footer>
</DModal>
*/
{
  "id": "563N1EeF",
  "block": "[[[8,[39,0],null,[[\"@bodyClass\",\"@closeModal\",\"@flash\",\"@title\"],[\"change-timestamp\",[30,1],[30,0,[\"flash\"]],[28,[37,1],[\"topic.change_timestamp.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"topic.change_timestamp.instructions\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,2],[15,0,[29,[\"alert alert-error \",[52,[51,[30,0,[\"validTimestamp\"]]],\"hidden\"]]]],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"topic.change_timestamp.invalid_timestamp\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"form\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@value\",\"@containerId\",\"@onSelect\"],[[28,[37,4],[[30,0,[\"date\"]]],null],\"date-container\",[28,[37,5],[[28,[37,6],[[30,0,[\"date\"]]],null]],null]]],null],[1,\"\\n      \"],[8,[39,7],null,[[\"@type\",\"@value\"],[\"time\",[30,0,[\"time\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,1,\"date-container\"],[12],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,8],[[24,0,\"btn-primary\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[30,0,[\"changeTimestamp\"]],[52,[30,0,[\"saving\"]],\"saving\",\"topic.change_timestamp.action\"]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"unless\",\"date-picker-past\",\"readonly\",\"fn\",\"mut\",\"input\",\"d-button\",\"if\"]]",
  "moduleName": "discourse/components/modal/change-timestamp.hbs",
  "isStrictMode": false
});