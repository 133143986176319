import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <CategoryChooser @value={{this.categoryId}} @onChange={{this.onChange}} />
*/
{
  "id": "HLHNYAx5",
  "block": "[[[8,[39,0],null,[[\"@value\",\"@onChange\"],[[30,0,[\"categoryId\"]],[30,0,[\"onChange\"]]]],null]],[],false,[\"category-chooser\"]]",
  "moduleName": "discourse/components/reviewable-field-category.hbs",
  "isStrictMode": false
});