import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="browser-search-tip">
  <span class="tip-label">
    {{this.translatedLabel}}
  </span>
  <span class="tip-description">
    {{i18n "search.browser_tip_description"}}
  </span>
</div>
*/
{
  "id": "eIJj0C+q",
  "block": "[[[10,0],[14,0,\"browser-search-tip\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"tip-label\"],[12],[1,\"\\n    \"],[1,[30,0,[\"translatedLabel\"]]],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,1],[14,0,\"tip-description\"],[12],[1,\"\\n    \"],[1,[28,[35,0],[\"search.browser_tip_description\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
  "moduleName": "discourse/components/search-menu/browser-search-tip.hbs",
  "isStrictMode": false
});