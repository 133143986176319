import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <PluginOutlet
  @name="category-title-before"
  @outletArgs={{hash category=this.category}}
/>
*/
{
  "id": "xN5FMwDU",
  "block": "[[[8,[39,0],null,[[\"@name\",\"@outletArgs\"],[\"category-title-before\",[28,[37,1],null,[[\"category\"],[[30,0,[\"category\"]]]]]]],null]],[],false,[\"plugin-outlet\",\"hash\"]]",
  "moduleName": "discourse/components/category-title-before.hbs",
  "isStrictMode": false
});