import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="modal-footer">
  <DButton
    @action={{route-action "closeModal"}}
    @label="close"
    class="btn-primary"
  />
</div>
*/
{
  "id": "Vof6JT7b",
  "block": "[[[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,1],[\"closeModal\"],null],\"close\"]],null],[1,\"\\n\"],[13]],[],false,[\"d-button\",\"route-action\"]]",
  "moduleName": "discourse/components/modal-footer-close.hbs",
  "isStrictMode": false
});