/* import __COLOCATED_TEMPLATE__ from './tap-tile.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { reads } from "@ember/object/computed";
import { propertyEqual } from "discourse/lib/computed";
import TEMPLATE from "./tap-tile.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend({
  init() {
    this._super(...arguments);
    this.set("elementId", `tap_tile_${this.tileId}`);
  },
  classNames: ["tap-tile"],
  classNameBindings: ["active"],
  attributeBindings: ["role", "ariaPressed", "tabIndex"],
  role: "button",
  tabIndex: 0,
  ariaPressed: reads("active"),
  click() {
    this.onChange(this.tileId);
  },
  keyDown(e) {
    if (e.key === "Enter") {
      e.stopPropagation();
      this.onChange(this.tileId);
    }
  },
  active: propertyEqual("activeTile", "tileId")
}));