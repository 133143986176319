import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <li
  aria-current={{this.ariaCurrent}}
  title={{@title}}
  class={{@class}}
  ...attributes
>
  {{#if this.models}}
    <LinkTo @route={{@route}} @models={{this.models}}>
      {{yield}}
    </LinkTo>
  {{else}}
    <LinkTo @route={{@route}}>
      {{yield}}
    </LinkTo>
  {{/if}}
</li>
*/
{
  "id": "HJnqsYNC",
  "block": "[[[11,\"li\"],[16,\"aria-current\",[30,0,[\"ariaCurrent\"]]],[16,\"title\",[30,1]],[16,0,[30,2]],[17,3],[12],[1,\"\\n\"],[41,[30,0,[\"models\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@route\",\"@models\"],[[30,4],[30,0,[\"models\"]]]],[[\"default\"],[[[[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,1],null,[[\"@route\"],[[30,4]]],[[\"default\"],[[[[1,\"\\n      \"],[18,5,null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[]]],[13]],[\"@title\",\"@class\",\"&attrs\",\"@route\",\"&default\"],false,[\"if\",\"link-to\",\"yield\"]]",
  "moduleName": "discourse/components/d-navigation-item.hbs",
  "isStrictMode": false
});