import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default class GroupsIndexRoute extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _defineProperty(this, "queryParams", {
      order: {
        refreshModel: true,
        replace: true
      },
      asc: {
        refreshModel: true,
        replace: true
      },
      filter: {
        refreshModel: true
      },
      type: {
        refreshModel: true,
        replace: true
      },
      username: {
        refreshModel: true
      }
    });
  }
  titleToken() {
    return I18n.t("groups.index.title");
  }
  model(params) {
    return params;
  }
  setupController(controller, params) {
    controller.loadGroups(params);
  }
}