import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  class={{this.classNames}}
  data-tab-id={{this.currentTabId}}
  data-max-width="320"
  {{did-insert this.triggerRenderedAppEvent}}
>
  <div class="panel-body">
    <div class="panel-body-contents">
      <div
        class="menu-tabs-container"
        role="tablist"
        aria-orientation="vertical"
        aria-label={{i18n "user_menu.sr_menu_tabs"}}
      >
        <div class="top-tabs tabs-list" {{did-insert this.focusFirstTab}}>
          {{#each this.topTabs as |tab|}}
            <UserMenu::MenuTab
              @tab={{tab}}
              @currentTabId={{this.currentTabId}}
              @onTabClick={{fn this.handleTabClick tab}}
            />
          {{/each}}
        </div>
        <div class="bottom-tabs tabs-list">
          {{#each this.bottomTabs as |tab|}}
            <UserMenu::MenuTab
              @tab={{tab}}
              @currentTabId={{this.currentTabId}}
              @onTabClick={{fn this.handleTabClick tab}}
            />
          {{/each}}
        </div>
      </div>
      <div
        id={{concat "quick-access-" this.currentTabId}}
        class="quick-access-panel"
        tabindex="-1"
      >
        <this.currentPanelComponent
          @closeUserMenu={{@closeUserMenu}}
          @filterByTypes={{this.currentNotificationTypes}}
          @ariaLabelledby={{concat "user-menu-button-" this.currentTabId}}
        />
      </div>
    </div>
  </div>
</div>
*/
{
  "id": "JKyGdQoF",
  "block": "[[[11,0],[16,0,[30,0,[\"classNames\"]]],[16,\"data-tab-id\",[30,0,[\"currentTabId\"]]],[24,\"data-max-width\",\"320\"],[4,[38,0],[[30,0,[\"triggerRenderedAppEvent\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"panel-body-contents\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"menu-tabs-container\"],[14,\"role\",\"tablist\"],[14,\"aria-orientation\",\"vertical\"],[15,\"aria-label\",[28,[37,1],[\"user_menu.sr_menu_tabs\"],null]],[12],[1,\"\\n        \"],[11,0],[24,0,\"top-tabs tabs-list\"],[4,[38,0],[[30,0,[\"focusFirstTab\"]]],null],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"topTabs\"]]],null]],null],null,[[[1,\"            \"],[8,[39,4],null,[[\"@tab\",\"@currentTabId\",\"@onTabClick\"],[[30,1],[30,0,[\"currentTabId\"]],[28,[37,5],[[30,0,[\"handleTabClick\"]],[30,1]],null]]],null],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"bottom-tabs tabs-list\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"bottomTabs\"]]],null]],null],null,[[[1,\"            \"],[8,[39,4],null,[[\"@tab\",\"@currentTabId\",\"@onTabClick\"],[[30,2],[30,0,[\"currentTabId\"]],[28,[37,5],[[30,0,[\"handleTabClick\"]],[30,2]],null]]],null],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[15,1,[28,[37,6],[\"quick-access-\",[30,0,[\"currentTabId\"]]],null]],[14,0,\"quick-access-panel\"],[14,\"tabindex\",\"-1\"],[12],[1,\"\\n        \"],[8,[30,0,[\"currentPanelComponent\"]],null,[[\"@closeUserMenu\",\"@filterByTypes\",\"@ariaLabelledby\"],[[30,3],[30,0,[\"currentNotificationTypes\"]],[28,[37,6],[\"user-menu-button-\",[30,0,[\"currentTabId\"]]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"tab\",\"tab\",\"@closeUserMenu\"],false,[\"did-insert\",\"i18n\",\"each\",\"-track-array\",\"user-menu/menu-tab\",\"fn\",\"concat\"]]",
  "moduleName": "discourse/components/user-menu/menu.hbs",
  "isStrictMode": false
});