import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
/* import __COLOCATED_TEMPLATE__ from './edit-slow-mode.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { fromSeconds, toSeconds } from "discourse/helpers/slow-mode";
import { extractError } from "discourse/lib/ajax-error";
import { timeShortcuts } from "discourse/lib/time-shortcut";
import Topic from "discourse/models/topic";
import I18n from "discourse-i18n";
import TEMPLATE from "./edit-slow-mode.hbs";
import { setComponentTemplate } from "@ember/component";
const SLOW_MODE_OPTIONS = [{
  id: "600",
  name: I18n.t("topic.slow_mode_update.durations.10_minutes")
}, {
  id: "900",
  name: I18n.t("topic.slow_mode_update.durations.15_minutes")
}, {
  id: "1800",
  name: I18n.t("topic.slow_mode_update.durations.30_minutes")
}, {
  id: "2700",
  name: I18n.t("topic.slow_mode_update.durations.45_minutes")
}, {
  id: "3600",
  name: I18n.t("topic.slow_mode_update.durations.1_hour")
}, {
  id: "7200",
  name: I18n.t("topic.slow_mode_update.durations.2_hours")
}, {
  id: "14400",
  name: I18n.t("topic.slow_mode_update.durations.4_hours")
}, {
  id: "28800",
  name: I18n.t("topic.slow_mode_update.durations.8_hours")
}, {
  id: "43200",
  name: I18n.t("topic.slow_mode_update.durations.12_hours")
}, {
  id: "86400",
  name: I18n.t("topic.slow_mode_update.durations.24_hours")
}, {
  id: "custom",
  name: I18n.t("topic.slow_mode_update.durations.custom")
}];
let EditSlowMode = (_class = class EditSlowMode extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "selectedSlowMode", _descriptor2, this);
    _initializerDefineProperty(this, "hours", _descriptor3, this);
    _initializerDefineProperty(this, "minutes", _descriptor4, this);
    _initializerDefineProperty(this, "seconds", _descriptor5, this);
    _initializerDefineProperty(this, "saveDisabled", _descriptor6, this);
    _initializerDefineProperty(this, "flash", _descriptor7, this);
    const currentDuration = parseInt(this.args.model.topic.slow_mode_seconds, 10);
    if (currentDuration) {
      const selectedDuration = this.slowModes.find(mode => mode.id === currentDuration.toString());
      if (selectedDuration) {
        this.selectedSlowMode = currentDuration.toString();
      } else {
        this.selectedSlowMode = "custom";
      }
      this._setFromSeconds(currentDuration);
    }
  }
  get slowModes() {
    return SLOW_MODE_OPTIONS;
  }
  get saveButtonLabel() {
    return this.args.model.topic.slow_mode_seconds && this.args.model.topic.slow_mode_seconds !== 0 ? "topic.slow_mode_update.update" : "topic.slow_mode_update.enable";
  }
  get timeShortcuts() {
    const timezone = this.currentUser.user_option.timezone;
    const shortcuts = timeShortcuts(timezone);
    const nextWeek = shortcuts.monday();
    nextWeek.label = "time_shortcut.next_week";
    return [shortcuts.laterToday(), shortcuts.tomorrow(), shortcuts.twoDays(), nextWeek, shortcuts.twoWeeks(), shortcuts.nextMonth(), shortcuts.twoMonths()];
  }
  get showCustomSelect() {
    return this.selectedSlowMode === "custom";
  }
  get durationIsSet() {
    return this.hours || this.minutes || this.seconds;
  }
  async enableSlowMode() {
    this.saveDisabled = true;
    const seconds = toSeconds(this._parseValue(this.hours), this._parseValue(this.minutes), this._parseValue(this.seconds));
    try {
      await Topic.setSlowMode(this.args.model.topic.id, seconds, this.args.model.topic.slow_mode_enabled_until);
      this.args.model.topic.set("slow_mode_seconds", seconds);
      this.args.closeModal();
    } catch {
      this.flash = I18n.t("generic_error");
    } finally {
      this.saveDisabled = false;
    }
  }
  async disableSlowMode() {
    this.saveDisabled = true;
    try {
      await Topic.setSlowMode(this.args.model.topic.id, 0);
      this.args.model.topic.set("slow_mode_seconds", 0);
      this.args.closeModal();
    } catch (e) {
      this.flash = extractError(e);
    } finally {
      this.saveDisabled = false;
    }
  }
  setSlowModeDuration(duration) {
    if (duration !== "custom") {
      let seconds = parseInt(duration, 10);
      this._setFromSeconds(seconds);
    }
    this.selectedSlowMode = duration;
  }
  _setFromSeconds(seconds) {
    const {
      hours,
      minutes,
      seconds: componentSeconds
    } = fromSeconds(seconds);
    this.hours = hours;
    this.minutes = minutes;
    this.seconds = componentSeconds;
  }
  _parseValue(value) {
    return parseInt(value, 10) || 0;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedSlowMode", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hours", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "minutes", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "seconds", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "saveDisabled", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "enableSlowMode", [action], Object.getOwnPropertyDescriptor(_class.prototype, "enableSlowMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "disableSlowMode", [action], Object.getOwnPropertyDescriptor(_class.prototype, "disableSlowMode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSlowModeDuration", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setSlowModeDuration"), _class.prototype)), _class);
export { EditSlowMode as default };
setComponentTemplate(TEMPLATE, EditSlowMode);