/* import __COLOCATED_TEMPLATE__ from './edit-category-panel.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { equal } from "@ember/object/computed";
import TEMPLATE from "./edit-category-panel.hbs";
const EditCategoryPanel = Component.extend({});
export default setComponentTemplate(TEMPLATE, EditCategoryPanel);
export function buildCategoryPanel(tab, extras) {
  return EditCategoryPanel.extend({
    activeTab: equal("selectedTab", tab),
    classNameBindings: [":edit-category-tab", "activeTab::hide", `:edit-category-tab-${tab}`]
  }, extras || {});
}