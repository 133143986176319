import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.showButton}}
  <DButton
    @action={{action "openSystemFilePicker"}}
    @label={{this.label}}
    @icon={{this.icon}}
  />
{{/if}}
{{#if this.acceptsAllFormats}}
  <input
    type="file"
    id={{this.fileInputId}}
    class={{this.fileInputClass}}
    multiple={{this.allowMultiple}}
    disabled={{this.fileInputDisabled}}
  />
{{else}}
  <input
    type="file"
    id={{this.fileInputId}}
    class={{this.fileInputClass}}
    accept={{this.acceptedFormats}}
    multiple={{this.allowMultiple}}
    disabled={{this.fileInputDisabled}}
  />
{{/if}}
*/
{
  "id": "+leCynKR",
  "block": "[[[41,[30,0,[\"showButton\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@action\",\"@label\",\"@icon\"],[[28,[37,2],[[30,0],\"openSystemFilePicker\"],null],[30,0,[\"label\"]],[30,0,[\"icon\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"acceptsAllFormats\"]],[[[1,\"  \"],[10,\"input\"],[15,1,[30,0,[\"fileInputId\"]]],[15,0,[30,0,[\"fileInputClass\"]]],[15,\"multiple\",[30,0,[\"allowMultiple\"]]],[15,\"disabled\",[30,0,[\"fileInputDisabled\"]]],[14,4,\"file\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,\"input\"],[15,1,[30,0,[\"fileInputId\"]]],[15,0,[30,0,[\"fileInputClass\"]]],[15,\"accept\",[30,0,[\"acceptedFormats\"]]],[15,\"multiple\",[30,0,[\"allowMultiple\"]]],[15,\"disabled\",[30,0,[\"fileInputDisabled\"]]],[14,4,\"file\"],[12],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"d-button\",\"action\"]]",
  "moduleName": "discourse/components/pick-files-button.hbs",
  "isStrictMode": false
});