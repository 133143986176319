import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DEditor
  @value={{this.composer.reply}}
  @placeholder={{this.replyPlaceholder}}
  @previewUpdated={{action "previewUpdated"}}
  @markdownOptions={{this.markdownOptions}}
  @extraButtons={{action "extraButtons"}}
  @importQuote={{action "importQuote"}}
  @showUploadModal={{this.showUploadModal}}
  @togglePreview={{action "togglePreview"}}
  @processPreview={{this.processPreview}}
  @validation={{this.validation}}
  @loading={{this.composer.loading}}
  @forcePreview={{this.forcePreview}}
  @showLink={{this.showLink}}
  @composerEvents={{true}}
  @onExpandPopupMenuOptions={{action "onExpandPopupMenuOptions"}}
  @onPopupMenuAction={{this.onPopupMenuAction}}
  @popupMenuOptions={{this.popupMenuOptions}}
  @formTemplateId={{this.composer.formTemplateId}}
  @formTemplateIds={{this.formTemplateIds}}
  @formTemplateInitialValues={{@formTemplateInitialValues}}
  @onSelectFormTemplate={{@onSelectFormTemplate}}
  @replyingToTopic={{this.composer.replyingToTopic}}
  @editingPost={{this.composer.editingPost}}
  @disabled={{this.disableTextarea}}
  @outletArgs={{hash composer=this.composer editorType="composer"}}
  @topicId={{this.composer.topic.id}}
  @categoryId={{this.composer.category.id}}
>
  {{yield}}
</DEditor>

{{#if this.allowUpload}}
  <PickFilesButton
    @fileInputId="file-uploader"
    @allowMultiple={{true}}
    name="file-uploader"
  />
{{/if}}
*/
{
  "id": "dv/pN7rH",
  "block": "[[[8,[39,0],null,[[\"@value\",\"@placeholder\",\"@previewUpdated\",\"@markdownOptions\",\"@extraButtons\",\"@importQuote\",\"@showUploadModal\",\"@togglePreview\",\"@processPreview\",\"@validation\",\"@loading\",\"@forcePreview\",\"@showLink\",\"@composerEvents\",\"@onExpandPopupMenuOptions\",\"@onPopupMenuAction\",\"@popupMenuOptions\",\"@formTemplateId\",\"@formTemplateIds\",\"@formTemplateInitialValues\",\"@onSelectFormTemplate\",\"@replyingToTopic\",\"@editingPost\",\"@disabled\",\"@outletArgs\",\"@topicId\",\"@categoryId\"],[[30,0,[\"composer\",\"reply\"]],[30,0,[\"replyPlaceholder\"]],[28,[37,1],[[30,0],\"previewUpdated\"],null],[30,0,[\"markdownOptions\"]],[28,[37,1],[[30,0],\"extraButtons\"],null],[28,[37,1],[[30,0],\"importQuote\"],null],[30,0,[\"showUploadModal\"]],[28,[37,1],[[30,0],\"togglePreview\"],null],[30,0,[\"processPreview\"]],[30,0,[\"validation\"]],[30,0,[\"composer\",\"loading\"]],[30,0,[\"forcePreview\"]],[30,0,[\"showLink\"]],true,[28,[37,1],[[30,0],\"onExpandPopupMenuOptions\"],null],[30,0,[\"onPopupMenuAction\"]],[30,0,[\"popupMenuOptions\"]],[30,0,[\"composer\",\"formTemplateId\"]],[30,0,[\"formTemplateIds\"]],[30,1],[30,2],[30,0,[\"composer\",\"replyingToTopic\"]],[30,0,[\"composer\",\"editingPost\"]],[30,0,[\"disableTextarea\"]],[28,[37,2],null,[[\"composer\",\"editorType\"],[[30,0,[\"composer\"]],\"composer\"]]],[30,0,[\"composer\",\"topic\",\"id\"]],[30,0,[\"composer\",\"category\",\"id\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,3,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[41,[30,0,[\"allowUpload\"]],[[[1,\"  \"],[8,[39,5],[[24,3,\"file-uploader\"]],[[\"@fileInputId\",\"@allowMultiple\"],[\"file-uploader\",true]],null],[1,\"\\n\"]],[]],null]],[\"@formTemplateInitialValues\",\"@onSelectFormTemplate\",\"&default\"],false,[\"d-editor\",\"action\",\"hash\",\"yield\",\"if\",\"pick-files-button\"]]",
  "moduleName": "discourse/components/composer-editor.hbs",
  "isStrictMode": false
});