import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n
    "user.associated_accounts.confirm_modal_title"
    provider=(i18n (concat "login." @model.provider_name ".name"))
  }}
  @closeModal={{@closeModal}}
  @flash={{this.flash}}
  @flashType="error"
>
  <:body>
    {{#if @model.existing_account_description}}
      <p>
        {{i18n
          "user.associated_accounts.confirm_description.disconnect"
          provider=(i18n (concat "login." @model.provider_name ".name"))
          account_description=@model.existing_account_description
        }}
      </p>
    {{/if}}

    <p>
      {{#if @model.account_description}}
        {{i18n
          "user.associated_accounts.confirm_description.account_specific"
          provider=(i18n (concat "login." @model.provider_name ".name"))
          account_description=@model.account_description
        }}
      {{else}}
        {{i18n
          "user.associated_accounts.confirm_description.generic"
          provider=(i18n (concat "login." @model.provider_name ".name"))
        }}
      {{/if}}
    </p>
  </:body>

  <:footer>
    <DButton
      @label="user.associated_accounts.connect"
      @action={{this.finishConnect}}
      @icon="plug"
      class="btn-primary"
    />
    <DButton @label="user.associated_accounts.cancel" @action={{@closeModal}} />
  </:footer>
</DModal>
*/
{
  "id": "NGzv3Ogg",
  "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\",\"@flash\",\"@flashType\"],[[28,[37,1],[\"user.associated_accounts.confirm_modal_title\"],[[\"provider\"],[[28,[37,1],[[28,[37,2],[\"login.\",[30,1,[\"provider_name\"]],\".name\"],null]],null]]]],[30,2],[30,0,[\"flash\"]],\"error\"]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,1,[\"existing_account_description\"]],[[[1,\"      \"],[10,2],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"user.associated_accounts.confirm_description.disconnect\"],[[\"provider\",\"account_description\"],[[28,[37,1],[[28,[37,2],[\"login.\",[30,1,[\"provider_name\"]],\".name\"],null]],null],[30,1,[\"existing_account_description\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,2],[12],[1,\"\\n\"],[41,[30,1,[\"account_description\"]],[[[1,\"        \"],[1,[28,[35,1],[\"user.associated_accounts.confirm_description.account_specific\"],[[\"provider\",\"account_description\"],[[28,[37,1],[[28,[37,2],[\"login.\",[30,1,[\"provider_name\"]],\".name\"],null]],null],[30,1,[\"account_description\"]]]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,1],[\"user.associated_accounts.confirm_description.generic\"],[[\"provider\"],[[28,[37,1],[[28,[37,2],[\"login.\",[30,1,[\"provider_name\"]],\".name\"],null]],null]]]]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\",\"@icon\"],[\"user.associated_accounts.connect\",[30,0,[\"finishConnect\"]],\"plug\"]],null],[1,\"\\n    \"],[8,[39,4],null,[[\"@label\",\"@action\"],[\"user.associated_accounts.cancel\",[30,2]]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"concat\",\"if\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/associate-account-confirm.hbs",
  "isStrictMode": false
});