import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";
let template = compiler({
  "1": function (container, depth0, helpers, partials, data) {
    var lookupProperty = container.lookupProperty || function (parent, propertyName) {
      if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
        return parent[propertyName];
      }
      return undefined;
    };
    return "  <span class=\"static-label\">" + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "view.staticLabel", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 2,
          "column": 29
        },
        "end": {
          "line": 2,
          "column": 49
        }
      }
    })) + "</span>\n";
  },
  "3": function (container, depth0, helpers, partials, data) {
    var stack1,
      alias1 = depth0 != null ? depth0 : container.nullContext || {},
      alias2 = container.escapeExpression,
      lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
    return "  <button class=\"topics-replies-toggle --all" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "view.allActive", {
      "name": "if",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "fn": container.program(4, data, 0),
      "inverse": container.noop,
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 4,
          "column": 44
        },
        "end": {
          "line": 4,
          "column": 80
        }
      }
    })) != null ? stack1 : "") + "\">\n    " + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(alias1, "filters.new.all", {
      "name": "i18n",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["StringLiteral"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 5,
          "column": 4
        },
        "end": {
          "line": 5,
          "column": 30
        }
      }
    })) + "\n  </button>\n  <button class=\"topics-replies-toggle --topics" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "view.topicsActive", {
      "name": "if",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "fn": container.program(4, data, 0),
      "inverse": container.noop,
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 7,
          "column": 47
        },
        "end": {
          "line": 7,
          "column": 86
        }
      }
    })) != null ? stack1 : "") + "\">\n    " + alias2(lookupProperty(helpers, "get").call(alias1, "view.topicsButtonLabel", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 8,
          "column": 4
        },
        "end": {
          "line": 8,
          "column": 30
        }
      }
    })) + "\n  </button>\n  <button class=\"topics-replies-toggle --replies" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "view.repliesActive", {
      "name": "if",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "fn": container.program(4, data, 0),
      "inverse": container.noop,
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 10,
          "column": 48
        },
        "end": {
          "line": 10,
          "column": 88
        }
      }
    })) != null ? stack1 : "") + "\">\n    " + alias2(lookupProperty(helpers, "get").call(alias1, "view.repliesButtonLabel", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 11,
          "column": 4
        },
        "end": {
          "line": 11,
          "column": 31
        }
      }
    })) + "\n  </button>\n";
  },
  "4": function (container, depth0, helpers, partials, data) {
    return " active";
  },
  "compiler": [8, ">= 4.3.0"],
  "main": function (container, depth0, helpers, partials, data) {
    var stack1,
      lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
    return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "view.staticLabel", {
      "name": "if",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "fn": container.program(1, data, 0),
      "inverse": container.program(3, data, 0),
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 1,
          "column": 0
        },
        "end": {
          "line": 13,
          "column": 7
        }
      }
    })) != null ? stack1 : "";
  },
  "useData": true
});
addRawTemplate("list/new-list-header-controls", template, {
  core: true
});
export default template;