import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal @title={{i18n "log_in"}} @closeModal={{@closeModal}}>
  <:body>
    {{html-safe
      (i18n
        "login.sent_activation_email_again" currentEmail=@model.currentEmail
      )
    }}
  </:body>
</DModal>
*/
{
  "id": "m2FNS6ta",
  "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"log_in\"],null],[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[[28,[37,1],[\"login.sent_activation_email_again\"],[[\"currentEmail\"],[[30,2,[\"currentEmail\"]]]]]],null]],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"html-safe\"]]",
  "moduleName": "discourse/components/modal/activation-resent.hbs",
  "isStrictMode": false
});