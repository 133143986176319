import { registerWidgetShim } from "discourse/widgets/render-glimmer";
import { createTemplateFactory } from "@ember/template-factory";
registerWidgetShim("before-header-logo-outlet", "div.before-header-logo-outlet", createTemplateFactory(
/*
  <PluginOutlet @name="before-header-logo" @outletArgs={{hash attrs=@data}} /> 
*/
{
  "id": "jiWtOVMf",
  "block": "[[[8,[39,0],null,[[\"@name\",\"@outletArgs\"],[\"before-header-logo\",[28,[37,1],null,[[\"attrs\"],[[30,1]]]]]],null],[1,\" \"]],[\"@data\"],false,[\"plugin-outlet\",\"hash\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/widgets/before-header-logo-outlet.js",
  "isStrictMode": false
}));