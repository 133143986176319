import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DButton
  @action={{@action}}
  @icon="times"
  @label="composer.esc"
  @ariaLabel="composer.esc_label"
  class="btn-transparent close"
/>
*/
{
  "id": "aeXYI+Dd",
  "block": "[[[8,[39,0],[[24,0,\"btn-transparent close\"]],[[\"@action\",\"@icon\",\"@label\",\"@ariaLabel\"],[[30,1],\"times\",\"composer.esc\",\"composer.esc_label\"]],null]],[\"@action\"],false,[\"d-button\"]]",
  "moduleName": "discourse/components/composer-tip-close-button.hbs",
  "isStrictMode": false
});