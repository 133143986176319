import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="signup-cta alert alert-info">
  {{#if this.session.hideSignupCta}}
    <h3>
      {{i18n "signup_cta.hidden_for_session"}}
    </h3>
  {{else}}
    <h3>{{replace-emoji (i18n "signup_cta.intro")}}</h3>
    <p>{{replace-emoji (i18n "signup_cta.value_prop")}}</p>

    <div class="buttons">
      <DButton
        @action={{route-action "showCreateAccount"}}
        @label="signup_cta.sign_up"
        @icon="user"
        class="btn-primary"
      />
      <DButton
        @action={{action "hideForSession"}}
        @label="signup_cta.hide_session"
        class="no-icon"
      />
      <a href {{on "click" this.neverShow}}>{{i18n
          "signup_cta.hide_forever"
        }}</a>
    </div>
  {{/if}}
</div>
*/
{
  "id": "lEqV6T74",
  "block": "[[[10,0],[14,0,\"signup-cta alert alert-info\"],[12],[1,\"\\n\"],[41,[30,0,[\"session\",\"hideSignupCta\"]],[[[1,\"    \"],[10,\"h3\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"signup_cta.hidden_for_session\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,\"h3\"],[12],[1,[28,[35,2],[[28,[37,1],[\"signup_cta.intro\"],null]],null]],[13],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,2],[[28,[37,1],[\"signup_cta.value_prop\"],null]],null]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"buttons\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@icon\"],[[28,[37,4],[\"showCreateAccount\"],null],\"signup_cta.sign_up\",\"user\"]],null],[1,\"\\n      \"],[8,[39,3],[[24,0,\"no-icon\"]],[[\"@action\",\"@label\"],[[28,[37,5],[[30,0],\"hideForSession\"],null],\"signup_cta.hide_session\"]],null],[1,\"\\n      \"],[11,3],[24,6,\"\"],[4,[38,6],[\"click\",[30,0,[\"neverShow\"]]],null],[12],[1,[28,[35,1],[\"signup_cta.hide_forever\"],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[],false,[\"if\",\"i18n\",\"replace-emoji\",\"d-button\",\"route-action\",\"action\",\"on\"]]",
  "moduleName": "discourse/components/signup-cta.hbs",
  "isStrictMode": false
});