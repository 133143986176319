import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @closeModal={{@closeModal}}
  @title={{i18n "user.user_notifications.ignore_duration_title"}}
  @flash={{this.flash}}
  @autoFocus="false"
  class="ignore-duration-with-username-modal"
>
  <:body>
    {{#if this.enableSelection}}
      <div class="controls tracking-controls">
        <label>{{d-icon "far-eye-slash" class="icon"}}
          {{i18n "user.user_notifications.ignore_duration_username"}}</label>
        <EmailGroupUserChooser
          @value={{this.ignoredUsername}}
          @onChange={{this.updateIgnoredUsername}}
          @options={{hash excludeCurrentUser=true maximum=1}}
        />
      </div>
    {{/if}}
    <FutureDateInput
      @label="user.user_notifications.ignore_duration_when"
      @input={{readonly this.ignoredUntil}}
      @customShortcuts={{this.timeShortcuts}}
      @includeDateTime={{false}}
      @onChangeInput={{fn (mut this.ignoredUntil)}}
    />
    <p>{{i18n "user.user_notifications.ignore_duration_note"}}</p>
  </:body>
  <:footer>
    <DButton
      @disabled={{this.saveDisabled}}
      @label="user.user_notifications.ignore_duration_save"
      @action={{this.ignore}}
      class="btn-primary"
    />
    <ConditionalLoadingSpinner @size="small" @condition={{this.loading}} />
  </:footer>
</DModal>
*/
{
  "id": "PBBPy9jp",
  "block": "[[[8,[39,0],[[24,0,\"ignore-duration-with-username-modal\"]],[[\"@closeModal\",\"@title\",\"@flash\",\"@autoFocus\"],[[30,1],[28,[37,1],[\"user.user_notifications.ignore_duration_title\"],null],[30,0,[\"flash\"]],\"false\"]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,0,[\"enableSelection\"]],[[[1,\"      \"],[10,0],[14,0,\"controls tracking-controls\"],[12],[1,\"\\n        \"],[10,\"label\"],[12],[1,[28,[35,3],[\"far-eye-slash\"],[[\"class\"],[\"icon\"]]]],[1,\"\\n          \"],[1,[28,[35,1],[\"user.user_notifications.ignore_duration_username\"],null]],[13],[1,\"\\n        \"],[8,[39,4],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,0,[\"ignoredUsername\"]],[30,0,[\"updateIgnoredUsername\"]],[28,[37,5],null,[[\"excludeCurrentUser\",\"maximum\"],[true,1]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,6],null,[[\"@label\",\"@input\",\"@customShortcuts\",\"@includeDateTime\",\"@onChangeInput\"],[\"user.user_notifications.ignore_duration_when\",[28,[37,7],[[30,0,[\"ignoredUntil\"]]],null],[30,0,[\"timeShortcuts\"]],false,[28,[37,8],[[28,[37,9],[[30,0,[\"ignoredUntil\"]]],null]],null]]],null],[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[\"user.user_notifications.ignore_duration_note\"],null]],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,10],[[24,0,\"btn-primary\"]],[[\"@disabled\",\"@label\",\"@action\"],[[30,0,[\"saveDisabled\"]],\"user.user_notifications.ignore_duration_save\",[30,0,[\"ignore\"]]]],null],[1,\"\\n    \"],[8,[39,11],null,[[\"@size\",\"@condition\"],[\"small\",[30,0,[\"loading\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"if\",\"d-icon\",\"email-group-user-chooser\",\"hash\",\"future-date-input\",\"readonly\",\"fn\",\"mut\",\"d-button\",\"conditional-loading-spinner\"]]",
  "moduleName": "discourse/components/modal/ignore-duration-with-username.hbs",
  "isStrictMode": false
});