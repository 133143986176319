let d = window.define;
d("discourse-widget-hbs/helpers", function () {
  return esc(require("discourse-widget-hbs/helpers"));
});
d("ember-this-fallback/deprecations-helper", function () {
  return esc(require("ember-this-fallback/deprecations-helper"));
});
d("ember-this-fallback/get-owner", function () {
  return esc(require("ember-this-fallback/get-owner"));
});
d("ember-this-fallback/is-component", function () {
  return esc(require("ember-this-fallback/is-component"));
});
d("ember-this-fallback/this-fallback-helper", function () {
  return esc(require("ember-this-fallback/this-fallback-helper"));
});
d("ember-this-fallback/try-lookup-helper", function () {
  return esc(require("ember-this-fallback/try-lookup-helper"));
});
d("ember-this-fallback/types/deprecations", function () {
  return esc(require("ember-this-fallback/types/deprecations"));
});
d("ember-this-fallback/types/util", function () {
  return esc(require("ember-this-fallback/types/util"));
});
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "ember-template-imports/-embroider-implicit-modules.js";
import "discourse-widget-hbs/-embroider-implicit-modules.js";
import "ember-cli-babel/-embroider-implicit-modules.js";
import "ember-this-fallback/-embroider-implicit-modules.js";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";