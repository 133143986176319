import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <form class="groups-form form-vertical">
  <GroupsFormMembershipFields @model={{this.model}} />
  <GroupManageSaveButton @model={{this.model}} />
</form>
*/
{
  "id": "1jAIzYSl",
  "block": "[[[10,\"form\"],[14,0,\"groups-form form-vertical\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"groups-form-membership-fields\",\"group-manage-save-button\"]]",
  "moduleName": "discourse/templates/group/manage/membership.hbs",
  "isStrictMode": false
});