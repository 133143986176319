import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
import Component from "@ember/component";
import highlightSearch from "discourse/lib/highlight-search";
import { observes, on } from "discourse-common/utils/decorators";
export default Component.extend((_dec = on("didInsertElement"), _dec2 = observes("highlight"), (_obj = {
  tagName: "span",
  _highlightOnInsert() {
    const term = this.highlight;
    highlightSearch(this.element, term);
  }
}, (_applyDecoratedDescriptor(_obj, "_highlightOnInsert", [_dec, _dec2], Object.getOwnPropertyDescriptor(_obj, "_highlightOnInsert"), _obj)), _obj)));