import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.enabled}}
  <div class="reviewable-claimed-topic">
    {{#if this.claimedBy}}
      <div class="claimed-by">
        {{avatar this.claimedBy imageSize="small"}}
        <span class="claimed-username">{{this.claimedBy.username}}</span>
      </div>
      <DButton
        @icon="times"
        @action={{action "unclaim"}}
        @disabled={{this.unassigning}}
        @title="review.unclaim.help"
        class="btn-small unclaim"
      />
    {{else}}
      <DButton
        @icon="user-plus"
        @title="review.claim.title"
        @action={{action "claim"}}
        class="btn-small claim"
      />
    {{/if}}
  </div>
{{/if}}
*/
{
  "id": "Vfzaim/m",
  "block": "[[[41,[30,0,[\"enabled\"]],[[[1,\"  \"],[10,0],[14,0,\"reviewable-claimed-topic\"],[12],[1,\"\\n\"],[41,[30,0,[\"claimedBy\"]],[[[1,\"      \"],[10,0],[14,0,\"claimed-by\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[[30,0,[\"claimedBy\"]]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n        \"],[10,1],[14,0,\"claimed-username\"],[12],[1,[30,0,[\"claimedBy\",\"username\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[39,2],[[24,0,\"btn-small unclaim\"]],[[\"@icon\",\"@action\",\"@disabled\",\"@title\"],[\"times\",[28,[37,3],[[30,0],\"unclaim\"],null],[30,0,[\"unassigning\"]],\"review.unclaim.help\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,2],[[24,0,\"btn-small claim\"]],[[\"@icon\",\"@title\",\"@action\"],[\"user-plus\",\"review.claim.title\",[28,[37,3],[[30,0],\"claim\"],null]]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"avatar\",\"d-button\",\"action\"]]",
  "moduleName": "discourse/components/reviewable-claimed-topic.hbs",
  "isStrictMode": false
});