import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.tags}}
  <div class="list-tags">
    {{#each this.tags as |t|}}{{discourse-tag t}}{{/each}}
  </div>
{{/if}}
*/
{
  "id": "RF7OqWzG",
  "block": "[[[41,[30,0,[\"tags\"]],[[[1,\"  \"],[10,0],[14,0,\"list-tags\"],[12],[1,\"\\n    \"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"tags\"]]],null]],null],null,[[[1,[28,[35,3],[[30,1]],null]]],[1]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"t\"],false,[\"if\",\"each\",\"-track-array\",\"discourse-tag\"]]",
  "moduleName": "discourse/components/reviewable-tags.hbs",
  "isStrictMode": false
});