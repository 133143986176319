import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <UserPreferences::Tags
  @model={{this.model}}
  @selectedTags={{this.selectedTags}}
  @save={{action "save"}}
  @siteSettings={{this.siteSettings}}
/>

<SaveControls
  @model={{this.model}}
  @action={{action "save"}}
  @saved={{this.saved}}
/>
*/
{
  "id": "iumgIZhk",
  "block": "[[[8,[39,0],null,[[\"@model\",\"@selectedTags\",\"@save\",\"@siteSettings\"],[[30,0,[\"model\"]],[30,0,[\"selectedTags\"]],[28,[37,1],[[30,0],\"save\"],null],[30,0,[\"siteSettings\"]]]],null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@model\",\"@action\",\"@saved\"],[[30,0,[\"model\"]],[28,[37,1],[[30,0],\"save\"],null],[30,0,[\"saved\"]]]],null]],[],false,[\"user-preferences/tags\",\"action\",\"save-controls\"]]",
  "moduleName": "discourse/templates/preferences/tags.hbs",
  "isStrictMode": false
});