import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import Controller from "@ember/controller";
import { gt } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend((_dec = discourseComputed("model.contact_url", "model.contact_email"), (_obj = {
  faqOverridden: gt("siteSettings.faq_url.length", 0),
  contactInfo(url, email) {
    if (url) {
      return I18n.t("about.contact_info", {
        contact_info: `<a href='${url}' target='_blank'>${url}</a>`
      });
    } else if (email) {
      return I18n.t("about.contact_info", {
        contact_info: email
      });
    } else {
      return null;
    }
  }
}, (_applyDecoratedDescriptor(_obj, "contactInfo", [_dec], Object.getOwnPropertyDescriptor(_obj, "contactInfo"), _obj)), _obj)));