import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#each this.colors as |c|}}
  <ColorPickerChoice
    @color={{c}}
    @usedColors={{this.usedColors}}
    @selectColor={{action "selectColor"}}
  >
    {{d-icon "check"}}
  </ColorPickerChoice>
{{/each}}
*/
{
  "id": "onNtuCxk",
  "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"colors\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@color\",\"@usedColors\",\"@selectColor\"],[[30,1],[30,0,[\"usedColors\"]],[28,[37,3],[[30,0],\"selectColor\"],null]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,4],[\"check\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[1]],null]],[\"c\"],false,[\"each\",\"-track-array\",\"color-picker-choice\",\"action\",\"d-icon\"]]",
  "moduleName": "discourse/components/color-picker.hbs",
  "isStrictMode": false
});