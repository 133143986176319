import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './categories-boxes-with-topics.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { isEmpty } from "@ember/utils";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./categories-boxes-with-topics.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("categories.[].uploaded_logo.url"), (_obj = {
  tagName: "section",
  classNameBindings: [":category-boxes-with-topics", "anyLogos:with-logos:no-logos"],
  lockIcon: "lock",
  anyLogos() {
    return this.categories.any(c => {
      return !isEmpty(c.get("uploaded_logo.url"));
    });
  }
}, (_applyDecoratedDescriptor(_obj, "anyLogos", [_dec], Object.getOwnPropertyDescriptor(_obj, "anyLogos"), _obj)), _obj))));