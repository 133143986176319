import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";
let template = compiler({
  "compiler": [8, ">= 4.3.0"],
  "main": function (container, depth0, helpers, partials, data) {
    var alias1 = depth0 != null ? depth0 : container.nullContext || {},
      alias2 = container.escapeExpression,
      alias3 = container.hooks.helperMissing,
      lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
    return "<button class='btn-transparent " + alias2(lookupProperty(helpers, "get").call(alias1, "class", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 1,
          "column": 31
        },
        "end": {
          "line": 1,
          "column": 40
        }
      }
    })) + "' title='" + alias2((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias3).call(alias1, "topics.bulk.toggle", {
      "name": "i18n",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["StringLiteral"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 1,
          "column": 49
        },
        "end": {
          "line": 1,
          "column": 78
        }
      }
    })) + "'>\n  " + alias2((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias3).call(alias1, "icon", {
      "name": "d-icon",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 2,
          "column": 2
        },
        "end": {
          "line": 2,
          "column": 17
        }
      }
    })) + "\n</button>\n";
  },
  "useData": true
});
addRawTemplate("flat-button", template, {
  core: true
});
export default template;