import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './login-buttons.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { isWebauthnSupported } from "discourse/lib/webauthn";
import { findAll } from "discourse/models/login-method";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./login-buttons.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("buttons.length", "showLoginWithEmailLink", "showPasskeysButton"), (_obj = {
  elementId: "login-buttons",
  classNameBindings: ["hidden"],
  hidden(buttonsCount, showLoginWithEmailLink, showPasskeysButton) {
    return buttonsCount === 0 && !showLoginWithEmailLink && !showPasskeysButton;
  },
  buttons() {
    return findAll();
  },
  showPasskeysButton() {
    return this.siteSettings.enable_local_logins && this.siteSettings.enable_passkeys && this.context === "login" && isWebauthnSupported();
  }
}, (_applyDecoratedDescriptor(_obj, "hidden", [_dec], Object.getOwnPropertyDescriptor(_obj, "hidden"), _obj), _applyDecoratedDescriptor(_obj, "buttons", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "buttons"), _obj), _applyDecoratedDescriptor(_obj, "showPasskeysButton", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "showPasskeysButton"), _obj)), _obj))));