import { postRNWebviewMessage } from "discourse/lib/utilities";
export async function getSiteThemeColor() {
  const siteThemeColor = document.querySelector('meta[name="theme-color"]');
  return siteThemeColor?.content;
}
export async function setSiteThemeColor() {
  let color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "000000";
  const _color = `#${color.replace(/^#*/, "")}`;
  const siteThemeColor = document.querySelector('meta[name="theme-color"]');
  if (siteThemeColor) {
    siteThemeColor.content = _color;
  }
  postRNWebviewMessage?.("headerBg", _color);
}