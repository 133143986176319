import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#each this.flags as |f|}}
  {{yield f}}
{{else}}
  {{i18n "flagging.cant"}}
{{/each}}
*/
{
  "id": "US7eVXzz",
  "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"flags\"]]],null]],null],null,[[[1,\"  \"],[18,2,[[30,1]]],[1,\"\\n\"]],[1]],[[[1,\"  \"],[1,[28,[35,3],[\"flagging.cant\"],null]],[1,\"\\n\"]],[]]]],[\"f\",\"&default\"],false,[\"each\",\"-track-array\",\"yield\",\"i18n\"]]",
  "moduleName": "discourse/components/flag-selection.hbs",
  "isStrictMode": false
});