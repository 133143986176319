import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{outlet}}
*/
{
  "id": "dFjK5FDi",
  "block": "[[[46,[28,[37,1],null,null],null,null,null]],[],false,[\"component\",\"-outlet\"]]",
  "moduleName": "discourse/templates/invites.hbs",
  "isStrictMode": false
});