import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if (or this.loading this.model.stream.canLoadMore)}}
  {{hide-application-footer}}
{{/if}}

{{#if this.model.stream.noContent}}
  <EmptyState
    @title={{this.model.emptyState.title}}
    @body={{this.model.emptyState.body}}
  />
{{/if}}
<UserStream @stream={{this.model.stream}} />
*/
{
  "id": "GtAewsXy",
  "block": "[[[41,[28,[37,1],[[30,0,[\"loading\"]],[30,0,[\"model\",\"stream\",\"canLoadMore\"]]],null],[[[1,\"  \"],[1,[34,2]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"model\",\"stream\",\"noContent\"]],[[[1,\"  \"],[8,[39,3],null,[[\"@title\",\"@body\"],[[30,0,[\"model\",\"emptyState\",\"title\"]],[30,0,[\"model\",\"emptyState\",\"body\"]]]],null],[1,\"\\n\"]],[]],null],[8,[39,4],null,[[\"@stream\"],[[30,0,[\"model\",\"stream\"]]]],null]],[],false,[\"if\",\"or\",\"hide-application-footer\",\"empty-state\",\"user-stream\"]]",
  "moduleName": "discourse/templates/user/stream.hbs",
  "isStrictMode": false
});