import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="badge-title">
  <form class="form-horizontal">

    <h3>{{i18n "badges.select_badge_for_title"}}</h3>

    <div class="control-group">
      <div class="controls">
        <ComboBox
          @value={{this._selectedUserBadgeId}}
          @nameProperty="badge.name"
          @content={{this.selectableUserBadges}}
          @onChange={{fn (mut this._selectedUserBadgeId)}}
        />
      </div>
    </div>

    <div class="control-group">
      <div class="controls">
        <DButton
          @action={{this.saveBadgeTitle}}
          @disabled={{this._isSaving}}
          @label={{if this._isSaving "saving" "save"}}
          class="btn-primary"
        />
        {{#if this.closeAction}}
          <DButton
            @action={{this.closeAction}}
            @label="close"
            class="btn-default close-btn"
          />
        {{/if}}
        {{#if this._isSaved}}
          <span role="status" class="badge-title__saved">{{i18n "saved"}}</span>
        {{/if}}
      </div>
    </div>
  </form>
</div>
*/
{
  "id": "7ipXNsg9",
  "block": "[[[10,0],[14,0,\"badge-title\"],[12],[1,\"\\n  \"],[10,\"form\"],[14,0,\"form-horizontal\"],[12],[1,\"\\n\\n    \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"badges.select_badge_for_title\"],null]],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@value\",\"@nameProperty\",\"@content\",\"@onChange\"],[[30,0,[\"_selectedUserBadgeId\"]],\"badge.name\",[30,0,[\"selectableUserBadges\"]],[28,[37,2],[[28,[37,3],[[30,0,[\"_selectedUserBadgeId\"]]],null]],null]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"saveBadgeTitle\"]],[30,0,[\"_isSaving\"]],[52,[30,0,[\"_isSaving\"]],\"saving\",\"save\"]]],null],[1,\"\\n\"],[41,[30,0,[\"closeAction\"]],[[[1,\"          \"],[8,[39,4],[[24,0,\"btn-default close-btn\"]],[[\"@action\",\"@label\"],[[30,0,[\"closeAction\"]],\"close\"]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"_isSaved\"]],[[[1,\"          \"],[10,1],[14,\"role\",\"status\"],[14,0,\"badge-title__saved\"],[12],[1,[28,[35,0],[\"saved\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"combo-box\",\"fn\",\"mut\",\"d-button\",\"if\"]]",
  "moduleName": "discourse/components/badge-title.hbs",
  "isStrictMode": false
});