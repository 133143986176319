import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <ComposerTipCloseButton @action={{fn this.closeMessage this.message}} />

<h3>{{i18n "composer.similar_topics"}}</h3>

<ul class="topics">
  {{#each this.message.similarTopics as |topic|}}
    <div class="similar-topic">
      <SearchMenu::Results::Type::Topic
        @result={{topic}}
        @withTopicUrl={{true}}
      />
    </div>
  {{/each}}
</ul>
*/
{
  "id": "yTQxeS7I",
  "block": "[[[8,[39,0],null,[[\"@action\"],[[28,[37,1],[[30,0,[\"closeMessage\"]],[30,0,[\"message\"]]],null]]],null],[1,\"\\n\\n\"],[10,\"h3\"],[12],[1,[28,[35,2],[\"composer.similar_topics\"],null]],[13],[1,\"\\n\\n\"],[10,\"ul\"],[14,0,\"topics\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"message\",\"similarTopics\"]]],null]],null],null,[[[1,\"    \"],[10,0],[14,0,\"similar-topic\"],[12],[1,\"\\n      \"],[8,[39,5],null,[[\"@result\",\"@withTopicUrl\"],[[30,1],true]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[1]],null],[13]],[\"topic\"],false,[\"composer-tip-close-button\",\"fn\",\"i18n\",\"each\",\"-track-array\",\"search-menu/results/type/topic\"]]",
  "moduleName": "discourse/templates/composer/similar-topics.hbs",
  "isStrictMode": false
});