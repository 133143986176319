let d = window.define;
d("@ember/test-waiters/build-waiter", function () {
  return esc(require("@ember/test-waiters/build-waiter"));
});
d("ember-test-waiters/index", function () {
  return esc(require("@ember/test-waiters/ember-test-waiters/index"));
});
d("@ember/test-waiters/index", function () {
  return esc(require("@ember/test-waiters/index"));
});
d("@ember/test-waiters/token", function () {
  return esc(require("@ember/test-waiters/token"));
});
d("@ember/test-waiters/types/index", function () {
  return esc(require("@ember/test-waiters/types/index"));
});
d("@ember/test-waiters/wait-for-promise", function () {
  return esc(require("@ember/test-waiters/wait-for-promise"));
});
d("@ember/test-waiters/wait-for", function () {
  return esc(require("@ember/test-waiters/wait-for"));
});
d("@ember/test-waiters/waiter-manager", function () {
  return esc(require("@ember/test-waiters/waiter-manager"));
});
import "@ember/test-waiters/-embroider-implicit-modules.js";
import esc from "../macros/src/addon/es-compat2";