import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="tag-group-content">
  <h3>{{i18n "tagging.groups.new_title"}}</h3>
  <TagGroupsForm @model={{this.model}} @onSave={{action "onSave"}} />
</div>
*/
{
  "id": "wgmASMO6",
  "block": "[[[10,0],[14,0,\"tag-group-content\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"tagging.groups.new_title\"],null]],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@model\",\"@onSave\"],[[30,0,[\"model\"]],[28,[37,2],[[30,0],\"onSave\"],null]]],null],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"tag-groups-form\",\"action\"]]",
  "moduleName": "discourse/templates/tag-groups-new.hbs",
  "isStrictMode": false
});