import { registerWidgetShim } from "discourse/widgets/render-glimmer";
import { createTemplateFactory } from "@ember/template-factory";
registerWidgetShim("home-logo-wrapper-outlet", "div.home-logo-wrapper-outlet", createTemplateFactory(
/*
  <PluginOutlet @name="home-logo-wrapper">
    <PluginOutlet @name="home-logo" @outletArgs={{hash minimized=@data.topic}}>
      <MountWidget @widget="home-logo" @args={{@data}} />
    </PluginOutlet>
  </PluginOutlet>
*/
{
  "id": "uLnFJf/m",
  "block": "[[[8,[39,0],null,[[\"@name\"],[\"home-logo-wrapper\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,0],null,[[\"@name\",\"@outletArgs\"],[\"home-logo\",[28,[37,1],null,[[\"minimized\"],[[30,1,[\"topic\"]]]]]]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,2],null,[[\"@widget\",\"@args\"],[\"home-logo\",[30,1]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@data\"],false,[\"plugin-outlet\",\"hash\",\"mount-widget\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/widgets/home-logo-wrapper-outlet.js",
  "isStrictMode": false
}));