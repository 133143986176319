import truthConvert from "../utils/truth-convert";
export default function or() {
  let arg = false;
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  for (arg of args) {
    if (truthConvert(arg) === true) {
      return arg;
    }
  }
  return arg;
}