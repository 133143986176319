import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="flagged-post-header">
  <ReviewableTopicLink @reviewable={{this.reviewable}} @tagName="" />
  <ReviewablePostEdits @reviewable={{this.reviewable}} @tagName="" />
</div>

<div class="post-contents-wrapper">
  <ReviewableCreatedBy
    @user={{this.reviewable.target_created_by}}
    @tagName=""
  />
  <div class="post-contents">
    <ReviewablePostHeader
      @reviewable={{this.reviewable}}
      @createdBy={{this.reviewable.target_created_by}}
      @tagName=""
    />
    <div class="post-body">
      {{#if this.reviewable.blank_post}}
        <p>{{i18n "review.deleted_post"}}</p>
      {{else}}
        {{html-safe this.reviewable.cooked}}
      {{/if}}
    </div>
    {{yield}}
  </div>
</div>
*/
{
  "id": "s7pXfIjv",
  "block": "[[[10,0],[14,0,\"flagged-post-header\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@reviewable\",\"@tagName\"],[[30,0,[\"reviewable\"]],\"\"]],null],[1,\"\\n  \"],[8,[39,1],null,[[\"@reviewable\",\"@tagName\"],[[30,0,[\"reviewable\"]],\"\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n  \"],[8,[39,2],null,[[\"@user\",\"@tagName\"],[[30,0,[\"reviewable\",\"target_created_by\"]],\"\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n    \"],[8,[39,3],null,[[\"@reviewable\",\"@createdBy\",\"@tagName\"],[[30,0,[\"reviewable\"]],[30,0,[\"reviewable\",\"target_created_by\"]],\"\"]],null],[1,\"\\n    \"],[10,0],[14,0,\"post-body\"],[12],[1,\"\\n\"],[41,[30,0,[\"reviewable\",\"blank_post\"]],[[[1,\"        \"],[10,2],[12],[1,[28,[35,5],[\"review.deleted_post\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,6],[[30,0,[\"reviewable\",\"cooked\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"reviewable-topic-link\",\"reviewable-post-edits\",\"reviewable-created-by\",\"reviewable-post-header\",\"if\",\"i18n\",\"html-safe\",\"yield\"]]",
  "moduleName": "discourse/components/reviewable-post.hbs",
  "isStrictMode": false
});