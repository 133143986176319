import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './search-term.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { DEFAULT_TYPE_FILTER, SEARCH_INPUT_ID } from "discourse/components/search-menu";
import { isiPad } from "discourse/lib/utilities";
import TEMPLATE from "./search-term.hbs";
import { setComponentTemplate } from "@ember/component";
const SECOND_ENTER_MAX_DELAY = 15000;
const onKeyUpCallbacks = [];
export function addOnKeyUpCallback(fn) {
  onKeyUpCallbacks.push(fn);
}
export function resetOnKeyUpCallbacks() {
  onKeyUpCallbacks.clear();
}
let SearchTerm = (_class = class SearchTerm extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "search", _descriptor, this);
    _initializerDefineProperty(this, "appEvents", _descriptor2, this);
    _initializerDefineProperty(this, "lastEnterTimestamp", _descriptor3, this);
    _initializerDefineProperty(this, "searchCleared", _descriptor4, this);
  }
  // make constant available in template
  get inputId() {
    return SEARCH_INPUT_ID;
  }
  updateSearchTerm(input) {
    this.parseAndUpdateSearchTerm(this.search.activeGlobalSearchTerm, input.target.value);
    this.searchCleared = this.search.activeGlobalSearchTerm ? false : true;
  }
  focus(element) {
    if (this.args.autofocus) {
      element.focus();
      element.select();
    }
  }
  onKeydown(e) {
    if (e.key === "Escape") {
      this.args.closeSearchMenu();
      e.preventDefault();
      e.stopPropagation();
    }
  }
  onKeyup(e) {
    if (onKeyUpCallbacks.length && !onKeyUpCallbacks.some(fn => fn(this, e))) {
      // Return early if any callbacks return false
      return;
    }
    this.args.openSearchMenu();
    this.search.handleArrowUpOrDown(e);
    if (e.key === "Enter") {
      const recentEnterHit = this.lastEnterTimestamp && Date.now() - this.lastEnterTimestamp < SECOND_ENTER_MAX_DELAY;
      if (e.ctrlKey || e.metaKey || isiPad() && e.altKey || this.args.typeFilter !== DEFAULT_TYPE_FILTER && recentEnterHit) {
        this.args.fullSearch();
        this.args.closeSearchMenu();
      } else {
        this.args.updateTypeFilter(null);
        this.args.triggerSearch();
      }
      this.lastEnterTimestamp = Date.now();
    }
    if (e.key === "Backspace") {
      if (!e.target.value) {
        // only clear context if we're not in the middle of a search
        if (this.searchCleared) {
          this.args.clearTopicContext();
          this.args.clearPMInboxContext();
          this.focus(e.target);
        }
        this.searchCleared = true;
      }
    }
    e.preventDefault();
  }
  parseAndUpdateSearchTerm(originalVal, newVal) {
    // remove zero-width chars
    const parsedVal = newVal.replace(/[\u200B-\u200D\uFEFF]/, "");
    if (parsedVal !== originalVal) {
      this.args.searchTermChanged(parsedVal);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "search", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "lastEnterTimestamp", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "searchCleared", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return !this.search.activeGlobalSearchTerm;
  }
}), _applyDecoratedDescriptor(_class.prototype, "updateSearchTerm", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateSearchTerm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focus", [action], Object.getOwnPropertyDescriptor(_class.prototype, "focus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeydown", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeydown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onKeyup", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onKeyup"), _class.prototype)), _class);
export { SearchTerm as default };
setComponentTemplate(TEMPLATE, SearchTerm);