import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
/* import __COLOCATED_TEMPLATE__ from './move-to-topic.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import DiscourseURL from "discourse/lib/url";
import { mergeTopic, movePosts } from "discourse/models/topic";
import I18n from "discourse-i18n";
import TEMPLATE from "./move-to-topic.hbs";
import { setComponentTemplate } from "@ember/component";
let MoveToTopic = (_class = class MoveToTopic extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "site", _descriptor2, this);
    _initializerDefineProperty(this, "topicName", _descriptor3, this);
    _initializerDefineProperty(this, "saving", _descriptor4, this);
    _initializerDefineProperty(this, "categoryId", _descriptor5, this);
    _initializerDefineProperty(this, "tags", _descriptor6, this);
    _initializerDefineProperty(this, "participants", _descriptor7, this);
    _initializerDefineProperty(this, "chronologicalOrder", _descriptor8, this);
    _initializerDefineProperty(this, "selection", _descriptor9, this);
    _initializerDefineProperty(this, "selectedTopicId", _descriptor10, this);
    _initializerDefineProperty(this, "flash", _descriptor11, this);
    if (this.args.model.topic.isPrivateMessage) {
      this.selection = this.canSplitToPM ? "new_message" : "existing_message";
    } else if (!this.canSplitTopic) {
      this.selection = "existing_topic";
    }
  }
  get newTopic() {
    return this.selection === "new_topic";
  }
  get existingTopic() {
    return this.selection === "existing_topic";
  }
  get newMessage() {
    return this.selection === "new_message";
  }
  get existingMessage() {
    return this.selection === "existing_message";
  }
  get buttonDisabled() {
    return this.saving || isEmpty(this.selectedTopicId) && isEmpty(this.topicName);
  }
  get buttonTitle() {
    if (this.newTopic) {
      return "topic.split_topic.title";
    } else if (this.existingTopic) {
      return "topic.merge_topic.title";
    } else if (this.newMessage) {
      return "topic.move_to_new_message.title";
    } else if (this.existingMessage) {
      return "topic.move_to_existing_message.title";
    } else {
      return "saving";
    }
  }
  get canSplitTopic() {
    return !this.args.model.selectedAllPosts && this.args.model.selectedPosts.length > 0 && this.args.model.selectedPosts.sort((a, b) => a.post_number - b.post_number)[0].post_type === this.site.get("post_types.regular");
  }
  get canSplitToPM() {
    return this.canSplitTopic && this.currentUser?.admin;
  }
  get canAddTags() {
    return this.site.can_create_tag;
  }
  get canTagMessages() {
    return this.site.can_tag_pms;
  }
  performMove() {
    if (this.newTopic) {
      this.movePostsTo("newTopic");
    } else if (this.existingTopic) {
      this.movePostsTo("existingTopic");
    } else if (this.newMessage) {
      this.movePostsTo("newMessage");
    } else if (this.existingMessage) {
      this.movePostsTo("existingMessage");
    }
  }
  async movePostsTo(type) {
    this.saving = true;
    this.flash = null;
    let mergeOptions, moveOptions;
    if (type === "existingTopic") {
      mergeOptions = {
        destination_topic_id: this.selectedTopicId,
        chronological_order: this.chronologicalOrder
      };
      moveOptions = {
        post_ids: this.args.model.selectedPostIds,
        ...mergeOptions
      };
    } else if (type === "existingMessage") {
      mergeOptions = {
        destination_topic_id: this.selectedTopicId,
        participants: this.participants.join(","),
        archetype: "private_message",
        chronological_order: this.chronologicalOrder
      };
      moveOptions = {
        post_ids: this.args.model.selectedPostIds,
        ...mergeOptions
      };
    } else if (type === "newTopic") {
      mergeOptions = {};
      moveOptions = {
        title: this.topicName,
        post_ids: this.args.model.selectedPostIds,
        category_id: this.categoryId,
        tags: this.tags
      };
    } else {
      mergeOptions = {};
      moveOptions = {
        title: this.topicName,
        post_ids: this.args.model.selectedPostIds,
        tags: this.tags,
        archetype: "private_message"
      };
    }
    try {
      let result;
      if (this.args.model.selectedAllPosts) {
        result = await mergeTopic(this.args.model.topic.id, mergeOptions);
      } else {
        result = await movePosts(this.args.model.topic.id, moveOptions);
      }
      this.args.closeModal();
      this.args.model.toggleMultiSelect();
      DiscourseURL.routeTo(result.url);
    } catch {
      this.flash = I18n.t("topic.move_to.error");
    } finally {
      this.saving = false;
    }
  }
  updateTopicName(newName) {
    this.topicName = newName;
  }
  updateCategoryId(newId) {
    this.categoryId = newId;
  }
  updateTags(newTags) {
    this.tags = newTags;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "topicName", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "saving", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "categoryId", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "tags", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "participants", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "chronologicalOrder", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selection", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "new_topic";
  }
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedTopicId", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "performMove", [action], Object.getOwnPropertyDescriptor(_class.prototype, "performMove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "movePostsTo", [action], Object.getOwnPropertyDescriptor(_class.prototype, "movePostsTo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTopicName", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateTopicName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateCategoryId", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateCategoryId"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTags", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateTags"), _class.prototype)), _class);
export { MoveToTopic as default };
setComponentTemplate(TEMPLATE, MoveToTopic);