import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  class={{concat-class "menu-panel" @panelClass @animationClass}}
  data-max-width="500"
>
  <div class="panel-body">
    <div class="panel-body-contents">
      {{yield}}
    </div>
  </div>
</div>
*/
{
  "id": "PRDp/nbf",
  "block": "[[[10,0],[15,0,[28,[37,0],[\"menu-panel\",[30,1],[30,2]],null]],[14,\"data-max-width\",\"500\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"panel-body\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"panel-body-contents\"],[12],[1,\"\\n      \"],[18,3,null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@panelClass\",\"@animationClass\",\"&default\"],false,[\"concat-class\",\"yield\"]]",
  "moduleName": "discourse/components/menu-panel.hbs",
  "isStrictMode": false
});