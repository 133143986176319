import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div id="security-key">
  <DButton
    @action={{this.action}}
    @icon="key"
    @label="login.security_key_authenticate"
    id="security-key-authenticate-button"
    class="btn-large btn-primary"
  />
  <p>
    {{#if this.otherMethodAllowed}}
      <a
        href
        class="toggle-second-factor-method"
        {{on "click" this.useAnotherMethod}}
      >{{i18n "login.security_key_alternative"}}</a>
    {{/if}}
  </p>
</div>
*/
{
  "id": "Mni7wD28",
  "block": "[[[10,0],[14,1,\"security-key\"],[12],[1,\"\\n  \"],[8,[39,0],[[24,1,\"security-key-authenticate-button\"],[24,0,\"btn-large btn-primary\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"action\"]],\"key\",\"login.security_key_authenticate\"]],null],[1,\"\\n  \"],[10,2],[12],[1,\"\\n\"],[41,[30,0,[\"otherMethodAllowed\"]],[[[1,\"      \"],[11,3],[24,6,\"\"],[24,0,\"toggle-second-factor-method\"],[4,[38,2],[\"click\",[30,0,[\"useAnotherMethod\"]]],null],[12],[1,[28,[35,3],[\"login.security_key_alternative\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[],false,[\"d-button\",\"if\",\"on\",\"i18n\"]]",
  "moduleName": "discourse/components/security-key-form.hbs",
  "isStrictMode": false
});