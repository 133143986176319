import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <li class="search-random-quick-tip">
  <span
    class={{concat-class
      "tip-label"
      (if this.randomTip.clickable "tip-clickable")
    }}
    role="button"
    {{on "click" this.tipSelected}}
  >
    {{this.randomTip.label}}
  </span>

  <span class="tip-description">
    {{this.randomTip.description}}
  </span>
</li>
*/
{
  "id": "blQcw2WA",
  "block": "[[[10,\"li\"],[14,0,\"search-random-quick-tip\"],[12],[1,\"\\n  \"],[11,1],[16,0,[28,[37,0],[\"tip-label\",[52,[30,0,[\"randomTip\",\"clickable\"]],\"tip-clickable\"]],null]],[24,\"role\",\"button\"],[4,[38,2],[\"click\",[30,0,[\"tipSelected\"]]],null],[12],[1,\"\\n    \"],[1,[30,0,[\"randomTip\",\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,1],[14,0,\"tip-description\"],[12],[1,\"\\n    \"],[1,[30,0,[\"randomTip\",\"description\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"concat-class\",\"if\",\"on\"]]",
  "moduleName": "discourse/components/search-menu/results/random-quick-tip.hbs",
  "isStrictMode": false
});