import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
/* import __COLOCATED_TEMPLATE__ from './revise-and-reject-post-reviewable.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import I18n from "discourse-i18n";
import TEMPLATE from "./revise-and-reject-post-reviewable.hbs";
import { setComponentTemplate } from "@ember/component";
const OTHER_REASON = "other_reason";
let ReviseAndRejectPostReviewable = (_class = class ReviseAndRejectPostReviewable extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    _initializerDefineProperty(this, "reason", _descriptor2, this);
    _initializerDefineProperty(this, "customReason", _descriptor3, this);
    _initializerDefineProperty(this, "feedback", _descriptor4, this);
    _initializerDefineProperty(this, "submitting", _descriptor5, this);
  }
  get configuredReasons() {
    const reasons = this.siteSettings.reviewable_revision_reasons.split("|").filter(Boolean).map(reason => ({
      id: reason,
      name: reason
    })).concat([{
      id: OTHER_REASON,
      name: I18n.t("review.revise_and_reject_post.other_reason")
    }]);
    return reasons;
  }
  get showCustomReason() {
    return this.reason === OTHER_REASON;
  }
  get sendPMDisabled() {
    return isEmpty(this.reason) || this.reason === OTHER_REASON && isEmpty(this.customReason) || this.submitting;
  }
  async rejectAndSendPM() {
    this.submitting = true;
    try {
      await this.args.model.performConfirmed(this.args.model.action, {
        revise_reason: this.reason,
        revise_custom_reason: this.customReason,
        revise_feedback: this.feedback
      });
      this.args.closeModal();
    } catch (error) {
      popupAjaxError(error);
    } finally {
      this.submitting = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "reason", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "customReason", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "feedback", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "submitting", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "rejectAndSendPM", [action], Object.getOwnPropertyDescriptor(_class.prototype, "rejectAndSendPM"), _class.prototype)), _class);
export { ReviseAndRejectPostReviewable as default };
setComponentTemplate(TEMPLATE, ReviseAndRejectPostReviewable);