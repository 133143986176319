import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './shared-draft-controls.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { service } from "@ember/service";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./shared-draft-controls.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("topic.destination_category_id"), (_obj = {
  tagName: "",
  dialog: service(),
  publishing: false,
  validCategory(destCatId) {
    return destCatId && destCatId !== this.site.shared_drafts_category_id;
  },
  actions: {
    updateDestinationCategory(categoryId) {
      return this.topic.updateDestinationCategory(categoryId);
    },
    publish() {
      this.dialog.yesNoConfirm({
        message: I18n.t("shared_drafts.confirm_publish"),
        didConfirm: () => {
          this.set("publishing", true);
          const destinationCategoryId = this.topic.destination_category_id;
          return this.topic.publish().then(() => {
            this.topic.setProperties({
              category_id: destinationCategoryId,
              destination_category_id: undefined,
              is_shared_draft: false
            });
          }).finally(() => {
            this.set("publishing", false);
          });
        }
      });
    }
  }
}, (_applyDecoratedDescriptor(_obj, "validCategory", [_dec], Object.getOwnPropertyDescriptor(_obj, "validCategory"), _obj)), _obj))));