import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DButton
  @action={{this.action}}
  @disabled={{this.buttonDisabled}}
  @label={{this.savingText}}
  class="btn-primary save-changes"
/>
{{#if this.saved}}
  <span class="saved">{{i18n "saved"}}</span>
{{/if}}

{{yield}}
*/
{
  "id": "/s8EjIov",
  "block": "[[[8,[39,0],[[24,0,\"btn-primary save-changes\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"action\"]],[30,0,[\"buttonDisabled\"]],[30,0,[\"savingText\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"saved\"]],[[[1,\"  \"],[10,1],[14,0,\"saved\"],[12],[1,[28,[35,2],[\"saved\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"d-button\",\"if\",\"i18n\",\"yield\"]]",
  "moduleName": "discourse/components/save-controls.hbs",
  "isStrictMode": false
});