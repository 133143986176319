import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
// Deprecated in favor of app/assets/javascripts/discourse/app/services/docking.js
import Mixin from "@ember/object/mixin";
import { cancel } from "@ember/runloop";
import discourseDebounce from "discourse-common/lib/debounce";
import discourseLater from "discourse-common/lib/later";
import { bind } from "discourse-common/utils/decorators";
const INITIAL_DELAY_MS = 50;
const DEBOUNCE_MS = 5;
export default Mixin.create((_obj = {
  _initialTimer: null,
  _queuedTimer: null,
  didInsertElement() {
    this._super(...arguments);
    window.addEventListener("scroll", this.queueDockCheck, {
      passive: true
    });
    document.addEventListener("touchmove", this.queueDockCheck, {
      passive: true
    });

    // dockCheck might happen too early on full page refresh
    this._initialTimer = discourseLater(this, this.safeDockCheck, INITIAL_DELAY_MS);
  },
  willDestroyElement() {
    this._super(...arguments);
    if (this._queuedTimer) {
      cancel(this._queuedTimer);
    }
    cancel(this._initialTimer);
    window.removeEventListener("scroll", this.queueDockCheck);
    document.removeEventListener("touchmove", this.queueDockCheck);
  },
  queueDockCheck() {
    this._queuedTimer = discourseDebounce(this, this.safeDockCheck, DEBOUNCE_MS);
  },
  safeDockCheck() {
    if (this.isDestroyed || this.isDestroying) {
      return;
    }
    this.dockCheck();
  }
}, (_applyDecoratedDescriptor(_obj, "queueDockCheck", [bind], Object.getOwnPropertyDescriptor(_obj, "queueDockCheck"), _obj), _applyDecoratedDescriptor(_obj, "safeDockCheck", [bind], Object.getOwnPropertyDescriptor(_obj, "safeDockCheck"), _obj)), _obj));