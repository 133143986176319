import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <UserPreferences::UserApiKeys @model={{@model}} />

<span>
  <PluginOutlet
    @name="user-preferences-apps"
    @connectorTagName="div"
    @outletArgs={{hash model=this.model}}
  />
</span>
*/
{
  "id": "d8/Gidhr",
  "block": "[[[8,[39,0],null,[[\"@model\"],[[30,1]]],null],[1,\"\\n\\n\"],[10,1],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"user-preferences-apps\",\"div\",[28,[37,2],null,[[\"model\"],[[30,0,[\"model\"]]]]]]],null],[1,\"\\n\"],[13]],[\"@model\"],false,[\"user-preferences/user-api-keys\",\"plugin-outlet\",\"hash\"]]",
  "moduleName": "discourse/templates/preferences/apps.hbs",
  "isStrictMode": false
});