import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="ai-summary__container">
  <ul class="ai-summary__list" {{did-insert this.setupAnimation}}>
    {{#each this.blocks as |block|}}
      <li
        class={{concat-class
          "ai-summary__list-item"
          (if block.show "show")
          (if block.shown "is-shown")
          (if block.blinking "blink")
        }}
        {{did-update (fn this.onBlinking block) block.blinking}}
        {{did-update (fn this.onShowing block) block.show}}
        {{will-destroy this.teardownAnimation}}
      ></li>
    {{/each}}
  </ul>

  <span>
    <div class="ai-summary__generating-text">
      {{i18n "summary.in_progress"}}
    </div>
    <span class="ai-summary__indicator-wave">
      <span class="ai-summary__indicator-dot">.</span>
      <span class="ai-summary__indicator-dot">.</span>
      <span class="ai-summary__indicator-dot">.</span>
    </span>
  </span>
</div>
*/
{
  "id": "VL1Gilc4",
  "block": "[[[10,0],[14,0,\"ai-summary__container\"],[12],[1,\"\\n  \"],[11,\"ul\"],[24,0,\"ai-summary__list\"],[4,[38,0],[[30,0,[\"setupAnimation\"]]],null],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"blocks\"]]],null]],null],null,[[[1,\"      \"],[11,\"li\"],[16,0,[28,[37,3],[\"ai-summary__list-item\",[52,[30,1,[\"show\"]],\"show\"],[52,[30,1,[\"shown\"]],\"is-shown\"],[52,[30,1,[\"blinking\"]],\"blink\"]],null]],[4,[38,5],[[28,[37,6],[[30,0,[\"onBlinking\"]],[30,1]],null],[30,1,[\"blinking\"]]],null],[4,[38,5],[[28,[37,6],[[30,0,[\"onShowing\"]],[30,1]],null],[30,1,[\"show\"]]],null],[4,[38,7],[[30,0,[\"teardownAnimation\"]]],null],[12],[13],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\\n  \"],[10,1],[12],[1,\"\\n    \"],[10,0],[14,0,\"ai-summary__generating-text\"],[12],[1,\"\\n      \"],[1,[28,[35,8],[\"summary.in_progress\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,1],[14,0,\"ai-summary__indicator-wave\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"ai-summary__indicator-dot\"],[12],[1,\".\"],[13],[1,\"\\n      \"],[10,1],[14,0,\"ai-summary__indicator-dot\"],[12],[1,\".\"],[13],[1,\"\\n      \"],[10,1],[14,0,\"ai-summary__indicator-dot\"],[12],[1,\".\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"block\"],false,[\"did-insert\",\"each\",\"-track-array\",\"concat-class\",\"if\",\"did-update\",\"fn\",\"will-destroy\",\"i18n\"]]",
  "moduleName": "discourse/components/ai-summary-skeleton.hbs",
  "isStrictMode": false
});