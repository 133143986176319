import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="wrapper">
  <textarea
    id="backupCodes"
    class="backup-codes-area"
    rows="10"
    readonly
  >{{this.formattedBackupCodes}}</textarea>

  <div class="controls">
    <DButton
      @action={{action "copyToClipboard"}}
      @icon="copy"
      @ariaLabel="user.second_factor_backup.copy_to_clipboard"
      @title="user.second_factor_backup.copy_to_clipboard"
      class="btn-default backup-codes-copy-btn"
    />

    <a
      download="{{this.siteTitleSlug}}-backup-codes.txt"
      class="btn btn-default no-text btn-icon backup-codes-download-btn"
      aria-label={{i18n "user.second_factor_backup.download_backup_codes"}}
      title={{i18n "user.second_factor_backup.download_backup_codes"}}
      rel="noopener noreferrer"
      target="_blank"
      href="data:application/octet-stream;charset=utf-8;base64,{{this.base64BackupCode}}"
    >
      {{d-icon "download"}}
    </a>
  </div>
</div>
*/
{
  "id": "BEPs8cH8",
  "block": "[[[10,0],[14,0,\"wrapper\"],[12],[1,\"\\n  \"],[10,\"textarea\"],[14,1,\"backupCodes\"],[14,0,\"backup-codes-area\"],[14,\"rows\",\"10\"],[14,\"readonly\",\"\"],[12],[1,[30,0,[\"formattedBackupCodes\"]]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"btn-default backup-codes-copy-btn\"]],[[\"@action\",\"@icon\",\"@ariaLabel\",\"@title\"],[[28,[37,1],[[30,0],\"copyToClipboard\"],null],\"copy\",\"user.second_factor_backup.copy_to_clipboard\",\"user.second_factor_backup.copy_to_clipboard\"]],null],[1,\"\\n\\n    \"],[10,3],[15,\"download\",[29,[[30,0,[\"siteTitleSlug\"]],\"-backup-codes.txt\"]]],[14,0,\"btn btn-default no-text btn-icon backup-codes-download-btn\"],[15,\"aria-label\",[28,[37,2],[\"user.second_factor_backup.download_backup_codes\"],null]],[15,\"title\",[28,[37,2],[\"user.second_factor_backup.download_backup_codes\"],null]],[14,\"rel\",\"noopener noreferrer\"],[14,\"target\",\"_blank\"],[15,6,[29,[\"data:application/octet-stream;charset=utf-8;base64,\",[30,0,[\"base64BackupCode\"]]]]],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"download\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"d-button\",\"action\",\"i18n\",\"d-icon\"]]",
  "moduleName": "discourse/components/backup-codes.hbs",
  "isStrictMode": false
});