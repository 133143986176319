import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.canLoadMore}}
  {{hide-application-footer}}
{{/if}}

<LoadMore @selector=".user-stream-item" @action={{action "loadMore"}}>
  <div class="user-stream">
    {{#each this.model as |post|}}
      <GroupPost @post={{post}} />
    {{else}}
      <div>{{i18n this.emptyText}}</div>
    {{/each}}
  </div>
  <ConditionalLoadingSpinner @condition={{this.loading}} />
</LoadMore>
*/
{
  "id": "V34KfL9I",
  "block": "[[[41,[30,0,[\"canLoadMore\"]],[[[1,\"  \"],[1,[34,1]],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,2],null,[[\"@selector\",\"@action\"],[\".user-stream-item\",[28,[37,3],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[10,0],[14,0,\"user-stream\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"      \"],[8,[39,6],null,[[\"@post\"],[[30,1]]],null],[1,\"\\n\"]],[1]],[[[1,\"      \"],[10,0],[12],[1,[28,[35,7],[[30,0,[\"emptyText\"]]],null]],[13],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,8],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],null],[1,\"\\n\"]],[]]]]]],[\"post\"],false,[\"if\",\"hide-application-footer\",\"load-more\",\"action\",\"each\",\"-track-array\",\"group-post\",\"i18n\",\"conditional-loading-spinner\"]]",
  "moduleName": "discourse/templates/group-activity-posts.hbs",
  "isStrictMode": false
});