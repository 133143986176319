import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <ComposerTipCloseButton @action={{fn this.closeMessage this.message}} />

<p>
  {{html-safe this.message.body}}
</p>
*/
{
  "id": "78kqa78/",
  "block": "[[[8,[39,0],null,[[\"@action\"],[[28,[37,1],[[30,0,[\"closeMessage\"]],[30,0,[\"message\"]]],null]]],null],[1,\"\\n\\n\"],[10,2],[12],[1,\"\\n  \"],[1,[28,[35,2],[[30,0,[\"message\",\"body\"]]],null]],[1,\"\\n\"],[13]],[],false,[\"composer-tip-close-button\",\"fn\",\"html-safe\"]]",
  "moduleName": "discourse/templates/composer/group-mentioned.hbs",
  "isStrictMode": false
});