import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _class;
/* import __COLOCATED_TEMPLATE__ from './change-category.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import TEMPLATE from "./change-category.hbs";
import { setComponentTemplate } from "@ember/component";
let ChangeCategory = (_class = class ChangeCategory extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "categoryId", 0);
  }
  async changeCategory() {
    await this.args.forEachPerformed({
      type: "change_category",
      category_id: this.categoryId
    }, t => t.set("category_id", this.categoryId));
  }
}, (_applyDecoratedDescriptor(_class.prototype, "changeCategory", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changeCategory"), _class.prototype)), _class);
export { ChangeCategory as default };
setComponentTemplate(TEMPLATE, ChangeCategory);