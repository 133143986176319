import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{body-class "static-privacy"}}

<section class="container">
  <div class="contents clearfix body-page">
    <PluginOutlet @name="above-static" />
    {{html-safe this.model.html}}
    <PluginOutlet @name="below-static" />
  </div>
</section>
*/
{
  "id": "eNQHHeWe",
  "block": "[[[1,[28,[35,0],[\"static-privacy\"],null]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"contents clearfix body-page\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@name\"],[\"above-static\"]],null],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"model\",\"html\"]]],null]],[1,\"\\n    \"],[8,[39,1],null,[[\"@name\"],[\"below-static\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"body-class\",\"plugin-outlet\",\"html-safe\"]]",
  "moduleName": "discourse/templates/privacy.hbs",
  "isStrictMode": false
});