import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './search-result-entry.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { logSearchLinkClick } from "discourse/lib/search";
import { modKeysPressed } from "discourse/lib/utilities";
import TEMPLATE from "./search-result-entry.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  tagName: "div",
  classNames: ["fps-result"],
  classNameBindings: ["bulkSelectEnabled"],
  attributeBindings: ["role"],
  role: "listitem",
  logClick(topicId, event) {
    // Avoid click logging when any modifier keys are pressed.
    if (event && modKeysPressed(event).length > 0) {
      return false;
    }
    if (this.searchLogId && topicId) {
      logSearchLinkClick({
        searchLogId: this.searchLogId,
        searchResultId: topicId,
        searchResultType: "topic"
      });
    }
  }
}, (_applyDecoratedDescriptor(_obj, "logClick", [action], Object.getOwnPropertyDescriptor(_obj, "logClick"), _obj)), _obj)));