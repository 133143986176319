import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { getOwner, setOwner } from "@ember/application";
import Helper from "@ember/component/helper";
import { registerDestructor } from "@ember/destroyable";
import { schedule } from "@ember/runloop";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { helperContext, registerRawHelper } from "discourse-common/lib/helpers";
import { RUNTIME_OPTIONS } from "discourse-common/lib/raw-handlebars-helpers";
import { findRawTemplate } from "discourse-common/lib/raw-templates";
import { bind } from "discourse-common/utils/decorators";
function renderRaw(ctx, template, templateName, params) {
  params = {
    ...params
  };
  params.parent ||= ctx;
  let context = helperContext();
  if (!params.view) {
    const viewClass = context.registry.resolve(`raw-view:${templateName}`);
    if (viewClass) {
      setOwner(params, getOwner(context));
      params.view = viewClass.create(params, context);
    }
    if (!params.view) {
      params = {
        ...params,
        ...context
      };
    }
  }
  return htmlSafe(template(params, RUNTIME_OPTIONS));
}
const helperFunction = function (templateName, params) {
  templateName = templateName.replace(".", "/");
  const template = findRawTemplate(templateName);
  if (!template) {
    // eslint-disable-next-line no-console
    console.warn("Could not find raw template: " + templateName);
    return;
  }
  return renderRaw(this, template, templateName, params);
};
registerRawHelper("raw", helperFunction);
let RawHelper = (_class = class RawHelper extends Helper {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "renderGlimmer", _descriptor, this);
  }
  compute(args, params) {
    registerDestructor(this, this.cleanup);
    return helperFunction(...args, params);
  }
  cleanup() {
    schedule("afterRender", () => this.renderGlimmer.cleanup());
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "renderGlimmer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "cleanup", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "cleanup"), _class.prototype)), _class);
export { RawHelper as default };