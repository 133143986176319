import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.showTopicTimer}}
  <h3 class="topic-timer-heading">
    <span title={{this.title}}>
      {{this.clockIcon}}
      {{this.notice}}
    </span>
    <div class="topic-timer-modify">
      {{#if this.showEdit}}
        <DButton
          @title="post.controls.edit_timer"
          @icon="pencil-alt"
          @action={{this.showTopicTimerModal}}
          class="topic-timer-edit no-text"
        />
      {{/if}}
      {{#if this.showTrashCan}}
        <DButton
          @title="post.controls.remove_timer"
          @icon="trash-alt"
          @action={{this.removeTopicTimer}}
          class="topic-timer-remove no-text"
        />
      {{/if}}
    </div>
  </h3>
{{/if}}
*/
{
  "id": "8jVZAuuT",
  "block": "[[[41,[30,0,[\"showTopicTimer\"]],[[[1,\"  \"],[10,\"h3\"],[14,0,\"topic-timer-heading\"],[12],[1,\"\\n    \"],[10,1],[15,\"title\",[30,0,[\"title\"]]],[12],[1,\"\\n      \"],[1,[30,0,[\"clockIcon\"]]],[1,\"\\n      \"],[1,[30,0,[\"notice\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"topic-timer-modify\"],[12],[1,\"\\n\"],[41,[30,0,[\"showEdit\"]],[[[1,\"        \"],[8,[39,1],[[24,0,\"topic-timer-edit no-text\"]],[[\"@title\",\"@icon\",\"@action\"],[\"post.controls.edit_timer\",\"pencil-alt\",[30,0,[\"showTopicTimerModal\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showTrashCan\"]],[[[1,\"        \"],[8,[39,1],[[24,0,\"topic-timer-remove no-text\"]],[[\"@title\",\"@icon\",\"@action\"],[\"post.controls.remove_timer\",\"trash-alt\",[30,0,[\"removeTopicTimer\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\"]]",
  "moduleName": "discourse/components/topic-timer-info.hbs",
  "isStrictMode": false
});