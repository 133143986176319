import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { tracked } from "@glimmer/tracking";
let SectionLink = (_class = class SectionLink {
  constructor(_ref, section) {
    let {
      external,
      icon,
      id,
      name,
      value
    } = _ref;
    _initializerDefineProperty(this, "linkDragCss", _descriptor, this);
    this.external = external;
    this.prefixValue = icon;
    this.id = id;
    this.name = name;
    this.text = name;
    this.value = value;
    this.section = section;
    this.withAnchor = value.match(/#\w+$/gi);
  }
  get shouldDisplay() {
    return true;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "linkDragCss", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { SectionLink as default };