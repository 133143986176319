import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './composer-action-title.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { alias } from "@ember/object/computed";
import { htmlSafe } from "@ember/template";
import { CREATE_SHARED_DRAFT, CREATE_TOPIC, EDIT, EDIT_SHARED_DRAFT, PRIVATE_MESSAGE, REPLY } from "discourse/models/composer";
import escape from "discourse-common/lib/escape";
import { iconHTML } from "discourse-common/lib/icon-library";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./composer-action-title.hbs";
const TITLES = {
  [PRIVATE_MESSAGE]: "topic.private_message",
  [CREATE_TOPIC]: "topic.create_long",
  [CREATE_SHARED_DRAFT]: "composer.create_shared_draft",
  [EDIT_SHARED_DRAFT]: "composer.edit_shared_draft"
};
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("options", "action", "model.tags", "model.category"), (_obj = {
  classNames: ["composer-action-title"],
  options: alias("model.replyOptions"),
  action: alias("model.action"),
  actionTitle(opts, action) {
    const result = this.model.customizationFor("actionTitle");
    if (result) {
      return result;
    }
    if (TITLES[action]) {
      return I18n.t(TITLES[action]);
    }
    if (action === REPLY) {
      if (opts.userAvatar && opts.userLink) {
        return this._formatReplyToUserPost(opts.userAvatar, opts.userLink);
      } else if (opts.topicLink) {
        return this._formatReplyToTopic(opts.topicLink);
      }
    }
    if (action === EDIT) {
      if (opts.userAvatar && opts.userLink && opts.postLink) {
        return this._formatEditUserPost(opts.userAvatar, opts.userLink, opts.postLink, opts.originalUser);
      }
    }
  },
  _formatEditUserPost(userAvatar, userLink, postLink, originalUser) {
    let editTitle = `
      <a class="post-link" href="${postLink.href}">${postLink.anchor}</a>
      ${userAvatar}
      <span class="username">${userLink.anchor}</span>
    `;
    if (originalUser) {
      editTitle += `
        ${iconHTML("share", {
        class: "reply-to-glyph"
      })}
        ${originalUser.avatar}
        <span class="original-username">${originalUser.username}</span>
      `;
    }
    return htmlSafe(editTitle);
  },
  _formatReplyToTopic(link) {
    return htmlSafe(`<a class="topic-link" href="${link.href}" data-topic-id="${this.get("model.topic.id")}">${link.anchor}</a>`);
  },
  _formatReplyToUserPost(avatar, link) {
    const htmlLink = `<a class="user-link" href="${link.href}">${escape(link.anchor)}</a>`;
    return htmlSafe(`${avatar}${htmlLink}`);
  }
}, (_applyDecoratedDescriptor(_obj, "actionTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "actionTitle"), _obj)), _obj))));