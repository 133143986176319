import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{~#if this.topicClosedArchived~}}
  <span
    title={{this.closedArchivedTitle}}
    class="topic-status"
  >{{this.closedArchivedIcon}}</span>
{{~/if~}}
{{~#if this.closedIcon~}}
  <span
    title={{this.closedTitle}}
    class="topic-status"
  >{{this.closedIcon}}</span>
{{~/if~}}
{{~#if this.archivedIcon~}}
  <span
    title={{this.archivedTitle}}
    class="topic-status"
  >{{this.archivedIcon}}</span>
{{~/if~}}
{{~#if this.topicPrivateMessage~}}
  <span
    title={{this.privateMessageTitle}}
    class="topic-status"
  >{{this.privateMessageIcon}}</span>
{{~/if~}}
{{~#if this.topicWarning~}}
  <span
    title={{this.warningTitle}}
    class="topic-status topic-status-warning"
  >{{this.warningIcon}}</span>
{{~/if~}}
{{~#if this.topicPinned~}}
  {{~#if this.canAct~}}
    <a
      href=""
      title={{this.pinnedTitle}}
      class="topic-status"
    >{{this.pinnedIcon}}</a>
  {{~else~}}
    <span
      title={{this.pinnedTitle}}
      class="topic-status"
    >{{this.pinnedIcon}}</span>
  {{~/if~}}
{{~/if~}}
{{~#if this.topicUnpinned~}}
  {{~#if this.canAct~}}
    <a
      href=""
      title={{this.unpinnedTitle}}
      class="topic-status"
    >{{this.unpinnedIcon}}</a>
  {{~else~}}
    <span
      title={{this.unpinnedTitle}}
      class="topic-status"
    >{{this.unpinnedIcon}}</span>
  {{~/if~}}
{{~/if~}}
{{~#if this.topicInvisible~}}
  <span
    title={{this.invisibleTitle}}
    class="topic-status"
  >{{this.invisibleIcon}}</span>
{{~/if~}}
<PluginOutlet
  @name="after-topic-status"
  @connectorTagName="div"
  @outletArgs={{hash topic=this.topic}}
/>
*/
{
  "id": "l1iM1Gha",
  "block": "[[[41,[30,0,[\"topicClosedArchived\"]],[[[10,1],[15,\"title\",[30,0,[\"closedArchivedTitle\"]]],[14,0,\"topic-status\"],[12],[1,[30,0,[\"closedArchivedIcon\"]]],[13]],[]],null],[41,[30,0,[\"closedIcon\"]],[[[10,1],[15,\"title\",[30,0,[\"closedTitle\"]]],[14,0,\"topic-status\"],[12],[1,[30,0,[\"closedIcon\"]]],[13]],[]],null],[41,[30,0,[\"archivedIcon\"]],[[[10,1],[15,\"title\",[30,0,[\"archivedTitle\"]]],[14,0,\"topic-status\"],[12],[1,[30,0,[\"archivedIcon\"]]],[13]],[]],null],[41,[30,0,[\"topicPrivateMessage\"]],[[[10,1],[15,\"title\",[30,0,[\"privateMessageTitle\"]]],[14,0,\"topic-status\"],[12],[1,[30,0,[\"privateMessageIcon\"]]],[13]],[]],null],[41,[30,0,[\"topicWarning\"]],[[[10,1],[15,\"title\",[30,0,[\"warningTitle\"]]],[14,0,\"topic-status topic-status-warning\"],[12],[1,[30,0,[\"warningIcon\"]]],[13]],[]],null],[41,[30,0,[\"topicPinned\"]],[[[41,[30,0,[\"canAct\"]],[[[10,3],[14,6,\"\"],[15,\"title\",[30,0,[\"pinnedTitle\"]]],[14,0,\"topic-status\"],[12],[1,[30,0,[\"pinnedIcon\"]]],[13]],[]],[[[10,1],[15,\"title\",[30,0,[\"pinnedTitle\"]]],[14,0,\"topic-status\"],[12],[1,[30,0,[\"pinnedIcon\"]]],[13]],[]]]],[]],null],[41,[30,0,[\"topicUnpinned\"]],[[[41,[30,0,[\"canAct\"]],[[[10,3],[14,6,\"\"],[15,\"title\",[30,0,[\"unpinnedTitle\"]]],[14,0,\"topic-status\"],[12],[1,[30,0,[\"unpinnedIcon\"]]],[13]],[]],[[[10,1],[15,\"title\",[30,0,[\"unpinnedTitle\"]]],[14,0,\"topic-status\"],[12],[1,[30,0,[\"unpinnedIcon\"]]],[13]],[]]]],[]],null],[41,[30,0,[\"topicInvisible\"]],[[[10,1],[15,\"title\",[30,0,[\"invisibleTitle\"]]],[14,0,\"topic-status\"],[12],[1,[30,0,[\"invisibleIcon\"]]],[13]],[]],null],[8,[39,1],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"after-topic-status\",\"div\",[28,[37,2],null,[[\"topic\"],[[30,0,[\"topic\"]]]]]]],null]],[],false,[\"if\",\"plugin-outlet\",\"hash\"]]",
  "moduleName": "discourse/components/topic-status.hbs",
  "isStrictMode": false
});