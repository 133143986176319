import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './group-manage-logs-row.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import TEMPLATE from "./group-manage-logs-row.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  tagName: "",
  expandDetails: false,
  toggleDetails() {
    this.toggleProperty("expandDetails");
  },
  filter(params) {
    this.set(`filters.${params.key}`, params.value);
  }
}, (_applyDecoratedDescriptor(_obj, "toggleDetails", [action], Object.getOwnPropertyDescriptor(_obj, "toggleDetails"), _obj), _applyDecoratedDescriptor(_obj, "filter", [action], Object.getOwnPropertyDescriptor(_obj, "filter"), _obj)), _obj)));