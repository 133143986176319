import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <p class="user-profile-hidden">{{i18n "user.profile_hidden"}}</p>
*/
{
  "id": "CQw46vVM",
  "block": "[[[10,2],[14,0,\"user-profile-hidden\"],[12],[1,[28,[35,0],[\"user.profile_hidden\"],null]],[13]],[],false,[\"i18n\"]]",
  "moduleName": "discourse/templates/user/profile-hidden.hbs",
  "isStrictMode": false
});