import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="column categories">
  <CategoriesOnly @categories={{this.categories}} />
</div>

<div class="column">
  <CategoriesTopicList
    @topics={{this.topics}}
    @filter="latest"
    class="latest-topic-list"
  />
</div>

<PluginOutlet @name="extra-categories-column" @connectorTagName="div" />
*/
{
  "id": "waTO2NCy",
  "block": "[[[10,0],[14,0,\"column categories\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@categories\"],[[30,0,[\"categories\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"column\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"latest-topic-list\"]],[[\"@topics\",\"@filter\"],[[30,0,[\"topics\"]],\"latest\"]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@name\",\"@connectorTagName\"],[\"extra-categories-column\",\"div\"]],null]],[],false,[\"categories-only\",\"categories-topic-list\",\"plugin-outlet\"]]",
  "moduleName": "discourse/components/categories-and-latest-topics.hbs",
  "isStrictMode": false
});