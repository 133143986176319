import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  class={{concat-class "timeline-container" this.classes}}
  {{did-insert this.addShowClass}}
>
  <div class="topic-timeline">
    <TopicTimeline::Container
      @model={{@model}}
      @enteredIndex={{this.enteredIndex}}
      @jumpTop={{@jumpTop}}
      @jumpBottom={{@jumpBottom}}
      @jumpEnd={{@jumpEnd}}
      @jumpToIndex={{@jumpToIndex}}
      @jumpToPostPrompt={{@jumpToPostPrompt}}
      @fullscreen={{@fullscreen}}
      @mobileView={{@mobileView}}
      @toggleMultiSelect={{@toggleMultiSelect}}
      @showTopicSlowModeUpdate={{@showTopicSlowModeUpdate}}
      @showTopReplies={{@showTopReplies}}
      @deleteTopic={{@deleteTopic}}
      @recoverTopic={{@recoverTopic}}
      @toggleClosed={{@toggleClosed}}
      @toggleArchived={{@toggleArchived}}
      @toggleVisibility={{@toggleVisibility}}
      @showTopicTimerModal={{@showTopicTimerModal}}
      @showFeatureTopic={{@showFeatureTopic}}
      @showChangeTimestamp={{@showChangeTimestamp}}
      @resetBumpDate={{@resetBumpDate}}
      @convertToPublicTopic={{@convertToPublicTopic}}
      @convertToPrivateMessage={{@convertToPrivateMessage}}
      @replyToPost={{@replyToPost}}
      @setDocked={{this.setDocked}}
      @setDockedBottom={{this.setDockedBottom}}
    />
  </div>
</div>
*/
{
  "id": "4I1nCIQt",
  "block": "[[[11,0],[16,0,[28,[37,0],[\"timeline-container\",[30,0,[\"classes\"]]],null]],[4,[38,1],[[30,0,[\"addShowClass\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"topic-timeline\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@model\",\"@enteredIndex\",\"@jumpTop\",\"@jumpBottom\",\"@jumpEnd\",\"@jumpToIndex\",\"@jumpToPostPrompt\",\"@fullscreen\",\"@mobileView\",\"@toggleMultiSelect\",\"@showTopicSlowModeUpdate\",\"@showTopReplies\",\"@deleteTopic\",\"@recoverTopic\",\"@toggleClosed\",\"@toggleArchived\",\"@toggleVisibility\",\"@showTopicTimerModal\",\"@showFeatureTopic\",\"@showChangeTimestamp\",\"@resetBumpDate\",\"@convertToPublicTopic\",\"@convertToPrivateMessage\",\"@replyToPost\",\"@setDocked\",\"@setDockedBottom\"],[[30,1],[30,0,[\"enteredIndex\"]],[30,2],[30,3],[30,4],[30,5],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12],[30,13],[30,14],[30,15],[30,16],[30,17],[30,18],[30,19],[30,20],[30,21],[30,22],[30,23],[30,0,[\"setDocked\"]],[30,0,[\"setDockedBottom\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@model\",\"@jumpTop\",\"@jumpBottom\",\"@jumpEnd\",\"@jumpToIndex\",\"@jumpToPostPrompt\",\"@fullscreen\",\"@mobileView\",\"@toggleMultiSelect\",\"@showTopicSlowModeUpdate\",\"@showTopReplies\",\"@deleteTopic\",\"@recoverTopic\",\"@toggleClosed\",\"@toggleArchived\",\"@toggleVisibility\",\"@showTopicTimerModal\",\"@showFeatureTopic\",\"@showChangeTimestamp\",\"@resetBumpDate\",\"@convertToPublicTopic\",\"@convertToPrivateMessage\",\"@replyToPost\"],false,[\"concat-class\",\"did-insert\",\"topic-timeline/container\"]]",
  "moduleName": "discourse/components/topic-timeline.hbs",
  "isStrictMode": false
});