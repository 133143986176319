import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DEditor @value={{this.value}} @change={{this.valueChanged}} />
*/
{
  "id": "adhFukNT",
  "block": "[[[8,[39,0],null,[[\"@value\",\"@change\"],[[30,0,[\"value\"]],[30,0,[\"valueChanged\"]]]],null]],[],false,[\"d-editor\"]]",
  "moduleName": "discourse/components/reviewable-field-editor.hbs",
  "isStrictMode": false
});