import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
import Controller, { inject as controller } from "@ember/controller";
import EmberObject, { action } from "@ember/object";
import discourseComputed, { observes } from "discourse-common/utils/decorators";
export default Controller.extend((_dec = discourseComputed("filters.action", "filters.acting_user", "filters.target_user", "filters.subject"), _dec2 = observes("filters.action", "filters.acting_user", "filters.target_user", "filters.subject"), (_obj = {
  group: controller(),
  application: controller(),
  loading: false,
  offset: 0,
  init() {
    this._super(...arguments);
    this.set("filters", EmberObject.create());
  },
  filterParams(filtersAction, acting_user, target_user, subject) {
    return {
      action: filtersAction,
      acting_user,
      target_user,
      subject
    };
  },
  _refreshModel() {
    this.get("group.model").findLogs(0, this.filterParams).then(results => {
      this.set("offset", 0);
      this.model.setProperties({
        logs: results.logs,
        all_loaded: results.all_loaded
      });
    });
  },
  reset() {
    this.setProperties({
      offset: 0,
      filters: EmberObject.create()
    });
  },
  loadMore() {
    if (this.get("model.all_loaded")) {
      return;
    }
    this.set("loading", true);
    this.get("group.model").findLogs(this.offset + 1, this.filterParams).then(results => {
      results.logs.forEach(result => this.get("model.logs").addObject(result));
      this.incrementProperty("offset");
      this.set("model.all_loaded", results.all_loaded);
    }).finally(() => this.set("loading", false));
  },
  clearFilter(key) {
    this.set(`filters.${key}`, "");
  }
}, (_applyDecoratedDescriptor(_obj, "filterParams", [_dec], Object.getOwnPropertyDescriptor(_obj, "filterParams"), _obj), _applyDecoratedDescriptor(_obj, "_refreshModel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_refreshModel"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [action], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "clearFilter", [action], Object.getOwnPropertyDescriptor(_obj, "clearFilter"), _obj)), _obj)));