import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './customize-form-template-view.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import I18n from "discourse-i18n";
import TEMPLATE from "./customize-form-template-view.hbs";
import { setComponentTemplate } from "@ember/component";
let CustomizeFormTemplateViewModal = (_class = class CustomizeFormTemplateViewModal extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "dialog", _descriptor2, this);
    _initializerDefineProperty(this, "showPreview", _descriptor3, this);
  }
  togglePreview() {
    this.showPreview = !this.showPreview;
  }
  editTemplate() {
    this.router.transitionTo("adminCustomizeFormTemplates.edit", this.args.model);
  }
  deleteTemplate() {
    return this.dialog.yesNoConfirm({
      message: I18n.t("admin.form_templates.delete_confirm", {
        template_name: this.args.model.name
      }),
      didConfirm: () => {
        ajax(`/admin/customize/form-templates/${this.args.model.id}.json`, {
          type: "DELETE"
        }).then(() => {
          this.args.refreshModel();
        }).catch(popupAjaxError);
      }
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showPreview", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "togglePreview", [action], Object.getOwnPropertyDescriptor(_class.prototype, "togglePreview"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editTemplate", [action], Object.getOwnPropertyDescriptor(_class.prototype, "editTemplate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteTemplate", [action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteTemplate"), _class.prototype)), _class);
export { CustomizeFormTemplateViewModal as default };
setComponentTemplate(TEMPLATE, CustomizeFormTemplateViewModal);