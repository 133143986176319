import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
/* import __COLOCATED_TEMPLATE__ from './topic-timeline.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import optionalService from "discourse/lib/optional-service";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./topic-timeline.hbs";
import { setComponentTemplate } from "@ember/component";
let TopicTimeline = (_class = class TopicTimeline extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "enteredIndex", _descriptor3, this);
    _initializerDefineProperty(this, "docked", _descriptor4, this);
    _initializerDefineProperty(this, "dockedBottom", _descriptor5, this);
    _defineProperty(this, "adminTools", optionalService());
    if (this.args.prevEvent) {
      this.enteredIndex = this.args.prevEvent.postIndex - 1;
    }
  }
  get createdAt() {
    return new Date(this.args.model.created_at);
  }
  get classes() {
    const classes = [];
    if (this.args.fullscreen) {
      classes.push("timeline-fullscreen");
    }
    if (this.docked) {
      classes.push("timeline-docked");
      if (this.dockedBottom) {
        classes.push("timeline-docked-bottom");
      }
    }
    return classes.join(" ");
  }
  addShowClass(element) {
    if (this.args.fullscreen && !this.args.addShowClass) {
      element.classList.add("show");
    }
  }
  setDocked(value) {
    if (this.docked !== value) {
      this.docked = value;
    }
  }
  setDockedBottom(value) {
    if (this.dockedBottom !== value) {
      this.dockedBottom = value;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "enteredIndex", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.enteredIndex;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "docked", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dockedBottom", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "addShowClass", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "addShowClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDocked", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setDocked"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDockedBottom", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setDockedBottom"), _class.prototype)), _class);
export { TopicTimeline as default };
setComponentTemplate(TEMPLATE, TopicTimeline);