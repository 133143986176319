import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './local-login-form.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import ForgotPassword from "discourse/components/modal/forgot-password";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { escapeExpression } from "discourse/lib/utilities";
import { getWebauthnCredential } from "discourse/lib/webauthn";
import I18n from "discourse-i18n";
import TEMPLATE from "./local-login-form.hbs";
import { setComponentTemplate } from "@ember/component";
let LocalLoginBody = (_class = class LocalLoginBody extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "modal", _descriptor, this);
    _initializerDefineProperty(this, "maskPassword", _descriptor2, this);
    _initializerDefineProperty(this, "processingEmailLink", _descriptor3, this);
    _initializerDefineProperty(this, "capsLockOn", _descriptor4, this);
  }
  get credentialsClass() {
    return this.args.showSecondFactor || this.args.showSecurityKey ? "hidden" : "";
  }
  get secondFactorClass() {
    return this.args.showSecondFactor || this.args.showSecurityKey ? "" : "hidden";
  }
  get disableLoginFields() {
    return this.args.showSecondFactor || this.args.showSecurityKey;
  }
  passkeyConditionalLogin() {
    if (this.args.canUsePasskeys) {
      this.args.passkeyLogin("conditional");
    }
  }
  togglePasswordMask() {
    this.maskPassword = !this.maskPassword;
  }
  async emailLogin(event) {
    event?.preventDefault();
    if (this.processingEmailLink) {
      return;
    }
    if (isEmpty(this.args.loginName)) {
      this.args.flashChanged(I18n.t("login.blank_username"));
      this.args.flashTypeChanged("info");
      return;
    }
    try {
      this.processingEmailLink = true;
      const data = await ajax("/u/email-login", {
        data: {
          login: this.args.loginName.trim()
        },
        type: "POST"
      });
      const loginName = escapeExpression(this.args.loginName);
      const isEmail = loginName.match(/@/);
      const key = isEmail ? "email_login.complete_email" : "email_login.complete_username";
      if (data.user_found === false) {
        this.args.flashChanged(htmlSafe(I18n.t(`${key}_not_found`, {
          email: loginName,
          username: loginName
        })));
        this.args.flashTypeChanged("error");
      } else {
        const postfix = data.hide_taken ? "" : "_found";
        this.args.flashChanged(htmlSafe(I18n.t(`${key}${postfix}`, {
          email: loginName,
          username: loginName
        })));
        this.args.flashTypeChanged("success");
      }
    } catch (e) {
      popupAjaxError(e);
    } finally {
      this.processingEmailLink = false;
    }
  }
  loginOnEnter(event) {
    if (event.key === "Enter") {
      this.args.login();
    }
  }
  handleForgotPassword(event) {
    event?.preventDefault();
    this.modal.show(ForgotPassword, {
      model: {
        emailOrUsername: this.args.loginName
      }
    });
  }
  authenticateSecurityKey() {
    getWebauthnCredential(this.args.securityKeyChallenge, this.args.securityKeyAllowedCredentialIds, credentialData => {
      this.args.securityKeyCredentialChanged(credentialData);
      this.args.login();
    }, error => {
      this.args.flashChanged(error);
      this.args.flashTypeChanged("error");
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "maskPassword", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "processingEmailLink", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "capsLockOn", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "passkeyConditionalLogin", [action], Object.getOwnPropertyDescriptor(_class.prototype, "passkeyConditionalLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePasswordMask", [action], Object.getOwnPropertyDescriptor(_class.prototype, "togglePasswordMask"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emailLogin", [action], Object.getOwnPropertyDescriptor(_class.prototype, "emailLogin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "loginOnEnter", [action], Object.getOwnPropertyDescriptor(_class.prototype, "loginOnEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleForgotPassword", [action], Object.getOwnPropertyDescriptor(_class.prototype, "handleForgotPassword"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "authenticateSecurityKey", [action], Object.getOwnPropertyDescriptor(_class.prototype, "authenticateSecurityKey"), _class.prototype)), _class);
export { LocalLoginBody as default };
setComponentTemplate(TEMPLATE, LocalLoginBody);