import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";
let template = compiler({
  "1": function (container, depth0, helpers, partials, data) {
    var stack1,
      alias1 = depth0 != null ? depth0 : container.nullContext || {},
      alias2 = container.escapeExpression,
      lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
    return "        <li class=\"hashtag-autocomplete__option\">\n          <a class=\"hashtag-autocomplete__link\" title=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "option.description", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 6,
          "column": 55
        },
        "end": {
          "line": 6,
          "column": 77
        }
      }
    })) + "\" href>" + ((stack1 = lookupProperty(helpers, "get").call(alias1, "option.icon", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 6,
          "column": 84
        },
        "end": {
          "line": 6,
          "column": 101
        }
      }
    })) != null ? stack1 : "") + "<span class=\"hashtag-autocomplete__text\">" + alias2(lookupProperty(helpers, "get").call(alias1, "option.text", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 6,
          "column": 142
        },
        "end": {
          "line": 6,
          "column": 157
        }
      }
    })) + ((stack1 = lookupProperty(helpers, "if").call(alias1, "option.secondary_text", {
      "name": "if",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "fn": container.program(2, data, 0),
      "inverse": container.noop,
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 6,
          "column": 157
        },
        "end": {
          "line": 6,
          "column": 266
        }
      }
    })) != null ? stack1 : "") + "</span></span>\n          </a>\n        </li>\n";
  },
  "2": function (container, depth0, helpers, partials, data) {
    var lookupProperty = container.lookupProperty || function (parent, propertyName) {
      if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
        return parent[propertyName];
      }
      return undefined;
    };
    return "<span class=\"hashtag-autocomplete__meta-text\">(" + container.escapeExpression(lookupProperty(helpers, "get").call(depth0 != null ? depth0 : container.nullContext || {}, "option.secondary_text", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 6,
          "column": 233
        },
        "end": {
          "line": 6,
          "column": 258
        }
      }
    })) + ")";
  },
  "compiler": [8, ">= 4.3.0"],
  "main": function (container, depth0, helpers, partials, data) {
    var stack1,
      lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
    return "<div class='autocomplete hashtag-autocomplete'>\n  <div class=\"hashtag-autocomplete__fadeout\">\n    <ul>\n" + ((stack1 = lookupProperty(helpers, "each").call(depth0 != null ? depth0 : container.nullContext || {}, "option", "in", "options", {
      "name": "each",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "fn": container.program(1, data, 0),
      "inverse": container.noop,
      "types": ["PathExpression", "CommentStatement", "PathExpression"],
      "contexts": [depth0, depth0, depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 4,
          "column": 6
        },
        "end": {
          "line": 9,
          "column": 15
        }
      }
    })) != null ? stack1 : "") + "    </ul>\n  </div>\n</div>\n";
  },
  "useData": true
});
addRawTemplate("hashtag-autocomplete", template, {
  core: true
});
export default template;