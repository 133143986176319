import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6;
/* import __COLOCATED_TEMPLATE__ from './forgot-password.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { isEmpty } from "@ember/utils";
import { ajax } from "discourse/lib/ajax";
import { extractError } from "discourse/lib/ajax-error";
import cookie from "discourse/lib/cookie";
import { escapeExpression } from "discourse/lib/utilities";
import getURL from "discourse-common/lib/get-url";
import I18n from "discourse-i18n";
import TEMPLATE from "./forgot-password.hbs";
import { setComponentTemplate } from "@ember/component";
let ForgotPassword = (_class = class ForgotPassword extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    _initializerDefineProperty(this, "emailOrUsername", _descriptor2, this);
    _initializerDefineProperty(this, "disabled", _descriptor3, this);
    _initializerDefineProperty(this, "helpSeen", _descriptor4, this);
    _initializerDefineProperty(this, "offerHelp", _descriptor5, this);
    _initializerDefineProperty(this, "flash", _descriptor6, this);
  }
  get submitDisabled() {
    if (this.disabled) {
      return true;
    } else if (this.siteSettings.hide_email_address_taken) {
      return !this.emailOrUsername.includes("@");
    } else {
      return isEmpty(this.emailOrUsername.trim());
    }
  }
  updateEmailOrUsername(event) {
    this.emailOrUsername = event.target.value;
  }
  help() {
    this.offerHelp = I18n.t("forgot_password.help", {
      basePath: getURL("")
    });
    this.helpSeen = true;
  }
  async resetPassword() {
    if (this.submitDisabled) {
      return false;
    }
    this.disabled = true;
    this.flash = null;
    try {
      const data = await ajax("/session/forgot_password", {
        data: {
          login: this.emailOrUsername.trim()
        },
        type: "POST"
      });
      const emailOrUsername = escapeExpression(this.emailOrUsername);
      let key = "forgot_password.complete";
      key += emailOrUsername.match(/@/) ? "_email" : "_username";
      if (data.user_found === false) {
        key += "_not_found";
        this.flash = htmlSafe(I18n.t(key, {
          email: emailOrUsername,
          username: emailOrUsername
        }));
      } else {
        key += data.user_found ? "_found" : "";
        this.emailOrUsername = "";
        this.offerHelp = I18n.t(key, {
          email: emailOrUsername,
          username: emailOrUsername
        });
        this.helpSeen = !data.user_found;
      }
    } catch (error) {
      this.flash = extractError(error);
    } finally {
      this.disabled = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "emailOrUsername", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return cookie("email") || this.args.model?.emailOrUsername || "";
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "disabled", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "helpSeen", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "offerHelp", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "updateEmailOrUsername", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateEmailOrUsername"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "help", [action], Object.getOwnPropertyDescriptor(_class.prototype, "help"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetPassword", [action], Object.getOwnPropertyDescriptor(_class.prototype, "resetPassword"), _class.prototype)), _class);
export { ForgotPassword as default };
setComponentTemplate(TEMPLATE, ForgotPassword);