import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  class="control-group preferences-navigation-menu-navigation"
  data-setting-name="user-navigation-menu-navigation"
>
  <legend class="control-label">{{i18n
      "user.experimental_sidebar.navigation_section"
    }}</legend>

  <div class="controls">
    <label>{{i18n
        "user.experimental_sidebar.navigation_section_instruction"
      }}</label>

    <PreferenceCheckbox
      @labelKey="user.experimental_sidebar.link_to_filtered_list_checkbox_description"
      @checked={{this.newSidebarLinkToFilteredList}}
      class="pref-link-to-filtered-list"
    />
    <PreferenceCheckbox
      @labelKey="user.experimental_sidebar.show_count_new_items_checkbox_description"
      @checked={{this.newSidebarShowCountOfNewItems}}
      class="pref-show-count-new-items"
    />
  </div>
</div>

<SaveControls
  @model={{this.model}}
  @action={{action "save"}}
  @saved={{this.saved}}
/>
*/
{
  "id": "3KTBU8Aj",
  "block": "[[[10,0],[14,0,\"control-group preferences-navigation-menu-navigation\"],[14,\"data-setting-name\",\"user-navigation-menu-navigation\"],[12],[1,\"\\n  \"],[10,\"legend\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"user.experimental_sidebar.navigation_section\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,0],[\"user.experimental_sidebar.navigation_section_instruction\"],null]],[13],[1,\"\\n\\n    \"],[8,[39,1],[[24,0,\"pref-link-to-filtered-list\"]],[[\"@labelKey\",\"@checked\"],[\"user.experimental_sidebar.link_to_filtered_list_checkbox_description\",[30,0,[\"newSidebarLinkToFilteredList\"]]]],null],[1,\"\\n    \"],[8,[39,1],[[24,0,\"pref-show-count-new-items\"]],[[\"@labelKey\",\"@checked\"],[\"user.experimental_sidebar.show_count_new_items_checkbox_description\",[30,0,[\"newSidebarShowCountOfNewItems\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@model\",\"@action\",\"@saved\"],[[30,0,[\"model\"]],[28,[37,3],[[30,0],\"save\"],null],[30,0,[\"saved\"]]]],null]],[],false,[\"i18n\",\"preference-checkbox\",\"save-controls\",\"action\"]]",
  "moduleName": "discourse/templates/preferences/navigation-menu.hbs",
  "isStrictMode": false
});