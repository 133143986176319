import Component from "@ember/component";
import { openLinkInNewTab, shouldOpenInNewTab } from "discourse/lib/click-track";
export default Component.extend({
  click(event) {
    if (event?.target?.tagName === "A") {
      if (shouldOpenInNewTab(event.target.href)) {
        openLinkInNewTab(event, event.target);
      }
    }
  }
});