import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './explain-reviewable.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import TEMPLATE from "./explain-reviewable.hbs";
import { setComponentTemplate } from "@ember/component";
let ExplainReviewable = (_class = class ExplainReviewable extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "store", _descriptor, this);
    _initializerDefineProperty(this, "loading", _descriptor2, this);
    _initializerDefineProperty(this, "reviewableExplanation", _descriptor3, this);
    this.loadExplanation();
  }
  async loadExplanation() {
    try {
      const result = await this.store.find("reviewable-explanation", this.args.model.reviewable.id);
      this.reviewableExplanation = result;
    } finally {
      this.loading = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "reviewableExplanation", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _applyDecoratedDescriptor(_class.prototype, "loadExplanation", [action], Object.getOwnPropertyDescriptor(_class.prototype, "loadExplanation"), _class.prototype)), _class);
export { ExplainReviewable as default };
setComponentTemplate(TEMPLATE, ExplainReviewable);