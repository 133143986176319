import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _obj, _init;
/* import __COLOCATED_TEMPLATE__ from './d-navigation.hbs'; */
import { tracked } from "@glimmer/tracking";
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { dependentKeyCompat } from "@ember/object/compat";
import { service } from "@ember/service";
import { htmlSafe } from "@ember/template";
import { setting } from "discourse/lib/computed";
import { filterTypeForMode } from "discourse/lib/filter-mode";
import { NotificationLevels } from "discourse/lib/notification-levels";
import NavItem from "discourse/models/nav-item";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./d-navigation.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed(), _dec2 = discourseComputed("category"), _dec3 = discourseComputed("category.notification_level"), _dec4 = discourseComputed("tagNotification", "additionalTags"), _dec5 = discourseComputed("category", "createTopicDisabled"), _dec6 = discourseComputed("createTopicDisabled", "hasDraft", "categoryReadOnlyBanner", "canCreateTopicOnTag", "tag.id"), _dec7 = discourseComputed("categoryReadOnlyBanner", "hasDraft"), _dec8 = discourseComputed("hasDraft"), _dec9 = discourseComputed("category.can_edit"), _dec10 = discourseComputed("additionalTags", "category", "tag.id"), _dec11 = discourseComputed("filterType", "category", "noSubcategories", "tag.id", "router.currentRoute.queryParams", "skipCategoriesNavItem"), _dec12 = discourseComputed("filterType"), (_obj = {
  router: service(),
  dialog: service(),
  tagName: "",
  filterMode: tracked(),
  fixedCategoryPositions: setting("fixed_category_positions"),
  get filterType() {
    return filterTypeForMode(this.filterMode);
  },
  categories() {
    let categories = this.site.categoriesList;
    if (!this.siteSettings.allow_uncategorized_topics) {
      categories = categories.filter(category => category.id !== this.site.uncategorized_category_id);
    }
    if (this.currentUser?.indirectly_muted_category_ids) {
      categories = categories.filter(category => !this.currentUser.indirectly_muted_category_ids.includes(category.id));
    }
    return categories;
  },
  showCategoryNotifications(category) {
    return category && this.currentUser;
  },
  categoryNotificationLevel(notificationLevel) {
    if (this.currentUser?.indirectly_muted_category_ids?.includes(this.category.id)) {
      return NotificationLevels.MUTED;
    } else {
      return notificationLevel;
    }
  },
  showTagNotifications(tagNotification, additionalTags) {
    return tagNotification && !additionalTags;
  },
  categoryReadOnlyBanner(category, createTopicDisabled) {
    if (category && this.currentUser && createTopicDisabled) {
      return category.read_only_banner;
    }
  },
  createTopicButtonDisabled(createTopicDisabled, hasDraft, categoryReadOnlyBanner, canCreateTopicOnTag, tagId) {
    if (tagId && !canCreateTopicOnTag) {
      return true;
    } else if (categoryReadOnlyBanner && !hasDraft) {
      return false;
    }
    return createTopicDisabled;
  },
  createTopicClass(categoryReadOnlyBanner, hasDraft) {
    let classNames = ["btn-default"];
    if (hasDraft) {
      classNames.push("open-draft");
    } else if (categoryReadOnlyBanner) {
      classNames.push("disabled");
    }
    return classNames.join(" ");
  },
  createTopicLabel(hasDraft) {
    return hasDraft ? "topic.open_draft" : "topic.create";
  },
  showCategoryEdit: canEdit => canEdit,
  showToggleInfo(additionalTags, category, tagId) {
    return !additionalTags && !category && tagId !== "none";
  },
  navItems(filterType, category, noSubcategories, tagId, currentRouteQueryParams, skipCategoriesNavItem) {
    return NavItem.buildList(category, {
      filterType,
      noSubcategories,
      currentRouteQueryParams,
      tagId,
      siteSettings: this.siteSettings,
      skipCategoriesNavItem
    });
  },
  notCategoriesRoute(filterType) {
    return filterType !== "categories";
  },
  async changeTagNotificationLevel(notificationLevel) {
    const response = await this.tagNotification.update({
      notification_level: notificationLevel
    });
    const payload = response.responseJson;
    this.tagNotification.set("notification_level", notificationLevel);
    this.currentUser.setProperties({
      watched_tags: payload.watched_tags,
      watching_first_post_tags: payload.watching_first_post_tags,
      tracked_tags: payload.tracked_tags,
      muted_tags: payload.muted_tags,
      regular_tags: payload.regular_tags
    });
  },
  actions: {
    changeCategoryNotificationLevel(notificationLevel) {
      this.category.setNotification(notificationLevel);
    },
    selectCategoryAdminDropdownAction(actionId) {
      switch (actionId) {
        case "create":
          this.createCategory();
          break;
        case "reorder":
          this.reorderCategories();
          break;
      }
    },
    clickCreateTopicButton() {
      if (this.categoryReadOnlyBanner && !this.hasDraft) {
        this.dialog.alert({
          message: htmlSafe(this.categoryReadOnlyBanner)
        });
      } else {
        this.createTopic();
      }
    }
  }
}, (_applyDecoratedDescriptor(_obj, "filterType", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_obj, "filterType"), _obj), _applyDecoratedDescriptor(_obj, "categories", [_dec], Object.getOwnPropertyDescriptor(_obj, "categories"), _obj), _applyDecoratedDescriptor(_obj, "showCategoryNotifications", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showCategoryNotifications"), _obj), _applyDecoratedDescriptor(_obj, "categoryNotificationLevel", [_dec3], Object.getOwnPropertyDescriptor(_obj, "categoryNotificationLevel"), _obj), _applyDecoratedDescriptor(_obj, "showTagNotifications", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showTagNotifications"), _obj), _applyDecoratedDescriptor(_obj, "categoryReadOnlyBanner", [_dec5], Object.getOwnPropertyDescriptor(_obj, "categoryReadOnlyBanner"), _obj), _applyDecoratedDescriptor(_obj, "createTopicButtonDisabled", [_dec6], Object.getOwnPropertyDescriptor(_obj, "createTopicButtonDisabled"), _obj), _applyDecoratedDescriptor(_obj, "createTopicClass", [_dec7], Object.getOwnPropertyDescriptor(_obj, "createTopicClass"), _obj), _applyDecoratedDescriptor(_obj, "createTopicLabel", [_dec8], Object.getOwnPropertyDescriptor(_obj, "createTopicLabel"), _obj), _applyDecoratedDescriptor(_obj, "showCategoryEdit", [_dec9], (_init = Object.getOwnPropertyDescriptor(_obj, "showCategoryEdit"), _init = _init ? _init.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "showToggleInfo", [_dec10], Object.getOwnPropertyDescriptor(_obj, "showToggleInfo"), _obj), _applyDecoratedDescriptor(_obj, "navItems", [_dec11], Object.getOwnPropertyDescriptor(_obj, "navItems"), _obj), _applyDecoratedDescriptor(_obj, "notCategoriesRoute", [_dec12], Object.getOwnPropertyDescriptor(_obj, "notCategoriesRoute"), _obj), _applyDecoratedDescriptor(_obj, "changeTagNotificationLevel", [action], Object.getOwnPropertyDescriptor(_obj, "changeTagNotificationLevel"), _obj)), _obj))));