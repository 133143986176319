import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#unless this.isMuted}}
  <span class="subcategory">
    <CategoryTitleBefore @category={{this.category}} />
    {{category-link this.category hideParent="true"}}
    {{#unless this.hideUnread}}
      <CategoryUnread
        @category={{this.category}}
        @unreadTopicsCount={{this.unreadTopicsCount}}
        @newTopicsCount={{this.newTopicsCount}}
      />
    {{/unless}}
  </span>
{{/unless}}
*/
{
  "id": "7m6NDasE",
  "block": "[[[41,[51,[30,0,[\"isMuted\"]]],[[[1,\"  \"],[10,1],[14,0,\"subcategory\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@category\"],[[30,0,[\"category\"]]]],null],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"category\"]]],[[\"hideParent\"],[\"true\"]]]],[1,\"\\n\"],[41,[51,[30,0,[\"hideUnread\"]]],[[[1,\"      \"],[8,[39,3],null,[[\"@category\",\"@unreadTopicsCount\",\"@newTopicsCount\"],[[30,0,[\"category\"]],[30,0,[\"unreadTopicsCount\"]],[30,0,[\"newTopicsCount\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"category-title-before\",\"category-link\",\"category-unread\"]]",
  "moduleName": "discourse/components/sub-category-item.hbs",
  "isStrictMode": false
});