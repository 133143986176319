import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './reviewable-user.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./reviewable-user.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("reviewable.user_fields"), (_obj = {
  userFields(fields) {
    return this.site.collectUserFields(fields);
  }
}, (_applyDecoratedDescriptor(_obj, "userFields", [_dec], Object.getOwnPropertyDescriptor(_obj, "userFields"), _obj)), _obj))));