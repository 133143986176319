import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
import { gt, not } from "@ember/object/computed";
import { propertyEqual } from "discourse/lib/computed";
import RestModel from "discourse/models/rest";
let Archetype = (_dec = gt("options.length", 0), _dec2 = propertyEqual("id", "site.default_archetype"), _dec3 = not("isDefault"), (_class = class Archetype extends RestModel {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "hasOptions", _descriptor, this);
    _initializerDefineProperty(this, "isDefault", _descriptor2, this);
    _initializerDefineProperty(this, "notDefault", _descriptor3, this);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "hasOptions", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isDefault", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "notDefault", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class));
export { Archetype as default };