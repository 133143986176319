import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @closeModal={{@closeModal}}
  @title={{i18n "login.change_email"}}
  @flash={{this.flash}}
>
  <:body>
    <ActivationEmailForm
      @email={{@model.newEmail}}
      @updateNewEmail={{this.updateNewEmail}}
    />
  </:body>
  <:footer>
    <DButton
      @action={{this.changeEmail}}
      @label="login.submit_new_email"
      @disabled={{this.submitDisabled}}
      class="btn-primary"
    />
    <DButton @action={{@closeModal}} @label="close" />
  </:footer>
</DModal>
*/
{
  "id": "2N97crKX",
  "block": "[[[8,[39,0],null,[[\"@closeModal\",\"@title\",\"@flash\"],[[30,1],[28,[37,1],[\"login.change_email\"],null],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@email\",\"@updateNewEmail\"],[[30,2,[\"newEmail\"]],[30,0,[\"updateNewEmail\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"changeEmail\"]],\"login.submit_new_email\",[30,0,[\"submitDisabled\"]]]],null],[1,\"\\n    \"],[8,[39,3],null,[[\"@action\",\"@label\"],[[30,1],\"close\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"activation-email-form\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/activation-edit.hbs",
  "isStrictMode": false
});