import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  id="suggested-topics"
  class="more-topics__list {{if this.hidden 'hidden'}}"
  role="complementary"
  aria-labelledby="suggested-topics-title"
  {{did-insert this.registerList}}
  {{will-destroy this.removeList}}
>
  {{#unless this.hidden}}
    <UserTip
      @id="suggested_topics"
      @titleText={{i18n "user_tips.suggested_topics.title"}}
      @contentText={{i18n "user_tips.suggested_topics.content"}}
      @placement="top-start"
    />
  {{/unless}}

  <h3 id="suggested-topics-title" class="more-topics__list-title">
    {{i18n this.suggestedTitleLabel}}
  </h3>

  <div class="topics">
    {{#if @topic.isPrivateMessage}}
      <BasicTopicList
        @hideCategory="true"
        @showPosters="true"
        @topics={{@topic.suggestedTopics}}
      />
    {{else}}
      <BasicTopicList @topics={{@topic.suggestedTopics}} />
    {{/if}}
  </div>
</div>
*/
{
  "id": "4zv3Wk5N",
  "block": "[[[11,0],[24,1,\"suggested-topics\"],[16,0,[29,[\"more-topics__list \",[52,[30,0,[\"hidden\"]],\"hidden\"]]]],[24,\"role\",\"complementary\"],[24,\"aria-labelledby\",\"suggested-topics-title\"],[4,[38,1],[[30,0,[\"registerList\"]]],null],[4,[38,2],[[30,0,[\"removeList\"]]],null],[12],[1,\"\\n\"],[41,[51,[30,0,[\"hidden\"]]],[[[1,\"    \"],[8,[39,4],null,[[\"@id\",\"@titleText\",\"@contentText\",\"@placement\"],[\"suggested_topics\",[28,[37,5],[\"user_tips.suggested_topics.title\"],null],[28,[37,5],[\"user_tips.suggested_topics.content\"],null],\"top-start\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,\"h3\"],[14,1,\"suggested-topics-title\"],[14,0,\"more-topics__list-title\"],[12],[1,\"\\n    \"],[1,[28,[35,5],[[30,0,[\"suggestedTitleLabel\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"topics\"],[12],[1,\"\\n\"],[41,[30,1,[\"isPrivateMessage\"]],[[[1,\"      \"],[8,[39,6],null,[[\"@hideCategory\",\"@showPosters\",\"@topics\"],[\"true\",\"true\",[30,1,[\"suggestedTopics\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,6],null,[[\"@topics\"],[[30,1,[\"suggestedTopics\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@topic\"],false,[\"if\",\"did-insert\",\"will-destroy\",\"unless\",\"user-tip\",\"i18n\",\"basic-topic-list\"]]",
  "moduleName": "discourse/components/suggested-topics.hbs",
  "isStrictMode": false
});