import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
/* import __COLOCATED_TEMPLATE__ from './more-topics.hbs'; */
import Component from "@glimmer/component";
import { action, computed } from "@ember/object";
import { service } from "@ember/service";
import { categoryBadgeHTML } from "discourse/helpers/category-link";
import getURL from "discourse-common/lib/get-url";
import { iconHTML } from "discourse-common/lib/icon-library";
import I18n from "discourse-i18n";
import TEMPLATE from "./more-topics.hbs";
import { setComponentTemplate } from "@ember/component";
let MoreTopics = (_dec = computed("moreTopicsPreferenceTracking.topicLists"), _dec2 = computed("moreTopicsPreferenceTracking.selectedTab"), _dec3 = computed("moreTopicsPreferenceTracking.topicLists"), _dec4 = computed("pmTopicTrackingState.isTracking", "pmTopicTrackingState.statesModificationCounter", "topicTrackingState.messageCount"), (_class = class MoreTopics extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
    _initializerDefineProperty(this, "moreTopicsPreferenceTracking", _descriptor2, this);
    _initializerDefineProperty(this, "pmTopicTrackingState", _descriptor3, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor4, this);
    _initializerDefineProperty(this, "currentUser", _descriptor5, this);
  }
  rememberTopicListPreference(value) {
    this.moreTopicsPreferenceTracking.updatePreference(value);
  }
  get singleList() {
    return this.availableTabs.length === 1;
  }
  get selectedTab() {
    return this.moreTopicsPreferenceTracking.selectedTab;
  }
  get availableTabs() {
    return this.moreTopicsPreferenceTracking.topicLists;
  }
  get browseMoreMessage() {
    return this.args.topic.isPrivateMessage ? this._privateMessageBrowseMoreMessage() : this._topicBrowseMoreMessage();
  }
  _privateMessageBrowseMoreMessage() {
    const username = this.currentUser.username;
    const suggestedGroupName = this.args.topic.suggested_group_name;
    const inboxFilter = suggestedGroupName ? "group" : "user";
    const unreadCount = this.pmTopicTrackingState.lookupCount("unread", {
      inboxFilter,
      groupName: suggestedGroupName
    });
    const newCount = this.pmTopicTrackingState.lookupCount("new", {
      inboxFilter,
      groupName: suggestedGroupName
    });
    if (unreadCount + newCount > 0) {
      const hasBoth = unreadCount > 0 && newCount > 0;
      if (suggestedGroupName) {
        return I18n.messageFormat("user.messages.read_more_group_pm_MF", {
          HAS_UNREAD_AND_NEW: hasBoth,
          UNREAD: unreadCount,
          NEW: newCount,
          username,
          groupName: suggestedGroupName,
          groupLink: this._groupLink(username, suggestedGroupName),
          basePath: getURL("")
        });
      } else {
        return I18n.messageFormat("user.messages.read_more_personal_pm_MF", {
          HAS_UNREAD_AND_NEW: hasBoth,
          UNREAD: unreadCount,
          NEW: newCount,
          username,
          basePath: getURL("")
        });
      }
    } else if (suggestedGroupName) {
      return I18n.t("user.messages.read_more_in_group", {
        groupLink: this._groupLink(username, suggestedGroupName)
      });
    } else {
      return I18n.t("user.messages.read_more", {
        basePath: getURL(""),
        username
      });
    }
  }
  _topicBrowseMoreMessage() {
    let category = this.args.topic.category;
    if (category && category.id === this.site.uncategorized_category_id) {
      category = null;
    }
    let unreadTopics = 0;
    let newTopics = 0;
    if (this.currentUser) {
      unreadTopics = this.topicTrackingState.countUnread();
      newTopics = this.topicTrackingState.countNew();
    }
    if (newTopics + unreadTopics > 0) {
      return I18n.messageFormat("topic.read_more_MF", {
        HAS_UNREAD_AND_NEW: unreadTopics > 0 && newTopics > 0,
        UNREAD: unreadTopics,
        NEW: newTopics,
        HAS_CATEGORY: category ? true : false,
        categoryLink: category ? categoryBadgeHTML(category) : null,
        basePath: getURL("")
      });
    } else if (category) {
      return I18n.t("topic.read_more_in_category", {
        categoryLink: categoryBadgeHTML(category),
        latestLink: getURL("/latest")
      });
    } else {
      return I18n.t("topic.read_more", {
        categoryLink: getURL("/categories"),
        latestLink: getURL("/latest")
      });
    }
  }
  _groupLink(username, groupName) {
    return `<a class="group-link" href="${getURL(`/u/${username}/messages/group/${groupName}`)}">${iconHTML("users")} ${groupName}</a>`;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "moreTopicsPreferenceTracking", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "pmTopicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "rememberTopicListPreference", [action], Object.getOwnPropertyDescriptor(_class.prototype, "rememberTopicListPreference"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "singleList", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "singleList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectedTab", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "selectedTab"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "availableTabs", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "availableTabs"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "browseMoreMessage", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "browseMoreMessage"), _class.prototype)), _class));
export { MoreTopics as default };
setComponentTemplate(TEMPLATE, MoreTopics);