import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './modal-tab.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { equal } from "@ember/object/computed";
import { propertyEqual } from "discourse/lib/computed";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./modal-tab.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("panel.title", "panel.rawTitle"), (_obj = {
  tagName: "li",
  classNames: ["modal-tab"],
  panel: null,
  selectedPanel: null,
  panelsLength: null,
  classNameBindings: ["isActive", "singleTab", "panel.id"],
  singleTab: equal("panelsLength", 1),
  isActive: propertyEqual("panel.id", "selectedPanel.id"),
  title(title, rawTitle) {
    return title ? I18n.t(title) : rawTitle;
  },
  click() {
    this.set("selectedPanel", this.panel);
    if (this.onSelectPanel) {
      this.onSelectPanel(this.panel);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "title", [_dec], Object.getOwnPropertyDescriptor(_obj, "title"), _obj)), _obj))));