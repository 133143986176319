import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="control-group form-template-field" data-field-type="dropdown">
  {{#if @attributes.label}}
    <label class="form-template-field__label">
      {{@attributes.label}}
      {{#if @validations.required}}
        {{d-icon "asterisk" class="form-template-field__required-indicator"}}
      {{/if}}
    </label>
  {{/if}}

  {{#if @attributes.description}}
    <span class="form-template-field__description">
      {{html-safe @attributes.description}}
    </span>
  {{/if}}

  {{! TODO(@keegan): Update implementation to use <ComboBox/> instead }}
  {{! Current using <select> as it integrates easily with FormData (will update in v2) }}
  <select
    name={{@id}}
    class="form-template-field__dropdown"
    required={{if @validations.required "required" ""}}
  >
    {{#if @attributes.none_label}}
      <option
        class="form-template-field__dropdown-placeholder"
        value=""
        disabled
        selected
        hidden
      >{{@attributes.none_label}}</option>
    {{/if}}
    {{#each @choices as |choice|}}
      <option value={{choice}} selected={{eq @value choice}}>{{choice}}</option>
    {{/each}}
  </select>
</div>
*/
{
  "id": "J7lnQKqp",
  "block": "[[[10,0],[14,0,\"control-group form-template-field\"],[14,\"data-field-type\",\"dropdown\"],[12],[1,\"\\n\"],[41,[30,1,[\"label\"]],[[[1,\"    \"],[10,\"label\"],[14,0,\"form-template-field__label\"],[12],[1,\"\\n      \"],[1,[30,1,[\"label\"]]],[1,\"\\n\"],[41,[30,2,[\"required\"]],[[[1,\"        \"],[1,[28,[35,1],[\"asterisk\"],[[\"class\"],[\"form-template-field__required-indicator\"]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,1,[\"description\"]],[[[1,\"    \"],[10,1],[14,0,\"form-template-field__description\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[[30,1,[\"description\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,\"  \"],[10,\"select\"],[15,3,[30,3]],[14,0,\"form-template-field__dropdown\"],[15,\"required\",[52,[30,2,[\"required\"]],\"required\",\"\"]],[12],[1,\"\\n\"],[41,[30,1,[\"none_label\"]],[[[1,\"      \"],[10,\"option\"],[14,0,\"form-template-field__dropdown-placeholder\"],[14,2,\"\"],[14,\"disabled\",\"\"],[14,\"selected\",\"\"],[14,\"hidden\",\"\"],[12],[1,[30,1,[\"none_label\"]]],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,4],[[28,[37,4],[[30,4]],null]],null],null,[[[1,\"      \"],[10,\"option\"],[15,2,[30,5]],[15,\"selected\",[28,[37,5],[[30,6],[30,5]],null]],[12],[1,[30,5]],[13],[1,\"\\n\"]],[5]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@attributes\",\"@validations\",\"@id\",\"@choices\",\"choice\",\"@value\"],false,[\"if\",\"d-icon\",\"html-safe\",\"each\",\"-track-array\",\"eq\"]]",
  "moduleName": "discourse/components/form-template-field/dropdown.hbs",
  "isStrictMode": false
});