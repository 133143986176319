import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import Component from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
export default Component.extend((_dec = discourseComputed("field.name"), (_obj = {
  classNameBindings: [":user-field", "field.field_type", "customFieldClass"],
  didInsertElement() {
    this._super(...arguments);
    let element = this.element.querySelector(".user-field.dropdown .select-kit-header");
    element = element || this.element.querySelector("input");
    this.field.element = element;
  },
  noneLabel() {
    return "user_fields.none";
  },
  customFieldClass(fieldName) {
    if (fieldName) {
      fieldName = fieldName.replace(/\s+/g, "-").replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, "").toLowerCase();
      return fieldName && `user-field-${fieldName}`;
    }
  }
}, (_applyDecoratedDescriptor(_obj, "noneLabel", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "noneLabel"), _obj), _applyDecoratedDescriptor(_obj, "customFieldClass", [_dec], Object.getOwnPropertyDescriptor(_obj, "customFieldClass"), _obj)), _obj)));