import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './navigation-item.hbs'; */
import { tracked } from "@glimmer/tracking";
import Component, { setComponentTemplate } from "@ember/component";
import { dependentKeyCompat } from "@ember/object/compat";
import { filterTypeForMode } from "discourse/lib/filter-mode";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./navigation-item.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("content.filterType", "filterType", "content.active"), _dec2 = discourseComputed("content.count", "content.name"), (_obj = {
  tagName: "li",
  classNameBindings: ["active", "content.hasIcon:has-icon", "content.classNames", "isHidden:hidden", "content.name"],
  attributeBindings: ["content.title:title"],
  hidden: false,
  activeClass: "",
  hrefLink: null,
  filterMode: tracked(),
  get filterType() {
    return filterTypeForMode(this.filterMode);
  },
  active(contentFilterType, filterType, active) {
    if (active !== undefined) {
      return active;
    }
    return contentFilterType === filterType;
  },
  isHidden(count, name) {
    return !this.active && this.currentUser && !this.currentUser.new_new_view_enabled && this.currentUser.trust_level > 0 && (name === "new" || name === "unread") && count < 1;
  },
  didReceiveAttrs() {
    this._super(...arguments);
    const content = this.content;
    let href = content.get("href");
    let urlSearchParams = new URLSearchParams();
    let addParamsEvenIfEmpty = false;

    // Include the category id if the option is present
    if (content.get("includeCategoryId")) {
      let categoryId = this.get("content.category.id");
      if (categoryId) {
        urlSearchParams.set("category_id", categoryId);
      }
    }

    // To reset the "filter" sticky param, at least one query param is needed.
    // If no query param is present, add an empty one to ensure a ? is
    // appended to the URL.
    if (content.currentRouteQueryParams) {
      if (content.currentRouteQueryParams.filter) {
        addParamsEvenIfEmpty = true;
      }
      if (content.currentRouteQueryParams.f) {
        urlSearchParams.set("f", content.currentRouteQueryParams.f);
      }
    }
    if (this.siteSettings.desktop_category_page_style === "categories_and_latest_topics_created_date") {
      urlSearchParams.set("order", "created");
    }
    const queryString = urlSearchParams.toString();
    if (addParamsEvenIfEmpty || queryString) {
      href += `?${queryString}`;
    }
    this.set("hrefLink", href);
    this.set("activeClass", this.active ? "active" : "");
  }
}, (_applyDecoratedDescriptor(_obj, "filterType", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_obj, "filterType"), _obj), _applyDecoratedDescriptor(_obj, "active", [_dec], Object.getOwnPropertyDescriptor(_obj, "active"), _obj), _applyDecoratedDescriptor(_obj, "isHidden", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isHidden"), _obj)), _obj))));