import { isPresent } from "@ember/utils";
import { categoryLinkHTML } from "discourse/helpers/category-link";
import { registerRawHelper } from "discourse-common/lib/helpers";
registerRawHelper("category-badge", categoryBadge);
export default function categoryBadge(cat) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return categoryLinkHTML(cat, {
    hideParent: options.hideParent,
    allowUncategorized: options.allowUncategorized,
    categoryStyle: options.categoryStyle,
    link: isPresent(options.link) ? options.link : false
  });
}