import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './uppy-backup-uploader.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { alias } from "@ember/object/computed";
import UppyUploadMixin from "discourse/mixins/uppy-upload";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./uppy-backup-uploader.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend(UppyUploadMixin, (_dec = discourseComputed("localBackupStorage"), _dec2 = discourseComputed("uploading", "uploadProgress"), (_obj = {
  id: "uppy-backup-uploader",
  tagName: "span",
  type: "backup",
  uploadRootPath: "/admin/backups",
  uploadUrl: "/admin/backups/upload",
  useMultipartUploadsIfAvailable(localBackupStorage) {
    return !localBackupStorage && this.siteSettings.enable_direct_s3_uploads;
  },
  // local backups
  useChunkedUploads: alias("localBackupStorage"),
  uploadButtonText(uploading, progress) {
    return uploading ? I18n.t("admin.backups.upload.uploading_progress", {
      progress
    }) : I18n.t("admin.backups.upload.label");
  },
  validateUploadedFilesOptions() {
    return {
      skipValidation: true
    };
  },
  uploadDone(responseData) {
    this.done(responseData.file_name);
  }
}, (_applyDecoratedDescriptor(_obj, "useMultipartUploadsIfAvailable", [_dec], Object.getOwnPropertyDescriptor(_obj, "useMultipartUploadsIfAvailable"), _obj), _applyDecoratedDescriptor(_obj, "uploadButtonText", [_dec2], Object.getOwnPropertyDescriptor(_obj, "uploadButtonText"), _obj)), _obj))));