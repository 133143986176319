import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <ComposerTipCloseButton @action={{fn this.closeMessage this.message}} />

{{html-safe this.message.body}}

<DButton
  @label="user.private_message"
  @icon="envelope"
  @action={{fn this.switchPM this.message}}
  class="btn-primary"
/>
*/
{
  "id": "WboXcjLd",
  "block": "[[[8,[39,0],null,[[\"@action\"],[[28,[37,1],[[30,0,[\"closeMessage\"]],[30,0,[\"message\"]]],null]]],null],[1,\"\\n\\n\"],[1,[28,[35,2],[[30,0,[\"message\",\"body\"]]],null]],[1,\"\\n\\n\"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@label\",\"@icon\",\"@action\"],[\"user.private_message\",\"envelope\",[28,[37,1],[[30,0,[\"switchPM\"]],[30,0,[\"message\"]]],null]]],null]],[],false,[\"composer-tip-close-button\",\"fn\",\"html-safe\",\"d-button\"]]",
  "moduleName": "discourse/templates/composer/get-a-room.hbs",
  "isStrictMode": false
});