import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <ComposerTipCloseButton @action={{fn this.closeMessage this.message}} />

{{html-safe this.message.body}}

{{#if this.currentUser.can_invite_to_forum}}
  <DButton
    @label="footer_nav.share"
    @icon="link"
    @action={{this.shareModal}}
    class="btn-primary"
  />
{{/if}}
*/
{
  "id": "FSYTAsWs",
  "block": "[[[8,[39,0],null,[[\"@action\"],[[28,[37,1],[[30,0,[\"closeMessage\"]],[30,0,[\"message\"]]],null]]],null],[1,\"\\n\\n\"],[1,[28,[35,2],[[30,0,[\"message\",\"body\"]]],null]],[1,\"\\n\\n\"],[41,[30,0,[\"currentUser\",\"can_invite_to_forum\"]],[[[1,\"  \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@label\",\"@icon\",\"@action\"],[\"footer_nav.share\",\"link\",[30,0,[\"shareModal\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"composer-tip-close-button\",\"fn\",\"html-safe\",\"if\",\"d-button\"]]",
  "moduleName": "discourse/templates/composer/dominating-topic.hbs",
  "isStrictMode": false
});