import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
const APPLE_NAVIGATOR_PLATFORMS = /iPhone|iPod|iPad|Macintosh|MacIntel/;
const APPLE_USER_AGENT_DATA_PLATFORM = /macOS/;
const ua = navigator.userAgent;
class Capabilities {
  constructor() {
    _defineProperty(this, "touch", navigator.maxTouchPoints > 1 || "ontouchstart" in window);
    _defineProperty(this, "isAndroid", ua.includes("Android"));
    _defineProperty(this, "isWinphone", ua.includes("Windows Phone"));
    _defineProperty(this, "isIpadOS", ua.includes("Mac OS") && !/iPhone|iPod/.test(ua) && this.touch);
    _defineProperty(this, "isIOS", (/iPhone|iPod/.test(ua) || this.isIpadOS) && !window.MSStream);
    _defineProperty(this, "isApple", APPLE_NAVIGATOR_PLATFORMS.test(navigator.platform) || navigator.userAgentData && APPLE_USER_AGENT_DATA_PLATFORM.test(navigator.userAgentData.platform));
    _defineProperty(this, "isOpera", !!window.opera || ua.includes(" OPR/"));
    _defineProperty(this, "isFirefox", ua.includes("Firefox"));
    _defineProperty(this, "isChrome", !!window.chrome && !this.isOpera);
    _defineProperty(this, "isSafari", /Constructor/.test(window.HTMLElement) || window.safari?.pushNotification?.toString() === "[object SafariRemoteNotification]");
    _defineProperty(this, "hasContactPicker", "contacts" in navigator && "ContactsManager" in window);
    _defineProperty(this, "canVibrate", "vibrate" in navigator);
    _defineProperty(this, "isPwa", window.matchMedia("(display-mode: standalone)").matches || window.navigator.standalone || document.referrer.includes("android-app://"));
    _defineProperty(this, "isiOSPWA", this.isPwa && this.isIOS);
    _defineProperty(this, "wasLaunchedFromDiscourseHub", window.location.search.includes("discourse_app=1"));
    _defineProperty(this, "isAppWebview", window.ReactNativeWebView !== undefined);
  }
  get userHasBeenActive() {
    return !("userActivation" in navigator) || navigator.userActivation.hasBeenActive;
  }
}
export const capabilities = new Capabilities();
export default class CapabilitiesServiceShim {
  static create() {
    return capabilities;
  }
}
_defineProperty(CapabilitiesServiceShim, "isServiceFactory", true);