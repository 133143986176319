import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DButton
  @icon={{this.copyIcon}}
  @action={{this.copy}}
  class={{this.copyClass}}
  @ariaLabel={{this.ariaLabel}}
/>
*/
{
  "id": "8eXQ7BvK",
  "block": "[[[8,[39,0],[[16,0,[30,0,[\"copyClass\"]]]],[[\"@icon\",\"@action\",\"@ariaLabel\"],[[30,0,[\"copyIcon\"]],[30,0,[\"copy\"]],[30,0,[\"ariaLabel\"]]]],null]],[],false,[\"d-button\"]]",
  "moduleName": "discourse/components/copy-button.hbs",
  "isStrictMode": false
});