import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './signup-cta.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { on } from "@ember/object/evented";
import discourseLater from "discourse-common/lib/later";
import TEMPLATE from "./signup-cta.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  action: "showCreateAccount",
  neverShow(event) {
    event?.preventDefault();
    this.keyValueStore.setItem("anon-cta-never", "t");
    this.session.set("showSignupCta", false);
  },
  actions: {
    hideForSession() {
      this.session.set("hideSignupCta", true);
      this.keyValueStore.setItem("anon-cta-hidden", Date.now());
      discourseLater(() => this.session.set("showSignupCta", false), 20 * 1000);
    }
  },
  _turnOffIfHidden: on("willDestroyElement", function () {
    if (this.session.get("hideSignupCta")) {
      this.session.set("showSignupCta", false);
    }
  })
}, (_applyDecoratedDescriptor(_obj, "neverShow", [action], Object.getOwnPropertyDescriptor(_obj, "neverShow"), _obj)), _obj)));