import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <MenuPanel
  @animationClass={{this.animationClass}}
  @panelClass="search-menu-panel"
>
  <SearchMenu
    @onClose={{@closeSearchMenu}}
    @inlineResults={{true}}
    @autofocusInput={{true}}
  />
</MenuPanel>
*/
{
  "id": "OTcRKFmr",
  "block": "[[[8,[39,0],null,[[\"@animationClass\",\"@panelClass\"],[[30,0,[\"animationClass\"]],\"search-menu-panel\"]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@onClose\",\"@inlineResults\",\"@autofocusInput\"],[[30,1],true,true]],null],[1,\"\\n\"]],[]]]]]],[\"@closeSearchMenu\"],false,[\"menu-panel\",\"search-menu\"]]",
  "moduleName": "discourse/components/search-menu-panel.hbs",
  "isStrictMode": false
});