import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './latest-topic-list-item.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { navigateToTopic, showEntrance } from "discourse/components/topic-list-item";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./latest-topic-list-item.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("topic"), (_obj = {
  attributeBindings: ["topic.id:data-topic-id"],
  classNameBindings: [":latest-topic-list-item", "unboundClassNames"],
  showEntrance,
  navigateToTopic,
  click(e) {
    // for events undefined has a different meaning than false
    if (this.showEntrance(e) === false) {
      return false;
    }
    return this.unhandledRowClick(e, this.topic);
  },
  // Can be overwritten by plugins to handle clicks on other parts of the row
  unhandledRowClick() {},
  unboundClassNames(topic) {
    let classes = [];
    if (topic.get("category")) {
      classes.push("category-" + topic.get("category.fullSlug"));
    }
    if (topic.get("tags")) {
      topic.get("tags").forEach(tagName => classes.push("tag-" + tagName));
    }
    ["liked", "archived", "bookmarked", "pinned", "closed", "visited"].forEach(name => {
      if (topic.get(name)) {
        classes.push(name);
      }
    });
    return classes.join(" ");
  }
}, (_applyDecoratedDescriptor(_obj, "unboundClassNames", [_dec], Object.getOwnPropertyDescriptor(_obj, "unboundClassNames"), _obj)), _obj))));