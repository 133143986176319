import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.topicResults}}
  {{! template-lint-disable no-invalid-interactive }}
  <div class="search-menu__show-more" {{on "keyup" this.onKeyup}}>
    {{#if this.moreUrl}}
      <a
        href={{this.moreUrl}}
        {{on "click" this.transitionToMoreUrl}}
        class="filter search-link"
      >
        {{i18n "more"}}...
      </a>
    {{else if this.topicResults.more}}
      <a
        {{on "click" (fn this.moreOfType this.topicResults.type)}}
        class="filter search-link"
      >
        {{i18n "more"}}...
      </a>
    {{/if}}
  </div>
{{/if}}
*/
{
  "id": "dVX0LYZ1",
  "block": "[[[41,[30,0,[\"topicResults\"]],[[[1,\"  \"],[11,0],[24,0,\"search-menu__show-more\"],[4,[38,1],[\"keyup\",[30,0,[\"onKeyup\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"moreUrl\"]],[[[1,\"      \"],[11,3],[16,6,[30,0,[\"moreUrl\"]]],[24,0,\"filter search-link\"],[4,[38,1],[\"click\",[30,0,[\"transitionToMoreUrl\"]]],null],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"more\"],null]],[1,\"...\\n      \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"topicResults\",\"more\"]],[[[1,\"      \"],[11,3],[24,0,\"filter search-link\"],[4,[38,1],[\"click\",[28,[37,3],[[30,0,[\"moreOfType\"]],[30,0,[\"topicResults\",\"type\"]]],null]],null],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"more\"],null]],[1,\"...\\n      \"],[13],[1,\"\\n    \"]],[]],null]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"on\",\"i18n\",\"fn\"]]",
  "moduleName": "discourse/components/search-menu/results/more-link.hbs",
  "isStrictMode": false
});