import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{! template-lint-disable no-pointer-down-event-binding }}
{{! template-lint-disable no-invalid-interactive }}

<nav
  class="horizontal-overflow-nav {{if this.hasScroll 'has-scroll'}}"
  aria-label={{@ariaLabel}}
>
  {{#if this.hasScroll}}
    <a
      role="button"
      {{on "mousedown" this.horizontalScroll}}
      {{on "mouseup" this.stopScroll}}
      {{on "mouseleave" this.stopScroll}}
      data-direction="left"
      class={{concat-class
        "horizontal-overflow-nav__scroll-left"
        (if this.hideLeftScroll "disabled")
      }}
    >
      {{d-icon "chevron-left"}}
    </a>
  {{/if}}

  <ul
    {{on-resize this.checkScroll}}
    {{on "scroll" this.watchScroll}}
    {{did-insert this.scrollToActive}}
    {{on "mousedown" this.scrollDrag}}
    class="nav-pills action-list {{@className}}"
    ...attributes
  >
    {{yield}}
  </ul>

  {{#if this.hasScroll}}
    <a
      role="button"
      {{on "mousedown" this.horizontalScroll}}
      {{on "mouseup" this.stopScroll}}
      {{on "mouseleave" this.stopScroll}}
      class={{concat-class
        "horizontal-overflow-nav__scroll-right"
        (if this.hideRightScroll "disabled")
      }}
    >
      {{d-icon "chevron-right"}}
    </a>
  {{/if}}
</nav>
*/
{
  "id": "6xDtbyK8",
  "block": "[[[1,\"\\n\"],[10,\"nav\"],[15,0,[29,[\"horizontal-overflow-nav \",[52,[30,0,[\"hasScroll\"]],\"has-scroll\"]]]],[15,\"aria-label\",[30,1]],[12],[1,\"\\n\"],[41,[30,0,[\"hasScroll\"]],[[[1,\"    \"],[11,3],[24,\"role\",\"button\"],[24,\"data-direction\",\"left\"],[16,0,[28,[37,1],[\"horizontal-overflow-nav__scroll-left\",[52,[30,0,[\"hideLeftScroll\"]],\"disabled\"]],null]],[4,[38,2],[\"mousedown\",[30,0,[\"horizontalScroll\"]]],null],[4,[38,2],[\"mouseup\",[30,0,[\"stopScroll\"]]],null],[4,[38,2],[\"mouseleave\",[30,0,[\"stopScroll\"]]],null],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"chevron-left\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[11,\"ul\"],[16,0,[29,[\"nav-pills action-list \",[30,2]]]],[17,3],[4,[38,4],[[30,0,[\"checkScroll\"]]],null],[4,[38,2],[\"scroll\",[30,0,[\"watchScroll\"]]],null],[4,[38,5],[[30,0,[\"scrollToActive\"]]],null],[4,[38,2],[\"mousedown\",[30,0,[\"scrollDrag\"]]],null],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"hasScroll\"]],[[[1,\"    \"],[11,3],[24,\"role\",\"button\"],[16,0,[28,[37,1],[\"horizontal-overflow-nav__scroll-right\",[52,[30,0,[\"hideRightScroll\"]],\"disabled\"]],null]],[4,[38,2],[\"mousedown\",[30,0,[\"horizontalScroll\"]]],null],[4,[38,2],[\"mouseup\",[30,0,[\"stopScroll\"]]],null],[4,[38,2],[\"mouseleave\",[30,0,[\"stopScroll\"]]],null],[12],[1,\"\\n      \"],[1,[28,[35,3],[\"chevron-right\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@ariaLabel\",\"@className\",\"&attrs\",\"&default\"],false,[\"if\",\"concat-class\",\"on\",\"d-icon\",\"on-resize\",\"did-insert\",\"yield\"]]",
  "moduleName": "discourse/components/horizontal-overflow-nav.hbs",
  "isStrictMode": false
});