import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.hasEdits}}
  <a
    href
    {{on "click" this.showEditHistory}}
    class="has-edits {{this.historyClass}}"
    title={{i18n "post.last_edited_on" dateTime=this.editedDate}}
  >
    {{d-icon "pencil-alt"}}
  </a>
{{/if}}
*/
{
  "id": "5lZNHzZN",
  "block": "[[[41,[30,0,[\"hasEdits\"]],[[[1,\"  \"],[11,3],[24,6,\"\"],[16,0,[29,[\"has-edits \",[30,0,[\"historyClass\"]]]]],[16,\"title\",[28,[37,1],[\"post.last_edited_on\"],[[\"dateTime\"],[[30,0,[\"editedDate\"]]]]]],[4,[38,2],[\"click\",[30,0,[\"showEditHistory\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"pencil-alt\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"i18n\",\"on\",\"d-icon\"]]",
  "moduleName": "discourse/components/reviewable-post-edits.hbs",
  "isStrictMode": false
});