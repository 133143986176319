import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <li class={{this.className}}>
  <a
    href={{this.linkHref}}
    title={{this.linkTitle}}
    {{on "click" this.onClick}}
  >
    {{#if this.iconComponent}}
      <this.iconComponent @data={{this.iconComponentArgs}} />
    {{else}}
      {{d-icon this.icon}}
    {{/if}}
    <div>
      {{#if this.label}}
        <span class={{concat "item-label " this.labelClass}}>
          {{this.label}}
        </span>
      {{/if}}
      {{#if this.description}}
        <span
          class={{concat "item-description " this.descriptionClass}}
          data-topic-id={{this.topicId}}
        >
          {{this.description}}
        </span>
      {{/if}}
    </div>

    {{#if this.endComponent}}
      <this.endComponent />
    {{/if}}
  </a>
  <PluginOutlet @name="menu-item-end" @outletArgs={{this.endOutletArgs}} />
</li>
*/
{
  "id": "VDgUsLnh",
  "block": "[[[10,\"li\"],[15,0,[30,0,[\"className\"]]],[12],[1,\"\\n  \"],[11,3],[16,6,[30,0,[\"linkHref\"]]],[16,\"title\",[30,0,[\"linkTitle\"]]],[4,[38,0],[\"click\",[30,0,[\"onClick\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"iconComponent\"]],[[[1,\"      \"],[8,[30,0,[\"iconComponent\"]],null,[[\"@data\"],[[30,0,[\"iconComponentArgs\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,2],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[10,0],[12],[1,\"\\n\"],[41,[30,0,[\"label\"]],[[[1,\"        \"],[10,1],[15,0,[28,[37,3],[\"item-label \",[30,0,[\"labelClass\"]]],null]],[12],[1,\"\\n          \"],[1,[30,0,[\"label\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"description\"]],[[[1,\"        \"],[10,1],[15,0,[28,[37,3],[\"item-description \",[30,0,[\"descriptionClass\"]]],null]],[15,\"data-topic-id\",[30,0,[\"topicId\"]]],[12],[1,\"\\n          \"],[1,[30,0,[\"description\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"endComponent\"]],[[[1,\"      \"],[8,[30,0,[\"endComponent\"]],null,null,null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n  \"],[8,[39,4],null,[[\"@name\",\"@outletArgs\"],[\"menu-item-end\",[30,0,[\"endOutletArgs\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"on\",\"if\",\"d-icon\",\"concat\",\"plugin-outlet\"]]",
  "moduleName": "discourse/components/user-menu/menu-item.hbs",
  "isStrictMode": false
});