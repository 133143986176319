import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import RestAdapter from "discourse/adapters/rest";
export default class PendingPostAdapter extends RestAdapter {
  constructor() {
    super(...arguments);
    _defineProperty(this, "jsonMode", true);
  }
  pathFor(_store, _type, params) {
    return `/posts/${params.username}/pending.json`;
  }
}