import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @closeModal={{@closeModal}}
  @title={{i18n "review.approval.title"}}
  class="post-enqueued-modal"
>
  <:body>
    <p>{{i18n "review.approval.description"}}</p>
    <p>
      {{html-safe
        (i18n "review.approval.pending_posts" count=@model.pending_count)
      }}
    </p>
  </:body>
  <:footer>
    <DButton
      @action={{@closeModal}}
      class="btn-primary"
      @label="review.approval.ok"
    />
  </:footer>
</DModal>
*/
{
  "id": "pXemSB1s",
  "block": "[[[8,[39,0],[[24,0,\"post-enqueued-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"review.approval.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[\"review.approval.description\"],null]],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,2],[[28,[37,1],[\"review.approval.pending_posts\"],[[\"count\"],[[30,2,[\"pending_count\"]]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,1],\"review.approval.ok\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"html-safe\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/post-enqueued.hbs",
  "isStrictMode": false
});