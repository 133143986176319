import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.model.error}}
  <div class="item error">
    {{#if this.model.forbidden}}
      {{i18n "errors.reasons.forbidden"}}
    {{else}}
      {{i18n "errors.desc.unknown"}}
    {{/if}}
  </div>
{{else if this.doesNotHaveNotifications}}
  <PluginOutlet @name="user-notifications-empty-state">
    <EmptyState
      @title={{i18n "user.no_notifications_page_title"}}
      @body={{this.emptyStateBody}}
    />
  </PluginOutlet>
{{else}}
  <PluginOutlet @name="user-notifications-above-filter" />
  <div class="user-notifications-filter">
    <NotificationsFilter
      @value={{this.filter}}
      @onChange={{this.updateFilter}}
    />
    <PluginOutlet
      @name="user-notifications-after-filter"
      @outletArgs={{hash items=this.items}}
    />
  </div>

  {{#if this.nothingFound}}
    <div class="alert alert-info">{{i18n "notifications.empty"}}</div>
  {{else}}
    <div class={{this.listContainerClassNames}}>
      {{#each this.items as |item|}}
        <UserMenu::MenuItem @item={{item}} />
      {{/each}}
      <ConditionalLoadingSpinner @condition={{this.loading}} />
      <PluginOutlet
        @name="user-notifications-list-bottom"
        @outletArgs={{hash controller=this}}
      />
    </div>
  {{/if}}
{{/if}}
*/
{
  "id": "Vak8GF4T",
  "block": "[[[41,[30,0,[\"model\",\"error\"]],[[[1,\"  \"],[10,0],[14,0,\"item error\"],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"forbidden\"]],[[[1,\"      \"],[1,[28,[35,1],[\"errors.reasons.forbidden\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,1],[\"errors.desc.unknown\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"doesNotHaveNotifications\"]],[[[1,\"  \"],[8,[39,2],null,[[\"@name\"],[\"user-notifications-empty-state\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@title\",\"@body\"],[[28,[37,1],[\"user.no_notifications_page_title\"],null],[30,0,[\"emptyStateBody\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  \"],[8,[39,2],null,[[\"@name\"],[\"user-notifications-above-filter\"]],null],[1,\"\\n  \"],[10,0],[14,0,\"user-notifications-filter\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@value\",\"@onChange\"],[[30,0,[\"filter\"]],[30,0,[\"updateFilter\"]]]],null],[1,\"\\n    \"],[8,[39,2],null,[[\"@name\",\"@outletArgs\"],[\"user-notifications-after-filter\",[28,[37,5],null,[[\"items\"],[[30,0,[\"items\"]]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"nothingFound\"]],[[[1,\"    \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,[28,[35,1],[\"notifications.empty\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[15,0,[30,0,[\"listContainerClassNames\"]]],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"        \"],[8,[39,8],null,[[\"@item\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[8,[39,9],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],null],[1,\"\\n      \"],[8,[39,2],null,[[\"@name\",\"@outletArgs\"],[\"user-notifications-list-bottom\",[28,[37,5],null,[[\"controller\"],[[30,0]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[]]]],[]]]],[\"item\"],false,[\"if\",\"i18n\",\"plugin-outlet\",\"empty-state\",\"notifications-filter\",\"hash\",\"each\",\"-track-array\",\"user-menu/menu-item\",\"conditional-loading-spinner\"]]",
  "moduleName": "discourse/templates/user/notifications-index.hbs",
  "isStrictMode": false
});