import escape from "discourse-common/lib/escape";
let customTagSectionLinkPrefixIcons = {};
export function registerCustomTagSectionLinkPrefixIcon(_ref) {
  let {
    tagName,
    prefixValue,
    prefixColor
  } = _ref;
  customTagSectionLinkPrefixIcons[tagName] = {
    prefixValue,
    prefixColor
  };
}
export function resetCustomTagSectionLinkPrefixIcons() {
  for (let key in customTagSectionLinkPrefixIcons) {
    if (customTagSectionLinkPrefixIcons.hasOwnProperty(key)) {
      delete customTagSectionLinkPrefixIcons[key];
    }
  }
}
export default class BaseTagSectionLink {
  constructor(_ref2) {
    let {
      tag,
      currentUser
    } = _ref2;
    this.tag = tag;
    this.tagName = tag.name;
    this.currentUser = currentUser;
  }
  get name() {
    return this.tagName;
  }
  get text() {
    return this.tagName;
  }
  get title() {
    return escape(this.tag.description);
  }
  get prefixType() {
    return "icon";
  }
  get prefixValue() {
    return customTagSectionLinkPrefixIcons[this.tagName]?.prefixValue || "tag";
  }
  get prefixColor() {
    return customTagSectionLinkPrefixIcons[this.tagName]?.prefixColor;
  }
}