import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="user-preferences_tracking-topics-controls">
  <div class="controls controls-dropdown">
    <label>{{i18n "user.new_topic_duration.label"}}</label>
    <ComboBox
      @valueProperty="value"
      @content={{@considerNewTopicOptions}}
      @value={{@model.user_option.new_topic_duration_minutes}}
      @onChange={{fn (mut @model.user_option.new_topic_duration_minutes)}}
      class="duration"
    />
  </div>

  <div class="controls controls-dropdown">
    <label>{{i18n "user.auto_track_topics"}}</label>
    <ComboBox
      @valueProperty="value"
      @content={{@autoTrackDurations}}
      @value={{@model.user_option.auto_track_topics_after_msecs}}
      @onChange={{action
        (mut @model.user_option.auto_track_topics_after_msecs)
      }}
    />
  </div>

  <div class="controls controls-dropdown">
    <label>{{i18n "user.notification_level_when_replying"}}</label>
    <ComboBox
      @valueProperty="value"
      @content={{@notificationLevelsForReplying}}
      @value={{@model.user_option.notification_level_when_replying}}
      @onChange={{action
        (mut @model.user_option.notification_level_when_replying)
      }}
    />
  </div>
</div>
*/
{
  "id": "WURD0lHE",
  "block": "[[[10,0],[14,0,\"user-preferences_tracking-topics-controls\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"controls controls-dropdown\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,0],[\"user.new_topic_duration.label\"],null]],[13],[1,\"\\n    \"],[8,[39,1],[[24,0,\"duration\"]],[[\"@valueProperty\",\"@content\",\"@value\",\"@onChange\"],[\"value\",[30,1],[30,2,[\"user_option\",\"new_topic_duration_minutes\"]],[28,[37,2],[[28,[37,3],[[30,2,[\"user_option\",\"new_topic_duration_minutes\"]]],null]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls controls-dropdown\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,0],[\"user.auto_track_topics\"],null]],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@valueProperty\",\"@content\",\"@value\",\"@onChange\"],[\"value\",[30,3],[30,2,[\"user_option\",\"auto_track_topics_after_msecs\"]],[28,[37,4],[[30,0],[28,[37,3],[[30,2,[\"user_option\",\"auto_track_topics_after_msecs\"]]],null]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls controls-dropdown\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,0],[\"user.notification_level_when_replying\"],null]],[13],[1,\"\\n    \"],[8,[39,1],null,[[\"@valueProperty\",\"@content\",\"@value\",\"@onChange\"],[\"value\",[30,4],[30,2,[\"user_option\",\"notification_level_when_replying\"]],[28,[37,4],[[30,0],[28,[37,3],[[30,2,[\"user_option\",\"notification_level_when_replying\"]]],null]],null]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@considerNewTopicOptions\",\"@model\",\"@autoTrackDurations\",\"@notificationLevelsForReplying\"],false,[\"i18n\",\"combo-box\",\"fn\",\"mut\",\"action\"]]",
  "moduleName": "discourse/components/user-preferences/topic-tracking.hbs",
  "isStrictMode": false
});