import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <span {{did-insert this.highlight}}>
  {{html-safe @string}}
</span>
*/
{
  "id": "1TPuLWeI",
  "block": "[[[11,1],[4,[38,0],[[30,0,[\"highlight\"]]],null],[12],[1,\"\\n  \"],[1,[28,[35,1],[[30,1]],null]],[1,\"\\n\"],[13]],[\"@string\"],false,[\"did-insert\",\"html-safe\"]]",
  "moduleName": "discourse/components/search-menu/highlighted-search.hbs",
  "isStrictMode": false
});