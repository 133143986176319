import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './horizontal-overflow-nav.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./horizontal-overflow-nav.hbs";
import { setComponentTemplate } from "@ember/component";
let HorizontalOverflowNav = (_class = class HorizontalOverflowNav extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "site", _descriptor, this);
    _initializerDefineProperty(this, "hasScroll", _descriptor2, this);
    _initializerDefineProperty(this, "hideRightScroll", _descriptor3, this);
    _initializerDefineProperty(this, "hideLeftScroll", _descriptor4, this);
    _defineProperty(this, "scrollInterval", void 0);
  }
  scrollToActive(element) {
    const activeElement = element.querySelector("a.active");
    activeElement?.scrollIntoView({
      block: "nearest",
      inline: "center"
    });
  }
  checkScroll(event) {
    if (this.site.mobileView) {
      return;
    }
    this.watchScroll(event);
    this.hasScroll = event.target.scrollWidth > event.target.offsetWidth;
  }
  stopScroll() {
    clearInterval(this.scrollInterval);
  }
  watchScroll(event) {
    if (this.site.mobileView) {
      return;
    }
    if (event.target.offsetWidth + event.target.scrollLeft === event.target.scrollWidth) {
      this.hideRightScroll = true;
      clearInterval(this.scrollInterval);
    } else {
      this.hideRightScroll = false;
    }
    if (event.target.scrollLeft === 0) {
      this.hideLeftScroll = true;
      clearInterval(this.scrollInterval);
    } else {
      this.hideLeftScroll = false;
    }
  }
  scrollDrag(event) {
    if (this.site.mobileView || !this.hasScroll) {
      return;
    }
    event.preventDefault();
    const navPills = event.target.closest(".nav-pills");
    const position = {
      left: navPills.scrollLeft,
      // current scroll
      x: event.clientX // mouse position
    };
    const mouseDragScroll = function (e) {
      let mouseChange = e.clientX - position.x;
      navPills.scrollLeft = position.left - mouseChange;
    };
    navPills.querySelectorAll("a").forEach(a => {
      a.style.cursor = "grabbing";
    });
    const removeDragScroll = function () {
      document.removeEventListener("mousemove", mouseDragScroll);
      navPills.querySelectorAll("a").forEach(a => {
        a.style.cursor = "pointer";
      });
    };
    document.addEventListener("mousemove", mouseDragScroll);
    document.addEventListener("mouseup", removeDragScroll, {
      once: true
    });
  }
  horizontalScroll(event) {
    // Do nothing if it is not left mousedown
    if (event.which !== 1) {
      return;
    }
    let scrollSpeed = 175;
    let siblingTarget = event.target.previousElementSibling;
    if (event.target.dataset.direction === "left") {
      scrollSpeed = scrollSpeed * -1;
      siblingTarget = event.target.nextElementSibling;
    }
    siblingTarget.scrollLeft += scrollSpeed;
    this.scrollInterval = setInterval(function () {
      siblingTarget.scrollLeft += scrollSpeed;
    }, 50);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "hasScroll", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "hideRightScroll", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "hideLeftScroll", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _applyDecoratedDescriptor(_class.prototype, "scrollToActive", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "scrollToActive"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "checkScroll", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "checkScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "stopScroll", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "stopScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "watchScroll", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "watchScroll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "scrollDrag", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "scrollDrag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "horizontalScroll", [action], Object.getOwnPropertyDescriptor(_class.prototype, "horizontalScroll"), _class.prototype)), _class);
export { HorizontalOverflowNav as default };
setComponentTemplate(TEMPLATE, HorizontalOverflowNav);