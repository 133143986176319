import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="created-by">
  {{#if this.user}}
    <UserLink @user={{this.user}}>{{avatar
        this.user
        imageSize="large"
      }}</UserLink>
  {{else}}
    {{d-icon "far-trash-alt" class="deleted-user-avatar"}}
  {{/if}}
</div>
*/
{
  "id": "1b0dD0sL",
  "block": "[[[10,0],[14,0,\"created-by\"],[12],[1,\"\\n\"],[41,[30,0,[\"user\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@user\"],[[30,0,[\"user\"]]]],[[\"default\"],[[[[1,[28,[35,2],[[30,0,[\"user\"]]],[[\"imageSize\"],[\"large\"]]]]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,3],[\"far-trash-alt\"],[[\"class\"],[\"deleted-user-avatar\"]]]],[1,\"\\n\"]],[]]],[13]],[],false,[\"if\",\"user-link\",\"avatar\",\"d-icon\"]]",
  "moduleName": "discourse/components/reviewable-created-by.hbs",
  "isStrictMode": false
});