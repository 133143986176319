/* import __COLOCATED_TEMPLATE__ from './featured-topic.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import $ from "jquery";
import TEMPLATE from "./featured-topic.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend({
  classNameBindings: [":featured-topic"],
  attributeBindings: ["topic.id:data-topic-id"],
  click(e) {
    if (e.target.closest(".last-posted-at")) {
      this.appEvents.trigger("topic-entrance:show", {
        topic: this.topic,
        position: $(e.target).offset()
      });
      return false;
    }
  }
}));