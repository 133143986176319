import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './second-factor-confirm-phrase.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import I18n from "discourse-i18n";
import TEMPLATE from "./second-factor-confirm-phrase.hbs";
import { setComponentTemplate } from "@ember/component";
let SecondFactorConfirmPhrase = (_class = class SecondFactorConfirmPhrase extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "dialog", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _initializerDefineProperty(this, "confirmPhraseInput", _descriptor3, this);
    _defineProperty(this, "disabledString", I18n.t("user.second_factor.disable"));
  }
  onConfirmPhraseInput() {
    if (this.confirmPhraseInput === this.disabledString) {
      this.dialog.set("confirmButtonDisabled", false);
    } else {
      this.dialog.set("confirmButtonDisabled", true);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "confirmPhraseInput", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "";
  }
}), _applyDecoratedDescriptor(_class.prototype, "onConfirmPhraseInput", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onConfirmPhraseInput"), _class.prototype)), _class);
export { SecondFactorConfirmPhrase as default };
setComponentTemplate(TEMPLATE, SecondFactorConfirmPhrase);