import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _RenamePasskey;
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { Input, setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import DButton from "discourse/components/d-button";
import { ajax } from "discourse/lib/ajax";
import { extractError } from "discourse/lib/ajax-error";
import I18n from "discourse-i18n";
import { createTemplateFactory } from "@ember/template-factory";
let RenamePasskey = (_class = (_RenamePasskey = class RenamePasskey extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "dialog", _descriptor2, this);
    _initializerDefineProperty(this, "passkeyName", _descriptor3, this);
    _initializerDefineProperty(this, "errorMessage", _descriptor4, this);
    _defineProperty(this, "instructions", I18n.t("user.passkeys.rename_passkey_instructions"));
    this.passkeyName = this.args.model.name;
  }
  async saveRename() {
    try {
      await ajax(`/u/rename_passkey/${this.args.model.id}`, {
        type: "PUT",
        data: {
          name: this.passkeyName
        }
      });
      this.errorMessage = null;
      this.router.refresh();
      this.dialog.didConfirmWrapped();
    } catch (error1) {
      this.errorMessage = extractError(error1);
    }
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    {{#if this.errorMessage}}
      <div class="alert alert-error">
        {{this.errorMessage}}
      </div>
    {{/if}}

    <div class="rename-passkey__form">
      <div class="rename-passkey__message">
        <p>{{this.instructions}}</p>
      </div>
      <form>
        <div class="rename-passkey__form inline-form">
          <Input @value={{this.passkeyName}} autofocus={{true}} @type="text" />
          <DButton
            class="btn-primary"
            @type="submit"
            @action={{this.saveRename}}
            @label="user.passkeys.save"
          />
        </div>
      </form>
    </div>
  
*/
{
  "id": "RNNTbKZI",
  "block": "[[[1,\"\\n\"],[41,[30,0,[\"errorMessage\"]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,\"\\n        \"],[1,[30,0,[\"errorMessage\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"rename-passkey__form\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"rename-passkey__message\"],[12],[1,\"\\n        \"],[10,2],[12],[1,[30,0,[\"instructions\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,\"form\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"rename-passkey__form inline-form\"],[12],[1,\"\\n          \"],[8,[32,0],[[16,\"autofocus\",true]],[[\"@value\",\"@type\"],[[30,0,[\"passkeyName\"]],\"text\"]],null],[1,\"\\n          \"],[8,[32,1],[[24,0,\"btn-primary\"]],[[\"@type\",\"@action\",\"@label\"],[\"submit\",[30,0,[\"saveRename\"]],\"user.passkeys.save\"]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/user-preferences/rename-passkey.js",
  "scope": () => [Input, DButton],
  "isStrictMode": true
}), _RenamePasskey), _RenamePasskey), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "passkeyName", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "saveRename", [action], Object.getOwnPropertyDescriptor(_class.prototype, "saveRename"), _class.prototype)), _class);
export { RenamePasskey as default };