import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './second-factor-edit.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { MAX_SECOND_FACTOR_NAME_LENGTH } from "discourse/models/user";
import TEMPLATE from "./second-factor-edit.hbs";
import { setComponentTemplate } from "@ember/component";
let SecondFactorEdit = (_class = class SecondFactorEdit extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "loading", _descriptor, this);
    _defineProperty(this, "maxSecondFactorNameLength", MAX_SECOND_FACTOR_NAME_LENGTH);
  }
  editSecondFactor() {
    this.loading = true;
    this.args.model.user.updateSecondFactor(this.args.model.secondFactor.id, this.args.model.secondFactor.name, false, this.args.model.secondFactor.method).then(response => {
      if (response.error) {
        return;
      }
      this.args.model.markDirty();
    }).catch(error => {
      this.args.closeModal();
      this.args.model.onError(error);
    }).finally(() => {
      this.loading = false;
      this.args.closeModal();
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "editSecondFactor", [action], Object.getOwnPropertyDescriptor(_class.prototype, "editSecondFactor"), _class.prototype)), _class);
export { SecondFactorEdit as default };
setComponentTemplate(TEMPLATE, SecondFactorEdit);