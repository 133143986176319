import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="d-lightbox__footer">
  {{#if @shouldDisplayTitle}}
    <div
      aria-hidden="true"
      class="d-lightbox__main-title"
      tabindex="0"
      {{on "click" @toggleExpandTitle passive=true capture=true}}
    >
      <span class="d-lightbox__item-title">
        {{~@currentItem.title~}}
      </span>
      <span class="d-lightbox__item-file-details">
        {{~@currentItem.fileDetails~}}
      </span>
    </div>
  {{/if}}
  <div class="d-lightbox__footer-buttons">
    {{#if @canZoom}}
      <DButton
        {{on "click" @toggleZoom passive=true capture=true}}
        @title="experimental_lightbox.buttons.zoom"
        @icon={{@zoomButtonIcon}}
        @ariaHidden="true"
        aria-hidden="true"
        class="d-lightbox__zoom-button btn-flat"
      />
    {{/if}}
    {{#if @canRotate}}
      <DButton
        {{on "click" @rotateImage passive=true capture=true}}
        @title="experimental_lightbox.buttons.rotate"
        @icon="redo"
        @ariaHidden="true"
        aria-hidden="true"
        class="d-lightbox__rotate-button btn-flat"
      />
    {{/if}}
    {{#if @canDownload}}
      <DButton
        {{on "click" @downloadImage passive=true capture=true}}
        @title="experimental_lightbox.buttons.download"
        @icon="download"
        @ariaHidden="true"
        class="d-lightbox__download-button btn-flat"
      />
    {{/if}}
  </div>
</div>
*/
{
  "id": "nAFbNfLK",
  "block": "[[[10,0],[14,0,\"d-lightbox__footer\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"    \"],[11,0],[24,\"aria-hidden\",\"true\"],[24,0,\"d-lightbox__main-title\"],[24,\"tabindex\",\"0\"],[4,[38,1],[\"click\",[30,2]],[[\"passive\",\"capture\"],[true,true]]],[12],[1,\"\\n      \"],[10,1],[14,0,\"d-lightbox__item-title\"],[12],[1,[30,3,[\"title\"]]],[13],[1,\"\\n      \"],[10,1],[14,0,\"d-lightbox__item-file-details\"],[12],[1,[30,3,[\"fileDetails\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,0],[14,0,\"d-lightbox__footer-buttons\"],[12],[1,\"\\n\"],[41,[30,4],[[[1,\"      \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"],[24,0,\"d-lightbox__zoom-button btn-flat\"],[4,[38,1],[\"click\",[30,6]],[[\"passive\",\"capture\"],[true,true]]]],[[\"@title\",\"@icon\",\"@ariaHidden\"],[\"experimental_lightbox.buttons.zoom\",[30,5],\"true\"]],null],[1,\"\\n\"]],[]],null],[41,[30,7],[[[1,\"      \"],[8,[39,2],[[24,\"aria-hidden\",\"true\"],[24,0,\"d-lightbox__rotate-button btn-flat\"],[4,[38,1],[\"click\",[30,8]],[[\"passive\",\"capture\"],[true,true]]]],[[\"@title\",\"@icon\",\"@ariaHidden\"],[\"experimental_lightbox.buttons.rotate\",\"redo\",\"true\"]],null],[1,\"\\n\"]],[]],null],[41,[30,9],[[[1,\"      \"],[8,[39,2],[[24,0,\"d-lightbox__download-button btn-flat\"],[4,[38,1],[\"click\",[30,10]],[[\"passive\",\"capture\"],[true,true]]]],[[\"@title\",\"@icon\",\"@ariaHidden\"],[\"experimental_lightbox.buttons.download\",\"download\",\"true\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@shouldDisplayTitle\",\"@toggleExpandTitle\",\"@currentItem\",\"@canZoom\",\"@zoomButtonIcon\",\"@toggleZoom\",\"@canRotate\",\"@rotateImage\",\"@canDownload\",\"@downloadImage\"],false,[\"if\",\"on\",\"d-button\"]]",
  "moduleName": "discourse/components/d-lightbox/footer.hbs",
  "isStrictMode": false
});