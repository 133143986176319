import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <LoadMore
  @selector=".paginated-topics-list .topic-list tr"
  @action={{action "loadMore"}}
  class="paginated-topics-list"
>
  <BasicTopicList @topicList={{this.model}} @showPosters={{true}} />
  <ConditionalLoadingSpinner @condition={{this.model.loadingMore}} />
</LoadMore>
*/
{
  "id": "O/nvKT0F",
  "block": "[[[8,[39,0],[[24,0,\"paginated-topics-list\"]],[[\"@selector\",\"@action\"],[\".paginated-topics-list .topic-list tr\",[28,[37,1],[[30,0],\"loadMore\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@topicList\",\"@showPosters\"],[[30,0,[\"model\"]],true]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@condition\"],[[30,0,[\"model\",\"loadingMore\"]]]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"load-more\",\"action\",\"basic-topic-list\",\"conditional-loading-spinner\"]]",
  "moduleName": "discourse/templates/group-activity-topics.hbs",
  "isStrictMode": false
});