import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#each this.periods as |p|}}
  <DButton
    @action={{fn this.changePeriod p}}
    @translatedLabel={{period-title p}}
    class="btn-default"
  />
{{/each}}
*/
{
  "id": "k1TABCMA",
  "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"periods\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],[[24,0,\"btn-default\"]],[[\"@action\",\"@translatedLabel\"],[[28,[37,3],[[30,0,[\"changePeriod\"]],[30,1]],null],[28,[37,4],[[30,1]],null]]],null],[1,\"\\n\"]],[1]],null]],[\"p\"],false,[\"each\",\"-track-array\",\"d-button\",\"fn\",\"period-title\"]]",
  "moduleName": "discourse/components/top-period-buttons.hbs",
  "isStrictMode": false
});