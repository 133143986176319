import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{body-class "has-sidebar-page"}}

<section id="d-sidebar" class="sidebar-container">
  {{#if this.showSwitchPanelButtonsOnTop}}
    <Sidebar::SwitchPanelButtons @buttons={{this.switchPanelButtons}} />
  {{/if}}

  <PluginOutlet @name="before-sidebar-sections" />

  {{#if this.sidebarState.showMainPanel}}
    <Sidebar::Sections
      @currentUser={{this.currentUser}}
      @collapsableSections={{true}}
      @panel={{this.sidebarState.currentPanel}}
    />
  {{else}}
    <Sidebar::ApiPanels
      @currentUser={{this.currentUser}}
      @collapsableSections={{true}}
    />
  {{/if}}

  <PluginOutlet @name="after-sidebar-sections" />

  {{#unless this.showSwitchPanelButtonsOnTop}}
    <Sidebar::SwitchPanelButtons @buttons={{this.switchPanelButtons}} />
  {{/unless}}

  <Sidebar::Footer />
</section>
*/
{
  "id": "d+5N4sAD",
  "block": "[[[1,[28,[35,0],[\"has-sidebar-page\"],null]],[1,\"\\n\\n\"],[10,\"section\"],[14,1,\"d-sidebar\"],[14,0,\"sidebar-container\"],[12],[1,\"\\n\"],[41,[30,0,[\"showSwitchPanelButtonsOnTop\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@buttons\"],[[30,0,[\"switchPanelButtons\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,3],null,[[\"@name\"],[\"before-sidebar-sections\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"sidebarState\",\"showMainPanel\"]],[[[1,\"    \"],[8,[39,4],null,[[\"@currentUser\",\"@collapsableSections\",\"@panel\"],[[30,0,[\"currentUser\"]],true,[30,0,[\"sidebarState\",\"currentPanel\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,5],null,[[\"@currentUser\",\"@collapsableSections\"],[[30,0,[\"currentUser\"]],true]],null],[1,\"\\n\"]],[]]],[1,\"\\n  \"],[8,[39,3],null,[[\"@name\"],[\"after-sidebar-sections\"]],null],[1,\"\\n\\n\"],[41,[51,[30,0,[\"showSwitchPanelButtonsOnTop\"]]],[[[1,\"    \"],[8,[39,2],null,[[\"@buttons\"],[[30,0,[\"switchPanelButtons\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,7],null,null,null],[1,\"\\n\"],[13]],[],false,[\"body-class\",\"if\",\"sidebar/switch-panel-buttons\",\"plugin-outlet\",\"sidebar/sections\",\"sidebar/api-panels\",\"unless\",\"sidebar/footer\"]]",
  "moduleName": "discourse/components/sidebar.hbs",
  "isStrictMode": false
});