import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  
*/
{
  "id": "4/3+QeFR",
  "block": "[[],[],false,[]]",
  "moduleName": "discourse/templates/hide-modal.hbs",
  "isStrictMode": false
});