import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DateTimeInput
  @date={{this.from}}
  @onChange={{action "onChangeRanges" (hash prop="from")}}
  @showTime={{this.showFromTime}}
  @placeholder={{i18n "dates.from_placeholder"}}
  @timezone={{@timezone}}
  class="from"
/>

<DateTimeInput
  @date={{this.to}}
  @relativeDate={{this.from}}
  @onChange={{action "onChangeRanges" (hash prop="to")}}
  @timeFirst={{this.toTimeFirst}}
  @showTime={{this.showToTime}}
  @clearable={{this.clearable}}
  @placeholder={{i18n "dates.to_placeholder"}}
  @timezone={{@timezone}}
  class="to"
/>
*/
{
  "id": "wBVuhpzf",
  "block": "[[[8,[39,0],[[24,0,\"from\"]],[[\"@date\",\"@onChange\",\"@showTime\",\"@placeholder\",\"@timezone\"],[[30,0,[\"from\"]],[28,[37,1],[[30,0],\"onChangeRanges\",[28,[37,2],null,[[\"prop\"],[\"from\"]]]],null],[30,0,[\"showFromTime\"]],[28,[37,3],[\"dates.from_placeholder\"],null],[30,1]]],null],[1,\"\\n\\n\"],[8,[39,0],[[24,0,\"to\"]],[[\"@date\",\"@relativeDate\",\"@onChange\",\"@timeFirst\",\"@showTime\",\"@clearable\",\"@placeholder\",\"@timezone\"],[[30,0,[\"to\"]],[30,0,[\"from\"]],[28,[37,1],[[30,0],\"onChangeRanges\",[28,[37,2],null,[[\"prop\"],[\"to\"]]]],null],[30,0,[\"toTimeFirst\"]],[30,0,[\"showToTime\"]],[30,0,[\"clearable\"]],[28,[37,3],[\"dates.to_placeholder\"],null],[30,1]]],null]],[\"@timezone\"],false,[\"date-time-input\",\"action\",\"hash\",\"i18n\"]]",
  "moduleName": "discourse/components/date-time-input-range.hbs",
  "isStrictMode": false
});