import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DButton
  class="show-advanced-search"
  title={{i18n "search.open_advanced"}}
  @action={{@openAdvancedSearch}}
  @icon="sliders-h"
/>
*/
{
  "id": "VPoT1DvP",
  "block": "[[[8,[39,0],[[24,0,\"show-advanced-search\"],[16,\"title\",[28,[37,1],[\"search.open_advanced\"],null]]],[[\"@action\",\"@icon\"],[[30,1],\"sliders-h\"]],null]],[\"@openAdvancedSearch\"],false,[\"d-button\",\"i18n\"]]",
  "moduleName": "discourse/components/search-menu/advanced-button.hbs",
  "isStrictMode": false
});