import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './request-group-membership-form.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import DiscourseURL from "discourse/lib/url";
import I18n from "discourse-i18n";
import TEMPLATE from "./request-group-membership-form.hbs";
import { setComponentTemplate } from "@ember/component";
let RequestGroupMembershipForm = (_class = class RequestGroupMembershipForm extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "loading", _descriptor, this);
    _initializerDefineProperty(this, "reason", _descriptor2, this);
  }
  get title() {
    return I18n.t("groups.membership_request.title", {
      group_name: this.args.model.group.name
    });
  }
  get disableSubmit() {
    return this.loading || isEmpty(this.reason);
  }
  async requestMember() {
    this.loading = true;
    try {
      const result = await this.args.model.group.requestMembership(this.reason);
      DiscourseURL.routeTo(result.relative_url);
    } catch (e) {
      popupAjaxError(e);
    } finally {
      this.loading = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "reason", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.group.membership_request_template;
  }
}), _applyDecoratedDescriptor(_class.prototype, "requestMember", [action], Object.getOwnPropertyDescriptor(_class.prototype, "requestMember"), _class.prototype)), _class);
export { RequestGroupMembershipForm as default };
setComponentTemplate(TEMPLATE, RequestGroupMembershipForm);