import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="topic-footer-main-buttons">
  {{#each this.buttons as |button|}}
    <DButton
      @action={{button.action}}
      @icon={{button.icon}}
      @translatedLabel={{button.label}}
      @translatedTitle={{button.title}}
      @translatedAriaLabel={{button.ariaLabel}}
      @disabled={{button.disabled}}
      id={{concat "topic-footer-button-" button.id}}
      class={{concatClass
        "btn-default"
        "topic-footer-button"
        button.classNames
      }}
    />
  {{/each}}
  <DButton
    @icon="reply"
    @action={{route-action "showLogin"}}
    @label="topic.reply.title"
    class="btn-primary"
  />
</div>
*/
{
  "id": "HptKa4jw",
  "block": "[[[10,0],[14,0,\"topic-footer-main-buttons\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"buttons\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],[[16,1,[28,[37,3],[\"topic-footer-button-\",[30,1,[\"id\"]]],null]],[16,0,[28,[37,4],[\"btn-default\",\"topic-footer-button\",[30,1,[\"classNames\"]]],null]]],[[\"@action\",\"@icon\",\"@translatedLabel\",\"@translatedTitle\",\"@translatedAriaLabel\",\"@disabled\"],[[30,1,[\"action\"]],[30,1,[\"icon\"]],[30,1,[\"label\"]],[30,1,[\"title\"]],[30,1,[\"ariaLabel\"]],[30,1,[\"disabled\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@icon\",\"@action\",\"@label\"],[\"reply\",[28,[37,5],[\"showLogin\"],null],\"topic.reply.title\"]],null],[1,\"\\n\"],[13]],[\"button\"],false,[\"each\",\"-track-array\",\"d-button\",\"concat\",\"concatClass\",\"route-action\"]]",
  "moduleName": "discourse/components/anonymous-topic-footer-buttons.hbs",
  "isStrictMode": false
});