import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class2, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './reviewable-field-category.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import TEMPLATE from "./reviewable-field-category.hbs";
import { setComponentTemplate } from "@ember/component";
let _class = (_class2 = class _class2 extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "categoryId", _descriptor, this);
  }
  onChange(category) {
    this.categoryId = category;
    this.args.categoryChanged?.(category);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "categoryId", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.value;
  }
}), _applyDecoratedDescriptor(_class2.prototype, "onChange", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "onChange"), _class2.prototype)), _class2);
export { _class as default };
setComponentTemplate(TEMPLATE, _class);