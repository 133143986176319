import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <span class="blurb">
  {{format-age @result.created_at}}
  <span class="blurb__separator"> - </span>
  {{#if this.siteSettings.use_pg_headlines_for_excerpt}}
    <span>{{html-safe @result.blurb}}</span>
  {{else}}
    <span class="blurb__text">
      <SearchMenu::HighlightedSearch @string={{@result.blurb}} />
    </span>
  {{/if}}
</span>
*/
{
  "id": "zXg8FlsU",
  "block": "[[[10,1],[14,0,\"blurb\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,1,[\"created_at\"]]],null]],[1,\"\\n  \"],[10,1],[14,0,\"blurb__separator\"],[12],[1,\" - \"],[13],[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"use_pg_headlines_for_excerpt\"]],[[[1,\"    \"],[10,1],[12],[1,[28,[35,2],[[30,1,[\"blurb\"]]],null]],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"blurb__text\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@string\"],[[30,1,[\"blurb\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"@result\"],false,[\"format-age\",\"if\",\"html-safe\",\"search-menu/highlighted-search\"]]",
  "moduleName": "discourse/components/search-menu/results/blurb.hbs",
  "isStrictMode": false
});