import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.flair}}
  <AvatarFlair
    @flairName={{this.flair.flairName}}
    @flairUrl={{this.flair.flairUrl}}
    @flairBgColor={{this.flair.flairBgColor}}
    @flairColor={{this.flair.flairColor}}
  />
{{/if}}
*/
{
  "id": "/5P0TS+t",
  "block": "[[[41,[30,0,[\"flair\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@flairName\",\"@flairUrl\",\"@flairBgColor\",\"@flairColor\"],[[30,0,[\"flair\",\"flairName\"]],[30,0,[\"flair\",\"flairUrl\"]],[30,0,[\"flair\",\"flairBgColor\"]],[30,0,[\"flair\",\"flairColor\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"avatar-flair\"]]",
  "moduleName": "discourse/components/user-avatar-flair.hbs",
  "isStrictMode": false
});