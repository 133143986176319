import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
/* import __COLOCATED_TEMPLATE__ from './edit-category-security.hbs'; */
import { not } from "@ember/object/computed";
import { buildCategoryPanel } from "discourse/components/edit-category-panel";
import PermissionType from "discourse/models/permission-type";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./edit-category-security.hbs";
import { setComponentTemplate } from "@ember/component";
export default setComponentTemplate(TEMPLATE, buildCategoryPanel("security", (_dec = discourseComputed("category.permissions.@each.permission_type"), _dec2 = discourseComputed("category.permissions.@each.permission_type"), _dec3 = discourseComputed("everyonePermission"), (_obj = {
  selectedGroup: null,
  noGroupSelected: not("selectedGroup"),
  everyonePermission(permissions) {
    return permissions.findBy("group_name", "everyone");
  },
  everyoneGrantedFull() {
    return this.everyonePermission && this.everyonePermission.permission_type === PermissionType.FULL;
  },
  minimumPermission(everyonePermission) {
    return everyonePermission ? everyonePermission.permission_type : PermissionType.READONLY;
  },
  actions: {
    onSelectGroup(group_name) {
      this.category.addPermission({
        group_name,
        permission_type: this.minimumPermission
      });
    }
  }
}, (_applyDecoratedDescriptor(_obj, "everyonePermission", [_dec], Object.getOwnPropertyDescriptor(_obj, "everyonePermission"), _obj), _applyDecoratedDescriptor(_obj, "everyoneGrantedFull", [_dec2], Object.getOwnPropertyDescriptor(_obj, "everyoneGrantedFull"), _obj), _applyDecoratedDescriptor(_obj, "minimumPermission", [_dec3], Object.getOwnPropertyDescriptor(_obj, "minimumPermission"), _obj)), _obj))));