import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './download-calendar.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { downloadGoogle, downloadIcs } from "discourse/lib/download-calendar";
import TEMPLATE from "./download-calendar.hbs";
import { setComponentTemplate } from "@ember/component";
let DownloadCalendar = (_class = class DownloadCalendar extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "selectedCalendar", _descriptor2, this);
    _initializerDefineProperty(this, "remember", _descriptor3, this);
  }
  downloadCalendar() {
    if (this.remember) {
      this.currentUser.set("user_option.default_calendar", this.selectedCalendar);
      this.currentUser.save(["default_calendar"]);
    }
    if (this.selectedCalendar === "ics") {
      downloadIcs(this.args.model.calendar.title, this.args.model.calendar.dates, {
        recurrenceRule: this.args.model.calendar.recurrenceRule,
        location: this.args.model.calendar.location,
        details: this.args.model.calendar.details
      });
    } else {
      downloadGoogle(this.args.model.calendar.title, this.args.model.calendar.dates, {
        recurrenceRule: this.args.model.calendar.recurrenceRule,
        location: this.args.model.calendar.location,
        details: this.args.model.calendar.details
      });
    }
    this.args.closeModal();
  }
  selectCalendar(calendar) {
    this.selectedCalendar = calendar;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "selectedCalendar", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "ics";
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "remember", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "downloadCalendar", [action], Object.getOwnPropertyDescriptor(_class.prototype, "downloadCalendar"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectCalendar", [action], Object.getOwnPropertyDescriptor(_class.prototype, "selectCalendar"), _class.prototype)), _class);
export { DownloadCalendar as default };
setComponentTemplate(TEMPLATE, DownloadCalendar);