import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#unless this.expanded}}
  <DButton @action={{this.expand}} @label={{this.label}} class="btn-link" />
{{/unless}}
{{#if this.expanded}}
  <span class="description" aria-live="assertive">{{this.details}}</span>
{{/if}}
*/
{
  "id": "WChOohYK",
  "block": "[[[41,[51,[30,0,[\"expanded\"]]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn-link\"]],[[\"@action\",\"@label\"],[[30,0,[\"expand\"]],[30,0,[\"label\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"expanded\"]],[[[1,\"  \"],[10,1],[14,0,\"description\"],[14,\"aria-live\",\"assertive\"],[12],[1,[30,0,[\"details\"]]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"d-button\",\"if\"]]",
  "moduleName": "discourse/components/hidden-details.hbs",
  "isStrictMode": false
});