import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.value}}
  <DButton
    @action={{fn this.clearFilter this.type}}
    @icon="times-circle"
    @translatedLabel={{concat this.label ": " this.filterText}}
    class="btn-default group-manage-logs-filter"
  />
{{/if}}
*/
{
  "id": "i1Wa6NVJ",
  "block": "[[[41,[30,0,[\"value\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn-default group-manage-logs-filter\"]],[[\"@action\",\"@icon\",\"@translatedLabel\"],[[28,[37,2],[[30,0,[\"clearFilter\"]],[30,0,[\"type\"]]],null],\"times-circle\",[28,[37,3],[[30,0,[\"label\"]],\": \",[30,0,[\"filterText\"]]],null]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\",\"fn\",\"concat\"]]",
  "moduleName": "discourse/components/group-manage-logs-filter.hbs",
  "isStrictMode": false
});