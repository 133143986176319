import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{body-class "tags-page"}}
{{outlet}}
*/
{
  "id": "5ID5SU8e",
  "block": "[[[1,[28,[35,0],[\"tags-page\"],null]],[1,\"\\n\"],[46,[28,[37,2],null,null],null,null,null]],[],false,[\"body-class\",\"component\",\"-outlet\"]]",
  "moduleName": "discourse/templates/tags.hbs",
  "isStrictMode": false
});