import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.site.mobileView}}
  <li class="navigation-toggle">
    <a href {{on "click" this.toggleDrop}} class="toggle-link">
      {{this.selectedNavItem.displayName}}
      {{d-icon "caret-down"}}
    </a>
  </li>
  {{#if this.expanded}}
    <ul class="drop">
      {{#each this.navItems as |navItem|}}
        <NavigationItem
          @content={{navItem}}
          @filterMode={{this.filterMode}}
          @category={{this.category}}
          class={{concat "nav-item_" navItem.name}}
        />
      {{/each}}
      <PluginOutlet
        @name="extra-nav-item"
        @connectorTagName="li"
        @outletArgs={{hash
          category=this.category
          tag=this.tag
          filterMode=this.filterMode
        }}
      />
    </ul>
  {{/if}}

  <PluginOutlet
    @name="inline-extra-nav-item"
    @connectorTagName="li"
    @outletArgs={{hash category=this.category filterMode=this.filterMode}}
  />
{{else}}
  {{#each this.navItems as |navItem|}}
    <NavigationItem
      @content={{navItem}}
      @filterMode={{this.filterMode}}
      @category={{this.category}}
      class={{concat "nav-item_" navItem.name}}
    />
  {{/each}}
  <PluginOutlet
    @name="extra-nav-item"
    @connectorTagName="li"
    @outletArgs={{hash
      category=this.category
      tag=this.tag
      filterMode=this.filterMode
    }}
  />
{{/if}}
*/
{
  "id": "vgpxbkuw",
  "block": "[[[41,[30,0,[\"site\",\"mobileView\"]],[[[1,\"  \"],[10,\"li\"],[14,0,\"navigation-toggle\"],[12],[1,\"\\n    \"],[11,3],[24,6,\"\"],[24,0,\"toggle-link\"],[4,[38,1],[\"click\",[30,0,[\"toggleDrop\"]]],null],[12],[1,\"\\n      \"],[1,[30,0,[\"selectedNavItem\",\"displayName\"]]],[1,\"\\n      \"],[1,[28,[35,2],[\"caret-down\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"    \"],[10,\"ul\"],[14,0,\"drop\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"navItems\"]]],null]],null],null,[[[1,\"        \"],[8,[39,5],[[16,0,[28,[37,6],[\"nav-item_\",[30,1,[\"name\"]]],null]]],[[\"@content\",\"@filterMode\",\"@category\"],[[30,1],[30,0,[\"filterMode\"]],[30,0,[\"category\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"      \"],[8,[39,7],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"extra-nav-item\",\"li\",[28,[37,8],null,[[\"category\",\"tag\",\"filterMode\"],[[30,0,[\"category\"]],[30,0,[\"tag\"]],[30,0,[\"filterMode\"]]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[8,[39,7],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"inline-extra-nav-item\",\"li\",[28,[37,8],null,[[\"category\",\"filterMode\"],[[30,0,[\"category\"]],[30,0,[\"filterMode\"]]]]]]],null],[1,\"\\n\"]],[]],[[[42,[28,[37,4],[[28,[37,4],[[30,0,[\"navItems\"]]],null]],null],null,[[[1,\"    \"],[8,[39,5],[[16,0,[28,[37,6],[\"nav-item_\",[30,2,[\"name\"]]],null]]],[[\"@content\",\"@filterMode\",\"@category\"],[[30,2],[30,0,[\"filterMode\"]],[30,0,[\"category\"]]]],null],[1,\"\\n\"]],[2]],null],[1,\"  \"],[8,[39,7],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"extra-nav-item\",\"li\",[28,[37,8],null,[[\"category\",\"tag\",\"filterMode\"],[[30,0,[\"category\"]],[30,0,[\"tag\"]],[30,0,[\"filterMode\"]]]]]]],null],[1,\"\\n\"]],[]]]],[\"navItem\",\"navItem\"],false,[\"if\",\"on\",\"d-icon\",\"each\",\"-track-array\",\"navigation-item\",\"concat\",\"plugin-outlet\",\"hash\"]]",
  "moduleName": "discourse/components/navigation-bar.hbs",
  "isStrictMode": false
});