import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <a href={{this.member.adminPath}}>
  {{avatar this.member imageSize="small"}}
</a>
<span>{{this.member.username}}</span>
{{#unless this.automatic}}
  <a href {{on "click" this.remove}} class="remove">
    {{d-icon "times"}}
  </a>
{{/unless}}
*/
{
  "id": "BwRq/Bxa",
  "block": "[[[10,3],[15,6,[30,0,[\"member\",\"adminPath\"]]],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"member\"]]],[[\"imageSize\"],[\"small\"]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,1],[12],[1,[30,0,[\"member\",\"username\"]]],[13],[1,\"\\n\"],[41,[51,[30,0,[\"automatic\"]]],[[[1,\"  \"],[11,3],[24,6,\"\"],[24,0,\"remove\"],[4,[38,2],[\"click\",[30,0,[\"remove\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"times\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"avatar\",\"unless\",\"on\",\"d-icon\"]]",
  "moduleName": "discourse/components/group-member.hbs",
  "isStrictMode": false
});