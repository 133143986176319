import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="more-topics__container">
  {{#unless this.singleList}}
    <div class="row">
      <ul class="nav nav-pills">
        {{#each this.availableTabs as |tab|}}
          <li>
            <DButton
              @translatedTitle={{tab.name}}
              @translatedLabel={{tab.name}}
              @action={{fn this.rememberTopicListPreference tab.id}}
              @icon={{tab.icon}}
              class={{if (eq tab.id this.selectedTab) "active"}}
            />
          </li>
        {{/each}}
      </ul>
    </div>
  {{/unless}}

  <div class="more-topics__lists {{if this.singleList 'single-list'}}">
    {{#if @topic.relatedMessages.length}}
      <RelatedMessages @topic={{@topic}} />
    {{/if}}

    {{#if @topic.suggestedTopics.length}}
      <SuggestedTopics @topic={{@topic}} />

      <span>
        <PluginOutlet
          @name="below-suggested-topics"
          @connectorTagName="div"
          @outletArgs={{hash topic=@topic}}
        />
      </span>
    {{/if}}

    <PluginOutlet
      @name="topic-more-content"
      @outletArgs={{hash model=@topic}}
    />

  </div>

  {{#if @topic.suggestedTopics.length}}
    <h3 class="more-topics__browse-more">
      {{html-safe this.browseMoreMessage}}
    </h3>
  {{/if}}
</div>
*/
{
  "id": "wE+wtfUy",
  "block": "[[[10,0],[14,0,\"more-topics__container\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"singleList\"]]],[[[1,\"    \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"nav nav-pills\"],[12],[1,\"\\n\"],[42,[28,[37,2],[[28,[37,2],[[30,0,[\"availableTabs\"]]],null]],null],null,[[[1,\"          \"],[10,\"li\"],[12],[1,\"\\n            \"],[8,[39,3],[[16,0,[52,[28,[37,5],[[30,1,[\"id\"]],[30,0,[\"selectedTab\"]]],null],\"active\"]]],[[\"@translatedTitle\",\"@translatedLabel\",\"@action\",\"@icon\"],[[30,1,[\"name\"]],[30,1,[\"name\"]],[28,[37,6],[[30,0,[\"rememberTopicListPreference\"]],[30,1,[\"id\"]]],null],[30,1,[\"icon\"]]]],null],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[15,0,[29,[\"more-topics__lists \",[52,[30,0,[\"singleList\"]],\"single-list\"]]]],[12],[1,\"\\n\"],[41,[30,2,[\"relatedMessages\",\"length\"]],[[[1,\"      \"],[8,[39,7],null,[[\"@topic\"],[[30,2]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,2,[\"suggestedTopics\",\"length\"]],[[[1,\"      \"],[8,[39,8],null,[[\"@topic\"],[[30,2]]],null],[1,\"\\n\\n      \"],[10,1],[12],[1,\"\\n        \"],[8,[39,9],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"below-suggested-topics\",\"div\",[28,[37,10],null,[[\"topic\"],[[30,2]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[8,[39,9],null,[[\"@name\",\"@outletArgs\"],[\"topic-more-content\",[28,[37,10],null,[[\"model\"],[[30,2]]]]]],null],[1,\"\\n\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,2,[\"suggestedTopics\",\"length\"]],[[[1,\"    \"],[10,\"h3\"],[14,0,\"more-topics__browse-more\"],[12],[1,\"\\n      \"],[1,[28,[35,11],[[30,0,[\"browseMoreMessage\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"tab\",\"@topic\"],false,[\"unless\",\"each\",\"-track-array\",\"d-button\",\"if\",\"eq\",\"fn\",\"related-messages\",\"suggested-topics\",\"plugin-outlet\",\"hash\",\"html-safe\"]]",
  "moduleName": "discourse/components/more-topics.hbs",
  "isStrictMode": false
});