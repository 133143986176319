import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="global-notice">
  {{#if this.visible}}
    {{#each this.notices as |notice|}}
      <div class="row">
        <div
          id="global-notice-{{notice.id}}"
          class="alert alert-{{notice.options.level}} {{notice.id}}"
        >
          {{#if notice.options.html}}
            {{html-safe notice.options.html}}
          {{/if}}

          <span class="text">{{html-safe notice.text}}</span>

          {{#if notice.options.dismissable}}
            <DButton
              @icon="times"
              @action={{fn this.dismissNotice notice}}
              class="btn-transparent close"
            />
          {{/if}}
        </div>
      </div>
    {{/each}}
  {{/if}}
</div>
*/
{
  "id": "EjtQ75wd",
  "block": "[[[10,0],[14,0,\"global-notice\"],[12],[1,\"\\n\"],[41,[30,0,[\"visible\"]],[[[42,[28,[37,2],[[28,[37,2],[[30,0,[\"notices\"]]],null]],null],null,[[[1,\"      \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n        \"],[10,0],[15,1,[29,[\"global-notice-\",[30,1,[\"id\"]]]]],[15,0,[29,[\"alert alert-\",[30,1,[\"options\",\"level\"]],\" \",[30,1,[\"id\"]]]]],[12],[1,\"\\n\"],[41,[30,1,[\"options\",\"html\"]],[[[1,\"            \"],[1,[28,[35,3],[[30,1,[\"options\",\"html\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"\\n          \"],[10,1],[14,0,\"text\"],[12],[1,[28,[35,3],[[30,1,[\"text\"]]],null]],[13],[1,\"\\n\\n\"],[41,[30,1,[\"options\",\"dismissable\"]],[[[1,\"            \"],[8,[39,4],[[24,0,\"btn-transparent close\"]],[[\"@icon\",\"@action\"],[\"times\",[28,[37,5],[[30,0,[\"dismissNotice\"]],[30,1]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[1]],null]],[]],null],[13]],[\"notice\"],false,[\"if\",\"each\",\"-track-array\",\"html-safe\",\"d-button\",\"fn\"]]",
  "moduleName": "discourse/components/global-notice.hbs",
  "isStrictMode": false
});