import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Input
  @type={{this.inputType}}
  class="date-picker"
  placeholder={{this.placeholder}}
  @value={{this.value}}
  autocomplete="off"
/>
*/
{
  "id": "kT0NJodg",
  "block": "[[[8,[39,0],[[24,0,\"date-picker\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]],[24,\"autocomplete\",\"off\"]],[[\"@type\",\"@value\"],[[30,0,[\"inputType\"]],[30,0,[\"value\"]]]],null]],[],false,[\"input\"]]",
  "moduleName": "discourse/components/date-picker.hbs",
  "isStrictMode": false
});