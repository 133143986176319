import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './feature-topic-on-profile.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import TEMPLATE from "./feature-topic-on-profile.hbs";
import { setComponentTemplate } from "@ember/component";
let FeatureTopicOnProfile = (_class = class FeatureTopicOnProfile extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "newFeaturedTopic", _descriptor, this);
    _initializerDefineProperty(this, "saving", _descriptor2, this);
  }
  get noTopicSelected() {
    return !this.newFeaturedTopic;
  }
  async save() {
    try {
      this.saving = true;
      await ajax(`/u/${this.args.model.user.username}/feature-topic`, {
        type: "PUT",
        data: {
          topic_id: this.newFeaturedTopic.id
        }
      });
      this.args.model.setFeaturedTopic(this.newFeaturedTopic);
      this.args.closeModal();
    } catch (error) {
      popupAjaxError(error);
    } finally {
      this.saving = false;
    }
  }
  newTopicSelected(topic) {
    this.newFeaturedTopic = topic;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "newFeaturedTopic", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "saving", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "save", [action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "newTopicSelected", [action], Object.getOwnPropertyDescriptor(_class.prototype, "newTopicSelected"), _class.prototype)), _class);
export { FeatureTopicOnProfile as default };
setComponentTemplate(TEMPLATE, FeatureTopicOnProfile);