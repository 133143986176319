import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "topic.actions.delete"}}
  @closeModal={{@closeModal}}
  class="delete-topic-confirm-modal"
  @flash={{this.flash}}
>
  <:body>
    <p>
      {{i18n
        "post.controls.delete_topic_confirm_modal"
        count=this.siteSettings.min_topic_views_for_delete_confirm
      }}
    </p>
  </:body>
  <:footer>
    <DButton
      @action={{this.deleteTopic}}
      @disabled={{this.deletingTopic}}
      @label={{if
        this.deletingTopic
        "deleting"
        "post.controls.delete_topic_confirm_modal_yes"
      }}
      class="btn-danger"
    />
    <DButton
      @action={{@closeModal}}
      @label="post.controls.delete_topic_confirm_modal_no"
      class="btn-primary"
    />
  </:footer>
</DModal>
*/
{
  "id": "l4hvC3AW",
  "block": "[[[8,[39,0],[[24,0,\"delete-topic-confirm-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[[28,[37,1],[\"topic.actions.delete\"],null],[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"post.controls.delete_topic_confirm_modal\"],[[\"count\"],[[30,0,[\"siteSettings\",\"min_topic_views_for_delete_confirm\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-danger\"]],[[\"@action\",\"@disabled\",\"@label\"],[[30,0,[\"deleteTopic\"]],[30,0,[\"deletingTopic\"]],[52,[30,0,[\"deletingTopic\"]],\"deleting\",\"post.controls.delete_topic_confirm_modal_yes\"]]],null],[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,1],\"post.controls.delete_topic_confirm_modal_no\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"d-button\",\"if\"]]",
  "moduleName": "discourse/components/modal/delete-topic-confirm.hbs",
  "isStrictMode": false
});