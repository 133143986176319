import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <form action="//google.com/search" id="google-search" class="inline-form">
  <input
    type="text"
    name="q"
    aria-label={{i18n "search.search_google"}}
    value={{this.searchTerm}}
  />
  <input name="as_sitesearch" value={{this.siteUrl}} type="hidden" />
  <button class="btn btn-primary" type="submit">{{i18n
      "search.search_google_button"
    }}</button>
</form>
*/
{
  "id": "g2bohitX",
  "block": "[[[10,\"form\"],[14,\"action\",\"//google.com/search\"],[14,1,\"google-search\"],[14,0,\"inline-form\"],[12],[1,\"\\n  \"],[10,\"input\"],[14,3,\"q\"],[15,\"aria-label\",[28,[37,0],[\"search.search_google\"],null]],[15,2,[30,0,[\"searchTerm\"]]],[14,4,\"text\"],[12],[13],[1,\"\\n  \"],[10,\"input\"],[14,3,\"as_sitesearch\"],[15,2,[30,0,[\"siteUrl\"]]],[14,4,\"hidden\"],[12],[13],[1,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-primary\"],[14,4,\"submit\"],[12],[1,[28,[35,0],[\"search.search_google_button\"],null]],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\"]]",
  "moduleName": "discourse/components/google-search.hbs",
  "isStrictMode": false
});