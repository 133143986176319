import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.canEdit}}
  {{#if this.currentUser.can_create_group}}
    <div class="control-group">
      <label class="control-label" for="name">{{i18n "groups.name"}}</label>

      <TextField
        @name="name"
        @value={{this.nameInput}}
        @placeholderKey="admin.groups.name_placeholder"
        class="input-xxlarge group-form-name"
      />

      <InputTip @validation={{this.nameValidation}} />
    </div>
  {{/if}}

  <div class="control-group">
    <label class="control-label" for="full_name">{{i18n
        "groups.manage.full_name"
      }}</label>

    <TextField
      @name="full_name"
      @value={{this.model.full_name}}
      class="input-xxlarge group-form-full-name"
    />
  </div>
{{/if}}

<div class="control-group">
  <label class="control-label" for="bio">{{i18n "groups.bio"}}</label>
  <DEditor @value={{this.model.bio_raw}} class="group-form-bio input-xxlarge" />
</div>

{{#if this.model.automatic}}
  <div class="control-group">
    <GroupFlairInputs @model={{this.model}} />
  </div>
{{/if}}

{{#if this.canEdit}}
  {{yield}}

  <span>
    <PluginOutlet
      @name="group-edit"
      @connectorTagName="div"
      @outletArgs={{hash group=this.model}}
    />
  </span>
{{/if}}
*/
{
  "id": "XE4SOz2D",
  "block": "[[[41,[30,0,[\"canEdit\"]],[[[41,[30,0,[\"currentUser\",\"can_create_group\"]],[[[1,\"    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[14,\"for\",\"name\"],[12],[1,[28,[35,1],[\"groups.name\"],null]],[13],[1,\"\\n\\n      \"],[8,[39,2],[[24,0,\"input-xxlarge group-form-name\"]],[[\"@name\",\"@value\",\"@placeholderKey\"],[\"name\",[30,0,[\"nameInput\"]],\"admin.groups.name_placeholder\"]],null],[1,\"\\n\\n      \"],[8,[39,3],null,[[\"@validation\"],[[30,0,[\"nameValidation\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[14,\"for\",\"full_name\"],[12],[1,[28,[35,1],[\"groups.manage.full_name\"],null]],[13],[1,\"\\n\\n    \"],[8,[39,2],[[24,0,\"input-xxlarge group-form-full-name\"]],[[\"@name\",\"@value\"],[\"full_name\",[30,0,[\"model\",\"full_name\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[14,\"for\",\"bio\"],[12],[1,[28,[35,1],[\"groups.bio\"],null]],[13],[1,\"\\n  \"],[8,[39,4],[[24,0,\"group-form-bio input-xxlarge\"]],[[\"@value\"],[[30,0,[\"model\",\"bio_raw\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"model\",\"automatic\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[8,[39,5],null,[[\"@model\"],[[30,0,[\"model\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"canEdit\"]],[[[1,\"  \"],[18,1,null],[1,\"\\n\\n  \"],[10,1],[12],[1,\"\\n    \"],[8,[39,7],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"group-edit\",\"div\",[28,[37,8],null,[[\"group\"],[[30,0,[\"model\"]]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"i18n\",\"text-field\",\"input-tip\",\"d-editor\",\"group-flair-inputs\",\"yield\",\"plugin-outlet\",\"hash\"]]",
  "moduleName": "discourse/components/groups-form-profile-fields.hbs",
  "isStrictMode": false
});