import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;
/* import __COLOCATED_TEMPLATE__ from './category-permission-row.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { alias, equal } from "@ember/object/computed";
import PermissionType from "discourse/models/permission-type";
import discourseComputed, { observes } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./category-permission-row.hbs";
const EVERYONE = "everyone";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("type"), _dec2 = discourseComputed("type"), _dec3 = discourseComputed("type"), _dec4 = discourseComputed("type"), _dec5 = discourseComputed("type"), _dec6 = observes("everyonePermissionType"), _dec7 = discourseComputed("everyonePermissionType", "type"), _dec8 = discourseComputed("replyDisabled"), _dec9 = discourseComputed("everyonePermissionType", "type"), _dec10 = discourseComputed("createDisabled"), (_obj = {
  classNames: ["permission-row", "row-body"],
  canCreate: equal("type", PermissionType.FULL),
  everyonePermissionType: alias("everyonePermission.permission_type"),
  canReply(value) {
    return value === PermissionType.CREATE_POST || value === PermissionType.FULL;
  },
  canReplyIcon() {
    return this.canReply ? "check-square" : "far-square";
  },
  canCreateIcon() {
    return this.canCreate ? "check-square" : "far-square";
  },
  replyGranted() {
    return this.type <= PermissionType.CREATE_POST ? "reply-granted" : "";
  },
  createGranted() {
    return this.type === PermissionType.FULL ? "create-granted" : "";
  },
  inheritFromEveryone() {
    if (this.group_name === EVERYONE) {
      return;
    }

    // groups cannot have a lesser permission than "everyone"
    if (this.everyonePermissionType < this.type) {
      this.updatePermission(this.everyonePermissionType);
    }
  },
  replyDisabled(everyonePermissionType) {
    if (this.group_name !== EVERYONE && everyonePermissionType && everyonePermissionType <= PermissionType.CREATE_POST) {
      return true;
    }
    return false;
  },
  replyTooltip(replyDisabled) {
    return replyDisabled ? I18n.t("category.permissions.inherited") : I18n.t("category.permissions.toggle_reply");
  },
  createDisabled(everyonePermissionType) {
    if (this.group_name !== EVERYONE && everyonePermissionType && everyonePermissionType === PermissionType.FULL) {
      return true;
    }
    return false;
  },
  createTooltip(createDisabled) {
    return createDisabled ? I18n.t("category.permissions.inherited") : I18n.t("category.permissions.toggle_full");
  },
  updatePermission(type) {
    this.category.updatePermission(this.group_name, type);
  },
  removeRow(event) {
    event?.preventDefault();
    this.category.removePermission(this.group_name);
  },
  actions: {
    setPermissionReply() {
      if (this.type <= PermissionType.CREATE_POST) {
        this.updatePermission(PermissionType.READONLY);
      } else {
        this.updatePermission(PermissionType.CREATE_POST);
      }
    },
    setPermissionFull() {
      if (this.group_name !== EVERYONE && this.everyonePermissionType === PermissionType.FULL) {
        return;
      }
      if (this.type === PermissionType.FULL) {
        this.updatePermission(PermissionType.CREATE_POST);
      } else {
        this.updatePermission(PermissionType.FULL);
      }
    }
  }
}, (_applyDecoratedDescriptor(_obj, "canReply", [_dec], Object.getOwnPropertyDescriptor(_obj, "canReply"), _obj), _applyDecoratedDescriptor(_obj, "canReplyIcon", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canReplyIcon"), _obj), _applyDecoratedDescriptor(_obj, "canCreateIcon", [_dec3], Object.getOwnPropertyDescriptor(_obj, "canCreateIcon"), _obj), _applyDecoratedDescriptor(_obj, "replyGranted", [_dec4], Object.getOwnPropertyDescriptor(_obj, "replyGranted"), _obj), _applyDecoratedDescriptor(_obj, "createGranted", [_dec5], Object.getOwnPropertyDescriptor(_obj, "createGranted"), _obj), _applyDecoratedDescriptor(_obj, "inheritFromEveryone", [_dec6], Object.getOwnPropertyDescriptor(_obj, "inheritFromEveryone"), _obj), _applyDecoratedDescriptor(_obj, "replyDisabled", [_dec7], Object.getOwnPropertyDescriptor(_obj, "replyDisabled"), _obj), _applyDecoratedDescriptor(_obj, "replyTooltip", [_dec8], Object.getOwnPropertyDescriptor(_obj, "replyTooltip"), _obj), _applyDecoratedDescriptor(_obj, "createDisabled", [_dec9], Object.getOwnPropertyDescriptor(_obj, "createDisabled"), _obj), _applyDecoratedDescriptor(_obj, "createTooltip", [_dec10], Object.getOwnPropertyDescriptor(_obj, "createTooltip"), _obj), _applyDecoratedDescriptor(_obj, "removeRow", [action], Object.getOwnPropertyDescriptor(_obj, "removeRow"), _obj)), _obj))));