import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './category-read-only-banner.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { and } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./category-read-only-banner.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  user() {
    return this.currentUser;
  },
  shouldShow: and("category.read_only_banner", "readOnly", "user")
}, (_applyDecoratedDescriptor(_obj, "user", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "user"), _obj)), _obj)));