import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if @count}}
  <LinkTo @route="full-page-search" @query={{hash q=this.searchParams}}>
    {{@count}}
  </LinkTo>
{{else}}
  &ndash;
{{/if}}
*/
{
  "id": "flfRzyWe",
  "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],null,[[\"@route\",\"@query\"],[\"full-page-search\",[28,[37,2],null,[[\"q\"],[[30,0,[\"searchParams\"]]]]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[30,1]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],[[[1,\"  –\\n\"]],[]]]],[\"@count\"],false,[\"if\",\"link-to\",\"hash\"]]",
  "moduleName": "discourse/components/user-summary-category-search.hbs",
  "isStrictMode": false
});