import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { service } from "@ember/service";
import DiscourseURL from "discourse/lib/url";
import StaticPage from "discourse/models/static-page";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
let FaqRoute = (_class = class FaqRoute extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    _defineProperty(this, "pageId", "faq");
    _defineProperty(this, "templateName", "faq");
  }
  activate() {
    super.activate(...arguments);
    DiscourseURL.jumpToElement(document.location.hash.slice(1));
  }
  beforeModel(transition) {
    if (this.pageId === "faq" && this.siteSettings.faq_url) {
      transition.abort();
      DiscourseURL.redirectTo(this.siteSettings.faq_url);
    }
  }
  model() {
    return StaticPage.find(this.pageId);
  }
  titleToken() {
    return I18n.t(this.pageId);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { FaqRoute as default };