import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.shouldShow}}
  {{#if this.href}}
    <a
      href={{this.href}}
      title={{i18n this.title}}
      aria-label={{i18n this.ariaLabel}}
    >
      <span class="private-message-glyph-wrapper">
        {{d-icon "envelope" class="private-message-glyph"}}
      </span>
    </a>
  {{~else}}
    <span class="private-message-glyph-wrapper">
      {{d-icon "envelope" class="private-message-glyph"}}
    </span>
  {{~/if}}
{{/if}}
*/
{
  "id": "N6MsCHwi",
  "block": "[[[41,[30,0,[\"shouldShow\"]],[[[41,[30,0,[\"href\"]],[[[1,\"    \"],[10,3],[15,6,[30,0,[\"href\"]]],[15,\"title\",[28,[37,1],[[30,0,[\"title\"]]],null]],[15,\"aria-label\",[28,[37,1],[[30,0,[\"ariaLabel\"]]],null]],[12],[1,\"\\n      \"],[10,1],[14,0,\"private-message-glyph-wrapper\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[\"envelope\"],[[\"class\"],[\"private-message-glyph\"]]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13]],[]],[[[1,\"    \"],[10,1],[14,0,\"private-message-glyph-wrapper\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"envelope\"],[[\"class\"],[\"private-message-glyph\"]]]],[1,\"\\n    \"],[13]],[]]]],[]],null]],[],false,[\"if\",\"i18n\",\"d-icon\"]]",
  "moduleName": "discourse/components/private-message-glyph.hbs",
  "isStrictMode": false
});