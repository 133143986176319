import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{raw "topic-status" topic=this.topic}}
<a href={{this.topic.lastUnreadUrl}} class="title">{{html-safe
    this.topic.fancyTitle
  }}</a>
<TopicPostBadges
  @unreadPosts={{this.topic.unread_posts}}
  @unseen={{this.topic.unseen}}
  @url={{this.topic.lastUnreadUrl}}
/>

<a href={{this.topic.lastPostUrl}} class="last-posted-at">{{format-age
    this.topic.last_posted_at
  }}</a>
*/
{
  "id": "3LpWP1hz",
  "block": "[[[1,[28,[35,0],[\"topic-status\"],[[\"topic\"],[[30,0,[\"topic\"]]]]]],[1,\"\\n\"],[10,3],[15,6,[30,0,[\"topic\",\"lastUnreadUrl\"]]],[14,0,\"title\"],[12],[1,[28,[35,1],[[30,0,[\"topic\",\"fancyTitle\"]]],null]],[13],[1,\"\\n\"],[8,[39,2],null,[[\"@unreadPosts\",\"@unseen\",\"@url\"],[[30,0,[\"topic\",\"unread_posts\"]],[30,0,[\"topic\",\"unseen\"]],[30,0,[\"topic\",\"lastUnreadUrl\"]]]],null],[1,\"\\n\\n\"],[10,3],[15,6,[30,0,[\"topic\",\"lastPostUrl\"]]],[14,0,\"last-posted-at\"],[12],[1,[28,[35,3],[[30,0,[\"topic\",\"last_posted_at\"]]],null]],[13]],[],false,[\"raw\",\"html-safe\",\"topic-post-badges\",\"format-age\"]]",
  "moduleName": "discourse/components/featured-topic.hbs",
  "isStrictMode": false
});