import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import Helper from "@ember/component/helper";
import { service } from "@ember/service";
let HtmlClass = (_class = class HtmlClass extends Helper {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "elementClasses", _descriptor, this);
  }
  compute(_ref) {
    let [...classes] = _ref;
    this.elementClasses.registerClasses(this, document.documentElement, classes.flatMap(c => c?.split(" ")).filter(Boolean));
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "elementClasses", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { HtmlClass as default };