import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _class, _PermissionType;
import EmberObject from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export function buildPermissionDescription(id) {
  return I18n.t("permission_types." + PermissionType.DESCRIPTION_KEYS[id]);
}
let PermissionType = (_dec = discourseComputed("id"), (_class = (_PermissionType = class PermissionType extends EmberObject {
  description(id) {
    return buildPermissionDescription(id);
  }
}, _defineProperty(_PermissionType, "FULL", 1), _defineProperty(_PermissionType, "CREATE_POST", 2), _defineProperty(_PermissionType, "READONLY", 3), _defineProperty(_PermissionType, "DESCRIPTION_KEYS", {
  1: "full",
  2: "create_post",
  3: "readonly"
}), _PermissionType), (_applyDecoratedDescriptor(_class.prototype, "description", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "description"), _class.prototype)), _class));
export { PermissionType as default };