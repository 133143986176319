import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.showPrompt}}
  <div
    class="software-update-prompt{{if
        this.animatePrompt
        ' require-software-refresh'
      }}"
  >
    <div class="wrap">
      <div class="update-prompt-main-content" aria-live="polite">
        <span
          role="button"
          onclick={{action "refreshPage"}}
          class="update-prompt-message"
        >{{d-icon "redo"}}
          {{html-safe (i18n "software_update_prompt.message")}}</span>
        <span class="update-prompt-dismiss"><span
            aria-label={{i18n "software_update_prompt.dismiss"}}
            role="button"
            onclick={{action "dismiss"}}
          >{{d-icon "times"}}</span></span>
      </div>
    </div>
  </div>
{{/if}}
*/
{
  "id": "Sh7MsQp1",
  "block": "[[[41,[30,0,[\"showPrompt\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"software-update-prompt\",[52,[30,0,[\"animatePrompt\"]],\" require-software-refresh\"]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"wrap\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"update-prompt-main-content\"],[14,\"aria-live\",\"polite\"],[12],[1,\"\\n        \"],[10,1],[14,\"role\",\"button\"],[15,\"onclick\",[28,[37,1],[[30,0],\"refreshPage\"],null]],[14,0,\"update-prompt-message\"],[12],[1,[28,[35,2],[\"redo\"],null]],[1,\"\\n          \"],[1,[28,[35,3],[[28,[37,4],[\"software_update_prompt.message\"],null]],null]],[13],[1,\"\\n        \"],[10,1],[14,0,\"update-prompt-dismiss\"],[12],[10,1],[15,\"aria-label\",[28,[37,4],[\"software_update_prompt.dismiss\"],null]],[14,\"role\",\"button\"],[15,\"onclick\",[28,[37,1],[[30,0],\"dismiss\"],null]],[12],[1,[28,[35,2],[\"times\"],null]],[13],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"action\",\"d-icon\",\"html-safe\",\"i18n\"]]",
  "moduleName": "discourse/components/software-update-prompt.hbs",
  "isStrictMode": false
});