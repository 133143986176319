import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @bodyClass="reject-reason-reviewable-modal__explain-reviewable"
  @closeModal={{@closeModal}}
  @title={{i18n "review.reject_reason.title"}}
  class="reject-reason-reviewable-modal"
>
  <:body>
    <Textarea @value={{this.rejectReason}} />
    <div class="control-group">
      <label>
        <Input
          @type="checkbox"
          class="reject-reason-reviewable-modal__send_email--inline"
          @checked={{this.sendEmail}}
        />
        {{i18n "review.reject_reason.send_email"}}
      </label>
    </div>
  </:body>

  <:footer>
    <DButton
      @icon="trash-alt"
      @action={{this.perform}}
      @label="admin.user.delete"
      class="btn-danger"
    />
    <DButton @action={{@closeModal}} @label="cancel" class="cancel" />
  </:footer>
</DModal>
*/
{
  "id": "0ON18SR+",
  "block": "[[[8,[39,0],[[24,0,\"reject-reason-reviewable-modal\"]],[[\"@bodyClass\",\"@closeModal\",\"@title\"],[\"reject-reason-reviewable-modal__explain-reviewable\",[30,1],[28,[37,1],[\"review.reject_reason.title\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@value\"],[[30,0,[\"rejectReason\"]]]],null],[1,\"    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[8,[39,3],[[24,0,\"reject-reason-reviewable-modal__send_email--inline\"]],[[\"@type\",\"@checked\"],[\"checkbox\",[30,0,[\"sendEmail\"]]]],null],[1,\"\\n        \"],[1,[28,[35,1],[\"review.reject_reason.send_email\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn-danger\"]],[[\"@icon\",\"@action\",\"@label\"],[\"trash-alt\",[30,0,[\"perform\"]],\"admin.user.delete\"]],null],[1,\"\\n    \"],[8,[39,4],[[24,0,\"cancel\"]],[[\"@action\",\"@label\"],[[30,1],\"cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"textarea\",\"input\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/reject-reason-reviewable.hbs",
  "isStrictMode": false
});