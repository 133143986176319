import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{body-class (concat "static-" this.model.path)}}

<section class="container">
  <WatchRead>
    <div class="contents clearfix body-page">
      <PluginOutlet @name="above-static" />
      {{html-safe this.model.html}}
      <PluginOutlet @name="below-static" />
    </div>
  </WatchRead>
</section>
*/
{
  "id": "sCzSm9nP",
  "block": "[[[1,[28,[35,0],[[28,[37,1],[\"static-\",[30,0,[\"model\",\"path\"]]],null]],null]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"container\"],[12],[1,\"\\n  \"],[8,[39,2],null,null,[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"contents clearfix body-page\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@name\"],[\"above-static\"]],null],[1,\"\\n      \"],[1,[28,[35,4],[[30,0,[\"model\",\"html\"]]],null]],[1,\"\\n      \"],[8,[39,3],null,[[\"@name\"],[\"below-static\"]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13]],[],false,[\"body-class\",\"concat\",\"watch-read\",\"plugin-outlet\",\"html-safe\"]]",
  "moduleName": "discourse/templates/faq.hbs",
  "isStrictMode": false
});