import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  class="composer-fullscreen-prompt"
  {{on "animationend" @removeFullScreenExitPrompt}}
>
  {{html-safe (i18n "composer.exit_fullscreen_prompt")}}
</div>
*/
{
  "id": "inbDeZ+q",
  "block": "[[[11,0],[24,0,\"composer-fullscreen-prompt\"],[4,[38,0],[\"animationend\",[30,1]],null],[12],[1,\"\\n  \"],[1,[28,[35,1],[[28,[37,2],[\"composer.exit_fullscreen_prompt\"],null]],null]],[1,\"\\n\"],[13]],[\"@removeFullScreenExitPrompt\"],false,[\"on\",\"html-safe\",\"i18n\"]]",
  "moduleName": "discourse/components/composer-fullscreen-prompt.hbs",
  "isStrictMode": false
});