import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.canRender}}
  {{yield this.info}}
{{/if}}
*/
{
  "id": "Je3pbzQj",
  "block": "[[[41,[30,0,[\"canRender\"]],[[[1,\"  \"],[18,1,[[30,0,[\"info\"]]]],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"if\",\"yield\"]]",
  "moduleName": "discourse/components/topic-navigation.hbs",
  "isStrictMode": false
});