/* import __COLOCATED_TEMPLATE__ from './browser-search-tip.hbs'; */
import Component from "@glimmer/component";
import { translateModKey } from "discourse/lib/utilities";
import I18n from "discourse-i18n";
import TEMPLATE from "./browser-search-tip.hbs";
import { setComponentTemplate } from "@ember/component";
export default class BrowserSearchTip extends Component {
  get translatedLabel() {
    return I18n.t("search.browser_tip", {
      modifier: translateModKey("Meta+")
    });
  }
}
setComponentTemplate(TEMPLATE, BrowserSearchTip);