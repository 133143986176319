import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  class="calendar-date-time-input"
  {{did-insert this.setupInternalDateTime}}
  {{did-insert this.setupPikaday}}
  {{did-update this.changeMinDate @minDate}}
  {{did-update this.changeDate @date}}
  {{did-update this.changeTime @time}}
>
  <Input class="fake-input" />

  <div class="date-picker" id="picker-container-{{@datePickerId}}"></div>

  <div class="time-pickers">
    {{d-icon "far-clock"}}
    <Input
      maxlength={{5}}
      placeholder="hh:mm"
      @type="time"
      @value={{this._time}}
      class="time-picker"
      {{on "input" (action this.onChangeTime)}}
    />
  </div>
</div>
*/
{
  "id": "IlYdxY5g",
  "block": "[[[11,0],[24,0,\"calendar-date-time-input\"],[4,[38,0],[[30,0,[\"setupInternalDateTime\"]]],null],[4,[38,0],[[30,0,[\"setupPikaday\"]]],null],[4,[38,1],[[30,0,[\"changeMinDate\"]],[30,1]],null],[4,[38,1],[[30,0,[\"changeDate\"]],[30,2]],null],[4,[38,1],[[30,0,[\"changeTime\"]],[30,3]],null],[12],[1,\"\\n  \"],[8,[39,2],[[24,0,\"fake-input\"]],null,null],[1,\"\\n\\n  \"],[10,0],[14,0,\"date-picker\"],[15,1,[29,[\"picker-container-\",[30,4]]]],[12],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"time-pickers\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"far-clock\"],null]],[1,\"\\n    \"],[8,[39,2],[[16,\"maxlength\",5],[24,\"placeholder\",\"hh:mm\"],[24,0,\"time-picker\"],[4,[38,4],[\"input\",[28,[37,5],[[30,0],[30,0,[\"onChangeTime\"]]],null]],null]],[[\"@type\",\"@value\"],[\"time\",[30,0,[\"_time\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@minDate\",\"@date\",\"@time\",\"@datePickerId\"],false,[\"did-insert\",\"did-update\",\"input\",\"d-icon\",\"on\",\"action\"]]",
  "moduleName": "discourse/components/calendar-date-time-input.hbs",
  "isStrictMode": false
});