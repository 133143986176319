import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.can_see_invite_details}}
  {{body-class "user-invites-page"}}

  <div class="user-navigation user-navigation-secondary">
    <HorizontalOverflowNav @ariaLabel="User secondary - invites">
      <NavItem
        @route="userInvited.show"
        @routeParam="pending"
        @i18nLabel={{this.pendingLabel}}
      />
      <NavItem
        @route="userInvited.show"
        @routeParam="expired"
        @i18nLabel={{this.expiredLabel}}
      />
      <NavItem
        @route="userInvited.show"
        @routeParam="redeemed"
        @i18nLabel={{this.redeemedLabel}}
      />
    </HorizontalOverflowNav>
  </div>
{{/if}}

{{outlet}}
*/
{
  "id": "MZe9kyy3",
  "block": "[[[41,[30,0,[\"can_see_invite_details\"]],[[[1,\"  \"],[1,[28,[35,1],[\"user-invites-page\"],null]],[1,\"\\n\\n  \"],[10,0],[14,0,\"user-navigation user-navigation-secondary\"],[12],[1,\"\\n    \"],[8,[39,2],null,[[\"@ariaLabel\"],[\"User secondary - invites\"]],[[\"default\"],[[[[1,\"\\n      \"],[8,[39,3],null,[[\"@route\",\"@routeParam\",\"@i18nLabel\"],[\"userInvited.show\",\"pending\",[30,0,[\"pendingLabel\"]]]],null],[1,\"\\n      \"],[8,[39,3],null,[[\"@route\",\"@routeParam\",\"@i18nLabel\"],[\"userInvited.show\",\"expired\",[30,0,[\"expiredLabel\"]]]],null],[1,\"\\n      \"],[8,[39,3],null,[[\"@route\",\"@routeParam\",\"@i18nLabel\"],[\"userInvited.show\",\"redeemed\",[30,0,[\"redeemedLabel\"]]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[46,[28,[37,5],null,null],null,null,null]],[],false,[\"if\",\"body-class\",\"horizontal-overflow-nav\",\"nav-item\",\"component\",\"-outlet\"]]",
  "moduleName": "discourse/templates/user-invited.hbs",
  "isStrictMode": false
});