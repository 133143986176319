import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if @canLoginLocal}}
  {{#unless @showSecurityKey}}
    <DButton
      @action={{@login}}
      id="login-button"
      form="login-form"
      @icon="unlock"
      @label={{@loginButtonLabel}}
      @disabled={{@loginDisabled}}
      class="btn btn-large btn-primary"
      tabindex={{unless @showSecondFactor "2"}}
    />
  {{/unless}}

  {{#if @showSignupLink}}
    <DButton
      class="btn-large btn-flat"
      id="new-account-link"
      @action={{@createAccount}}
      @label="create_account.title"
      tabindex="3"
    />
  {{/if}}
{{/if}}
<ConditionalLoadingSpinner @condition={{@loggingIn}} @size="small" />
<PluginOutlet @name="login-after-modal-footer" @connectorTagName="div" />
*/
{
  "id": "PLC6GAMT",
  "block": "[[[41,[30,1],[[[41,[51,[30,2]],[[[1,\"    \"],[8,[39,2],[[24,1,\"login-button\"],[24,\"form\",\"login-form\"],[24,0,\"btn btn-large btn-primary\"],[16,\"tabindex\",[52,[51,[30,3]],\"2\"]]],[[\"@action\",\"@icon\",\"@label\",\"@disabled\"],[[30,4],\"unlock\",[30,5],[30,6]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,7],[[[1,\"    \"],[8,[39,2],[[24,0,\"btn-large btn-flat\"],[24,1,\"new-account-link\"],[24,\"tabindex\",\"3\"]],[[\"@action\",\"@label\"],[[30,8],\"create_account.title\"]],null],[1,\"\\n\"]],[]],null]],[]],null],[8,[39,3],null,[[\"@condition\",\"@size\"],[[30,9],\"small\"]],null],[1,\"\\n\"],[8,[39,4],null,[[\"@name\",\"@connectorTagName\"],[\"login-after-modal-footer\",\"div\"]],null]],[\"@canLoginLocal\",\"@showSecurityKey\",\"@showSecondFactor\",\"@login\",\"@loginButtonLabel\",\"@loginDisabled\",\"@showSignupLink\",\"@createAccount\",\"@loggingIn\"],false,[\"if\",\"unless\",\"d-button\",\"conditional-loading-spinner\",\"plugin-outlet\"]]",
  "moduleName": "discourse/components/modal/login/footer.hbs",
  "isStrictMode": false
});