import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './emoji-uploader.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { notEmpty } from "@ember/object/computed";
import { isEmpty } from "@ember/utils";
import UppyUploadMixin from "discourse/mixins/uppy-upload";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./emoji-uploader.hbs";
const DEFAULT_GROUP = "default";
export default setComponentTemplate(TEMPLATE, Component.extend(UppyUploadMixin, (_dec = discourseComputed("uploading", "uploadProgress"), _dec2 = discourseComputed("uploading"), (_obj = {
  type: "emoji",
  uploadUrl: "/admin/customize/emojis",
  hasName: notEmpty("name"),
  hasGroup: notEmpty("group"),
  group: "default",
  emojiGroups: null,
  newEmojiGroups: null,
  tagName: null,
  preventDirectS3Uploads: true,
  didReceiveAttrs() {
    this._super(...arguments);
    this.set("newEmojiGroups", this.emojiGroups);
  },
  createEmojiGroup(group) {
    let newEmojiGroups = this.newEmojiGroups;
    if (group !== DEFAULT_GROUP) {
      newEmojiGroups = this.emojiGroups.concat([group]).uniq();
    }
    this.setProperties({
      newEmojiGroups,
      group
    });
  },
  _perFileData() {
    const payload = {};
    if (!isEmpty(this.name)) {
      payload.name = this.name;

      // if uploading multiple files, we can't use the name for every emoji
      this.set("name", null);
    }
    if (!isEmpty(this.group) && this.group !== DEFAULT_GROUP) {
      payload.group = this.group;
    }
    return payload;
  },
  validateUploadedFilesOptions() {
    return {
      imagesOnly: true
    };
  },
  uploadDone(upload) {
    this.done(upload, this.group);
    this.set("name", null);
  },
  chooseFiles() {
    this.fileInputEl.click();
  },
  buttonLabel(uploading, uploadProgress) {
    if (uploading) {
      return `${I18n.t("admin.emoji.uploading")} ${uploadProgress}%`;
    } else {
      return I18n.t("admin.emoji.add");
    }
  },
  buttonIcon(uploading) {
    if (uploading) {
      return "spinner";
    } else {
      return "plus";
    }
  }
}, (_applyDecoratedDescriptor(_obj, "createEmojiGroup", [action], Object.getOwnPropertyDescriptor(_obj, "createEmojiGroup"), _obj), _applyDecoratedDescriptor(_obj, "chooseFiles", [action], Object.getOwnPropertyDescriptor(_obj, "chooseFiles"), _obj), _applyDecoratedDescriptor(_obj, "buttonLabel", [_dec], Object.getOwnPropertyDescriptor(_obj, "buttonLabel"), _obj), _applyDecoratedDescriptor(_obj, "buttonIcon", [_dec2], Object.getOwnPropertyDescriptor(_obj, "buttonIcon"), _obj)), _obj))));