import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.post}}
  <div class="reviewable-conversation-post">
    {{#if this.showUsername}}
      <LinkTo
        @route="user"
        @model={{this.post.user}}
        class="username"
      >@{{this.post.user.username}}</LinkTo>
    {{/if}}
    {{html-safe this.post.excerpt}}
  </div>
{{/if}}
*/
{
  "id": "rEzH4/zT",
  "block": "[[[41,[30,0,[\"post\"]],[[[1,\"  \"],[10,0],[14,0,\"reviewable-conversation-post\"],[12],[1,\"\\n\"],[41,[30,0,[\"showUsername\"]],[[[1,\"      \"],[8,[39,1],[[24,0,\"username\"]],[[\"@route\",\"@model\"],[\"user\",[30,0,[\"post\",\"user\"]]]],[[\"default\"],[[[[1,\"@\"],[1,[30,0,[\"post\",\"user\",\"username\"]]]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[1,[28,[35,2],[[30,0,[\"post\",\"excerpt\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"link-to\",\"html-safe\"]]",
  "moduleName": "discourse/components/reviewable-conversation-post.hbs",
  "isStrictMode": false
});