import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;
import Controller, { inject as controller } from "@ember/controller";
import EmberObject, { action } from "@ember/object";
import Badge from "discourse/models/badge";
import UserBadge from "discourse/models/user-badge";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend((_dec = discourseComputed("userBadgesAll"), _dec2 = discourseComputed("filteredList"), _dec3 = discourseComputed("username"), _dec4 = discourseComputed("username", "model.grant_count", "userBadges.grant_count"), _dec5 = discourseComputed("model.grant_count", "userBadges.grant_count"), _dec6 = discourseComputed("model.allow_title", "model.has_badge", "model"), _dec7 = discourseComputed("noMoreBadges", "grantCount", "userBadges.length"), _dec8 = discourseComputed("user", "model.grant_count"), (_obj = {
  application: controller(),
  queryParams: ["username"],
  noMoreBadges: false,
  userBadges: null,
  hiddenSetTitle: true,
  filteredList(userBadgesAll) {
    return userBadgesAll.filterBy("badge.allow_title", true);
  },
  selectableUserBadges(filteredList) {
    return [EmberObject.create({
      id: 0,
      badge: Badge.create({
        name: I18n.t("badges.none")
      })
    }), ...filteredList.uniqBy("badge.name")];
  },
  user(username) {
    if (username) {
      return this.userBadges[0].get("user");
    }
  },
  grantCount(username, modelCount, userCount) {
    return username ? userCount : modelCount;
  },
  othersCount(modelCount, userCount) {
    return modelCount - userCount;
  },
  canSelectTitle(hasTitleBadges, hasBadge) {
    return this.siteSettings.enable_badges && hasTitleBadges && hasBadge;
  },
  canLoadMore(noMoreBadges, grantCount, userBadgeLength) {
    if (noMoreBadges) {
      return false;
    }
    return grantCount > (userBadgeLength || 0);
  },
  canShowOthers(user, grantCount) {
    return !!user && grantCount > 1;
  },
  loadMore() {
    if (!this.canLoadMore) {
      return;
    }
    if (this.loadingMore) {
      return;
    }
    this.set("loadingMore", true);
    const userBadges = this.userBadges;
    UserBadge.findByBadgeId(this.get("model.id"), {
      offset: userBadges.length,
      username: this.username
    }).then(result => {
      userBadges.pushObjects(result);
      if (userBadges.length === 0) {
        this.set("noMoreBadges", true);
      }
    }).finally(() => {
      this.set("loadingMore", false);
    });
  },
  toggleSetUserTitle() {
    return this.toggleProperty("hiddenSetTitle");
  }
}, (_applyDecoratedDescriptor(_obj, "filteredList", [_dec], Object.getOwnPropertyDescriptor(_obj, "filteredList"), _obj), _applyDecoratedDescriptor(_obj, "selectableUserBadges", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectableUserBadges"), _obj), _applyDecoratedDescriptor(_obj, "user", [_dec3], Object.getOwnPropertyDescriptor(_obj, "user"), _obj), _applyDecoratedDescriptor(_obj, "grantCount", [_dec4], Object.getOwnPropertyDescriptor(_obj, "grantCount"), _obj), _applyDecoratedDescriptor(_obj, "othersCount", [_dec5], Object.getOwnPropertyDescriptor(_obj, "othersCount"), _obj), _applyDecoratedDescriptor(_obj, "canSelectTitle", [_dec6], Object.getOwnPropertyDescriptor(_obj, "canSelectTitle"), _obj), _applyDecoratedDescriptor(_obj, "canLoadMore", [_dec7], Object.getOwnPropertyDescriptor(_obj, "canLoadMore"), _obj), _applyDecoratedDescriptor(_obj, "canShowOthers", [_dec8], Object.getOwnPropertyDescriptor(_obj, "canShowOthers"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [action], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "toggleSetUserTitle", [action], Object.getOwnPropertyDescriptor(_obj, "toggleSetUserTitle"), _obj)), _obj)));