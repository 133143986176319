import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './d-navigation-item.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { makeArray } from "discourse-common/lib/helpers";
import TEMPLATE from "./d-navigation-item.hbs";
import { setComponentTemplate } from "@ember/component";
let DNavigationItem = (_class = class DNavigationItem extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
  }
  get ariaCurrent() {
    // when there are multiple levels of navigation
    // we want the active parent to get `aria-current="page"`
    // and the active child to get `aria-current="location"`
    if (this.args.ariaCurrentContext === "parentNav" && this.router.currentRouteName !== this.args.route &&
    // not the current route
    this.router.currentRoute.parent.name.includes(this.args.route) // but is the current parent route
    ) {
      return "page";
    }
    if (this.router.currentRouteName !== this.args.route) {
      return null;
    }
    if (this.args.ariaCurrentContext === "subNav") {
      return "location";
    } else {
      return "page";
    }
  }
  get models() {
    return makeArray(this.args.models || this.args.model);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { DNavigationItem as default };
setComponentTemplate(TEMPLATE, DNavigationItem);