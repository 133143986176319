import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#each this.messages as |message|}}
  <ComposerMessage
    @message={{message}}
    @closeMessage={{this.closeMessage}}
    @shareModal={{fn (mut this.showShareModal) true}}
    @switchPM={{this.switchPM}}
  />
  {{#if this.showShareModal}}
    <Modal::ShareTopic
      @closeModal={{fn (mut this.showShareModal) false}}
      @model={{this.shareModalData}}
    />
  {{/if}}
{{/each}}
*/
{
  "id": "ATeamNJW",
  "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"messages\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@message\",\"@closeMessage\",\"@shareModal\",\"@switchPM\"],[[30,1],[30,0,[\"closeMessage\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"showShareModal\"]]],null],true],null],[30,0,[\"switchPM\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"showShareModal\"]],[[[1,\"    \"],[8,[39,6],null,[[\"@closeModal\",\"@model\"],[[28,[37,3],[[28,[37,4],[[30,0,[\"showShareModal\"]]],null],false],null],[30,0,[\"shareModalData\"]]]],null],[1,\"\\n\"]],[]],null]],[1]],null]],[\"message\"],false,[\"each\",\"-track-array\",\"composer-message\",\"fn\",\"mut\",\"if\",\"modal/share-topic\"]]",
  "moduleName": "discourse/components/composer-messages.hbs",
  "isStrictMode": false
});