import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './bookmark-list.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { Promise } from "rsvp";
import BookmarkModal from "discourse/components/modal/bookmark";
import { ajax } from "discourse/lib/ajax";
import { BookmarkFormData } from "discourse/lib/bookmark-form-data";
import { openLinkInNewTab, shouldOpenInNewTab } from "discourse/lib/click-track";
import I18n from "discourse-i18n";
import TEMPLATE from "./bookmark-list.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  dialog: service(),
  modal: service(),
  classNames: ["bookmark-list-wrapper"],
  removeBookmark(bookmark) {
    return new Promise((resolve, reject) => {
      const deleteBookmark = () => {
        bookmark.destroy().then(() => {
          this.appEvents.trigger("bookmarks:changed", null, bookmark.attachedTo());
          this._removeBookmarkFromList(bookmark);
          resolve(true);
        }).catch(error => {
          reject(error);
        });
      };
      if (!bookmark.reminder_at) {
        return deleteBookmark();
      }
      this.dialog.deleteConfirm({
        message: I18n.t("bookmarks.confirm_delete"),
        didConfirm: () => deleteBookmark(),
        didCancel: () => resolve(false)
      });
    });
  },
  screenExcerptForExternalLink(event) {
    if (event?.target?.tagName === "A") {
      if (shouldOpenInNewTab(event.target.href)) {
        openLinkInNewTab(event, event.target);
      }
    }
  },
  editBookmark(bookmark) {
    this.modal.show(BookmarkModal, {
      model: {
        bookmark: new BookmarkFormData(bookmark),
        afterSave: savedData => {
          this.appEvents.trigger("bookmarks:changed", savedData, bookmark.attachedTo());
          this.reload();
        },
        afterDelete: () => {
          this.reload();
        }
      }
    });
  },
  clearBookmarkReminder(bookmark) {
    return ajax(`/bookmarks/${bookmark.id}`, {
      type: "PUT",
      data: {
        reminder_at: null
      }
    }).then(() => {
      bookmark.set("reminder_at", null);
    });
  },
  togglePinBookmark(bookmark) {
    bookmark.togglePin().then(this.reload);
  },
  _removeBookmarkFromList(bookmark) {
    this.content.removeObject(bookmark);
  }
}, (_applyDecoratedDescriptor(_obj, "removeBookmark", [action], Object.getOwnPropertyDescriptor(_obj, "removeBookmark"), _obj), _applyDecoratedDescriptor(_obj, "screenExcerptForExternalLink", [action], Object.getOwnPropertyDescriptor(_obj, "screenExcerptForExternalLink"), _obj), _applyDecoratedDescriptor(_obj, "editBookmark", [action], Object.getOwnPropertyDescriptor(_obj, "editBookmark"), _obj), _applyDecoratedDescriptor(_obj, "clearBookmarkReminder", [action], Object.getOwnPropertyDescriptor(_obj, "clearBookmarkReminder"), _obj), _applyDecoratedDescriptor(_obj, "togglePinBookmark", [action], Object.getOwnPropertyDescriptor(_obj, "togglePinBookmark"), _obj)), _obj)));