import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './edit-user-directory-columns.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { reload } from "discourse/helpers/page-reloader";
import { ajax } from "discourse/lib/ajax";
import { extractError, popupAjaxError } from "discourse/lib/ajax-error";
import TEMPLATE from "./edit-user-directory-columns.hbs";
import { setComponentTemplate } from "@ember/component";
const UP = "up";
const DOWN = "down";
let EditUserDirectoryColumns = (_class = class EditUserDirectoryColumns extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "loading", _descriptor, this);
    _initializerDefineProperty(this, "columns", _descriptor2, this);
    _initializerDefineProperty(this, "labelKey", _descriptor3, this);
    _initializerDefineProperty(this, "flash", _descriptor4, this);
    this.setupColumns();
  }
  async setupColumns() {
    try {
      const response = await ajax("edit-directory-columns.json");
      this.loading = false;
      this.columns = response.directory_columns.sort((a, b) => a.position > b.position ? 1 : -1).map(c => ({
        ...c,
        enabled: Boolean(c.enabled)
      }));
    } catch (e) {
      popupAjaxError(e);
    }
  }
  async save() {
    this.loading = true;
    this.flash = null;
    const data = {
      directory_columns: this.columns.map(c => ({
        id: c.id,
        enabled: c.enabled,
        position: c.position
      }))
    };
    try {
      await ajax("edit-directory-columns.json", {
        type: "PUT",
        data
      });
      reload();
    } catch (e) {
      this.loading = false;
      this.flash = extractError(e);
    }
  }
  resetToDefault() {
    const resetColumns = this.columns.sort((a, b) => {
      const a1 = a.automatic_position || (a.user_field?.position || 0) + 1000;
      const b1 = b.automatic_position || (b.user_field?.position || 0) + 1000;
      if (a1 === b1) {
        return a.name.localeCompare(b.name);
      } else {
        return a1 > b1 ? 1 : -1;
      }
    }).map((column, index) => ({
      ...column,
      position: column.automatic_position || index + 1,
      enabled: column.type === "automatic"
    }));
    this.columns = resetColumns;
  }
  moveUp(column) {
    this._moveColumn(UP, column);
  }
  moveDown(column) {
    this._moveColumn(DOWN, column);
  }
  _moveColumn(direction, column) {
    if (direction === UP && column.position === 1 || direction === DOWN && column.position === this.columns.length) {
      return;
    }
    const positionOnClick = column.position;
    const newPosition = direction === UP ? positionOnClick - 1 : positionOnClick + 1;
    const previousColumn = this.columns.find(c => c.position === newPosition);
    column.position = newPosition;
    previousColumn.position = positionOnClick;
    this.columns = this.columns.sort((a, b) => a.position > b.position ? 1 : -1);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "columns", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "labelKey", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "setupColumns", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setupColumns"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetToDefault", [action], Object.getOwnPropertyDescriptor(_class.prototype, "resetToDefault"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moveUp", [action], Object.getOwnPropertyDescriptor(_class.prototype, "moveUp"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "moveDown", [action], Object.getOwnPropertyDescriptor(_class.prototype, "moveDown"), _class.prototype)), _class);
export { EditUserDirectoryColumns as default };
setComponentTemplate(TEMPLATE, EditUserDirectoryColumns);