import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _class, _SiteSettingsService;
import { TrackedObject } from "@ember-compat/tracked-built-ins";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import PreloadStore from "discourse/lib/preload-store";
export function createSiteSettingsFromPreloaded(data) {
  const settings = new TrackedObject(data);
  settings.groupSettingArray = groupSetting => {
    const setting = settings[groupSetting];
    if (!setting) {
      return [];
    }
    return setting.toString().split("|").filter(Boolean).map(groupId => parseInt(groupId, 10));
  };
  return settings;
}
let SiteSettingsService = disableImplicitInjections(_class = (_SiteSettingsService = class SiteSettingsService {
  static create() {
    return createSiteSettingsFromPreloaded(PreloadStore.get("siteSettings"));
  }
}, _defineProperty(_SiteSettingsService, "isServiceFactory", true), _SiteSettingsService)) || _class;
export { SiteSettingsService as default };