import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
import Component from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
const LIST_TYPE = {
  NORMAL: "normal",
  MUTED: "muted"
};
export default Component.extend((_dec = discourseComputed("category.isHidden", "category.hasMuted", "listType"), _dec2 = discourseComputed("category.isMuted", "listType"), _dec3 = discourseComputed("topicTrackingState.messageCount"), _dec4 = discourseComputed("topicTrackingState.messageCount"), (_obj = {
  tagName: "",
  category: null,
  listType: LIST_TYPE.NORMAL,
  isHidden(isHiddenCategory, hasMuted, listType) {
    return isHiddenCategory && listType === LIST_TYPE.NORMAL || !hasMuted && listType === LIST_TYPE.MUTED;
  },
  isMuted(isMutedCategory, listType) {
    return isMutedCategory && listType === LIST_TYPE.NORMAL || !isMutedCategory && listType === LIST_TYPE.MUTED;
  },
  unreadTopicsCount() {
    return this.category.unreadTopicsCount;
  },
  newTopicsCount() {
    return this.category.newTopicsCount;
  }
}, (_applyDecoratedDescriptor(_obj, "isHidden", [_dec], Object.getOwnPropertyDescriptor(_obj, "isHidden"), _obj), _applyDecoratedDescriptor(_obj, "isMuted", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isMuted"), _obj), _applyDecoratedDescriptor(_obj, "unreadTopicsCount", [_dec3], Object.getOwnPropertyDescriptor(_obj, "unreadTopicsCount"), _obj), _applyDecoratedDescriptor(_obj, "newTopicsCount", [_dec4], Object.getOwnPropertyDescriptor(_obj, "newTopicsCount"), _obj)), _obj)));