import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './flat-button.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./flat-button.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("title"), (_obj = {
  tagName: "button",
  classNames: ["btn-flat"],
  attributeBindings: ["disabled", "translatedTitle:title"],
  translatedTitle(title) {
    if (title) {
      return I18n.t(title);
    }
  },
  keyDown(event) {
    if (event.key === "Enter") {
      this.action?.();
      return false;
    }
  },
  click() {
    this.action?.();
    return false;
  }
}, (_applyDecoratedDescriptor(_obj, "translatedTitle", [_dec], Object.getOwnPropertyDescriptor(_obj, "translatedTitle"), _obj)), _obj))));