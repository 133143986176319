import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @bodyClass="grant-badge"
  @closeModal={{@closeModal}}
  @flash={{this.flash}}
  @flashType={{this.flashType}}
  @title={{i18n "admin.badges.grant_badge"}}
  class="grant-badge-modal"
  {{did-insert this.loadBadges}}
>
  <:body>
    <ConditionalLoadingSpinner @condition={{this.loading}}>
      {{#if this.noAvailableBadges}}
        <p>{{i18n "admin.badges.no_badges"}}</p>
      {{else}}
        <p>
          <ComboBox
            @value={{this.selectedBadgeId}}
            @content={{this.availableBadges}}
            @onChange={{fn (mut this.selectedBadgeId)}}
            @options={{hash filterable=true none="badges.none"}}
          />
        </p>
      {{/if}}
    </ConditionalLoadingSpinner>
  </:body>
  <:footer>
    <DButton
      @disabled={{this.buttonDisabled}}
      @action={{this.performGrantBadge}}
      @label="admin.badges.grant"
      class="btn-primary"
    />
  </:footer>
</DModal>
*/
{
  "id": "Tuc7NNQN",
  "block": "[[[8,[39,0],[[24,0,\"grant-badge-modal\"],[4,[38,2],[[30,0,[\"loadBadges\"]]],null]],[[\"@bodyClass\",\"@closeModal\",\"@flash\",\"@flashType\",\"@title\"],[\"grant-badge\",[30,1],[30,0,[\"flash\"]],[30,0,[\"flashType\"]],[28,[37,1],[\"admin.badges.grant_badge\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"noAvailableBadges\"]],[[[1,\"        \"],[10,2],[12],[1,[28,[35,1],[\"admin.badges.no_badges\"],null]],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[12],[1,\"\\n          \"],[8,[39,5],null,[[\"@value\",\"@content\",\"@onChange\",\"@options\"],[[30,0,[\"selectedBadgeId\"]],[30,0,[\"availableBadges\"]],[28,[37,6],[[28,[37,7],[[30,0,[\"selectedBadgeId\"]]],null]],null],[28,[37,8],null,[[\"filterable\",\"none\"],[true,\"badges.none\"]]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,9],[[24,0,\"btn-primary\"]],[[\"@disabled\",\"@action\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[30,0,[\"performGrantBadge\"]],\"admin.badges.grant\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"did-insert\",\"conditional-loading-spinner\",\"if\",\"combo-box\",\"fn\",\"mut\",\"hash\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/grant-badge.hbs",
  "isStrictMode": false
});