import { registerWidgetShim } from "discourse/widgets/render-glimmer";
import { createTemplateFactory } from "@ember/template-factory";
registerWidgetShim("post-user-tip-shim", "div.post-user-tip-shim", createTemplateFactory(
/*
  <UserTip @id="post_menu" @triggerSelector=".post-controls .actions .show-more-actions" @placement="top" @titleText={{i18n "user_tips.post_menu.title"}} @contentText={{i18n "user_tips.post_menu.content"}} />
*/
{
  "id": "gqhvzWaU",
  "block": "[[[8,[39,0],null,[[\"@id\",\"@triggerSelector\",\"@placement\",\"@titleText\",\"@contentText\"],[\"post_menu\",\".post-controls .actions .show-more-actions\",\"top\",[28,[37,1],[\"user_tips.post_menu.title\"],null],[28,[37,1],[\"user_tips.post_menu.content\"],null]]],null]],[],false,[\"user-tip\",\"i18n\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/widgets/post-user-tip-shim.js",
  "isStrictMode": false
}));