import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <UserInfo @user={{@user}}>
  {{d-icon @icon}}
  <span class={{@countClass}}>{{number @user.count}}</span>
</UserInfo>
*/
{
  "id": "YVbucpIN",
  "block": "[[[8,[39,0],null,[[\"@user\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,1],[[30,2]],null]],[1,\"\\n  \"],[10,1],[15,0,[30,3]],[12],[1,[28,[35,2],[[30,1,[\"count\"]]],null]],[13],[1,\"\\n\"]],[]]]]]],[\"@user\",\"@icon\",\"@countClass\"],false,[\"user-info\",\"d-icon\",\"number\"]]",
  "moduleName": "discourse/components/user-summary-user.hbs",
  "isStrictMode": false
});