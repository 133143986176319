import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="container">
  <h2>{{i18n "login.to_continue"}}</h2>

  <p style="margin-top: 1em">{{i18n "login.preferences"}}</p>

  <DButton
    @action={{route-action "showLogin"}}
    @label="log_in"
    class="btn-primary"
  />
</div>
*/
{
  "id": "2tsWWimX",
  "block": "[[[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,[28,[35,0],[\"login.to_continue\"],null]],[13],[1,\"\\n\\n  \"],[10,2],[14,5,\"margin-top: 1em\"],[12],[1,[28,[35,0],[\"login.preferences\"],null]],[13],[1,\"\\n\\n  \"],[8,[39,1],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,2],[\"showLogin\"],null],\"log_in\"]],null],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"d-button\",\"route-action\"]]",
  "moduleName": "discourse/templates/login-preferences.hbs",
  "isStrictMode": false
});