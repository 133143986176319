import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
/* import __COLOCATED_TEMPLATE__ from './edit-category-tab.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { empty } from "@ember/object/computed";
import { scheduleOnce } from "@ember/runloop";
import { underscore } from "@ember/string";
import { propertyEqual } from "discourse/lib/computed";
import DiscourseURL from "discourse/lib/url";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./edit-category-tab.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("tab"), _dec2 = discourseComputed("tab"), _dec3 = discourseComputed("params.slug", "params.parentSlug"), (_obj = {
  tagName: "li",
  classNameBindings: ["active", "tabClassName"],
  newCategory: empty("params.slug"),
  tabClassName(tab) {
    return "edit-category-" + tab;
  },
  active: propertyEqual("selectedTab", "tab"),
  title(tab) {
    return I18n.t(`category.${underscore(tab)}`);
  },
  didInsertElement() {
    this._super(...arguments);
    scheduleOnce("afterRender", this, this._addToCollection);
  },
  willDestroyElement() {
    this._super(...arguments);
    this.setProperties({
      selectedTab: "general",
      params: {}
    });
  },
  _addToCollection() {
    this.panels.addObject(this.tabClassName);
  },
  fullSlug(slug, parentSlug) {
    const slugPart = parentSlug && slug ? `${parentSlug}/${slug}` : slug;
    return getURL(`/c/${slugPart}/edit/${this.tab}`);
  },
  select(event) {
    event?.preventDefault();
    this.set("selectedTab", this.tab);
    if (!this.newCategory) {
      DiscourseURL.routeTo(this.fullSlug);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "tabClassName", [_dec], Object.getOwnPropertyDescriptor(_obj, "tabClassName"), _obj), _applyDecoratedDescriptor(_obj, "title", [_dec2], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "fullSlug", [_dec3], Object.getOwnPropertyDescriptor(_obj, "fullSlug"), _obj), _applyDecoratedDescriptor(_obj, "select", [action], Object.getOwnPropertyDescriptor(_obj, "select"), _obj)), _obj))));