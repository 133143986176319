import { htmlSafe } from "@ember/template";
export function renderSpinner(cssClass) {
  let html = "<div class='spinner";
  if (cssClass) {
    html += " " + cssClass;
  }
  return html + "'></div>";
}
export const spinnerHTML = renderSpinner();
export default function loadingSpinner() {
  let {
    size
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return htmlSafe(renderSpinner(size));
}