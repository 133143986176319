import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
/* import __COLOCATED_TEMPLATE__ from './not-activated.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { resendActivationEmail } from "discourse/lib/user-activation";
import ActivationEdit from "./activation-edit";
import ActivationResent from "./activation-resent";
import TEMPLATE from "./not-activated.hbs";
import { setComponentTemplate } from "@ember/component";
let NotActivated = (_class = class NotActivated extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "modal", _descriptor, this);
  }
  sendActivationEmail() {
    resendActivationEmail(this.args.model.currentEmail).then(() => {
      this.modal.show(ActivationResent, {
        model: {
          currentEmail: this.args.model.currentEmail
        }
      });
    });
  }
  editActivationEmail() {
    this.modal.show(ActivationEdit, {
      model: {
        currentEmail: this.args.model.currentEmail,
        newEmail: this.args.model.currentEmail
      }
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "sendActivationEmail", [action], Object.getOwnPropertyDescriptor(_class.prototype, "sendActivationEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "editActivationEmail", [action], Object.getOwnPropertyDescriptor(_class.prototype, "editActivationEmail"), _class.prototype)), _class);
export { NotActivated as default };
setComponentTemplate(TEMPLATE, NotActivated);