import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DButton
  @action={{@togglePasswordMask}}
  @label={{if @maskPassword "login.show_password" "login.hide_password"}}
  @title={{if
    @maskPassword
    "login.show_password_title"
    "login.hide_password_title"
  }}
  class="btn-link toggle-password-mask"
/>
*/
{
  "id": "QZEASR5+",
  "block": "[[[8,[39,0],[[24,0,\"btn-link toggle-password-mask\"]],[[\"@action\",\"@label\",\"@title\"],[[30,1],[52,[30,2],\"login.show_password\",\"login.hide_password\"],[52,[30,2],\"login.show_password_title\",\"login.hide_password_title\"]]],null]],[\"@togglePasswordMask\",\"@maskPassword\"],false,[\"d-button\",\"if\"]]",
  "moduleName": "discourse/components/toggle-password-mask.hbs",
  "isStrictMode": false
});