import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{@model.name}}
  @closeModal={{@closeModal}}
  class="customize-form-template-view-modal"
>
  <:body>
    <div class="control-group">
      <DToggleSwitch
        class="form-templates__preview-toggle"
        @state={{this.showPreview}}
        @label="admin.form_templates.view_template.toggle_preview"
        {{on "click" this.togglePreview}}
      />
    </div>
    {{#if this.showPreview}}
      <FormTemplateField::Wrapper @id={{@model.id}} />
    {{else}}
      <HighlightedCode @lang="yaml" @code={{@model.template}} />
    {{/if}}
  </:body>

  <:footer>
    <DButton
      class="btn-primary"
      @action={{this.editTemplate}}
      @icon="pencil-alt"
      @label="admin.form_templates.view_template.edit"
    />
    <DModalCancel @close={{@closeModal}} />
    <DButton
      class="btn-danger"
      @action={{this.deleteTemplate}}
      @icon="trash-alt"
      @label="admin.form_templates.view_template.delete"
    />
  </:footer>
</DModal>
*/
{
  "id": "2x9+Frg2",
  "block": "[[[8,[39,0],[[24,0,\"customize-form-template-view-modal\"]],[[\"@title\",\"@closeModal\"],[[30,1,[\"name\"]],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[8,[39,1],[[24,0,\"form-templates__preview-toggle\"],[4,[38,2],[\"click\",[30,0,[\"togglePreview\"]]],null]],[[\"@state\",\"@label\"],[[30,0,[\"showPreview\"]],\"admin.form_templates.view_template.toggle_preview\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"showPreview\"]],[[[1,\"      \"],[8,[39,4],null,[[\"@id\"],[[30,1,[\"id\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,5],null,[[\"@lang\",\"@code\"],[\"yaml\",[30,1,[\"template\"]]]],null],[1,\"\\n\"]],[]]],[1,\"  \"]],[]],[[[1,\"\\n    \"],[8,[39,6],[[24,0,\"btn-primary\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"editTemplate\"]],\"pencil-alt\",\"admin.form_templates.view_template.edit\"]],null],[1,\"\\n    \"],[8,[39,7],null,[[\"@close\"],[[30,2]]],null],[1,\"\\n    \"],[8,[39,6],[[24,0,\"btn-danger\"]],[[\"@action\",\"@icon\",\"@label\"],[[30,0,[\"deleteTemplate\"]],\"trash-alt\",\"admin.form_templates.view_template.delete\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\"],false,[\"d-modal\",\"d-toggle-switch\",\"on\",\"if\",\"form-template-field/wrapper\",\"highlighted-code\",\"d-button\",\"d-modal-cancel\"]]",
  "moduleName": "discourse/components/modal/customize-form-template-view.hbs",
  "isStrictMode": false
});