import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <input
  placeholder={{this.placeholder}}
  class="group-selector"
  type="text"
  name="groups"
/>
*/
{
  "id": "xrzpIVwv",
  "block": "[[[10,\"input\"],[15,\"placeholder\",[30,0,[\"placeholder\"]]],[14,0,\"group-selector\"],[14,3,\"groups\"],[14,4,\"text\"],[12],[13]],[],false,[]]",
  "moduleName": "discourse/components/group-selector.hbs",
  "isStrictMode": false
});