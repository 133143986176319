import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#each this.buttons as |b|}}
  <button
    type="button"
    class="btn btn-social {{b.name}}"
    {{on "click" (action this.externalLogin b)}}
    aria-label={{b.screenReaderTitle}}
  >
    {{#if b.isGoogle}}
      <GoogleIcon />
    {{else if b.icon}}
      {{d-icon b.icon}}
    {{else}}
      {{d-icon "sign-in-alt"}}
    {{/if}}
    <span class="btn-social-title">{{b.title}}</span>
  </button>
{{/each}}

{{#if this.showPasskeysButton}}
  <PasskeyLoginButton @passkeyLogin={{this.passkeyLogin}} />
{{/if}}

<PluginOutlet @name="after-login-buttons" />
*/
{
  "id": "tUgcekDF",
  "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"buttons\"]]],null]],null],null,[[[1,\"  \"],[11,\"button\"],[16,0,[29,[\"btn btn-social \",[30,1,[\"name\"]]]]],[16,\"aria-label\",[30,1,[\"screenReaderTitle\"]]],[24,4,\"button\"],[4,[38,2],[\"click\",[28,[37,3],[[30,0],[30,0,[\"externalLogin\"]],[30,1]],null]],null],[12],[1,\"\\n\"],[41,[30,1,[\"isGoogle\"]],[[[1,\"      \"],[8,[39,5],null,null,null],[1,\"\\n\"]],[]],[[[41,[30,1,[\"icon\"]],[[[1,\"      \"],[1,[28,[35,6],[[30,1,[\"icon\"]]],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,6],[\"sign-in-alt\"],null]],[1,\"\\n    \"]],[]]]],[]]],[1,\"    \"],[10,1],[14,0,\"btn-social-title\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[41,[30,0,[\"showPasskeysButton\"]],[[[1,\"  \"],[8,[39,7],null,[[\"@passkeyLogin\"],[[30,0,[\"passkeyLogin\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,8],null,[[\"@name\"],[\"after-login-buttons\"]],null]],[\"b\"],false,[\"each\",\"-track-array\",\"on\",\"action\",\"if\",\"google-icon\",\"d-icon\",\"passkey-login-button\",\"plugin-outlet\"]]",
  "moduleName": "discourse/components/login-buttons.hbs",
  "isStrictMode": false
});