import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <CreateInviteUploader @autoFindInput={{false}} as |uploader setElement|>
  <DModal
    @title={{i18n "user.invited.bulk_invite.text"}}
    class="create-invite-bulk-modal -large"
    @closeModal={{@closeModal}}
  >
    <:body>
      {{#if uploader.uploaded}}
        {{i18n "user.invited.bulk_invite.success"}}
      {{else}}
        {{html-safe (i18n "user.invited.bulk_invite.instructions")}}
        <input
          id="csv-file"
          disabled={{uploader.uploading}}
          type="file"
          accept=".csv"
          {{did-insert setElement}}
        />
      {{/if}}
    </:body>
    <:footer>
      {{#unless uploader.uploaded}}
        <DButton
          @icon="link"
          @translatedLabel={{if
            uploader.uploading
            (i18n
              "user.invited.bulk_invite.progress"
              progress=uploader.uploadProgress
            )
            (i18n "user.invited.bulk_invite.text")
          }}
          class="btn-primary"
          @action={{uploader.startUpload}}
          @disabled={{uploader.submitDisabled}}
        />
      {{/unless}}
      <DButton @label="close" class="btn-primary" @action={{@closeModal}} />
    </:footer>
  </DModal>
</CreateInviteUploader>
*/
{
  "id": "qOR0/94l",
  "block": "[[[8,[39,0],null,[[\"@autoFindInput\"],[false]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],[[24,0,\"create-invite-bulk-modal -large\"]],[[\"@title\",\"@closeModal\"],[[28,[37,2],[\"user.invited.bulk_invite.text\"],null],[30,3]]],[[\"body\",\"footer\"],[[[[1,\"\\n\"],[41,[30,1,[\"uploaded\"]],[[[1,\"        \"],[1,[28,[35,2],[\"user.invited.bulk_invite.success\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,4],[[28,[37,2],[\"user.invited.bulk_invite.instructions\"],null]],null]],[1,\"\\n        \"],[11,\"input\"],[24,1,\"csv-file\"],[16,\"disabled\",[30,1,[\"uploading\"]]],[24,\"accept\",\".csv\"],[24,4,\"file\"],[4,[38,5],[[30,2]],null],[12],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[]],[[[1,\"\\n\"],[41,[51,[30,1,[\"uploaded\"]]],[[[1,\"        \"],[8,[39,7],[[24,0,\"btn-primary\"]],[[\"@icon\",\"@translatedLabel\",\"@action\",\"@disabled\"],[\"link\",[52,[30,1,[\"uploading\"]],[28,[37,2],[\"user.invited.bulk_invite.progress\"],[[\"progress\"],[[30,1,[\"uploadProgress\"]]]]],[28,[37,2],[\"user.invited.bulk_invite.text\"],null]],[30,1,[\"startUpload\"]],[30,1,[\"submitDisabled\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[8,[39,7],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\"],[\"close\",[30,3]]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n\"]],[1,2]]]]]],[\"uploader\",\"setElement\",\"@closeModal\"],false,[\"create-invite-uploader\",\"d-modal\",\"i18n\",\"if\",\"html-safe\",\"did-insert\",\"unless\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/create-invite-bulk.hbs",
  "isStrictMode": false
});