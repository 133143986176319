import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./sidebar.hbs";
import { setComponentTemplate } from "@ember/component";
let Sidebar = (_class = class Sidebar extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "appEvents", _descriptor, this);
    _initializerDefineProperty(this, "site", _descriptor2, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
    _initializerDefineProperty(this, "currentUser", _descriptor4, this);
    _initializerDefineProperty(this, "sidebarState", _descriptor5, this);
    if (this.site.mobileView) {
      document.addEventListener("click", this.collapseSidebar);
    }
  }
  get showSwitchPanelButtonsOnTop() {
    return this.siteSettings.default_sidebar_switch_panel_position === "top";
  }
  get switchPanelButtons() {
    if (!this.sidebarState.displaySwitchPanelButtons || this.sidebarState.panels.length === 1 || !this.currentUser) {
      return [];
    }
    return this.sidebarState.panels.filter(panel => panel !== this.sidebarState.currentPanel && !panel.hidden);
  }
  collapseSidebar(event) {
    let shouldCollapseSidebar = false;
    const isClickWithinSidebar = event.composedPath().some(element => {
      if (element?.className !== "sidebar-section-header-caret" && ["A", "BUTTON"].includes(element.nodeName)) {
        shouldCollapseSidebar = true;
        return true;
      }
      return element.className && element.className === "sidebar-wrapper";
    });
    if (shouldCollapseSidebar || !isClickWithinSidebar) {
      this.args.toggleSidebar();
    }
  }
  willDestroy() {
    super.willDestroy(...arguments);
    if (this.site.mobileView) {
      document.removeEventListener("click", this.collapseSidebar);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "appEvents", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "sidebarState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "collapseSidebar", [bind], Object.getOwnPropertyDescriptor(_class.prototype, "collapseSidebar"), _class.prototype)), _class);
export { Sidebar as default };
setComponentTemplate(TEMPLATE, Sidebar);