import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
/* import __COLOCATED_TEMPLATE__ from './topics.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { filterTypeForMode } from "discourse/lib/filter-mode";
import { userPath } from "discourse/lib/url";
import I18n from "discourse-i18n";
import TEMPLATE from "./topics.hbs";
import { setComponentTemplate } from "@ember/component";
let DiscoveryTopics = (_class = class DiscoveryTopics extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "composer", _descriptor2, this);
    _initializerDefineProperty(this, "modal", _descriptor3, this);
    _initializerDefineProperty(this, "currentUser", _descriptor4, this);
    _initializerDefineProperty(this, "topicTrackingState", _descriptor5, this);
    _initializerDefineProperty(this, "site", _descriptor6, this);
    _initializerDefineProperty(this, "loadingNew", _descriptor7, this);
  }
  get redirectedReason() {
    return this.currentUser?.user_option.redirected_to_top?.reason;
  }
  get order() {
    return this.args.model.get("params.order");
  }
  get ascending() {
    return this.args.model.get("params.ascending") === "true";
  }
  get hasTopics() {
    return this.args.model.get("topics.length") > 0;
  }
  get allLoaded() {
    return !this.args.model.get("more_topics_url");
  }
  get latest() {
    return filterTypeForMode(this.args.model.filter) === "latest";
  }
  get top() {
    return filterTypeForMode(this.args.model.filter) === "top";
  }
  get hot() {
    return filterTypeForMode(this.args.model.filter) === "hot";
  }
  get new() {
    return filterTypeForMode(this.args.model.filter) === "new";
  }

  // Show newly inserted topics
  async showInserted(event) {
    event?.preventDefault();
    if (this.args.model.loadingBefore) {
      return; // Already loading
    }
    const {
      topicTrackingState
    } = this;
    try {
      const topicIds = [...topicTrackingState.newIncoming];
      await this.args.model.loadBefore(topicIds, true);
      topicTrackingState.clearIncoming(topicIds);
    } catch (e) {
      popupAjaxError(e);
    }
  }
  get showTopicsAndRepliesToggle() {
    return this.new && this.currentUser?.new_new_view_enabled;
  }
  get newRepliesCount() {
    this.topicTrackingState.get("messageCount"); // Autotrack this

    if (this.currentUser?.new_new_view_enabled) {
      return this.topicTrackingState.countUnread({
        categoryId: this.args.category?.id,
        noSubcategories: this.args.noSubcategories,
        tagId: this.args.tag?.id
      });
    } else {
      return 0;
    }
  }
  get newTopicsCount() {
    this.topicTrackingState.get("messageCount"); // Autotrack this

    if (this.currentUser?.new_new_view_enabled) {
      return this.topicTrackingState.countNew({
        categoryId: this.args.category?.id,
        noSubcategories: this.args.noSubcategories,
        tagId: this.args.tag?.id
      });
    } else {
      return 0;
    }
  }
  get showTopicPostBadges() {
    return !this.new || this.currentUser?.new_new_view_enabled;
  }
  get footerMessage() {
    const topicsLength = this.args.model.get("topics.length");
    if (!this.allLoaded) {
      return;
    }
    const {
      category,
      tag
    } = this.args;
    if (category) {
      return I18n.t("topics.bottom.category", {
        category: category.get("name")
      });
    } else if (tag) {
      return I18n.t("topics.bottom.tag", {
        tag: tag.id
      });
    } else {
      const split = (this.args.model.get("filter") || "").split("/");
      if (topicsLength === 0) {
        return I18n.t("topics.none." + split[0], {
          category: split[1]
        });
      } else {
        return I18n.t("topics.bottom." + split[0], {
          category: split[1]
        });
      }
    }
  }
  get footerEducation() {
    const topicsLength = this.args.model.get("topics.length");
    if (!this.allLoaded || topicsLength > 0 || !this.currentUser) {
      return;
    }
    const segments = (this.args.model.get("filter") || "").split("/");
    let tab = segments[segments.length - 1];
    if (tab !== "new" && tab !== "unread") {
      return;
    }
    if (tab === "new" && this.currentUser.new_new_view_enabled) {
      tab = "new_new";
    }
    return I18n.t("topics.none.educate." + tab, {
      userPrefsUrl: userPath(`${this.currentUser.get("username_lower")}/preferences/tracking`)
    });
  }
  get renderNewListHeaderControls() {
    return this.site.mobileView && this.showTopicsAndRepliesToggle && !this.args.bulkSelectEnabled;
  }
  get expandGloballyPinned() {
    return !this.expandAllPinned;
  }
  get expandAllPinned() {
    return this.args.tag || this.args.category;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "composer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "topicTrackingState", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "loadingNew", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "showInserted", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showInserted"), _class.prototype)), _class);
export { DiscoveryTopics as default };
setComponentTemplate(TEMPLATE, DiscoveryTopics);