import { template as compiler } from "discourse-common/lib/raw-handlebars";
import { addRawTemplate } from "discourse-common/lib/raw-templates";
let template = compiler({
  "1": function (container, depth0, helpers, partials, data) {
    var alias1 = depth0 != null ? depth0 : container.nullContext || {},
      alias2 = container.escapeExpression,
      lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
    return "    <div class=\"participant-group\">\n      <a\n        class=\"user-group trigger-group-card\"\n        href=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "group.url", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 10,
          "column": 14
        },
        "end": {
          "line": 10,
          "column": 27
        }
      }
    })) + "\"\n        data-group-card=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "group.name", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 11,
          "column": 25
        },
        "end": {
          "line": 11,
          "column": 39
        }
      }
    })) + "\"\n      >\n        " + alias2((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || container.hooks.helperMissing).call(alias1, "users", {
      "name": "d-icon",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["StringLiteral"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 13,
          "column": 8
        },
        "end": {
          "line": 13,
          "column": 26
        }
      }
    })) + "\n        " + alias2(lookupProperty(helpers, "get").call(alias1, "group.name", {
      "name": "get",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["PathExpression"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 14,
          "column": 8
        },
        "end": {
          "line": 14,
          "column": 22
        }
      }
    })) + "\n      </a>\n    </div>\n";
  },
  "compiler": [8, ">= 4.3.0"],
  "main": function (container, depth0, helpers, partials, data) {
    var stack1,
      alias1 = depth0 != null ? depth0 : container.nullContext || {},
      lookupProperty = container.lookupProperty || function (parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined;
      };
    return "<div\n  class=\"participant-group-wrapper\"\n  role=\"list\"\n  aria-label=\"" + container.escapeExpression((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || container.hooks.helperMissing).call(alias1, "topic.participant_groups", {
      "name": "i18n",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "types": ["StringLiteral"],
      "contexts": [depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 4,
          "column": 14
        },
        "end": {
          "line": 4,
          "column": 49
        }
      }
    })) + "\"\n>\n" + ((stack1 = lookupProperty(helpers, "each").call(alias1, "group", "in", "groups", {
      "name": "each",
      "hash": {},
      "hashTypes": {},
      "hashContexts": {},
      "fn": container.program(1, data, 0),
      "inverse": container.noop,
      "types": ["PathExpression", "CommentStatement", "PathExpression"],
      "contexts": [depth0, depth0, depth0],
      "data": data,
      "loc": {
        "start": {
          "line": 6,
          "column": 2
        },
        "end": {
          "line": 17,
          "column": 11
        }
      }
    })) != null ? stack1 : "") + "</div>";
  },
  "useData": true
});
addRawTemplate("list/participant-groups", template, {
  core: true
});
export default template;