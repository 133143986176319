import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="control-group form-template-field" data-field-type="checkbox">
  <label class="form-template-field__label">
    <Input
      name={{@id}}
      class="form-template-field__checkbox"
      @checked={{@value}}
      @type="checkbox"
      required={{if @validations.required "required" ""}}
    />
    {{@attributes.label}}
    {{#if @validations.required}}
      {{d-icon "asterisk" class="form-template-field__required-indicator"}}
    {{/if}}
  </label>

  {{#if @attributes.description}}
    <span class="form-template-field__description">
      {{html-safe @attributes.description}}
    </span>
  {{/if}}
</div>
*/
{
  "id": "GQeqc3ut",
  "block": "[[[10,0],[14,0,\"control-group form-template-field\"],[14,\"data-field-type\",\"checkbox\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"form-template-field__label\"],[12],[1,\"\\n    \"],[8,[39,0],[[16,3,[30,1]],[24,0,\"form-template-field__checkbox\"],[16,\"required\",[52,[30,2,[\"required\"]],\"required\",\"\"]]],[[\"@checked\",\"@type\"],[[30,3],\"checkbox\"]],null],[1,\"\\n    \"],[1,[30,4,[\"label\"]]],[1,\"\\n\"],[41,[30,2,[\"required\"]],[[[1,\"      \"],[1,[28,[35,2],[\"asterisk\"],[[\"class\"],[\"form-template-field__required-indicator\"]]]],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\\n\"],[41,[30,4,[\"description\"]],[[[1,\"    \"],[10,1],[14,0,\"form-template-field__description\"],[12],[1,\"\\n      \"],[1,[28,[35,3],[[30,4,[\"description\"]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@id\",\"@validations\",\"@value\",\"@attributes\"],false,[\"input\",\"if\",\"d-icon\",\"html-safe\"]]",
  "moduleName": "discourse/components/form-template-field/checkbox.hbs",
  "isStrictMode": false
});