import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#each @stream.content as |item|}}
  <UserStreamItem
    @item={{item}}
    @removeBookmark={{action "removeBookmark"}}
    @resumeDraft={{action "resumeDraft"}}
    @removeDraft={{action "removeDraft"}}
  />
{{/each}}
*/
{
  "id": "3ZQh1mmf",
  "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,1,[\"content\"]]],null]],null],null,[[[1,\"  \"],[8,[39,2],null,[[\"@item\",\"@removeBookmark\",\"@resumeDraft\",\"@removeDraft\"],[[30,2],[28,[37,3],[[30,0],\"removeBookmark\"],null],[28,[37,3],[[30,0],\"resumeDraft\"],null],[28,[37,3],[[30,0],\"removeDraft\"],null]]],null],[1,\"\\n\"]],[2]],null]],[\"@stream\",\"item\"],false,[\"each\",\"-track-array\",\"user-stream-item\",\"action\"]]",
  "moduleName": "discourse/components/user-stream.hbs",
  "isStrictMode": false
});