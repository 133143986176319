import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './navigation-bar.hbs'; */
import { tracked } from "@glimmer/tracking";
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { dependentKeyCompat } from "@ember/object/compat";
import { next } from "@ember/runloop";
import $ from "jquery";
import { filterTypeForMode } from "discourse/lib/filter-mode";
import DiscourseURL from "discourse/lib/url";
import discourseComputed, { observes } from "discourse-common/utils/decorators";
import TEMPLATE from "./navigation-bar.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("filterType", "navItems"), _dec2 = observes("expanded"), (_obj = {
  tagName: "ul",
  classNameBindings: [":nav", ":nav-pills"],
  elementId: "navigation-bar",
  filterMode: tracked(),
  get filterType() {
    return filterTypeForMode(this.filterMode);
  },
  init() {
    this._super(...arguments);
  },
  selectedNavItem(filterType, navItems) {
    let item = navItems.find(i => i.active === true);
    item = item || navItems.find(i => i.get("filterType") === filterType);
    if (!item) {
      let connectors = this.connectors;
      let category = this.category;
      if (connectors && category) {
        connectors.forEach(c => {
          if (c.connectorClass && typeof c.connectorClass.path === "function" && typeof c.connectorClass.displayName === "function") {
            let path = c.connectorClass.path(category);
            if (path.indexOf(filterType) > 0) {
              item = {
                displayName: c.connectorClass.displayName()
              };
            }
          }
        });
      }
    }
    return item || navItems[0];
  },
  closedNav() {
    if (!this.expanded) {
      this.ensureDropClosed();
    }
  },
  ensureDropClosed() {
    if (!this.element || this.isDestroying || this.isDestroyed) {
      return;
    }
    if (this.expanded) {
      this.set("expanded", false);
    }
    $(window).off("click.navigation-bar");
    DiscourseURL.appEvents.off("dom:clean", this, this.ensureDropClosed);
  },
  toggleDrop(event) {
    event?.preventDefault();
    this.set("expanded", !this.expanded);
    if (this.expanded) {
      DiscourseURL.appEvents.on("dom:clean", this, this.ensureDropClosed);
      next(() => {
        if (!this.expanded) {
          return;
        }
        $(this.element.querySelector(".drop a")).on("click", () => {
          this.element.querySelector(".drop").style.display = "none";
          next(() => {
            this.ensureDropClosed();
          });
          return true;
        });
        $(window).on("click.navigation-bar", () => {
          this.ensureDropClosed();
          return true;
        });
      });
    }
  }
}, (_applyDecoratedDescriptor(_obj, "filterType", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_obj, "filterType"), _obj), _applyDecoratedDescriptor(_obj, "selectedNavItem", [_dec], Object.getOwnPropertyDescriptor(_obj, "selectedNavItem"), _obj), _applyDecoratedDescriptor(_obj, "closedNav", [_dec2], Object.getOwnPropertyDescriptor(_obj, "closedNav"), _obj), _applyDecoratedDescriptor(_obj, "toggleDrop", [action], Object.getOwnPropertyDescriptor(_obj, "toggleDrop"), _obj)), _obj))));