import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <input
  class="hidden-upload-field"
  disabled={{this.uploading}}
  type="file"
  accept="image/*"
  aria-hidden="true"
/>
<DButton
  @translatedLabel={{this.uploadLabel}}
  @icon="far-image"
  @disabled={{this.uploading}}
  @action={{this.chooseImage}}
  @title="user.change_avatar.upload_title"
  class="btn-default avatar-uploader__button"
  data-uploaded={{this.customAvatarUploaded}}
  data-avatar-upload-id={{this.uploadedAvatarId}}
/>

{{#if this.imageIsNotASquare}}
  <div class="warning">{{i18n "user.change_avatar.image_is_not_a_square"}}</div>
{{/if}}
*/
{
  "id": "sn8+QVhZ",
  "block": "[[[10,\"input\"],[14,0,\"hidden-upload-field\"],[15,\"disabled\",[30,0,[\"uploading\"]]],[14,\"accept\",\"image/*\"],[14,\"aria-hidden\",\"true\"],[14,4,\"file\"],[12],[13],[1,\"\\n\"],[8,[39,0],[[24,0,\"btn-default avatar-uploader__button\"],[16,\"data-uploaded\",[30,0,[\"customAvatarUploaded\"]]],[16,\"data-avatar-upload-id\",[30,0,[\"uploadedAvatarId\"]]]],[[\"@translatedLabel\",\"@icon\",\"@disabled\",\"@action\",\"@title\"],[[30,0,[\"uploadLabel\"]],\"far-image\",[30,0,[\"uploading\"]],[30,0,[\"chooseImage\"]],\"user.change_avatar.upload_title\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"imageIsNotASquare\"]],[[[1,\"  \"],[10,0],[14,0,\"warning\"],[12],[1,[28,[35,2],[\"user.change_avatar.image_is_not_a_square\"],null]],[13],[1,\"\\n\"]],[]],null]],[],false,[\"d-button\",\"if\",\"i18n\"]]",
  "moduleName": "discourse/components/avatar-uploader.hbs",
  "isStrictMode": false
});