import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
import { action } from "@ember/object";
import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default DiscourseRoute.extend((_obj = {
  router: service(),
  titleToken() {
    return I18n.t("groups.messages");
  },
  model() {
    return this.modelFor("group");
  },
  afterModel(group) {
    if (!group.get("is_group_user") && !(this.currentUser && this.currentUser.admin)) {
      this.router.transitionTo("group.members", group);
    }
  },
  triggerRefresh() {
    this.refresh();
  }
}, (_applyDecoratedDescriptor(_obj, "triggerRefresh", [action], Object.getOwnPropertyDescriptor(_obj, "triggerRefresh"), _obj)), _obj));