import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.item.truncated}}
  <a
    href
    class={{if this.expanded "collapse-item" "expand-item"}}
    onclick={{action "toggleItem"}}
    title={{i18n "post.expand_collapse"}}
  >
    {{#if this.expanded}}
      {{d-icon "chevron-up"}}
    {{else}}
      {{d-icon "chevron-down"}}
    {{/if}}
  </a>
{{/if}}
*/
{
  "id": "ySzYa6KQ",
  "block": "[[[41,[30,0,[\"item\",\"truncated\"]],[[[1,\"  \"],[10,3],[14,6,\"\"],[15,0,[52,[30,0,[\"expanded\"]],\"collapse-item\",\"expand-item\"]],[15,\"onclick\",[28,[37,1],[[30,0],\"toggleItem\"],null]],[15,\"title\",[28,[37,2],[\"post.expand_collapse\"],null]],[12],[1,\"\\n\"],[41,[30,0,[\"expanded\"]],[[[1,\"      \"],[1,[28,[35,3],[\"chevron-up\"],null]],[1,\"\\n\"]],[]],[[[1,\"      \"],[1,[28,[35,3],[\"chevron-down\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"action\",\"i18n\",\"d-icon\"]]",
  "moduleName": "discourse/components/expand-post.hbs",
  "isStrictMode": false
});