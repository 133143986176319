import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { service } from "@ember/service";
import BaseSectionLink from "discourse/lib/sidebar/base-community-section-link";
import I18n from "discourse-i18n";
let AdminRevampSectionLink = (_class = class AdminRevampSectionLink extends BaseSectionLink {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
  }
  get name() {
    return "admin-revamp";
  }
  get route() {
    return "admin-revamp";
  }
  get title() {
    return I18n.t("sidebar.sections.community.links.admin.content");
  }
  get text() {
    return I18n.t(`sidebar.sections.community.links.${this.overridenName.toLowerCase()}.content`, {
      defaultValue: this.overridenName
    });
  }
  get shouldDisplay() {
    if (!this.currentUser) {
      return false;
    }
    return this.currentUser.use_admin_sidebar;
  }
  get defaultPrefixValue() {
    return "star";
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { AdminRevampSectionLink as default };