import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <ComposerActions
  @composerModel={{this.model}}
  @replyOptions={{this.model.replyOptions}}
  @canWhisper={{this.canWhisper}}
  @action={{this.model.action}}
  @tabindex={{this.tabindex}}
  @topic={{this.model.topic}}
  @post={{this.model.post}}
  @whisper={{this.model.whisper}}
  @noBump={{this.model.noBump}}
  @options={{hash mobilePlacementStrategy="fixed"}}
/>

<span class="action-title">
  {{this.actionTitle}}
</span>
*/
{
  "id": "m8ynof3v",
  "block": "[[[8,[39,0],null,[[\"@composerModel\",\"@replyOptions\",\"@canWhisper\",\"@action\",\"@tabindex\",\"@topic\",\"@post\",\"@whisper\",\"@noBump\",\"@options\"],[[30,0,[\"model\"]],[30,0,[\"model\",\"replyOptions\"]],[30,0,[\"canWhisper\"]],[30,0,[\"model\",\"action\"]],[30,0,[\"tabindex\"]],[30,0,[\"model\",\"topic\"]],[30,0,[\"model\",\"post\"]],[30,0,[\"model\",\"whisper\"]],[30,0,[\"model\",\"noBump\"]],[28,[37,1],null,[[\"mobilePlacementStrategy\"],[\"fixed\"]]]]],null],[1,\"\\n\\n\"],[10,1],[14,0,\"action-title\"],[12],[1,\"\\n  \"],[1,[30,0,[\"actionTitle\"]]],[1,\"\\n\"],[13]],[],false,[\"composer-actions\",\"hash\"]]",
  "moduleName": "discourse/components/composer-action-title.hbs",
  "isStrictMode": false
});