import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{avatar
  @result
  imageSize="small"
  template=@result.avatar_template
  username=@result.username
}}
<div class="user-titles">
  {{#if (and @displayNameWithUser @result.name)}}
    <span class="name">{{@result.name}}</span>
  {{/if}}
  <span class="username">
    {{format-username @result.username}}
  </span>
  {{#if @result.custom_data}}
    {{#each @result.custom_data as |row|}}
      <span class="custom-field">{{row.name}}: {{row.value}}</span>
    {{/each}}
  {{/if}}
</div>
*/
{
  "id": "T+jeEPjf",
  "block": "[[[1,[28,[35,0],[[30,1]],[[\"imageSize\",\"template\",\"username\"],[\"small\",[30,1,[\"avatar_template\"]],[30,1,[\"username\"]]]]]],[1,\"\\n\"],[10,0],[14,0,\"user-titles\"],[12],[1,\"\\n\"],[41,[28,[37,2],[[30,2],[30,1,[\"name\"]]],null],[[[1,\"    \"],[10,1],[14,0,\"name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,1],[14,0,\"username\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[[30,1,[\"username\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[41,[30,1,[\"custom_data\"]],[[[42,[28,[37,5],[[28,[37,5],[[30,1,[\"custom_data\"]]],null]],null],null,[[[1,\"      \"],[10,1],[14,0,\"custom-field\"],[12],[1,[30,3,[\"name\"]]],[1,\": \"],[1,[30,3,[\"value\"]]],[13],[1,\"\\n\"]],[3]],null]],[]],null],[13]],[\"@result\",\"@displayNameWithUser\",\"row\"],false,[\"avatar\",\"if\",\"and\",\"format-username\",\"each\",\"-track-array\"]]",
  "moduleName": "discourse/components/search-menu/results/type/user.hbs",
  "isStrictMode": false
});