import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
/* import __COLOCATED_TEMPLATE__ from './feature-topic.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import EmberObject, { action } from "@ember/object";
import { service } from "@ember/service";
import { categoryLinkHTML } from "discourse/helpers/category-link";
import { ajax } from "discourse/lib/ajax";
import I18n from "discourse-i18n";
import TEMPLATE from "./feature-topic.hbs";
import { setComponentTemplate } from "@ember/component";
let FeatureTopic = (_class = class FeatureTopic extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "dialog", _descriptor2, this);
    _initializerDefineProperty(this, "loading", _descriptor3, this);
    _initializerDefineProperty(this, "pinnedInCategoryCount", _descriptor4, this);
    _initializerDefineProperty(this, "pinnedGloballyCount", _descriptor5, this);
    _initializerDefineProperty(this, "bannerCount", _descriptor6, this);
    _initializerDefineProperty(this, "pinInCategoryTipShownAt", _descriptor7, this);
    _initializerDefineProperty(this, "pinGloballyTipShownAt", _descriptor8, this);
    this.loadFeatureStats();
  }
  get categoryLink() {
    return categoryLinkHTML(this.args.model.topic.category, {
      allowUncategorized: true
    });
  }
  get unPinMessage() {
    let name = "topic.feature_topic.unpin";
    if (this.args.model.topic.pinned_globally) {
      name += "_globally";
    }
    if (moment(this.args.model.topic.pinned_until) > moment()) {
      name += "_until";
    }
    const until = moment(this.args.model.topic.pinned_until).format("LL");
    return I18n.t(name, {
      categoryLink: this.categoryLink,
      until
    });
  }
  get canPinGlobally() {
    return this.currentUser.canManageTopic && this.args.model.topic.details.can_pin_unpin_topic;
  }
  get pinMessage() {
    return I18n.t("topic.feature_topic.pin", {
      categoryLink: this.categoryLink
    });
  }
  get alreadyPinnedMessage() {
    const key = this.pinnedInCategoryCount === 0 ? "topic.feature_topic.not_pinned" : "topic.feature_topic.already_pinned";
    return I18n.t(key, {
      categoryLink: this.categoryLink,
      count: this.pinnedInCategoryCount
    });
  }
  get pinDisabled() {
    return !this._isDateValid(this.parsedPinnedInCategoryUntil);
  }
  get pinGloballyDisabled() {
    return !this._isDateValid(this.parsedPinnedGloballyUntil);
  }
  get parsedPinnedInCategoryUntil() {
    return this._parseDate(this.args.model.topic.pinnedInCategoryUntil);
  }
  get parsedPinnedGloballyUntil() {
    return this._parseDate(this.args.model.topic.pinnedGloballyUntil);
  }
  get pinInCategoryValidation() {
    if (this.pinDisabled) {
      return EmberObject.create({
        failed: true,
        reason: I18n.t("topic.feature_topic.pin_validation")
      });
    }
  }
  get pinGloballyValidation() {
    if (this.pinGloballyDisabled) {
      return EmberObject.create({
        failed: true,
        reason: I18n.t("topic.feature_topic.pin_validation")
      });
    }
  }
  _parseDate(date) {
    return moment(date, ["YYYY-MM-DD", "YYYY-MM-DD HH:mm"]);
  }
  _isDateValid(parsedDate) {
    return parsedDate.isValid() && parsedDate > moment();
  }
  async loadFeatureStats() {
    try {
      this.loading = true;
      const result = await ajax("/topics/feature_stats.json", {
        data: {
          category_id: this.args.model.topic.category.id
        }
      });
      if (result) {
        this.pinnedInCategoryCount = result.pinned_in_category_count;
        this.pinnedGloballyCount = result.pinned_globally_count;
        this.bannerCount = result.banner_count;
      }
    } finally {
      this.loading = false;
    }
  }
  async _confirmBeforePinningGlobally() {
    if (this.pinnedGloballyCount < 4) {
      this.args.model.pinGlobally();
      this.args.closeModal();
    } else {
      this.dialog.yesNoConfirm({
        message: I18n.t("topic.feature_topic.confirm_pin_globally", {
          count: this.pinnedGloballyCount
        }),
        didConfirm: () => {
          this.args.model.pinGlobally();
          this.args.closeModal();
        }
      });
    }
  }
  pin() {
    if (this.pinDisabled) {
      this.pinInCategoryTipShownAt = Date.now();
    } else {
      this.args.model.togglePinned();
      this.args.closeModal();
    }
  }
  pinGlobally() {
    if (this.pinGloballyDisabled) {
      this.pinGloballyTipShownAt = Date.now();
    } else {
      this._confirmBeforePinningGlobally();
    }
  }
  unpin() {
    this.args.model.togglePinned();
    this.args.closeModal();
  }
  makeBanner() {
    this.args.model.makeBanner();
    this.args.closeModal();
  }
  removeBanner() {
    this.args.model.removeBanner();
    this.args.closeModal();
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "pinnedInCategoryCount", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "pinnedGloballyCount", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "bannerCount", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "pinInCategoryTipShownAt", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "pinGloballyTipShownAt", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "loadFeatureStats", [action], Object.getOwnPropertyDescriptor(_class.prototype, "loadFeatureStats"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pin", [action], Object.getOwnPropertyDescriptor(_class.prototype, "pin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "pinGlobally", [action], Object.getOwnPropertyDescriptor(_class.prototype, "pinGlobally"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "unpin", [action], Object.getOwnPropertyDescriptor(_class.prototype, "unpin"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "makeBanner", [action], Object.getOwnPropertyDescriptor(_class.prototype, "makeBanner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeBanner", [action], Object.getOwnPropertyDescriptor(_class.prototype, "removeBanner"), _class.prototype)), _class);
export { FeatureTopic as default };
setComponentTemplate(TEMPLATE, FeatureTopic);