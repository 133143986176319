import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.title}}
  <h3>{{this.title}}</h3>
{{/if}}
{{#if this.category}}
  <CategoryTitleLink @category={{this.category}} />
{{/if}}
{{#if this.tagGroupName}}
  <h3>{{this.tagGroupName}}</h3>
{{/if}}
{{#each this.sortedTags as |tag|}}
  <div class="tag-box">
    {{discourse-tag
      tag.id
      description=tag.description
      isPrivateMessage=this.isPrivateMessage
      pmOnly=tag.pmOnly
      tagsForUser=this.tagsForUser
    }}
    {{#if tag.pmOnly}}
      {{d-icon "envelope"}}
    {{/if}}
    {{#if tag.totalCount}}
      <span class="tag-count">
        x
        {{tag.totalCount}}
      </span>
    {{/if}}
  </div>
{{/each}}
<div class="clearfix"></div>
*/
{
  "id": "8u9V0Lt0",
  "block": "[[[41,[30,0,[\"title\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,[30,0,[\"title\"]]],[13],[1,\"\\n\"]],[]],null],[41,[30,0,[\"category\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@category\"],[[30,0,[\"category\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"tagGroupName\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,[30,0,[\"tagGroupName\"]]],[13],[1,\"\\n\"]],[]],null],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"sortedTags\"]]],null]],null],null,[[[1,\"  \"],[10,0],[14,0,\"tag-box\"],[12],[1,\"\\n    \"],[1,[28,[35,4],[[30,1,[\"id\"]]],[[\"description\",\"isPrivateMessage\",\"pmOnly\",\"tagsForUser\"],[[30,1,[\"description\"]],[30,0,[\"isPrivateMessage\"]],[30,1,[\"pmOnly\"]],[30,0,[\"tagsForUser\"]]]]]],[1,\"\\n\"],[41,[30,1,[\"pmOnly\"]],[[[1,\"      \"],[1,[28,[35,5],[\"envelope\"],null]],[1,\"\\n\"]],[]],null],[41,[30,1,[\"totalCount\"]],[[[1,\"      \"],[10,1],[14,0,\"tag-count\"],[12],[1,\"\\n        x\\n        \"],[1,[30,1,[\"totalCount\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"]],[1]],null],[10,0],[14,0,\"clearfix\"],[12],[13]],[\"tag\"],false,[\"if\",\"category-title-link\",\"each\",\"-track-array\",\"discourse-tag\",\"d-icon\"]]",
  "moduleName": "discourse/components/tag-list.hbs",
  "isStrictMode": false
});