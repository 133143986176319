import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{html-safe this.translatedText}}
*/
{
  "id": "DQBQg4Aa",
  "block": "[[[1,[28,[35,0],[[30,0,[\"translatedText\"]]],null]]],[],false,[\"html-safe\"]]",
  "moduleName": "discourse/components/discourse-linked-text.hbs",
  "isStrictMode": false
});