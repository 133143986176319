import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <span class="group-name">
  <span class="group-name-label">{{this.group_name}}</span>
  <a class="remove-permission" href {{on "click" this.removeRow}}>
    {{d-icon "far-trash-alt"}}
  </a>
</span>
<span class="options actionable">
  <DButton @icon="check-square" @disabled={{true}} class="btn-flat see" />

  <DButton
    @icon={{this.canReplyIcon}}
    @action={{action "setPermissionReply"}}
    @translatedTitle={{this.replyTooltip}}
    @disabled={{this.replyDisabled}}
    class={{concat-class "btn btn-flat reply-toggle" this.replyGranted}}
  />

  <DButton
    @icon={{this.canCreateIcon}}
    @action={{action "setPermissionFull"}}
    @translatedTitle={{this.createTooltip}}
    @disabled={{this.createDisabled}}
    class={{concat-class "btn-flat create-toggle" this.createGranted}}
  />
</span>
*/
{
  "id": "xUTUXgAt",
  "block": "[[[10,1],[14,0,\"group-name\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"group-name-label\"],[12],[1,[30,0,[\"group_name\"]]],[13],[1,\"\\n  \"],[11,3],[24,0,\"remove-permission\"],[24,6,\"\"],[4,[38,0],[\"click\",[30,0,[\"removeRow\"]]],null],[12],[1,\"\\n    \"],[1,[28,[35,1],[\"far-trash-alt\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,1],[14,0,\"options actionable\"],[12],[1,\"\\n  \"],[8,[39,2],[[24,0,\"btn-flat see\"]],[[\"@icon\",\"@disabled\"],[\"check-square\",true]],null],[1,\"\\n\\n  \"],[8,[39,2],[[16,0,[28,[37,3],[\"btn btn-flat reply-toggle\",[30,0,[\"replyGranted\"]]],null]]],[[\"@icon\",\"@action\",\"@translatedTitle\",\"@disabled\"],[[30,0,[\"canReplyIcon\"]],[28,[37,4],[[30,0],\"setPermissionReply\"],null],[30,0,[\"replyTooltip\"]],[30,0,[\"replyDisabled\"]]]],null],[1,\"\\n\\n  \"],[8,[39,2],[[16,0,[28,[37,3],[\"btn-flat create-toggle\",[30,0,[\"createGranted\"]]],null]]],[[\"@icon\",\"@action\",\"@translatedTitle\",\"@disabled\"],[[30,0,[\"canCreateIcon\"]],[28,[37,4],[[30,0],\"setPermissionFull\"],null],[30,0,[\"createTooltip\"]],[30,0,[\"createDisabled\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"on\",\"d-icon\",\"d-button\",\"concat-class\",\"action\"]]",
  "moduleName": "discourse/components/category-permission-row.hbs",
  "isStrictMode": false
});