import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="empty-state-container">
  <div class="empty-state">
    <span data-test-title class="empty-state-title">{{@title}}</span>
    <div class="empty-state-body">
      <p data-test-body>{{@body}}</p>
    </div>
  </div>
</div>
*/
{
  "id": "JYBJpchh",
  "block": "[[[10,0],[14,0,\"empty-state-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"empty-state\"],[12],[1,\"\\n    \"],[10,1],[14,0,\"empty-state-title\"],[12],[1,[30,1]],[13],[1,\"\\n    \"],[10,0],[14,0,\"empty-state-body\"],[12],[1,\"\\n      \"],[10,2],[12],[1,[30,2]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@title\",\"@body\"],false,[]]",
  "moduleName": "discourse/components/empty-state.hbs",
  "isStrictMode": false
});