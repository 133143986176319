define("discourse/plugins/docker_manager/discourse/routes/update-processes", ["exports", "@ember/routing/route", "@ember/runloop", "discourse-common/lib/later", "discourse-common/utils/decorators", "discourse/plugins/docker_manager/discourse/models/process-list"], function (_exports, _route, _runloop, _later, _decorators, _processList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  const REFRESH_INTERVAL = 5000;
  let UpgradeProcesses = _exports.default = (_class = class UpgradeProcesses extends _route.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "processes", null);
      _defineProperty(this, "refreshTimer", null);
      _defineProperty(this, "autoRefresh", false);
    }
    model() {
      this.processes = new _processList.default();
      this.autoRefresh = true;
      this.refresh();
      return this.processes;
    }
    deactivate() {
      this.autoRefresh = false;
    }
    async refresh() {
      if (this.autoRefresh) {
        await this.processes.refresh();
        this.refreshTimer = (0, _later.default)(this.refresh, REFRESH_INTERVAL);
      } else {
        (0, _runloop.cancel)(this.refreshTimer);
      }
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "refresh", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "refresh"), _class.prototype)), _class);
});