import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="future-date-input">
  <div class="control-group">
    <label class={{this.labelClasses}}>
      {{#if this.displayLabelIcon}}{{d-icon
          this.displayLabelIcon
        }}{{/if}}{{this.displayLabel}}
    </label>
    <FutureDateInputSelector
      @value={{readonly this.selection}}
      @content={{this.shortcuts}}
      @clearable={{this.clearable}}
      @onChangeInput={{this.onChangeInput}}
      @onChange={{fn (mut this.selection)}}
      @options={{hash none="time_shortcut.select_timeframe"}}
    />
  </div>

  {{#if this.displayDateAndTimePicker}}
    <div class="control-group future-date-input-date-picker">
      {{d-icon "calendar-alt"}}
      <DatePickerFuture
        @value={{this._date}}
        @defaultDate={{this._date}}
        @onSelect={{action "onChangeDate"}}
      />
    </div>

    <div class="control-group future-date-input-time-picker">
      {{d-icon "far-clock"}}
      <Input
        placeholder="--:--"
        @type="time"
        class="time-input"
        @value={{this._time}}
        disabled={{this.timeInputDisabled}}
        {{on "input" (with-event-value this.onChangeTime)}}
      />
    </div>
  {{/if}}
</div>
*/
{
  "id": "FWRU/lpO",
  "block": "[[[10,0],[14,0,\"future-date-input\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[15,0,[30,0,[\"labelClasses\"]]],[12],[1,\"\\n      \"],[41,[30,0,[\"displayLabelIcon\"]],[[[1,[28,[35,1],[[30,0,[\"displayLabelIcon\"]]],null]]],[]],null],[1,[30,0,[\"displayLabel\"]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@content\",\"@clearable\",\"@onChangeInput\",\"@onChange\",\"@options\"],[[28,[37,3],[[30,0,[\"selection\"]]],null],[30,0,[\"shortcuts\"]],[30,0,[\"clearable\"]],[30,0,[\"onChangeInput\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"selection\"]]],null]],null],[28,[37,6],null,[[\"none\"],[\"time_shortcut.select_timeframe\"]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"displayDateAndTimePicker\"]],[[[1,\"    \"],[10,0],[14,0,\"control-group future-date-input-date-picker\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"calendar-alt\"],null]],[1,\"\\n      \"],[8,[39,7],null,[[\"@value\",\"@defaultDate\",\"@onSelect\"],[[30,0,[\"_date\"]],[30,0,[\"_date\"]],[28,[37,8],[[30,0],\"onChangeDate\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"control-group future-date-input-time-picker\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"far-clock\"],null]],[1,\"\\n      \"],[8,[39,9],[[24,\"placeholder\",\"--:--\"],[24,0,\"time-input\"],[16,\"disabled\",[30,0,[\"timeInputDisabled\"]]],[4,[38,10],[\"input\",[28,[37,11],[[30,0,[\"onChangeTime\"]]],null]],null]],[[\"@type\",\"@value\"],[\"time\",[30,0,[\"_time\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"if\",\"d-icon\",\"future-date-input-selector\",\"readonly\",\"fn\",\"mut\",\"hash\",\"date-picker-future\",\"action\",\"input\",\"on\",\"with-event-value\"]]",
  "moduleName": "discourse/components/future-date-input.hbs",
  "isStrictMode": false
});