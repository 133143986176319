import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj, _init;
/* import __COLOCATED_TEMPLATE__ from './pwa-install-banner.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed, { bind, on } from "discourse-common/utils/decorators";
import TEMPLATE from "./pwa-install-banner.hbs";
const USER_DISMISSED_PROMPT_KEY = "dismissed-pwa-install-banner";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = on("didInsertElement"), _dec2 = on("willDestroyElement"), _dec3 = discourseComputed("deferredInstallPromptEvent", "bannerDismissed"), (_obj = {
  deferredInstallPromptEvent: null,
  _onInstallPrompt(event) {
    // Prevent Chrome 76+ from automatically showing the prompt
    event.preventDefault();
    // Stash the event so it can be triggered later
    this.set("deferredInstallPromptEvent", event);
  },
  _registerListener() {
    window.addEventListener("beforeinstallprompt", this._onInstallPrompt);
  },
  _unregisterListener() {
    window.removeEventListener("beforeinstallprompt", this._onInstallPrompt);
  },
  bannerDismissed: {
    set(value) {
      this.keyValueStore.set({
        key: USER_DISMISSED_PROMPT_KEY,
        value
      });
      return this.keyValueStore.get(USER_DISMISSED_PROMPT_KEY);
    },
    get() {
      return this.keyValueStore.get(USER_DISMISSED_PROMPT_KEY);
    }
  },
  showPWAInstallBanner(deferredInstallPromptEvent, bannerDismissed) {
    return this.capabilities.isAndroid && this.get("currentUser.trust_level") > 0 && deferredInstallPromptEvent &&
    // Pass the browser engagement checks
    !window.matchMedia("(display-mode: standalone)").matches &&
    // Not be in the installed PWA already
    !this.capabilities.isAppWebview &&
    // not launched via official app
    !bannerDismissed // Have not a previously dismissed install banner
    ;
  },
  actions: {
    turnOn() {
      this.set("bannerDismissed", true);
      this.deferredInstallPromptEvent.prompt();
    },
    dismiss() {
      this.set("bannerDismissed", true);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "_onInstallPrompt", [bind], Object.getOwnPropertyDescriptor(_obj, "_onInstallPrompt"), _obj), _applyDecoratedDescriptor(_obj, "_registerListener", [_dec], Object.getOwnPropertyDescriptor(_obj, "_registerListener"), _obj), _applyDecoratedDescriptor(_obj, "_unregisterListener", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_unregisterListener"), _obj), _applyDecoratedDescriptor(_obj, "bannerDismissed", [discourseComputed], (_init = Object.getOwnPropertyDescriptor(_obj, "bannerDismissed"), _init = _init ? _init.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "showPWAInstallBanner", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showPWAInstallBanner"), _obj)), _obj))));