import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj, _init;
import Controller from "@ember/controller";
import { service } from "@ember/service";
import { resendActivationEmail } from "discourse/lib/user-activation";
import { wavingHandURL } from "discourse/lib/waving-hand-url";
import getUrl from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend((_obj = {
  router: service(),
  envelopeImageUrl: getUrl("/images/envelope.svg"),
  welcomeTitle() {
    return I18n.t("invites.welcome_to", {
      site_name: this.siteSettings.title
    });
  },
  wavingHandURL: () => wavingHandURL(),
  actions: {
    sendActivationEmail() {
      resendActivationEmail(this.get("accountCreated.username")).then(() => {
        this.router.transitionTo("account-created.resent");
      });
    },
    editActivationEmail() {
      this.router.transitionTo("account-created.edit-email");
    }
  }
}, (_applyDecoratedDescriptor(_obj, "welcomeTitle", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "welcomeTitle"), _obj), _applyDecoratedDescriptor(_obj, "wavingHandURL", [discourseComputed], (_init = Object.getOwnPropertyDescriptor(_obj, "wavingHandURL"), _init = _init ? _init.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init;
  }
}), _obj)), _obj));