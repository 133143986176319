import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './topic-navigation-popup.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./topic-navigation-popup.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("popupId"), (_obj = {
  tagName: "",
  popupId: null,
  hidden: false,
  init() {
    this._super(...arguments);
    if (this.popupKey) {
      const value = this.keyValueStore.getItem(this.popupKey);
      if (value === true || value > +new Date()) {
        this.set("hidden", true);
      } else {
        this.keyValueStore.removeItem(this.popupKey);
      }
    }
  },
  popupKey(popupId) {
    if (popupId) {
      return `dismiss_topic_nav_popup_${popupId}`;
    }
  },
  close() {
    this.set("hidden", true);
    if (this.popupKey) {
      if (this.dismissDuration) {
        const expiry = +new Date() + this.dismissDuration;
        this.keyValueStore.setItem(this.popupKey, expiry);
      } else {
        this.keyValueStore.setItem(this.popupKey, true);
      }
    }
  }
}, (_applyDecoratedDescriptor(_obj, "popupKey", [_dec], Object.getOwnPropertyDescriptor(_obj, "popupKey"), _obj), _applyDecoratedDescriptor(_obj, "close", [action], Object.getOwnPropertyDescriptor(_obj, "close"), _obj)), _obj))));