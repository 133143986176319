import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { INPUT_DELAY } from "discourse-common/config/environment";
import discourseDebounce from "discourse-common/lib/debounce";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend((_dec = discourseComputed("groups.extras.type_filters"), (_obj = {
  router: service(),
  queryParams: ["order", "asc", "filter", "type"],
  order: null,
  asc: null,
  filter: "",
  type: null,
  groups: null,
  isLoading: false,
  types(typeFilters) {
    const types = [];
    if (typeFilters) {
      typeFilters.forEach(type => types.push({
        id: type,
        name: I18n.t(`groups.index.${type}_groups`)
      }));
    }
    return types;
  },
  loadGroups(params) {
    this.set("isLoading", true);
    this.store.findAll("group", params).then(groups => {
      this.set("groups", groups);
    }).finally(() => this.set("isLoading", false));
  },
  onFilterChanged(filter) {
    discourseDebounce(this, this._debouncedFilter, filter, INPUT_DELAY);
  },
  loadMore() {
    this.groups && this.groups.loadMore();
  },
  new() {
    this.router.transitionTo("groups.new");
  },
  _debouncedFilter(filter) {
    this.set("filter", filter);
  }
}, (_applyDecoratedDescriptor(_obj, "types", [_dec], Object.getOwnPropertyDescriptor(_obj, "types"), _obj), _applyDecoratedDescriptor(_obj, "onFilterChanged", [action], Object.getOwnPropertyDescriptor(_obj, "onFilterChanged"), _obj), _applyDecoratedDescriptor(_obj, "loadMore", [action], Object.getOwnPropertyDescriptor(_obj, "loadMore"), _obj), _applyDecoratedDescriptor(_obj, "new", [action], Object.getOwnPropertyDescriptor(_obj, "new"), _obj)), _obj)));