import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{this.tipReason}} {{d-icon "times-circle"}}
*/
{
  "id": "3kGtAn2e",
  "block": "[[[1,[30,0,[\"tipReason\"]]],[1,\" \"],[1,[28,[35,0],[\"times-circle\"],null]]],[],false,[\"d-icon\"]]",
  "moduleName": "discourse/components/popup-input-tip.hbs",
  "isStrictMode": false
});