import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  aria-hidden="true"
  class="d-lightbox__backdrop"
  tabindex="-1"
  {{on "click" @close passive=true capture=true}}
></div>
*/
{
  "id": "VyInGRq1",
  "block": "[[[11,0],[24,\"aria-hidden\",\"true\"],[24,0,\"d-lightbox__backdrop\"],[24,\"tabindex\",\"-1\"],[4,[38,0],[\"click\",[30,1]],[[\"passive\",\"capture\"],[true,true]]],[12],[13]],[\"@close\"],false,[\"on\"]]",
  "moduleName": "discourse/components/d-lightbox/backdrop.hbs",
  "isStrictMode": false
});