let d = window.define;
d("ember-resolver/container-debug-adapter", function () {
  return esc(require("ember-resolver/container-debug-adapter"));
});
d("ember-resolver/features", function () {
  return esc(require("ember-resolver/features"));
});
d("ember-resolver/index", function () {
  return esc(require("ember-resolver/index"));
});
d("ember-resolver/string/cache", function () {
  return esc(require("ember-resolver/string/cache"));
});
d("ember-resolver/string/index", function () {
  return esc(require("ember-resolver/string/index"));
});
d("ember-resolver/utils/class-factory", function () {
  return esc(require("ember-resolver/utils/class-factory"));
});
import "truth-helpers/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "ember-resolver/-embroider-implicit-modules.js";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";