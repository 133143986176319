import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
import Component from "@ember/component";
import { service } from "@ember/service";
import $ from "jquery";
import LoadMore from "discourse/mixins/load-more";
import { observes, on } from "discourse-common/utils/decorators";
export default Component.extend(LoadMore, (_dec = on("didInsertElement"), _dec2 = on("willDestroyElement"), _dec3 = observes("incomingCount"), (_obj = {
  classNames: ["contents"],
  eyelineSelector: ".topic-list-item",
  documentTitle: service(),
  _monitorTrackingState() {
    this.stateChangeCallbackId = this.topicTrackingState.onStateChange(() => this._updateTrackingTopics());
  },
  _removeTrackingStateChangeMonitor() {
    if (this.stateChangeCallbackId) {
      this.topicTrackingState.offStateChange(this.stateChangeCallbackId);
    }
  },
  _updateTrackingTopics() {
    this.topicTrackingState.updateTopics(this.model.topics);
  },
  _updateTitle() {
    this.documentTitle.updateContextCount(this.incomingCount);
  },
  actions: {
    loadMore() {
      var _this = this;
      this.documentTitle.updateContextCount(0);
      this.model.loadMore().then(function () {
        let {
          moreTopicsUrl,
          newTopics
        } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
        if (newTopics && newTopics.length && _this.bulkSelectHelper?.bulkSelectEnabled) {
          _this.bulkSelectHelper.addTopics(newTopics);
        }
        if (moreTopicsUrl && $(window).height() >= $(document).height()) {
          _this.send("loadMore");
        }
      });
    }
  }
}, (_applyDecoratedDescriptor(_obj, "_monitorTrackingState", [_dec], Object.getOwnPropertyDescriptor(_obj, "_monitorTrackingState"), _obj), _applyDecoratedDescriptor(_obj, "_removeTrackingStateChangeMonitor", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_removeTrackingStateChangeMonitor"), _obj), _applyDecoratedDescriptor(_obj, "_updateTitle", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_updateTitle"), _obj)), _obj)));