import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <ReviewableItem @reviewable={{this.reviewable}} />
*/
{
  "id": "8+48Es0D",
  "block": "[[[8,[39,0],null,[[\"@reviewable\"],[[30,0,[\"reviewable\"]]]],null]],[],false,[\"reviewable-item\"]]",
  "moduleName": "discourse/templates/review-show.hbs",
  "isStrictMode": false
});