import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="ignored-user-list-item">
  <span class="ignored-user-name">{{this.item}}</span>
  <DButton
    @action={{fn (action "removeIgnoredUser") this.item}}
    @icon="times"
    class="remove-ignored-user no-text btn-icon"
  />
</div>
*/
{
  "id": "yXL+wP+m",
  "block": "[[[10,0],[14,0,\"ignored-user-list-item\"],[12],[1,\"\\n  \"],[10,1],[14,0,\"ignored-user-name\"],[12],[1,[30,0,[\"item\"]]],[13],[1,\"\\n  \"],[8,[39,0],[[24,0,\"remove-ignored-user no-text btn-icon\"]],[[\"@action\",\"@icon\"],[[28,[37,1],[[28,[37,2],[[30,0],\"removeIgnoredUser\"],null],[30,0,[\"item\"]]],null],\"times\"]],null],[1,\"\\n\"],[13]],[],false,[\"d-button\",\"fn\",\"action\"]]",
  "moduleName": "discourse/components/ignored-user-list-item.hbs",
  "isStrictMode": false
});