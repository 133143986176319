import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="container">
  {{html-safe this.model}}
</div>
*/
{
  "id": "uBV4FEwu",
  "block": "[[[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"model\"]]],null]],[1,\"\\n\"],[13]],[],false,[\"html-safe\"]]",
  "moduleName": "discourse/templates/unknown.hbs",
  "isStrictMode": false
});