import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @closeModal={{@closeModal}}
  @title={{i18n "admin.form_templates.preview_modal.title"}}
  class="form-template-form-preview-modal"
>
  <:body>
    <FormTemplateField::Wrapper @content={{@content}} />
  </:body>
</DModal>
*/
{
  "id": "pchady3e",
  "block": "[[[8,[39,0],[[24,0,\"form-template-form-preview-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"admin.form_templates.preview_modal.title\"],null]]],[[\"body\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@content\"],[[30,2]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@content\"],false,[\"d-modal\",\"i18n\",\"form-template-field/wrapper\"]]",
  "moduleName": "discourse/components/modal/form-template-form-preview.hbs",
  "isStrictMode": false
});