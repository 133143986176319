import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.visible}}
  <div class="row">
    <div id="banner" class={{this.overlay}}>
      <div class="floated-buttons">
        {{#if this.currentUser.staff}}
          <a href={{this.banner.url}} class="btn btn-transparent edit-banner">
            {{d-icon "pencil-alt"}}
            {{#if this.site.desktopView}}
              {{html-safe (i18n "banner.edit")}}
            {{/if}}
          </a>
        {{/if}}

        <DButton
          @icon="times"
          @action={{this.dismiss}}
          @title="banner.close"
          class="btn-transparent close"
        />
      </div>
      <div id="banner-content">
        {{html-safe this.content}}
      </div>
    </div>
  </div>
{{/if}}
*/
{
  "id": "byBzADqx",
  "block": "[[[41,[30,0,[\"visible\"]],[[[1,\"  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,1,\"banner\"],[15,0,[30,0,[\"overlay\"]]],[12],[1,\"\\n      \"],[10,0],[14,0,\"floated-buttons\"],[12],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"          \"],[10,3],[15,6,[30,0,[\"banner\",\"url\"]]],[14,0,\"btn btn-transparent edit-banner\"],[12],[1,\"\\n            \"],[1,[28,[35,1],[\"pencil-alt\"],null]],[1,\"\\n\"],[41,[30,0,[\"site\",\"desktopView\"]],[[[1,\"              \"],[1,[28,[35,2],[[28,[37,3],[\"banner.edit\"],null]],null]],[1,\"\\n\"]],[]],null],[1,\"          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[8,[39,4],[[24,0,\"btn-transparent close\"]],[[\"@icon\",\"@action\",\"@title\"],[\"times\",[30,0,[\"dismiss\"]],\"banner.close\"]],null],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,1,\"banner-content\"],[12],[1,\"\\n        \"],[1,[28,[35,2],[[30,0,[\"content\"]]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-icon\",\"html-safe\",\"i18n\",\"d-button\"]]",
  "moduleName": "discourse/components/discourse-banner.hbs",
  "isStrictMode": false
});