import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{body-class "navigation-filter"}}

<section class="navigation-container">
  <div class="topic-query-filter">
    <div class="topic-query-filter__input">
      {{d-icon "filter" class="topic-query-filter__icon"}}
      <Input
        class="topic-query-filter__filter-term"
        @value={{this.newQueryString}}
        @enter={{action @updateTopicsListQueryParams this.newQueryString}}
        @type="text"
        id="queryStringInput"
        autocomplete="off"
      />
      {{! EXPERIMENTAL OUTLET - don't use because it will be removed soon  }}
      <PluginOutlet
        @name="below-filter-input"
        @outletArgs={{hash
          updateQueryString=this.updateQueryString
          newQueryString=this.newQueryString
        }}
      />
    </div>
    {{#if this.newQueryString}}
      <div class="topic-query-filter__controls">
        <DButton
          @icon="times"
          @action={{this.clearInput}}
          @disabled={{unless this.newQueryString "true"}}
        />

        {{#if this.discoveryFilter.q}}
          <DButton
            @icon={{this.copyIcon}}
            @action={{this.copyQueryString}}
            @disabled={{unless this.newQueryString "true"}}
            class={{this.copyClass}}
          />
        {{/if}}
      </div>
    {{/if}}
  </div>
</section>
*/
{
  "id": "dW6FS8Vl",
  "block": "[[[1,[28,[35,0],[\"navigation-filter\"],null]],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"navigation-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"topic-query-filter\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"topic-query-filter__input\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"filter\"],[[\"class\"],[\"topic-query-filter__icon\"]]]],[1,\"\\n      \"],[8,[39,2],[[24,0,\"topic-query-filter__filter-term\"],[24,1,\"queryStringInput\"],[24,\"autocomplete\",\"off\"]],[[\"@value\",\"@enter\",\"@type\"],[[30,0,[\"newQueryString\"]],[28,[37,3],[[30,0],[30,1],[30,0,[\"newQueryString\"]]],null],\"text\"]],null],[1,\"\\n\"],[1,\"      \"],[8,[39,4],null,[[\"@name\",\"@outletArgs\"],[\"below-filter-input\",[28,[37,5],null,[[\"updateQueryString\",\"newQueryString\"],[[30,0,[\"updateQueryString\"]],[30,0,[\"newQueryString\"]]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"newQueryString\"]],[[[1,\"      \"],[10,0],[14,0,\"topic-query-filter__controls\"],[12],[1,\"\\n        \"],[8,[39,7],null,[[\"@icon\",\"@action\",\"@disabled\"],[\"times\",[30,0,[\"clearInput\"]],[52,[51,[30,0,[\"newQueryString\"]]],\"true\"]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"discoveryFilter\",\"q\"]],[[[1,\"          \"],[8,[39,7],[[16,0,[30,0,[\"copyClass\"]]]],[[\"@icon\",\"@action\",\"@disabled\"],[[30,0,[\"copyIcon\"]],[30,0,[\"copyQueryString\"]],[52,[51,[30,0,[\"newQueryString\"]]],\"true\"]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@updateTopicsListQueryParams\"],false,[\"body-class\",\"d-icon\",\"input\",\"action\",\"plugin-outlet\",\"hash\",\"if\",\"d-button\",\"unless\"]]",
  "moduleName": "discourse/components/discovery/filter-navigation.hbs",
  "isStrictMode": false
});