import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <GroupManageEmailSettings @group={{this.model}} />
*/
{
  "id": "RpCq8iTK",
  "block": "[[[8,[39,0],null,[[\"@group\"],[[30,0,[\"model\"]]]],null]],[],false,[\"group-manage-email-settings\"]]",
  "moduleName": "discourse/templates/group/manage/email.hbs",
  "isStrictMode": false
});