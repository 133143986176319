import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var _class;
import Service from "@ember/service";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import KeyValueStore from "discourse/lib/key-value-store";
const PROXIED_METHODS = Object.getOwnPropertyNames(KeyValueStore.prototype).reject(p => p === "constructor");

/**
 * This is the global key-value-store which is injectable as a service.
 * Alternatively, consumers can use `discourse/lib/key-value-store` directly
 * to create their own namespaced store.
 * */
let KeyValueStoreService = disableImplicitInjections(_class = class KeyValueStoreService extends Service {
  constructor() {
    super(...arguments);
    _defineProperty(this, "_keyValueStore", new KeyValueStore("discourse_"));
    for (const name of PROXIED_METHODS) {
      this[name] = this._keyValueStore[name].bind(this._keyValueStore);
    }
  }
}) || _class;
export { KeyValueStoreService as default };