import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './group-member.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import TEMPLATE from "./group-member.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  classNames: ["item"],
  remove(event) {
    event?.preventDefault();
    this.removeAction(this.member);
  }
}, (_applyDecoratedDescriptor(_obj, "remove", [action], Object.getOwnPropertyDescriptor(_obj, "remove"), _obj)), _obj)));