import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './activation-edit.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { extractError } from "discourse/lib/ajax-error";
import { changeEmail } from "discourse/lib/user-activation";
import ActivationResent from "./activation-resent";
import TEMPLATE from "./activation-edit.hbs";
import { setComponentTemplate } from "@ember/component";
let ActivationEdit = (_class = class ActivationEdit extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "login", _descriptor, this);
    _initializerDefineProperty(this, "modal", _descriptor2, this);
    _initializerDefineProperty(this, "newEmail", _descriptor3, this);
    _initializerDefineProperty(this, "flash", _descriptor4, this);
  }
  get submitDisabled() {
    return this.newEmail === this.args.model.currentEmail;
  }
  async changeEmail() {
    try {
      await changeEmail({
        username: this.login?.loginName,
        password: this.login?.loginPassword,
        email: this.newEmail
      });
      this.modal.show(ActivationResent, {
        model: {
          currentEmail: this.newEmail
        }
      });
    } catch (e) {
      this.flash = extractError(e);
    }
  }
  updateNewEmail(email) {
    this.newEmail = email;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "login", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "newEmail", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.newEmail;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "changeEmail", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changeEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateNewEmail", [action], Object.getOwnPropertyDescriptor(_class.prototype, "updateNewEmail"), _class.prototype)), _class);
export { ActivationEdit as default };
setComponentTemplate(TEMPLATE, ActivationEdit);