import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
import Controller, { inject as controller } from "@ember/controller";
import { alias } from "@ember/object/computed";
import { duration } from "discourse/lib/formatter";
import discourseComputed from "discourse-common/utils/decorators";

// should be kept in sync with 'UserSummary::MAX_BADGES'
const MAX_BADGES = 6;
export default Controller.extend((_dec = discourseComputed("model.badges.length"), _dec2 = discourseComputed("model.time_read"), _dec3 = discourseComputed("model.time_read"), _dec4 = discourseComputed("model.time_read", "model.recent_time_read"), _dec5 = discourseComputed("model.recent_time_read"), _dec6 = discourseComputed("model.recent_time_read"), (_obj = {
  userController: controller("user"),
  user: alias("userController.model"),
  moreBadges(badgesLength) {
    return badgesLength >= MAX_BADGES;
  },
  timeRead(timeReadSeconds) {
    return duration(timeReadSeconds, {
      format: "tiny"
    });
  },
  timeReadMedium(timeReadSeconds) {
    return duration(timeReadSeconds, {
      format: "medium"
    });
  },
  showRecentTimeRead(timeRead, recentTimeRead) {
    return timeRead !== recentTimeRead && recentTimeRead !== 0;
  },
  recentTimeRead(recentTimeReadSeconds) {
    return recentTimeReadSeconds > 0 ? duration(recentTimeReadSeconds, {
      format: "tiny"
    }) : null;
  },
  recentTimeReadMedium(recentTimeReadSeconds) {
    return recentTimeReadSeconds > 0 ? duration(recentTimeReadSeconds, {
      format: "medium"
    }) : null;
  }
}, (_applyDecoratedDescriptor(_obj, "moreBadges", [_dec], Object.getOwnPropertyDescriptor(_obj, "moreBadges"), _obj), _applyDecoratedDescriptor(_obj, "timeRead", [_dec2], Object.getOwnPropertyDescriptor(_obj, "timeRead"), _obj), _applyDecoratedDescriptor(_obj, "timeReadMedium", [_dec3], Object.getOwnPropertyDescriptor(_obj, "timeReadMedium"), _obj), _applyDecoratedDescriptor(_obj, "showRecentTimeRead", [_dec4], Object.getOwnPropertyDescriptor(_obj, "showRecentTimeRead"), _obj), _applyDecoratedDescriptor(_obj, "recentTimeRead", [_dec5], Object.getOwnPropertyDescriptor(_obj, "recentTimeRead"), _obj), _applyDecoratedDescriptor(_obj, "recentTimeReadMedium", [_dec6], Object.getOwnPropertyDescriptor(_obj, "recentTimeReadMedium"), _obj)), _obj)));