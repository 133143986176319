import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj, _init;
import TextField from "discourse/components/text-field";
import { allowOnlyNumericInput } from "discourse/lib/utilities";
import deprecated from "discourse-common/lib/deprecated";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default TextField.extend((_dec = discourseComputed("number"), _dec2 = discourseComputed("placeholderKey"), (_obj = {
  classNameBindings: ["invalid"],
  init() {
    this._super(...arguments);
    deprecated(`NumberField component is deprecated. Use native <input> elements instead.\ne.g. <input {{on "input" (with-event-value (fn (mut this.value)))}} type="number" value={{this.value}} />`, {
      id: "discourse.number-field",
      since: "3.2.0.beta5",
      dropFrom: "3.3.0"
    });
  },
  keyDown: function (event) {
    allowOnlyNumericInput(event, this._minNumber && this._minNumber < 0);
  },
  get _minNumber() {
    if (!this.get("min")) {
      return;
    }
    return parseInt(this.get("min"), 10);
  },
  get _maxNumber() {
    if (!this.get("max")) {
      return;
    }
    return parseInt(this.get("max"), 10);
  },
  value: {
    get(number) {
      return parseInt(number, 10);
    },
    set(value) {
      const num = parseInt(value, 10);
      if (isNaN(num)) {
        this.set("invalid", true);
        return value;
      } else {
        this.set("invalid", false);
        this.set("number", num);
        return num.toString();
      }
    }
  },
  placeholder(key) {
    return key ? I18n.t(key) : "";
  }
}, (_applyDecoratedDescriptor(_obj, "value", [_dec], (_init = Object.getOwnPropertyDescriptor(_obj, "value"), _init = _init ? _init.value : undefined, {
  enumerable: true,
  configurable: true,
  writable: true,
  initializer: function () {
    return _init;
  }
}), _obj), _applyDecoratedDescriptor(_obj, "placeholder", [_dec2], Object.getOwnPropertyDescriptor(_obj, "placeholder"), _obj)), _obj)));