import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{d-icon this.icon translatedTitle=this.title class=this.cssClasses}}
*/
{
  "id": "d+6KRt2O",
  "block": "[[[1,[28,[35,0],[[30,0,[\"icon\"]]],[[\"translatedTitle\",\"class\"],[[30,0,[\"title\"]],[30,0,[\"cssClasses\"]]]]]]],[],false,[\"d-icon\"]]",
  "moduleName": "discourse/components/bookmark-icon.hbs",
  "isStrictMode": false
});