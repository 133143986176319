import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.showFullName}}
  <span class="groups-info-name">{{this.group.full_name}}</span>
{{else}}
  <span class="groups-info-name">{{this.group.displayName}}</span>
{{/if}}
*/
{
  "id": "xvzLkauX",
  "block": "[[[41,[30,0,[\"showFullName\"]],[[[1,\"  \"],[10,1],[14,0,\"groups-info-name\"],[12],[1,[30,0,[\"group\",\"full_name\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,1],[14,0,\"groups-info-name\"],[12],[1,[30,0,[\"group\",\"displayName\"]]],[13],[1,\"\\n\"]],[]]]],[],false,[\"if\"]]",
  "moduleName": "discourse/components/groups-info.hbs",
  "isStrictMode": false
});