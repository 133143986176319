import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{component
  this.customComponent
  tab=this.tab
  selectedTab=this.selectedTab
  category=this.category
}}
*/
{
  "id": "j67r9u89",
  "block": "[[[46,[30,0,[\"customComponent\"]],null,[[\"tab\",\"selectedTab\",\"category\"],[[30,0,[\"tab\"]],[30,0,[\"selectedTab\"]],[30,0,[\"category\"]]]],null]],[],false,[\"component\"]]",
  "moduleName": "discourse/components/edit-category-panel.hbs",
  "isStrictMode": false
});