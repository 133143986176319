import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './mobile-nav.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import $ from "jquery";
import { on } from "discourse-common/utils/decorators";
import TEMPLATE from "./mobile-nav.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = on("init"), (_obj = {
  _init() {
    if (this.site.desktopView) {
      let classes = this.desktopClass;
      if (classes) {
        classes = classes.split(" ");
        this.set("classNames", classes);
      }
    }
  },
  tagName: "ul",
  selectedHtml: null,
  classNames: ["mobile-nav"],
  router: service(),
  currentRouteChanged() {
    this.set("expanded", false);
    next(() => this._updateSelectedHtml());
  },
  _updateSelectedHtml() {
    if (!this.element || this.isDestroying || this.isDestroyed) {
      return;
    }
    const active = this.element.querySelector(".active");
    if (active && active.innerHTML) {
      this.set("selectedHtml", active.innerHTML);
    }
  },
  didInsertElement() {
    this._super(...arguments);
    this._updateSelectedHtml();
    this.router.on("routeDidChange", this, this.currentRouteChanged);
  },
  willDestroyElement() {
    this._super(...arguments);
    this.router.off("routeDidChange", this, this.currentRouteChanged);
  },
  toggleExpanded(event) {
    event?.preventDefault();
    this.toggleProperty("expanded");
    next(() => {
      if (this.expanded) {
        $(window).off("click.mobile-nav").on("click.mobile-nav", e => {
          if (!this.element || this.isDestroying || this.isDestroyed) {
            return;
          }
          const expander = this.element.querySelector(".expander");
          if (expander && e.target !== expander) {
            this.set("expanded", false);
            $(window).off("click.mobile-nav");
          }
        });
      }
    });
  }
}, (_applyDecoratedDescriptor(_obj, "_init", [_dec], Object.getOwnPropertyDescriptor(_obj, "_init"), _obj), _applyDecoratedDescriptor(_obj, "toggleExpanded", [action], Object.getOwnPropertyDescriptor(_obj, "toggleExpanded"), _obj)), _obj))));