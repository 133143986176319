import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#each this._childComponents as |info|}}
  {{#in-element info.element insertBefore=null}}
    <info.component
      @data={{info.data}}
      @setWrapperElementAttrs={{info.setWrapperElementAttrs}}
    />
  {{/in-element}}
{{/each}}
*/
{
  "id": "/zwe00Eu",
  "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"_childComponents\"]]],null]],null],null,[[[40,[[[1,\"    \"],[8,[30,1,[\"component\"]],null,[[\"@data\",\"@setWrapperElementAttrs\"],[[30,1,[\"data\"]],[30,1,[\"setWrapperElementAttrs\"]]]],null],[1,\"\\n\"]],[]],\"%cursor:0%\",[28,[37,3],[[30,1,[\"element\"]]],null],null]],[1]],null]],[\"info\"],false,[\"each\",\"-track-array\",\"in-element\",\"-in-el-null\"]]",
  "moduleName": "discourse/components/mount-widget.hbs",
  "isStrictMode": false
});