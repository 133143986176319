import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _class;
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
let GroupHistory = (_dec = discourseComputed("action"), (_class = class GroupHistory extends RestModel {
  actionTitle(action) {
    return I18n.t(`group_histories.actions.${action}`);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "actionTitle", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "actionTitle"), _class.prototype)), _class));
export { GroupHistory as default };