import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <EmailGroupUserChooser
  @id="private-message-users"
  @value={{this.splitRecipients}}
  @onChange={{action "updateRecipients"}}
  @options={{hash
    topicId=this.topicId
    none="composer.users_placeholder"
    includeMessageableGroups=true
    allowEmails=this.currentUser.can_send_private_email_messages
    autoWrap=true
  }}
/>
*/
{
  "id": "KhWwI0hr",
  "block": "[[[8,[39,0],null,[[\"@id\",\"@value\",\"@onChange\",\"@options\"],[\"private-message-users\",[30,0,[\"splitRecipients\"]],[28,[37,1],[[30,0],\"updateRecipients\"],null],[28,[37,2],null,[[\"topicId\",\"none\",\"includeMessageableGroups\",\"allowEmails\",\"autoWrap\"],[[30,0,[\"topicId\"]],\"composer.users_placeholder\",true,[30,0,[\"currentUser\",\"can_send_private_email_messages\"]],true]]]]],null]],[],false,[\"email-group-user-chooser\",\"action\",\"hash\"]]",
  "moduleName": "discourse/components/composer-user-selector.hbs",
  "isStrictMode": false
});