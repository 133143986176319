import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import I18n from "discourse-i18n";
let ConfirmOldEmailController = (_class = class ConfirmOldEmailController extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "dialog", _descriptor, this);
    _initializerDefineProperty(this, "router", _descriptor2, this);
    _initializerDefineProperty(this, "loading", _descriptor3, this);
  }
  async confirm() {
    this.loading = true;
    try {
      await ajax(`/u/confirm-old-email/${this.model.token}.json`, {
        type: "PUT"
      });
    } catch (error) {
      popupAjaxError(error);
      return;
    } finally {
      this.loading = false;
    }
    await new Promise(resolve => this.dialog.dialog({
      message: I18n.t("user.change_email.authorizing_old.confirm_success"),
      type: "alert",
      didConfirm: resolve
    }));
    this.router.transitionTo("/my/preferences/account");
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "confirm", [action], Object.getOwnPropertyDescriptor(_class.prototype, "confirm"), _class.prototype)), _class);
export { ConfirmOldEmailController as default };