import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class2, _descriptor;
import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
let _class = (_class2 = class _class2 extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "tagName", _descriptor, this);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "tagName", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
})), _class2);
export { _class as default };