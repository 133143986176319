import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _obj;
/* import __COLOCATED_TEMPLATE__ from './reviewable-item.hbs'; */
import { getOwner } from "@ember/application";
import Component, { setComponentTemplate } from "@ember/component";
import { action, set } from "@ember/object";
import { service } from "@ember/service";
import { classify, dasherize } from "@ember/string";
import ExplainReviewableModal from "discourse/components/modal/explain-reviewable";
import RejectReasonReviewableModal from "discourse/components/modal/reject-reason-reviewable";
import ReviseAndRejectPostReviewable from "discourse/components/modal/revise-and-reject-post-reviewable";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import optionalService from "discourse/lib/optional-service";
import Category from "discourse/models/category";
import discourseComputed, { bind } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./reviewable-item.hbs";
let _components = {};
const pluginReviewableParams = {};

// The mappings defined here are default core mappings, and cannot be overridden
// by plugins.
const defaultActionModalClassMap = {
  revise_and_reject_post: ReviseAndRejectPostReviewable
};
const actionModalClassMap = {
  ...defaultActionModalClassMap
};
export function addPluginReviewableParam(reviewableType, param) {
  pluginReviewableParams[reviewableType] ? pluginReviewableParams[reviewableType].push(param) : pluginReviewableParams[reviewableType] = [param];
}
export function registerReviewableActionModal(actionName, modalClass) {
  if (Object.keys(defaultActionModalClassMap).includes(actionName)) {
    throw new Error(`Cannot override default action modal class for ${actionName} (mapped to ${defaultActionModalClassMap[actionName].name})!`);
  }
  actionModalClassMap[actionName] = modalClass;
}
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("reviewable.type", "reviewable.last_performing_username", "siteSettings.blur_tl0_flagged_posts_media", "reviewable.target_created_by_trust_level"), _dec2 = discourseComputed("reviewable.created_from_flag", "reviewable.status"), _dec3 = discourseComputed("reviewable.topic", "reviewable.topic_id", "reviewable.removed_topic_id"), _dec4 = discourseComputed("siteSettings.reviewable_claiming", "topicId"), _dec5 = discourseComputed("claimEnabled", "siteSettings.reviewable_claiming", "reviewable.claimed_by"), _dec6 = discourseComputed("siteSettings.reviewable_claiming", "reviewable.claimed_by"), _dec7 = discourseComputed("reviewable.type"), _dec8 = discourseComputed("_updates.category_id", "reviewable.category.id"), (_obj = {
  adminTools: optionalService(),
  dialog: service(),
  modal: service(),
  siteSettings: service(),
  currentUser: service(),
  tagName: "",
  updating: null,
  editing: false,
  _updates: null,
  customClasses(type, lastPerformingUsername, blurEnabled, trustLevel) {
    let classes = dasherize(type);
    if (lastPerformingUsername) {
      classes = `${classes} reviewable-stale`;
    }
    if (blurEnabled && trustLevel === 0) {
      classes = `${classes} blur-images`;
    }
    return classes;
  },
  displayContextQuestion(createdFromFlag, status) {
    return createdFromFlag && status === 0;
  },
  topicId(topic, topicId, removedTopicId) {
    return topic && topic.id || topicId || removedTopicId;
  },
  claimEnabled(claimMode, topicId) {
    return claimMode !== "disabled" && !!topicId;
  },
  canPerform(claimEnabled, claimMode, claimedBy) {
    if (!claimEnabled) {
      return true;
    }
    if (claimedBy) {
      return claimedBy.id === this.currentUser.id;
    }
    return claimMode !== "required";
  },
  claimHelp(claimMode, claimedBy) {
    if (claimedBy) {
      return claimedBy.id === this.currentUser.id ? I18n.t("review.claim_help.claimed_by_you") : I18n.t("review.claim_help.claimed_by_other", {
        username: claimedBy.username
      });
    }
    return claimMode === "optional" ? I18n.t("review.claim_help.optional") : I18n.t("review.claim_help.required");
  },
  reviewableComponent(type) {
    if (_components[type] !== undefined) {
      return _components[type];
    }
    const dasherized = dasherize(type);
    const owner = getOwner(this);
    const componentExists = owner.hasRegistration(`component:${dasherized}`) || owner.hasRegistration(`template:components/${dasherized}`);
    _components[type] = componentExists ? dasherized : null;
    return _components[type];
  },
  tagCategoryId(updatedCategoryId, categoryId) {
    return updatedCategoryId || categoryId;
  },
  _performConfirmed(performableAction) {
    let additionalData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let reviewable = this.reviewable;
    let performAction = () => {
      let version = reviewable.get("version");
      this.set("updating", true);
      const data = {
        send_email: reviewable.sendEmail,
        reject_reason: reviewable.rejectReason,
        ...additionalData
      };
      (pluginReviewableParams[reviewable.type] || []).forEach(param => {
        if (reviewable[param]) {
          data[param] = reviewable[param];
        }
      });
      return ajax(`/review/${reviewable.id}/perform/${performableAction.server_action}?version=${version}`, {
        type: "PUT",
        data
      }).then(result => {
        let performResult = result.reviewable_perform_result;

        // "fast track" to update the current user's reviewable count before the message bus finds out.
        if (performResult.reviewable_count !== undefined) {
          this.currentUser.updateReviewableCount(performResult.reviewable_count);
        }
        if (performResult.unseen_reviewable_count !== undefined) {
          this.currentUser.set("unseen_reviewable_count", performResult.unseen_reviewable_count);
        }
        if (this.remove) {
          this.remove(performResult.remove_reviewable_ids);
        } else {
          return this.store.find("reviewable", reviewable.id);
        }
      }).catch(popupAjaxError).finally(() => this.set("updating", false));
    };
    if (performableAction.client_action) {
      let actionMethod = this[`client${classify(performableAction.client_action)}`];
      if (actionMethod) {
        return actionMethod.call(this, reviewable, performAction);
      } else {
        // eslint-disable-next-line no-console
        console.error(`No handler for ${performableAction.client_action} found`);
        return;
      }
    } else {
      return performAction();
    }
  },
  clientSuspend(reviewable, performAction) {
    this._penalize("showSuspendModal", reviewable, performAction);
  },
  clientSilence(reviewable, performAction) {
    this._penalize("showSilenceModal", reviewable, performAction);
  },
  _penalize(adminToolMethod, reviewable, performAction) {
    let adminTools = this.adminTools;
    if (adminTools) {
      let createdBy = reviewable.get("target_created_by");
      let postId = reviewable.get("post_id");
      let postEdit = reviewable.get("raw");
      return adminTools[adminToolMethod](createdBy, {
        postId,
        postEdit,
        before: performAction
      });
    }
  },
  explainReviewable(reviewable, event) {
    event.preventDefault();
    this.modal.show(ExplainReviewableModal, {
      model: {
        reviewable
      }
    });
  },
  actions: {
    edit() {
      this.set("editing", true);
      this.set("_updates", {
        payload: {}
      });
    },
    cancelEdit() {
      this.set("editing", false);
    },
    saveEdit() {
      let updates = this._updates;

      // Remove empty objects
      Object.keys(updates).forEach(name => {
        let attr = updates[name];
        if (typeof attr === "object" && Object.keys(attr).length === 0) {
          delete updates[name];
        }
      });
      this.set("updating", true);
      return this.reviewable.update(updates).then(() => this.set("editing", false)).catch(popupAjaxError).finally(() => this.set("updating", false));
    },
    categoryChanged(categoryId) {
      let category = Category.findById(categoryId);
      if (!category) {
        category = Category.findUncategorized();
      }
      set(this._updates, "category_id", category.id);
    },
    valueChanged(fieldId, event) {
      set(this._updates, fieldId, event.target.value);
    },
    perform(performableAction) {
      if (this.updating) {
        return;
      }
      const message = performableAction.get("confirm_message");
      const requireRejectReason = performableAction.get("require_reject_reason");
      const actionModalClass = requireRejectReason ? RejectReasonReviewableModal : actionModalClassMap[performableAction.server_action];
      if (message) {
        this.dialog.confirm({
          message,
          didConfirm: () => this._performConfirmed(performableAction)
        });
      } else if (actionModalClass) {
        this.modal.show(actionModalClass, {
          model: {
            reviewable: this.reviewable,
            performConfirmed: this._performConfirmed,
            action: performableAction
          }
        });
      } else {
        return this._performConfirmed(performableAction);
      }
    }
  }
}, (_applyDecoratedDescriptor(_obj, "customClasses", [_dec], Object.getOwnPropertyDescriptor(_obj, "customClasses"), _obj), _applyDecoratedDescriptor(_obj, "displayContextQuestion", [_dec2], Object.getOwnPropertyDescriptor(_obj, "displayContextQuestion"), _obj), _applyDecoratedDescriptor(_obj, "topicId", [_dec3], Object.getOwnPropertyDescriptor(_obj, "topicId"), _obj), _applyDecoratedDescriptor(_obj, "claimEnabled", [_dec4], Object.getOwnPropertyDescriptor(_obj, "claimEnabled"), _obj), _applyDecoratedDescriptor(_obj, "canPerform", [_dec5], Object.getOwnPropertyDescriptor(_obj, "canPerform"), _obj), _applyDecoratedDescriptor(_obj, "claimHelp", [_dec6], Object.getOwnPropertyDescriptor(_obj, "claimHelp"), _obj), _applyDecoratedDescriptor(_obj, "reviewableComponent", [_dec7], Object.getOwnPropertyDescriptor(_obj, "reviewableComponent"), _obj), _applyDecoratedDescriptor(_obj, "tagCategoryId", [_dec8], Object.getOwnPropertyDescriptor(_obj, "tagCategoryId"), _obj), _applyDecoratedDescriptor(_obj, "_performConfirmed", [bind], Object.getOwnPropertyDescriptor(_obj, "_performConfirmed"), _obj), _applyDecoratedDescriptor(_obj, "explainReviewable", [action], Object.getOwnPropertyDescriptor(_obj, "explainReviewable"), _obj)), _obj))));