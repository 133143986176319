import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <h3>{{i18n this.title}}</h3>
<ul>
  {{yield}}
</ul>
*/
{
  "id": "ceqSN3Cy",
  "block": "[[[10,\"h3\"],[12],[1,[28,[35,0],[[30,0,[\"title\"]]],null]],[13],[1,\"\\n\"],[10,\"ul\"],[12],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"i18n\",\"yield\"]]",
  "moduleName": "discourse/components/popup-menu.hbs",
  "isStrictMode": false
});