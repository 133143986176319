import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.value}}
  <div class={{this.classes}}>
    <div class="name">{{this.name}}</div>
    <div class="value">{{this.value}}</div>
  </div>
{{/if}}
*/
{
  "id": "yOMLoOfQ",
  "block": "[[[41,[30,0,[\"value\"]],[[[1,\"  \"],[10,0],[15,0,[30,0,[\"classes\"]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"name\"],[12],[1,[30,0,[\"name\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"value\"],[12],[1,[30,0,[\"value\"]]],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
  "moduleName": "discourse/components/reviewable-field.hbs",
  "isStrictMode": false
});