import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{this.title}}
  @closeModal={{@closeModal}}
  @inline={{@inline}}
  class="request-group-membership-form"
>
  <:body>
    <div class="control-group">
      <label>
        {{i18n "groups.membership_request.reason"}}
      </label>

      <ExpandingTextArea
        {{on "input" (with-event-value (fn (mut this.reason)))}}
        value={{this.reason}}
        maxlength="5000"
      />
    </div>
  </:body>

  <:footer>
    <DButton
      @action={{this.requestMember}}
      @label="groups.membership_request.submit"
      @disabled={{this.disableSubmit}}
      class="btn-primary"
    />

    <DModalCancel @close={{@closeModal}} />
    <ConditionalLoadingSpinner @size="small" @condition={{this.loading}} />
  </:footer>
</DModal>
*/
{
  "id": "v9d4nGj/",
  "block": "[[[8,[39,0],[[24,0,\"request-group-membership-form\"]],[[\"@title\",\"@closeModal\",\"@inline\"],[[30,0,[\"title\"]],[30,1],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,\"\\n        \"],[1,[28,[35,1],[\"groups.membership_request.reason\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[8,[39,2],[[16,2,[30,0,[\"reason\"]]],[24,\"maxlength\",\"5000\"],[4,[38,3],[\"input\",[28,[37,4],[[28,[37,5],[[28,[37,6],[[30,0,[\"reason\"]]],null]],null]],null]],null]],null,null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,7],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"requestMember\"]],\"groups.membership_request.submit\",[30,0,[\"disableSubmit\"]]]],null],[1,\"\\n\\n    \"],[8,[39,8],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n    \"],[8,[39,9],null,[[\"@size\",\"@condition\"],[\"small\",[30,0,[\"loading\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@inline\"],false,[\"d-modal\",\"i18n\",\"expanding-text-area\",\"on\",\"with-event-value\",\"fn\",\"mut\",\"d-button\",\"d-modal-cancel\",\"conditional-loading-spinner\"]]",
  "moduleName": "discourse/components/modal/request-group-membership-form.hbs",
  "isStrictMode": false
});