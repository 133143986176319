import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <ConditionalLoadingSpinner @condition={{this.loading}}>
  {{#if this.topics}}
    <TopicList
      @showPosters={{this.showPosters}}
      @hideCategory={{this.hideCategory}}
      @topics={{this.topics}}
      @expandExcerpts={{this.expandExcerpts}}
      @bulkSelectHelper={{this.bulkSelectHelper}}
      @canBulkSelect={{this.canBulkSelect}}
      @tagsForUser={{this.tagsForUser}}
      @changeSort={{this.changeSort}}
      @order={{this.order}}
      @ascending={{this.ascending}}
      @focusLastVisitedTopic={{this.focusLastVisitedTopic}}
    />
  {{else}}
    {{#unless this.loadingMore}}
      <div class="alert alert-info">
        {{i18n "choose_topic.none_found"}}
      </div>
    {{/unless}}
  {{/if}}
</ConditionalLoadingSpinner>
*/
{
  "id": "tDJBmgMz",
  "block": "[[[8,[39,0],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"topics\"]],[[[1,\"    \"],[8,[39,2],null,[[\"@showPosters\",\"@hideCategory\",\"@topics\",\"@expandExcerpts\",\"@bulkSelectHelper\",\"@canBulkSelect\",\"@tagsForUser\",\"@changeSort\",\"@order\",\"@ascending\",\"@focusLastVisitedTopic\"],[[30,0,[\"showPosters\"]],[30,0,[\"hideCategory\"]],[30,0,[\"topics\"]],[30,0,[\"expandExcerpts\"]],[30,0,[\"bulkSelectHelper\"]],[30,0,[\"canBulkSelect\"]],[30,0,[\"tagsForUser\"]],[30,0,[\"changeSort\"]],[30,0,[\"order\"]],[30,0,[\"ascending\"]],[30,0,[\"focusLastVisitedTopic\"]]]],null],[1,\"\\n\"]],[]],[[[41,[51,[30,0,[\"loadingMore\"]]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info\"],[12],[1,\"\\n        \"],[1,[28,[35,4],[\"choose_topic.none_found\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[]]]]]],[],false,[\"conditional-loading-spinner\",\"if\",\"topic-list\",\"unless\",\"i18n\"]]",
  "moduleName": "discourse/components/basic-topic-list.hbs",
  "isStrictMode": false
});