import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <ul class="user-stream">
  {{#each this.model as |pending_post|}}
    <PendingPost @post={{pending_post}} />
  {{/each}}
</ul>
*/
{
  "id": "J0zkRWfr",
  "block": "[[[10,\"ul\"],[14,0,\"user-stream\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"model\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@post\"],[[30,1]]],null],[1,\"\\n\"]],[1]],null],[13]],[\"pending_post\"],false,[\"each\",\"-track-array\",\"pending-post\"]]",
  "moduleName": "discourse/templates/user-activity-pending.hbs",
  "isStrictMode": false
});