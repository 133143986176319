import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "raw_email.title"}}
  class="incoming-email-modal"
  @closeModal={{@closeModal}}
>
  <:body>
    <div class="incoming-email-tabs">
      <DButton
        @action={{this.displayRaw}}
        @label="post.raw_email.displays.raw.button"
        @title="post.raw_email.displays.raw.title"
        class={{if (eq this.tab "raw") "active"}}
      />

      {{#if this.textPart}}
        <DButton
          @action={{this.displayTextPart}}
          @label="post.raw_email.displays.text_part.button"
          @title="post.raw_email.displays.text_part.title"
          class={{if (eq this.tab "text_part") "active"}}
        />
      {{/if}}

      {{#if this.htmlPart}}
        <DButton
          @action={{this.displayHtmlPart}}
          @label="post.raw_email.displays.html_part.button"
          @title="post.raw_email.displays.html_part.title"
          class={{if (eq this.tab "html_part") "active"}}
        />
      {{/if}}
    </div>

    <div class="incoming-email-content">
      {{#if (eq this.tab "raw")}}
        {{#if this.rawEmail}}
          <Textarea @value={{this.rawEmail}} />
        {{else}}
          {{i18n "raw_email.not_available"}}
        {{/if}}
      {{/if}}
      {{#if (eq this.tab "text_part")}}
        <Textarea @value={{this.textPart}} />
      {{/if}}
      {{#if (eq this.tab "html_part")}}
        <IframedHtml @html={{this.htmlPart}} class="incoming-email-html-part" />
      {{/if}}
    </div>
  </:body>
</DModal>
*/
{
  "id": "g0CvwioU",
  "block": "[[[8,[39,0],[[24,0,\"incoming-email-modal\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"raw_email.title\"],null],[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"incoming-email-tabs\"],[12],[1,\"\\n      \"],[8,[39,2],[[16,0,[52,[28,[37,4],[[30,0,[\"tab\"]],\"raw\"],null],\"active\"]]],[[\"@action\",\"@label\",\"@title\"],[[30,0,[\"displayRaw\"]],\"post.raw_email.displays.raw.button\",\"post.raw_email.displays.raw.title\"]],null],[1,\"\\n\\n\"],[41,[30,0,[\"textPart\"]],[[[1,\"        \"],[8,[39,2],[[16,0,[52,[28,[37,4],[[30,0,[\"tab\"]],\"text_part\"],null],\"active\"]]],[[\"@action\",\"@label\",\"@title\"],[[30,0,[\"displayTextPart\"]],\"post.raw_email.displays.text_part.button\",\"post.raw_email.displays.text_part.title\"]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"htmlPart\"]],[[[1,\"        \"],[8,[39,2],[[16,0,[52,[28,[37,4],[[30,0,[\"tab\"]],\"html_part\"],null],\"active\"]]],[[\"@action\",\"@label\",\"@title\"],[[30,0,[\"displayHtmlPart\"]],\"post.raw_email.displays.html_part.button\",\"post.raw_email.displays.html_part.title\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"incoming-email-content\"],[12],[1,\"\\n\"],[41,[28,[37,4],[[30,0,[\"tab\"]],\"raw\"],null],[[[41,[30,0,[\"rawEmail\"]],[[[1,\"          \"],[8,[39,5],null,[[\"@value\"],[[30,0,[\"rawEmail\"]]]],null],[1,\"\"]],[]],[[[1,\"          \"],[1,[28,[35,1],[\"raw_email.not_available\"],null]],[1,\"\"]],[]]]],[]],null],[41,[28,[37,4],[[30,0,[\"tab\"]],\"text_part\"],null],[[[1,\"        \"],[8,[39,5],null,[[\"@value\"],[[30,0,[\"textPart\"]]]],null],[1,\"\"]],[]],null],[41,[28,[37,4],[[30,0,[\"tab\"]],\"html_part\"],null],[[[1,\"        \"],[8,[39,6],[[24,0,\"incoming-email-html-part\"]],[[\"@html\"],[[30,0,[\"htmlPart\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"d-button\",\"if\",\"eq\",\"textarea\",\"iframed-html\"]]",
  "moduleName": "discourse/components/modal/raw-email.hbs",
  "isStrictMode": false
});