import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if @inline}}
  {{yield}}
{{else if @element}}
  {{#if @append}}
    {{#in-element @element insertBefore=null}}{{yield}}{{/in-element}}
  {{else}}
    {{#in-element @element}}{{yield}}{{/in-element}}
  {{/if}}
{{/if}}
*/
{
  "id": "qfxJbZN7",
  "block": "[[[41,[30,1],[[[1,\"  \"],[18,4,null],[1,\"\\n\"]],[]],[[[41,[30,2],[[[41,[30,3],[[[1,\"    \"],[40,[[[18,4,null]],[]],\"%cursor:0%\",[28,[37,3],[[30,2]],null],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[40,[[[18,4,null]],[]],\"%cursor:1%\",[28,[37,3],[[30,2]],null]],[1,\"\\n\"]],[]]]],[]],null]],[]]]],[\"@inline\",\"@element\",\"@append\",\"&default\"],false,[\"if\",\"yield\",\"in-element\",\"-in-el-null\"]]",
  "moduleName": "discourse/components/conditional-in-element.hbs",
  "isStrictMode": false
});