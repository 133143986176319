import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { tracked } from "@glimmer/tracking";
import Service from "@ember/service";
let AdminCustomUserFields = (_class = class AdminCustomUserFields extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "additionalProperties", _descriptor, this);
  }
  addProperty(property) {
    this.additionalProperties.push(property);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "additionalProperties", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
})), _class);
export { AdminCustomUserFields as default };