import { SELECTORS } from "../constants";
export async function setCarouselScrollPosition() {
  let {
    behavior = "instant"
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const carouselItem = document.querySelector(SELECTORS.ACTIVE_CAROUSEL_ITEM);
  if (!carouselItem) {
    return;
  }
  const left = carouselItem.offsetLeft - carouselItem.offsetWidth - carouselItem.offsetWidth / 2;
  const top = carouselItem.offsetTop - carouselItem.offsetHeight - carouselItem.offsetHeight / 2;
  carouselItem.parentElement.scrollTo({
    behavior,
    left,
    top
  });
}