import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _class, _descriptor;
import { reads } from "@ember/object/computed";
import { cook } from "discourse/lib/text";
import { userPath } from "discourse/lib/url";
import RestModel from "discourse/models/rest";
import discourseComputed from "discourse-common/utils/decorators";
import Category from "./category";
let PendingPost = (_dec = reads("topic_url"), _dec2 = discourseComputed("username"), _dec3 = discourseComputed("category_id"), (_class = class PendingPost extends RestModel {
  constructor() {
    super(...arguments);
    _defineProperty(this, "expandedExcerpt", null);
    _initializerDefineProperty(this, "postUrl", _descriptor, this);
    _defineProperty(this, "truncated", false);
  }
  init() {
    super.init(...arguments);
    cook(this.raw_text).then(cooked => {
      this.set("expandedExcerpt", cooked);
    });
  }
  userUrl(username) {
    return userPath(username.toLowerCase());
  }
  category() {
    return Category.findById(this.category_id);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "postUrl", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "userUrl", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "userUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "category", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "category"), _class.prototype)), _class));
export { PendingPost as default };