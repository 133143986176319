let d = window.define;
d("ember-resize-observer-service/services/resize-observer", function () {
  return esc(require("ember-resize-observer-service/services/resize-observer"));
});
d("ember-resize-observer-service/utils/ignore-ro-error", function () {
  return esc(require("ember-resize-observer-service/utils/ignore-ro-error"));
});
import "ember-modifier/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "ember-cli-htmlbars/-embroider-implicit-modules.js";
import "ember-resize-observer-service/-embroider-implicit-modules.js";
import esc from "../../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";