import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _class;
import ArrayProxy from "@ember/array/proxy";
import { Promise } from "rsvp";
import discourseComputed from "discourse-common/utils/decorators";
let ResultSet = (_dec = discourseComputed("totalRows", "length"), (_class = class ResultSet extends ArrayProxy {
  constructor() {
    super(...arguments);
    _defineProperty(this, "loading", false);
    _defineProperty(this, "loadingMore", false);
    _defineProperty(this, "totalRows", 0);
    _defineProperty(this, "refreshing", false);
    _defineProperty(this, "content", null);
    _defineProperty(this, "loadMoreUrl", null);
    _defineProperty(this, "refreshUrl", null);
    _defineProperty(this, "findArgs", null);
    _defineProperty(this, "store", null);
    _defineProperty(this, "resultSetMeta", null);
    _defineProperty(this, "__type", null);
  }
  canLoadMore(totalRows, length) {
    return length < totalRows;
  }
  loadMore() {
    const loadMoreUrl = this.loadMoreUrl;
    if (!loadMoreUrl) {
      return;
    }
    const totalRows = this.totalRows;
    if (this.length < totalRows && !this.loadingMore) {
      this.set("loadingMore", true);
      return this.store.appendResults(this, this.__type, loadMoreUrl).finally(() => this.set("loadingMore", false));
    }
    return Promise.resolve();
  }
  refresh() {
    if (this.refreshing) {
      return;
    }
    const refreshUrl = this.refreshUrl;
    if (!refreshUrl) {
      return;
    }
    this.set("refreshing", true);
    return this.store.refreshResults(this, this.__type, refreshUrl).finally(() => this.set("refreshing", false));
  }
}, (_applyDecoratedDescriptor(_class.prototype, "canLoadMore", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "canLoadMore"), _class.prototype)), _class));
export { ResultSet as default };