/* import __COLOCATED_TEMPLATE__ from './color-picker.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import TEMPLATE from "./color-picker.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend({
  classNames: "colors-container",
  actions: {
    selectColor(color) {
      this.set("value", color);
    }
  }
}));