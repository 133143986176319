import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
import Controller, { inject as controller } from "@ember/controller";
import EmberObject, { action } from "@ember/object";
import { alias, gt, not, or } from "@ember/object/computed";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import UserStatusModal from "discourse/components/modal/user-status";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { propertyNotEqual, setting } from "discourse/lib/computed";
import { exportUserArchive } from "discourse/lib/export-csv";
import DiscourseURL from "discourse/lib/url";
import CanCheckEmails from "discourse/mixins/can-check-emails";
import { findAll } from "discourse/models/login-method";
import getURL from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Controller.extend(CanCheckEmails, (_dec = discourseComputed(), _dec2 = discourseComputed("model.filteredGroups"), _dec3 = discourseComputed("model.associated_accounts"), _dec4 = discourseComputed("model.associated_accounts.[]"), _dec5 = discourseComputed("model.email", "model.secondary_emails.[]", "model.unconfirmed_emails.[]"), _dec6 = discourseComputed("model.second_factor_enabled", "canCheckEmails", "model.is_anonymous"), _dec7 = discourseComputed("siteSettings.max_allowed_secondary_emails", "model.can_edit_email"), (_obj = {
  dialog: service(),
  modal: service(),
  user: controller(),
  canDownloadPosts: alias("user.viewingSelf"),
  init() {
    this._super(...arguments);
    this.saveAttrNames = ["name", "title", "primary_group_id", "flair_group_id", "status"];
    this.set("revoking", {});
  },
  canEditName: setting("enable_names"),
  canSelectUserStatus: setting("enable_user_status"),
  canSaveUser: true,
  newNameInput: null,
  newTitleInput: null,
  newPrimaryGroupInput: null,
  newStatus: null,
  revoking: null,
  cannotDeleteAccount: not("currentUser.can_delete_account"),
  deleteDisabled: or("model.isSaving", "deleting", "cannotDeleteAccount"),
  reset() {
    this.set("passwordProgress", null);
  },
  nameInstructions() {
    return I18n.t(this.siteSettings.full_name_required ? "user.name.instructions_required" : "user.name.instructions");
  },
  canSelectTitle: gt("model.availableTitles.length", 0),
  canSelectFlair: gt("model.availableFlairs.length", 0),
  canSelectPrimaryGroup(primaryGroupOptions) {
    return primaryGroupOptions.length > 0 && this.siteSettings.user_selected_primary_groups;
  },
  associatedAccountsLoaded(associatedAccounts) {
    return typeof associatedAccounts !== "undefined";
  },
  authProviders(accounts) {
    const allMethods = findAll();
    const result = allMethods.map(method => {
      return {
        method,
        account: accounts.find(account => account.name === method.name) // Will be undefined if no account
      };
    });
    return result.filter(value => value.account || value.method.can_connect);
  },
  disableConnectButtons: propertyNotEqual("model.id", "currentUser.id"),
  emails(primaryEmail, secondaryEmails, unconfirmedEmails) {
    const emails = [];
    if (primaryEmail) {
      emails.push(EmberObject.create({
        email: primaryEmail,
        primary: true,
        confirmed: true
      }));
    }
    if (secondaryEmails) {
      secondaryEmails.forEach(email => {
        emails.push(EmberObject.create({
          email,
          confirmed: true
        }));
      });
    }
    if (unconfirmedEmails) {
      unconfirmedEmails.forEach(email => {
        emails.push(EmberObject.create({
          email
        }));
      });
    }
    return emails.sort((a, b) => a.email.localeCompare(b.email));
  },
  canUpdateAssociatedAccounts(secondFactorEnabled, canCheckEmails, isAnonymous) {
    if (secondFactorEnabled || !canCheckEmails || isAnonymous) {
      return false;
    }
    return findAll().length > 0;
  },
  canAddEmail(maxAllowedSecondaryEmails, canEditEmail) {
    return maxAllowedSecondaryEmails > 0 && canEditEmail;
  },
  resendConfirmationEmail(email, event) {
    event?.preventDefault();
    email.set("resending", true);
    this.model.addEmail(email.email).then(() => {
      email.set("resent", true);
    }).finally(() => {
      email.set("resending", false);
    });
  },
  showUserStatusModal(status) {
    this.modal.show(UserStatusModal, {
      model: {
        status,
        hidePauseNotifications: true,
        saveAction: s => this.set("newStatus", s),
        deleteAction: () => this.set("newStatus", null)
      }
    });
  },
  actions: {
    save() {
      this.set("saved", false);
      this.model.setProperties({
        name: this.newNameInput,
        title: this.newTitleInput,
        primary_group_id: this.newPrimaryGroupInput,
        flair_group_id: this.newFlairGroupId,
        status: this.newStatus
      });
      return this.model.save(this.saveAttrNames).then(() => this.set("saved", true)).catch(popupAjaxError);
    },
    setPrimaryEmail(email) {
      this.model.setPrimaryEmail(email).catch(popupAjaxError);
    },
    destroyEmail(email) {
      this.model.destroyEmail(email);
    },
    delete() {
      this.dialog.alert({
        message: I18n.t("user.delete_account_confirm"),
        buttons: [{
          icon: "exclamation-triangle",
          label: I18n.t("user.delete_account"),
          class: "btn-danger",
          action: () => {
            return this.model.delete().then(() => {
              next(() => {
                this.dialog.alert({
                  message: I18n.t("user.deleted_yourself"),
                  didConfirm: () => DiscourseURL.redirectAbsolute(getURL("/")),
                  didCancel: () => DiscourseURL.redirectAbsolute(getURL("/"))
                });
              });
            }, () => {
              next(() => this.dialog.alert(I18n.t("user.delete_yourself_not_allowed")));
              this.set("deleting", false);
            });
          }
        }, {
          label: I18n.t("composer.cancel")
        }]
      });
    },
    revokeAccount(account) {
      this.set(`revoking.${account.name}`, true);
      this.model.revokeAssociatedAccount(account.name).then(result => {
        if (result.success) {
          this.model.associated_accounts.removeObject(account);
        } else {
          this.dialog.alert(result.message);
        }
      }).catch(popupAjaxError).finally(() => this.set(`revoking.${account.name}`, false));
    },
    connectAccount(method) {
      method.doLogin({
        reconnect: true
      });
    },
    exportUserArchive() {
      this.dialog.yesNoConfirm({
        message: I18n.t("user.download_archive.confirm"),
        didConfirm: () => exportUserArchive()
      });
    }
  }
}, (_applyDecoratedDescriptor(_obj, "nameInstructions", [_dec], Object.getOwnPropertyDescriptor(_obj, "nameInstructions"), _obj), _applyDecoratedDescriptor(_obj, "canSelectPrimaryGroup", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canSelectPrimaryGroup"), _obj), _applyDecoratedDescriptor(_obj, "associatedAccountsLoaded", [_dec3], Object.getOwnPropertyDescriptor(_obj, "associatedAccountsLoaded"), _obj), _applyDecoratedDescriptor(_obj, "authProviders", [_dec4], Object.getOwnPropertyDescriptor(_obj, "authProviders"), _obj), _applyDecoratedDescriptor(_obj, "emails", [_dec5], Object.getOwnPropertyDescriptor(_obj, "emails"), _obj), _applyDecoratedDescriptor(_obj, "canUpdateAssociatedAccounts", [_dec6], Object.getOwnPropertyDescriptor(_obj, "canUpdateAssociatedAccounts"), _obj), _applyDecoratedDescriptor(_obj, "canAddEmail", [_dec7], Object.getOwnPropertyDescriptor(_obj, "canAddEmail"), _obj), _applyDecoratedDescriptor(_obj, "resendConfirmationEmail", [action], Object.getOwnPropertyDescriptor(_obj, "resendConfirmationEmail"), _obj), _applyDecoratedDescriptor(_obj, "showUserStatusModal", [action], Object.getOwnPropertyDescriptor(_obj, "showUserStatusModal"), _obj)), _obj)));