import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <h3>
  {{this.message}}
  {{yield}}
</h3>
{{#if this.education}}
  <div class="education">
    {{html-safe this.education}}
  </div>
{{/if}}
*/
{
  "id": "A55koHGZ",
  "block": "[[[10,\"h3\"],[12],[1,\"\\n  \"],[1,[30,0,[\"message\"]]],[1,\"\\n  \"],[18,1,null],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"education\"]],[[[1,\"  \"],[10,0],[14,0,\"education\"],[12],[1,\"\\n    \"],[1,[28,[35,2],[[30,0,[\"education\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[\"&default\"],false,[\"yield\",\"if\",\"html-safe\"]]",
  "moduleName": "discourse/components/footer-message.hbs",
  "isStrictMode": false
});