import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _class, _descriptor;
import { i18n } from "discourse/lib/computed";
import RestModel from "discourse/models/rest";
import UserAction from "discourse/models/user-action";
import discourseComputed from "discourse-common/utils/decorators";
let UserActionStat = (_dec = i18n("action_type", "user_action_groups.%@"), _dec2 = discourseComputed("action_type"), _dec3 = discourseComputed("action_type"), (_class = class UserActionStat extends RestModel {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "description", _descriptor, this);
  }
  isPM(actionType) {
    return actionType === UserAction.TYPES.messages_sent || actionType === UserAction.TYPES.messages_received;
  }
  isResponse(actionType) {
    return actionType === UserAction.TYPES.replies || actionType === UserAction.TYPES.quotes;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "description", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "isPM", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "isPM"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "isResponse", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "isResponse"), _class.prototype)), _class));
export { UserActionStat as default };