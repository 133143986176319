import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _obj;
/* import __COLOCATED_TEMPLATE__ from './cdn-img.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { htmlSafe } from "@ember/template";
import { getURLWithCDN } from "discourse-common/lib/get-url";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./cdn-img.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("src"), _dec2 = discourseComputed("width", "height"), (_obj = {
  tagName: "",
  cdnSrc(src) {
    return getURLWithCDN(src);
  },
  style(width, height) {
    if (width && height) {
      return htmlSafe(`--aspect-ratio: ${width / height};`);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "cdnSrc", [_dec], Object.getOwnPropertyDescriptor(_obj, "cdnSrc"), _obj), _applyDecoratedDescriptor(_obj, "style", [_dec2], Object.getOwnPropertyDescriptor(_obj, "style"), _obj)), _obj))));