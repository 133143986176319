import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <ComposerTipCloseButton @action={{fn this.closeMessage this.message}} />

{{#if this.message.title}}
  <h3>{{this.message.title}}</h3>
{{/if}}

{{html-safe this.message.body}}
*/
{
  "id": "bSe00HVY",
  "block": "[[[8,[39,0],null,[[\"@action\"],[[28,[37,1],[[30,0,[\"closeMessage\"]],[30,0,[\"message\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"message\",\"title\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,[30,0,[\"message\",\"title\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,[28,[35,3],[[30,0,[\"message\",\"body\"]]],null]]],[],false,[\"composer-tip-close-button\",\"fn\",\"if\",\"html-safe\"]]",
  "moduleName": "discourse/templates/composer/education.hbs",
  "isStrictMode": false
});