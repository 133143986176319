import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "topics.bulk.actions"}}
  @closeModal={{@closeModal}}
  class="topic-bulk-actions-modal -large"
>
  <p>
    {{html-safe (i18n "topics.bulk.selected" count=@model.topics.length)}}
  </p>

  {{#if this.showProgress}}
    <p>
      {{html-safe (i18n "topics.bulk.progress" count=this.processedTopicCount)}}
    </p>
  {{else if this.activeComponent}}
    <this.activeComponent
      @loading={{this.loading}}
      @topics={{@model.topics}}
      @category={{@model.category}}
      @setComponent={{this.setComponent}}
      @forEachPerformed={{this.forEachPerformed}}
      @performAndRefresh={{this.performAndRefresh}}
    />
  {{else}}
    <div class="bulk-buttons">
      {{#each this.buttons as |button|}}
        <DButton
          @action={{fn
            button.action
            (hash
              topics=@model.topics
              category=@model.category
              setComponent=this.setComponent
              performAndRefresh=this.performAndRefresh
              forEachPerformed=this.forEachPerformed
            )
          }}
          @label={{button.label}}
          @icon={{button.icon}}
          class={{button.class}}
        />
      {{/each}}
    </div>
  {{/if}}
</DModal>
*/
{
  "id": "OyR7VKkm",
  "block": "[[[8,[39,0],[[24,0,\"topic-bulk-actions-modal -large\"]],[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"topics.bulk.actions\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[10,2],[12],[1,\"\\n    \"],[1,[28,[35,2],[[28,[37,1],[\"topics.bulk.selected\"],[[\"count\"],[[30,2,[\"topics\",\"length\"]]]]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showProgress\"]],[[[1,\"    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,2],[[28,[37,1],[\"topics.bulk.progress\"],[[\"count\"],[[30,0,[\"processedTopicCount\"]]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[41,[30,0,[\"activeComponent\"]],[[[1,\"    \"],[8,[30,0,[\"activeComponent\"]],null,[[\"@loading\",\"@topics\",\"@category\",\"@setComponent\",\"@forEachPerformed\",\"@performAndRefresh\"],[[30,0,[\"loading\"]],[30,2,[\"topics\"]],[30,2,[\"category\"]],[30,0,[\"setComponent\"]],[30,0,[\"forEachPerformed\"]],[30,0,[\"performAndRefresh\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"bulk-buttons\"],[12],[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"buttons\"]]],null]],null],null,[[[1,\"        \"],[8,[39,6],[[16,0,[30,3,[\"class\"]]]],[[\"@action\",\"@label\",\"@icon\"],[[28,[37,7],[[30,3,[\"action\"]],[28,[37,8],null,[[\"topics\",\"category\",\"setComponent\",\"performAndRefresh\",\"forEachPerformed\"],[[30,2,[\"topics\"]],[30,2,[\"category\"]],[30,0,[\"setComponent\"]],[30,0,[\"performAndRefresh\"]],[30,0,[\"forEachPerformed\"]]]]]],null],[30,3,[\"label\"]],[30,3,[\"icon\"]]]],null],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]],[]]]],[]]]]]],[\"@closeModal\",\"@model\",\"button\"],false,[\"d-modal\",\"i18n\",\"html-safe\",\"if\",\"each\",\"-track-array\",\"d-button\",\"fn\",\"hash\"]]",
  "moduleName": "discourse/components/modal/topic-bulk-actions.hbs",
  "isStrictMode": false
});