import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class2, _class3, _class4;
import EmberObject from "@ember/object";
import { dependentKeyCompat } from "@ember/object/compat";
import { service } from "@ember/service";
import deprecated from "discourse-common/lib/deprecated";
let reopenedClasses = [];
function ControllerShim(resolverName, deprecationId) {
  var _class, _descriptor;
  return _class = class AbstractControllerShim extends EmberObject {
    static printDeprecation() {
      deprecated(`${resolverName} no longer exists, and this shim will eventually be removed. To fetch information about the current discovery route, use the discovery service instead.`, {
        id: deprecationId
      });
    }
    static reopen() {
      this.printDeprecation();
      reopenedClasses.push(resolverName);
      return super.reopen(...arguments);
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "discovery", _descriptor, this);
      this.constructor.printDeprecation();
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "discovery", [service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class;
}
let NavigationCategoryControllerShim = (_class2 = class NavigationCategoryControllerShim extends ControllerShim("controller:navigation/category", "discourse.navigation-category-controller") {
  get category() {
    this.constructor.printDeprecation();
    return this.discovery.category;
  }
}, (_applyDecoratedDescriptor(_class2.prototype, "category", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_class2.prototype, "category"), _class2.prototype)), _class2);
let DiscoveryTopicsControllerShim = (_class3 = class DiscoveryTopicsControllerShim extends ControllerShim("controller:discovery/topics", "discourse.discovery-topics-controller") {
  get model() {
    this.constructor.printDeprecation();
    if (this.discovery.onDiscoveryRoute) {
      return this.discovery.currentTopicList;
    }
  }
  get category() {
    this.constructor.printDeprecation();
    if (this.discovery.onDiscoveryRoute) {
      return this.discovery.category;
    }
  }
}, (_applyDecoratedDescriptor(_class3.prototype, "model", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_class3.prototype, "model"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "category", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_class3.prototype, "category"), _class3.prototype)), _class3);
let TagShowControllerShim = (_class4 = class TagShowControllerShim extends ControllerShim("controller:tag-show", "discourse.tag-show-controller") {
  get tag() {
    this.constructor.printDeprecation();
    return this.discovery.tag;
  }
}, (_applyDecoratedDescriptor(_class4.prototype, "tag", [dependentKeyCompat], Object.getOwnPropertyDescriptor(_class4.prototype, "tag"), _class4.prototype)), _class4);
export default {
  initialize(container) {
    container.register("controller:navigation/category", NavigationCategoryControllerShim);
    container.register("controller:discovery/topics", DiscoveryTopicsControllerShim);
    container.register("controller:tag-show", TagShowControllerShim);
    container.lookup("service:router").on("routeDidChange", transition => {
      const destination = transition.to?.name;
      if (destination?.startsWith("discovery.") || destination?.startsWith("tags.show") || destination === "tag.show") {
        // Ensure any reopened shims are initialized in case anything has added observers
        reopenedClasses.forEach(resolverName => container.lookup(resolverName));
      }
    });
  }
};