import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal @closeModal={{@closeModal}}>
  <:body>
    <p>{{html-safe (i18n "post.controls.delete_topic_disallowed_modal")}}</p>
  </:body>
  <:footer>
    <DButton @action={{@closeModal}} class="btn-primary" @label="close" />
  </:footer>
</DModal>
*/
{
  "id": "sQFUiqfh",
  "block": "[[[8,[39,0],null,[[\"@closeModal\"],[[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,[28,[35,1],[[28,[37,2],[\"post.controls.delete_topic_disallowed_modal\"],null]],null]],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,1],\"close\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"html-safe\",\"i18n\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/delete-topic-disallowed.hbs",
  "isStrictMode": false
});