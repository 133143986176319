import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{category-link @result link=false allowUncategorized=true}}
*/
{
  "id": "aSaxH6Gy",
  "block": "[[[1,[28,[35,0],[[30,1]],[[\"link\",\"allowUncategorized\"],[false,true]]]]],[\"@result\"],false,[\"category-link\"]]",
  "moduleName": "discourse/components/search-menu/results/type/category.hbs",
  "isStrictMode": false
});