import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import Service from "@ember/service";
export default class AdminTopicMenuButtons extends Service {
  constructor() {
    super(...arguments);
    _defineProperty(this, "callbacks", []);
  }
  addButton(callback) {
    this.callbacks.push(callback);
  }
}