/* import __COLOCATED_TEMPLATE__ from './expand-post.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { ajax } from "discourse/lib/ajax";
import TEMPLATE from "./expand-post.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend({
  tagName: "",
  expanded: null,
  _loading: false,
  actions: {
    toggleItem() {
      if (this._loading) {
        return false;
      }
      const item = this.item;
      if (this.expanded) {
        this.set("expanded", false);
        item.set("expandedExcerpt", null);
        return false;
      }
      const topicId = item.get("topic_id");
      const postNumber = item.get("post_number");
      this._loading = true;
      ajax(`/posts/by_number/${topicId}/${postNumber}.json`).then(result => {
        this.set("expanded", true);
        item.set("expandedExcerpt", result.cooked);
      }).finally(() => this._loading = false);
      return false;
    }
  }
}));