import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "topic.make_public.title"}}
  @closeModal={{@closeModal}}
  class="convert-to-public-topic"
  @flash={{this.flash}}
>
  <:body>
    <div class="instructions">
      {{i18n "topic.make_public.choose_category"}}
    </div>
    <CategoryChooser
      @value={{this.publicCategoryId}}
      @onChange={{fn (mut this.publicCategoryId)}}
    />
  </:body>
  <:footer>
    <DButton
      class="btn-primary"
      @action={{this.makePublic}}
      @label="composer.modal_ok"
      @disabled={{this.saving}}
    />
    <DButton
      class="btn-flat d-modal-cancel"
      @action={{@closeModal}}
      @label="cancel"
    />
  </:footer>
</DModal>
*/
{
  "id": "zYMLPY8Z",
  "block": "[[[8,[39,0],[[24,0,\"convert-to-public-topic\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[[28,[37,1],[\"topic.make_public.title\"],null],[30,1],[30,0,[\"flash\"]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,\"\\n      \"],[1,[28,[35,1],[\"topic.make_public.choose_category\"],null]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@onChange\"],[[30,0,[\"publicCategoryId\"]],[28,[37,3],[[28,[37,4],[[30,0,[\"publicCategoryId\"]]],null]],null]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"makePublic\"]],\"composer.modal_ok\",[30,0,[\"saving\"]]]],null],[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-flat d-modal-cancel\"]],[[\"@action\",\"@label\"],[[30,1],\"cancel\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"category-chooser\",\"fn\",\"mut\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/convert-to-public-topic.hbs",
  "isStrictMode": false
});