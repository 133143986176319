import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DButton
  @action={{action "enterTop"}}
  @ariaLabel="topic_entrance.sr_jump_top_button"
  class="btn-default full jump-top"
>
  {{d-icon "step-backward"}}
  {{html-safe this.topDate}}
</DButton>

<DButton
  @action={{action "enterBottom"}}
  @ariaLabel="topic_entrance.sr_jump_bottom_button"
  class="btn-default full jump-bottom"
>
  {{html-safe this.bottomDate}}
  {{d-icon "step-forward"}}
</DButton>
*/
{
  "id": "6i45Ny/9",
  "block": "[[[8,[39,0],[[24,0,\"btn-default full jump-top\"]],[[\"@action\",\"@ariaLabel\"],[[28,[37,1],[[30,0],\"enterTop\"],null],\"topic_entrance.sr_jump_top_button\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,2],[\"step-backward\"],null]],[1,\"\\n  \"],[1,[28,[35,3],[[30,0,[\"topDate\"]]],null]],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],[[24,0,\"btn-default full jump-bottom\"]],[[\"@action\",\"@ariaLabel\"],[[28,[37,1],[[30,0],\"enterBottom\"],null],\"topic_entrance.sr_jump_bottom_button\"]],[[\"default\"],[[[[1,\"\\n  \"],[1,[28,[35,3],[[30,0,[\"bottomDate\"]]],null]],[1,\"\\n  \"],[1,[28,[35,2],[\"step-forward\"],null]],[1,\"\\n\"]],[]]]]]],[],false,[\"d-button\",\"action\",\"d-icon\",\"html-safe\"]]",
  "moduleName": "discourse/components/topic-entrance.hbs",
  "isStrictMode": false
});