import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{if
    @model.post.notice
    (i18n "post.controls.change_post_notice")
    (i18n "post.controls.add_post_notice")
  }}
  @closeModal={{@closeModal}}
  class="change-post-notice-modal"
>
  <:body>
    <form><Textarea @value={{this.notice}} /></form>
  </:body>
  <:footer>
    <DButton
      @label={{if this.saving "saving" "save"}}
      @action={{fn this.setNotice this.notice}}
      @disabled={{this.disabled}}
      class="btn-primary"
    />
    {{#if @model.post.notice}}
      <DButton
        @label="post.controls.delete_post_notice"
        @action={{this.setNotice}}
        @disabled={{this.saving}}
        class="btn-danger"
      />
    {{/if}}
    <DModalCancel @close={{@closeModal}} />
  </:footer>
</DModal>
*/
{
  "id": "MvNVqYJV",
  "block": "[[[8,[39,0],[[24,0,\"change-post-notice-modal\"]],[[\"@title\",\"@closeModal\"],[[52,[30,1,[\"post\",\"notice\"]],[28,[37,2],[\"post.controls.change_post_notice\"],null],[28,[37,2],[\"post.controls.add_post_notice\"],null]],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,\"form\"],[12],[8,[39,3],null,[[\"@value\"],[[30,0,[\"notice\"]]]],null],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@label\",\"@action\",\"@disabled\"],[[52,[30,0,[\"saving\"]],\"saving\",\"save\"],[28,[37,5],[[30,0,[\"setNotice\"]],[30,0,[\"notice\"]]],null],[30,0,[\"disabled\"]]]],null],[1,\"\\n\"],[41,[30,1,[\"post\",\"notice\"]],[[[1,\"      \"],[8,[39,4],[[24,0,\"btn-danger\"]],[[\"@label\",\"@action\",\"@disabled\"],[\"post.controls.delete_post_notice\",[30,0,[\"setNotice\"]],[30,0,[\"saving\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,6],null,[[\"@close\"],[[30,2]]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\"],false,[\"d-modal\",\"if\",\"i18n\",\"textarea\",\"d-button\",\"fn\",\"d-modal-cancel\"]]",
  "moduleName": "discourse/components/modal/change-post-notice.hbs",
  "isStrictMode": false
});