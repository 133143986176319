import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './reviewable-queued-post.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import RawEmailModal from "discourse/components/modal/raw-email";
import TEMPLATE from "./reviewable-queued-post.hbs";
import { setComponentTemplate } from "@ember/component";
let ReviewableQueuedPost = (_class = class ReviewableQueuedPost extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "modal", _descriptor, this);
    _initializerDefineProperty(this, "isCollapsed", _descriptor2, this);
    _initializerDefineProperty(this, "isLongPost", _descriptor3, this);
    _initializerDefineProperty(this, "postBodyHeight", _descriptor4, this);
    _defineProperty(this, "maxPostHeight", 300);
  }
  showRawEmail(event) {
    event?.preventDefault();
    this.modal.show(RawEmailModal, {
      model: {
        rawEmail: this.args.reviewable.payload.raw_email
      }
    });
  }
  toggleContent() {
    this.isCollapsed = !this.isCollapsed;
  }
  get collapseButtonProps() {
    if (this.isCollapsed) {
      return {
        label: "review.show_more",
        icon: "chevron-down"
      };
    }
    return {
      label: "review.show_less",
      icon: "chevron-up"
    };
  }
  setPostBodyHeight(offsetHeight) {
    this.postBodyHeight = offsetHeight;
    if (this.postBodyHeight > this.maxPostHeight) {
      this.isCollapsed = true;
      this.isLongPost = true;
    } else {
      this.isCollapsed = false;
      this.isLongPost = false;
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "modal", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLongPost", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "postBodyHeight", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return 0;
  }
}), _applyDecoratedDescriptor(_class.prototype, "showRawEmail", [action], Object.getOwnPropertyDescriptor(_class.prototype, "showRawEmail"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleContent", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleContent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setPostBodyHeight", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setPostBodyHeight"), _class.prototype)), _class);
export { ReviewableQueuedPost as default };
setComponentTemplate(TEMPLATE, ReviewableQueuedPost);