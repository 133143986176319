import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class2, _descriptor;
import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
let _class = (_class2 = class _class2 extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
  }
  model(params) {
    return this.modelFor("user").get("groups").find(group => {
      return group.name.toLowerCase() === params.name.toLowerCase();
    });
  }
  afterModel(model) {
    if (!model) {
      this.router.transitionTo("exception-unknown");
      return;
    }
  }
  setupController(controller, model) {
    controller.set("group", model);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class2);
export { _class as default };