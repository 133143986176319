import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DButton
  @action={{@close}}
  @translatedLabel={{i18n "cancel"}}
  class="btn-flat d-modal-cancel"
/>
*/
{
  "id": "/jbbeH0V",
  "block": "[[[8,[39,0],[[24,0,\"btn-flat d-modal-cancel\"]],[[\"@action\",\"@translatedLabel\"],[[30,1],[28,[37,1],[\"cancel\"],null]]],null]],[\"@close\"],false,[\"d-button\",\"i18n\"]]",
  "moduleName": "discourse/components/d-modal-cancel.hbs",
  "isStrictMode": false
});