import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @title={{i18n "user.second_factor.security_key.edit"}}
  @closeModal={{@closeModal}}
  @tagName="form"
>
  <:body>
    <div class="input-group">
      <label for="security-key-name">{{i18n
          "user.second_factor.security_key.edit_description"
        }}</label>
      <Input
        name="security-key-name"
        id="security-key-name"
        maxlength={{this.maxSecondFactorNameLength}}
        @type="text"
        @value={{@model.securityKey.name}}
      />
    </div>
  </:body>
  <:footer>
    <DButton
      @action={{this.editSecurityKey}}
      class="btn-primary"
      @label="user.second_factor.security_key.save"
      @type="submit"
    />
  </:footer>
</DModal>
*/
{
  "id": "PMwXmBPc",
  "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\",\"@tagName\"],[[28,[37,1],[\"user.second_factor.security_key.edit\"],null],[30,1],\"form\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"input-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,\"for\",\"security-key-name\"],[12],[1,[28,[35,1],[\"user.second_factor.security_key.edit_description\"],null]],[13],[1,\"\\n      \"],[8,[39,2],[[24,3,\"security-key-name\"],[24,1,\"security-key-name\"],[16,\"maxlength\",[30,0,[\"maxSecondFactorNameLength\"]]]],[[\"@type\",\"@value\"],[\"text\",[30,2,[\"securityKey\",\"name\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@type\"],[[30,0,[\"editSecurityKey\"]],\"user.second_factor.security_key.save\",\"submit\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"input\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/second-factor-edit-security-key.hbs",
  "isStrictMode": false
});