export default function slice() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }
  let array = args.pop();
  if (array instanceof Function) {
    array = array.call();
  }
  if (!Array.isArray(array) || array.length === 0) {
    return [];
  }
  return array.slice(...args);
}