import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{yield
  (hash
    data=this.data
    uploading=this.uploading
    uploadProgress=this.uploadProgress
    uploaded=this.uploaded
    submitDisabled=this.submitDisabled
    startUpload=(action "startUpload")
  )
  this.setElement
}}
*/
{
  "id": "gBs5EjqI",
  "block": "[[[18,1,[[28,[37,1],null,[[\"data\",\"uploading\",\"uploadProgress\",\"uploaded\",\"submitDisabled\",\"startUpload\"],[[30,0,[\"data\"]],[30,0,[\"uploading\"]],[30,0,[\"uploadProgress\"]],[30,0,[\"uploaded\"]],[30,0,[\"submitDisabled\"]],[28,[37,2],[[30,0],\"startUpload\"],null]]]],[30,0,[\"setElement\"]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"action\"]]",
  "moduleName": "discourse/components/create-invite-uploader.hbs",
  "isStrictMode": false
});