import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <a
  href={{this.hrefLink}}
  class={{this.activeClass}}
  aria-current={{if this.activeClass "true"}}
>
  {{#if this.hasIcon}}
    <span class={{this.content.name}}></span>
  {{/if}}
  {{this.content.displayName}}
</a>
*/
{
  "id": "sBzN9Gtv",
  "block": "[[[10,3],[15,6,[30,0,[\"hrefLink\"]]],[15,0,[30,0,[\"activeClass\"]]],[15,\"aria-current\",[52,[30,0,[\"activeClass\"]],\"true\"]],[12],[1,\"\\n\"],[41,[30,0,[\"hasIcon\"]],[[[1,\"    \"],[10,1],[15,0,[30,0,[\"content\",\"name\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[1,[30,0,[\"content\",\"displayName\"]]],[1,\"\\n\"],[13]],[],false,[\"if\"]]",
  "moduleName": "discourse/components/navigation-item.hbs",
  "isStrictMode": false
});