import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="group-result {{if @result.flairUrl '--with-flair'}}">
  {{#if @result.flairUrl}}
    <AvatarFlair
      @flairName={{@result.name}}
      @flairUrl={{@result.flairUrl}}
      @flairBgColor={{@result.flairBgColor}}
      @flairColor={{@result.flairColor}}
      @class="avatar-flair__wrapper"
    />
  {{else}}
    {{d-icon "users"}}
  {{/if}}
  <div class="group-names {{if @result.fullName '--group-with-slug'}}">
    <span class="name">{{or @result.fullName @result.name}}</span>
    {{! show the name of the group if we also show the full name }}
    {{#if @result.fullName}}
      <div class="slug">{{@result.name}}</div>
    {{/if}}
  </div>
</div>
*/
{
  "id": "qWOr+f/B",
  "block": "[[[10,0],[15,0,[29,[\"group-result \",[52,[30,1,[\"flairUrl\"]],\"--with-flair\"]]]],[12],[1,\"\\n\"],[41,[30,1,[\"flairUrl\"]],[[[1,\"    \"],[8,[39,1],null,[[\"@flairName\",\"@flairUrl\",\"@flairBgColor\",\"@flairColor\",\"@class\"],[[30,1,[\"name\"]],[30,1,[\"flairUrl\"]],[30,1,[\"flairBgColor\"]],[30,1,[\"flairColor\"]],\"avatar-flair__wrapper\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,2],[\"users\"],null]],[1,\"\\n\"]],[]]],[1,\"  \"],[10,0],[15,0,[29,[\"group-names \",[52,[30,1,[\"fullName\"]],\"--group-with-slug\"]]]],[12],[1,\"\\n    \"],[10,1],[14,0,\"name\"],[12],[1,[28,[35,3],[[30,1,[\"fullName\"]],[30,1,[\"name\"]]],null]],[13],[1,\"\\n\"],[41,[30,1,[\"fullName\"]],[[[1,\"      \"],[10,0],[14,0,\"slug\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"@result\"],false,[\"if\",\"avatar-flair\",\"d-icon\",\"or\"]]",
  "moduleName": "discourse/components/search-menu/results/type/group.hbs",
  "isStrictMode": false
});