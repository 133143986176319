import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <PluginOutlet
  @name="above-topic-list-item"
  @outletArgs={{hash topic=this.topic}}
/>
{{this.topicListItemContents}}
*/
{
  "id": "r8eDUZIW",
  "block": "[[[8,[39,0],null,[[\"@name\",\"@outletArgs\"],[\"above-topic-list-item\",[28,[37,1],null,[[\"topic\"],[[30,0,[\"topic\"]]]]]]],null],[1,\"\\n\"],[1,[30,0,[\"topicListItemContents\"]]]],[],false,[\"plugin-outlet\",\"hash\"]]",
  "moduleName": "discourse/components/topic-list-item.hbs",
  "isStrictMode": false
});