import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  id="related-messages"
  class="more-topics__list {{if this.hidden 'hidden'}}"
  role="complementary"
  aria-labelledby="related-messages-title"
  {{did-insert this.registerList}}
  {{will-destroy this.removeList}}
>
  <h3 id="related-messages-title" class="more-topics__list-title">
    {{i18n "related_messages.title"}}
  </h3>

  <div class="topics">
    <BasicTopicList
      @hideCategory="true"
      @showPosters="true"
      @topics={{@topic.relatedMessages}}
    />
  </div>

  {{#if this.targetUser}}
    <h3 class="see-all-pms-message">
      {{html-safe
        (i18n
          "related_messages.see_all"
          path=this.searchLink
          username=this.targetUser.username
        )
      }}
    </h3>
  {{/if}}
</div>
*/
{
  "id": "Ydiy8Olw",
  "block": "[[[11,0],[24,1,\"related-messages\"],[16,0,[29,[\"more-topics__list \",[52,[30,0,[\"hidden\"]],\"hidden\"]]]],[24,\"role\",\"complementary\"],[24,\"aria-labelledby\",\"related-messages-title\"],[4,[38,1],[[30,0,[\"registerList\"]]],null],[4,[38,2],[[30,0,[\"removeList\"]]],null],[12],[1,\"\\n  \"],[10,\"h3\"],[14,1,\"related-messages-title\"],[14,0,\"more-topics__list-title\"],[12],[1,\"\\n    \"],[1,[28,[35,3],[\"related_messages.title\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"topics\"],[12],[1,\"\\n    \"],[8,[39,4],null,[[\"@hideCategory\",\"@showPosters\",\"@topics\"],[\"true\",\"true\",[30,1,[\"relatedMessages\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"targetUser\"]],[[[1,\"    \"],[10,\"h3\"],[14,0,\"see-all-pms-message\"],[12],[1,\"\\n      \"],[1,[28,[35,5],[[28,[37,3],[\"related_messages.see_all\"],[[\"path\",\"username\"],[[30,0,[\"searchLink\"]],[30,0,[\"targetUser\",\"username\"]]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"@topic\"],false,[\"if\",\"did-insert\",\"will-destroy\",\"i18n\",\"basic-topic-list\",\"html-safe\"]]",
  "moduleName": "discourse/components/related-messages.hbs",
  "isStrictMode": false
});