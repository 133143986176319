import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  class="header-contents"
  id={{this.id}}
  role="button"
  tabindex="0"
  aria-label={{this.ariaLabel}}
  aria-pressed={{this.pressedState}}
>

  {{yield}}
  <span class="text">
    {{directory-table-header-title
      field=this.field
      labelKey=this.labelKey
      icon=this.icon
      translated=this.translated
    }}
    {{this.chevronIcon}}
  </span>
</div>
*/
{
  "id": "mJT5cJoN",
  "block": "[[[10,0],[14,0,\"header-contents\"],[15,1,[30,0,[\"id\"]]],[14,\"role\",\"button\"],[14,\"tabindex\",\"0\"],[15,\"aria-label\",[30,0,[\"ariaLabel\"]]],[15,\"aria-pressed\",[30,0,[\"pressedState\"]]],[12],[1,\"\\n\\n  \"],[18,1,null],[1,\"\\n  \"],[10,1],[14,0,\"text\"],[12],[1,\"\\n    \"],[1,[28,[35,1],null,[[\"field\",\"labelKey\",\"icon\",\"translated\"],[[30,0,[\"field\"]],[30,0,[\"labelKey\"]],[30,0,[\"icon\"]],[30,0,[\"translated\"]]]]]],[1,\"\\n    \"],[1,[30,0,[\"chevronIcon\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\",\"directory-table-header-title\"]]",
  "moduleName": "discourse/components/table-header-toggle.hbs",
  "isStrictMode": false
});