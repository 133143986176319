import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _class;
/* import __COLOCATED_TEMPLATE__ from './user-status-picker.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action, computed } from "@ember/object";
import { scheduleOnce } from "@ember/runloop";
import { emojiUnescape } from "discourse/lib/text";
import { escapeExpression } from "discourse/lib/utilities";
import TEMPLATE from "./user-status-picker.hbs";
let UserStatusPicker = (_dec = computed("status.emoji"), (_class = class UserStatusPicker extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "tagName", "");
    _defineProperty(this, "isFocused", false);
    _defineProperty(this, "emojiPickerIsActive", false);
  }
  didInsertElement() {
    super.didInsertElement(...arguments);
    if (!this.status) {
      this.set("status", {});
    }
    document.querySelector(".user-status-description")?.focus();
  }
  get emojiHtml() {
    const emoji = escapeExpression(`:${this.status.emoji}:`);
    return emojiUnescape(emoji);
  }
  focusEmojiButton() {
    document.querySelector(".btn-emoji")?.focus();
  }
  blur() {
    this.set("isFocused", false);
  }
  emojiSelected(emoji) {
    this.set("status.emoji", emoji);
    this.set("emojiPickerIsActive", false);
    scheduleOnce("afterRender", this, this.focusEmojiButton);
  }
  focus() {
    this.set("isFocused", true);
  }
  onEmojiPickerOutsideClick() {
    this.set("emojiPickerIsActive", false);
  }
  setDefaultEmoji() {
    if (!this.status.emoji) {
      this.set("status.emoji", "speech_balloon");
    }
  }
  toggleEmojiPicker(event) {
    event.stopPropagation();
    this.set("emojiPickerIsActive", !this.emojiPickerIsActive);
  }
}, (_applyDecoratedDescriptor(_class.prototype, "emojiHtml", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "emojiHtml"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "blur", [action], Object.getOwnPropertyDescriptor(_class.prototype, "blur"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "emojiSelected", [action], Object.getOwnPropertyDescriptor(_class.prototype, "emojiSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "focus", [action], Object.getOwnPropertyDescriptor(_class.prototype, "focus"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onEmojiPickerOutsideClick", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onEmojiPickerOutsideClick"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDefaultEmoji", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setDefaultEmoji"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleEmojiPicker", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEmojiPicker"), _class.prototype)), _class));
export { UserStatusPicker as default };
setComponentTemplate(TEMPLATE, UserStatusPicker);