import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{~#if this.showBasedOnPosition}}
  <div class="row {{this.containerClass}}">
    {{~#if this.showDismissRead}}
      <DButton
        @action={{this.dismissReadPosts}}
        @translatedLabel={{this.dismissLabel}}
        @title="topics.bulk.dismiss_tooltip"
        id={{this.dismissReadId}}
        class="btn-default dismiss-read"
      />
    {{/if~}}
    {{~#if this.showResetNew}}
      <DButton
        @action={{this.resetNew}}
        @icon="check"
        @translatedLabel={{this.dismissNewLabel}}
        id={{this.dismissNewId}}
        class="btn-default dismiss-read"
      />
    {{/if~}}
  </div>
{{/if~}}
*/
{
  "id": "+BgvqNKM",
  "block": "[[[41,[30,0,[\"showBasedOnPosition\"]],[[[1,\"  \"],[10,0],[15,0,[29,[\"row \",[30,0,[\"containerClass\"]]]]],[12],[41,[30,0,[\"showDismissRead\"]],[[[1,\"      \"],[8,[39,1],[[16,1,[30,0,[\"dismissReadId\"]]],[24,0,\"btn-default dismiss-read\"]],[[\"@action\",\"@translatedLabel\",\"@title\"],[[30,0,[\"dismissReadPosts\"]],[30,0,[\"dismissLabel\"]],\"topics.bulk.dismiss_tooltip\"]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"showResetNew\"]],[[[1,\"      \"],[8,[39,1],[[16,1,[30,0,[\"dismissNewId\"]]],[24,0,\"btn-default dismiss-read\"]],[[\"@action\",\"@icon\",\"@translatedLabel\"],[[30,0,[\"resetNew\"]],\"check\",[30,0,[\"dismissNewLabel\"]]]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\"]]",
  "moduleName": "discourse/components/topic-dismiss-buttons.hbs",
  "isStrictMode": false
});