import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.canCreateTopic}}
  <DButtonTooltip>
    <:button>
      <DButton
        @action={{this.action}}
        @icon="plus"
        @disabled={{this.disabled}}
        @label={{this.label}}
        id="create-topic"
        class={{this.btnClass}}
      />
    </:button>
    <:tooltip>
      {{#if @disabled}}
        <DTooltip
          @icon="info-circle"
          @content={{i18n "topic.create_disabled_category"}}
        />
      {{/if}}
    </:tooltip>
  </DButtonTooltip>
{{/if}}
*/
{
  "id": "daVYhCIf",
  "block": "[[[41,[30,0,[\"canCreateTopic\"]],[[[1,\"  \"],[8,[39,1],null,null,[[\"button\",\"tooltip\"],[[[[1,\"\\n      \"],[8,[39,2],[[24,1,\"create-topic\"],[16,0,[30,0,[\"btnClass\"]]]],[[\"@action\",\"@icon\",\"@disabled\",\"@label\"],[[30,0,[\"action\"]],\"plus\",[30,0,[\"disabled\"]],[30,0,[\"label\"]]]],null],[1,\"\\n    \"]],[]],[[[1,\"\\n\"],[41,[30,1],[[[1,\"        \"],[8,[39,3],null,[[\"@icon\",\"@content\"],[\"info-circle\",[28,[37,4],[\"topic.create_disabled_category\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@disabled\"],false,[\"if\",\"d-button-tooltip\",\"d-button\",\"d-tooltip\",\"i18n\"]]",
  "moduleName": "discourse/components/create-topic-button.hbs",
  "isStrictMode": false
});