import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{html-safe this.text}}
*/
{
  "id": "5pDKnoM2",
  "block": "[[[1,[28,[35,0],[[30,0,[\"text\"]]],null]]],[],false,[\"html-safe\"]]",
  "moduleName": "discourse/components/text-overflow.hbs",
  "isStrictMode": false
});