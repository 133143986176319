import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
/* import __COLOCATED_TEMPLATE__ from './group-selector.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { isEmpty } from "@ember/utils";
import $ from "jquery";
import { findRawTemplate } from "discourse-common/lib/raw-templates";
import discourseComputed, { observes, on } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./group-selector.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("placeholderKey"), _dec2 = observes("groupNames"), _dec3 = on("didInsertElement"), (_obj = {
  placeholder(placeholderKey) {
    return placeholderKey ? I18n.t(placeholderKey) : "";
  },
  _update() {
    if (this.canReceiveUpdates === "true") {
      this._initializeAutocomplete({
        updateData: true
      });
    }
  },
  _initializeAutocomplete(opts) {
    let selectedGroups;
    let groupNames = this.groupNames;
    $(this.element.querySelector("input")).autocomplete({
      debounced: true,
      allowAny: false,
      items: Array.isArray(groupNames) ? groupNames : isEmpty(groupNames) ? [] : [groupNames],
      single: this.single,
      fullWidthWrap: this.fullWidthWrap,
      updateData: opts && opts.updateData ? opts.updateData : false,
      onChangeItems: items => {
        selectedGroups = items;
        if (this.onChangeCallback) {
          this.onChangeCallback(this.groupNames, selectedGroups);
        } else {
          this.set("groupNames", items.join(","));
        }
      },
      transformComplete: g => {
        return g.name;
      },
      dataSource: term => {
        return this.groupFinder(term).then(groups => {
          if (!selectedGroups) {
            return groups;
          }
          return groups.filter(group => {
            return !selectedGroups.any(s => s === group.name);
          });
        });
      },
      template: findRawTemplate("group-selector-autocomplete")
    });
  }
}, (_applyDecoratedDescriptor(_obj, "placeholder", [_dec], Object.getOwnPropertyDescriptor(_obj, "placeholder"), _obj), _applyDecoratedDescriptor(_obj, "_update", [_dec2], Object.getOwnPropertyDescriptor(_obj, "_update"), _obj), _applyDecoratedDescriptor(_obj, "_initializeAutocomplete", [_dec3], Object.getOwnPropertyDescriptor(_obj, "_initializeAutocomplete"), _obj)), _obj))));