import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <h3 class="stats-title">{{i18n (concat "user.summary." @title)}}</h3>
{{yield}}
*/
{
  "id": "jWdvCjy7",
  "block": "[[[10,\"h3\"],[14,0,\"stats-title\"],[12],[1,[28,[35,0],[[28,[37,1],[\"user.summary.\",[30,1]],null]],null]],[13],[1,\"\\n\"],[18,2,null]],[\"@title\",\"&default\"],false,[\"i18n\",\"concat\",\"yield\"]]",
  "moduleName": "discourse/components/user-summary-section.hbs",
  "isStrictMode": false
});