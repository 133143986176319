import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div
  style={{this.style}}
  class="timeline-scroller"
  {{draggable
    didStartDrag=@didStartDrag
    didEndDrag=@didEndDrag
    dragMove=@dragMove
  }}
  ...attributes
>
  {{#if @fullscreen}}
    <div class="timeline-scroller-content">
      <div class="timeline-replies">
        {{this.repliesShort}}
      </div>
      {{#if @date}}
        <div class="timeline-ago">
          {{this.timelineAgo}}
        </div>
      {{/if}}
      {{#if (and @showDockedButton (not @dragging))}}
        <TopicTimeline::BackButton @onGoBack={{@onGoBack}} />
      {{/if}}
    </div>
    <div class="timeline-handle"></div>
  {{else}}
    <div class="timeline-handle"></div>
    <div class="timeline-scroller-content">
      <div class="timeline-replies">
        {{this.repliesShort}}
      </div>
      {{#if @date}}
        <div class="timeline-ago">
          {{this.timelineAgo}}
        </div>
      {{/if}}
      {{#if (and @showDockedButton (not @dragging))}}
        <TopicTimeline::BackButton @onGoBack={{@onGoBack}} />
      {{/if}}
    </div>
  {{/if}}
</div>
*/
{
  "id": "wLtEmEbL",
  "block": "[[[11,0],[16,5,[30,0,[\"style\"]]],[24,0,\"timeline-scroller\"],[17,1],[4,[38,0],null,[[\"didStartDrag\",\"didEndDrag\",\"dragMove\"],[[30,2],[30,3],[30,4]]]],[12],[1,\"\\n\"],[41,[30,5],[[[1,\"    \"],[10,0],[14,0,\"timeline-scroller-content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"timeline-replies\"],[12],[1,\"\\n        \"],[1,[30,0,[\"repliesShort\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[10,0],[14,0,\"timeline-ago\"],[12],[1,\"\\n          \"],[1,[30,0,[\"timelineAgo\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,2],[[30,7],[28,[37,3],[[30,8]],null]],null],[[[1,\"        \"],[8,[39,4],null,[[\"@onGoBack\"],[[30,9]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"timeline-handle\"],[12],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"timeline-handle\"],[12],[13],[1,\"\\n    \"],[10,0],[14,0,\"timeline-scroller-content\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"timeline-replies\"],[12],[1,\"\\n        \"],[1,[30,0,[\"repliesShort\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,6],[[[1,\"        \"],[10,0],[14,0,\"timeline-ago\"],[12],[1,\"\\n          \"],[1,[30,0,[\"timelineAgo\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[28,[37,2],[[30,7],[28,[37,3],[[30,8]],null]],null],[[[1,\"        \"],[8,[39,4],null,[[\"@onGoBack\"],[[30,9]]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"@didStartDrag\",\"@didEndDrag\",\"@dragMove\",\"@fullscreen\",\"@date\",\"@showDockedButton\",\"@dragging\",\"@onGoBack\"],false,[\"draggable\",\"if\",\"and\",\"not\",\"topic-timeline/back-button\"]]",
  "moduleName": "discourse/components/topic-timeline/scroller.hbs",
  "isStrictMode": false
});