import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.showPercentage}}
  <div title={{this.percentage.title}} class="user-flag-percentage">
    <span
      class="percentage-label {{this.percentage.className}}"
    >{{this.percentage.label}}</span>
    {{d-icon this.percentage.icon}}
  </div>
{{/if}}
*/
{
  "id": "PzzJcGil",
  "block": "[[[41,[30,0,[\"showPercentage\"]],[[[1,\"  \"],[10,0],[15,\"title\",[30,0,[\"percentage\",\"title\"]]],[14,0,\"user-flag-percentage\"],[12],[1,\"\\n    \"],[10,1],[15,0,[29,[\"percentage-label \",[30,0,[\"percentage\",\"className\"]]]]],[12],[1,[30,0,[\"percentage\",\"label\"]]],[13],[1,\"\\n    \"],[1,[28,[35,1],[[30,0,[\"percentage\",\"icon\"]]],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-icon\"]]",
  "moduleName": "discourse/components/user-flag-percentage.hbs",
  "isStrictMode": false
});