import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
/* import __COLOCATED_TEMPLATE__ from './categories-only.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./categories-only.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("showMutedCategories", "filteredCategories.length"), _dec2 = discourseComputed("showMuted", "filteredCategories.length"), _dec3 = discourseComputed("categories", "categories.length"), _dec4 = discourseComputed("categories", "categories.length"), (_obj = {
  tagName: "",
  showMuted: false,
  mutedToggleIcon(showMutedCategories, filteredCategoriesLength) {
    if (filteredCategoriesLength === 0) {
      return;
    }
    if (showMutedCategories) {
      return "minus";
    }
    return "plus";
  },
  showMutedCategories(showMuted, filteredCategoriesLength) {
    return showMuted || filteredCategoriesLength === 0;
  },
  filteredCategories(categories, categoriesLength) {
    if (!categories || categoriesLength === 0) {
      return [];
    }
    return categories.filter(cat => !cat.isHidden);
  },
  mutedCategories(categories, categoriesLength) {
    if (!categories || categoriesLength === 0) {
      return [];
    }

    // hide in single category pages
    if (categories.firstObject.parent_category_id) {
      return [];
    }
    return categories.filterBy("hasMuted");
  },
  toggleShowMuted(event) {
    event?.preventDefault();
    this.toggleProperty("showMuted");
  }
}, (_applyDecoratedDescriptor(_obj, "mutedToggleIcon", [_dec], Object.getOwnPropertyDescriptor(_obj, "mutedToggleIcon"), _obj), _applyDecoratedDescriptor(_obj, "showMutedCategories", [_dec2], Object.getOwnPropertyDescriptor(_obj, "showMutedCategories"), _obj), _applyDecoratedDescriptor(_obj, "filteredCategories", [_dec3], Object.getOwnPropertyDescriptor(_obj, "filteredCategories"), _obj), _applyDecoratedDescriptor(_obj, "mutedCategories", [_dec4], Object.getOwnPropertyDescriptor(_obj, "mutedCategories"), _obj), _applyDecoratedDescriptor(_obj, "toggleShowMuted", [action], Object.getOwnPropertyDescriptor(_obj, "toggleShowMuted"), _obj)), _obj))));