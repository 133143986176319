import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="fps-result-entries" role="list">
  {{#each this.posts as |post|}}
    <SearchResultEntry
      @post={{post}}
      @bulkSelectEnabled={{this.bulkSelectEnabled}}
      @selected={{this.selected}}
      @highlightQuery={{this.highlightQuery}}
      @searchLogId={{this.searchLogId}}
    />
  {{/each}}
</div>
*/
{
  "id": "Q31gFmjo",
  "block": "[[[10,0],[14,0,\"fps-result-entries\"],[14,\"role\",\"list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"posts\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@post\",\"@bulkSelectEnabled\",\"@selected\",\"@highlightQuery\",\"@searchLogId\"],[[30,1],[30,0,[\"bulkSelectEnabled\"]],[30,0,[\"selected\"]],[30,0,[\"highlightQuery\"]],[30,0,[\"searchLogId\"]]]],null],[1,\"\\n\"]],[1]],null],[13]],[\"post\"],false,[\"each\",\"-track-array\",\"search-result-entry\"]]",
  "moduleName": "discourse/components/search-result-entries.hbs",
  "isStrictMode": false
});