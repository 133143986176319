import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="header">
  <h3 class="title">{{html-safe this.shareTitle}}</h3>
</div>

<div class="body">
  <DTextarea
    @value={{this.shareUrl}}
    @aria-label={{i18n "share.url"}}
    class="topic-share-url"
  />

  <div class="sources">
    {{#each this.sources as |source|}}
      <ShareSource @source={{source}} @action={{this.share}} />
    {{/each}}
  </div>
</div>
*/
{
  "id": "g2zI0eIa",
  "block": "[[[10,0],[14,0,\"header\"],[12],[1,\"\\n  \"],[10,\"h3\"],[14,0,\"title\"],[12],[1,[28,[35,0],[[30,0,[\"shareTitle\"]]],null]],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"body\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"topic-share-url\"]],[[\"@value\",\"@aria-label\"],[[30,0,[\"shareUrl\"]],[28,[37,2],[\"share.url\"],null]]],null],[1,\"\\n\\n  \"],[10,0],[14,0,\"sources\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"sources\"]]],null]],null],null,[[[1,\"      \"],[8,[39,5],null,[[\"@source\",\"@action\"],[[30,1],[30,0,[\"share\"]]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"source\"],false,[\"html-safe\",\"d-textarea\",\"i18n\",\"each\",\"-track-array\",\"share-source\"]]",
  "moduleName": "discourse/components/share-panel.hbs",
  "isStrictMode": false
});