import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './directory-table.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import TEMPLATE from "./directory-table.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  _table: null,
  didInsertElement() {
    this._super(...arguments);
    this.setProperties({
      _table: this.element.querySelector(".directory-table"),
      _columnCount: this.showTimeRead ? this.columns.length + 1 : this.columns.length
    });
    this._table.style.gridTemplateColumns = `minmax(15em, 3fr) repeat(${this._columnCount}, minmax(max-content, 1fr))`;
  },
  setActiveHeader(header) {
    // After render, scroll table left to ensure the order by column is visible
    if (!this._table) {
      this.set("_table", document.querySelector(".directory-table"));
    }
    const scrollPixels = header.offsetLeft + header.offsetWidth + 10 - this._table.offsetWidth;
    if (scrollPixels > 0) {
      this._table.scrollLeft = scrollPixels;
    }
  }
}, (_applyDecoratedDescriptor(_obj, "setActiveHeader", [action], Object.getOwnPropertyDescriptor(_obj, "setActiveHeader"), _obj)), _obj)));