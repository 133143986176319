import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
import { tracked } from "@glimmer/tracking";
import Controller from "@ember/controller";
import { action } from "@ember/object";
let _class = (_class2 = class _class2 extends Controller {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "tagsForUser", _descriptor, this);
    _initializerDefineProperty(this, "sortedByCount", _descriptor2, this);
    _initializerDefineProperty(this, "sortedByName", _descriptor3, this);
    _initializerDefineProperty(this, "sortProperties", _descriptor4, this);
  }
  sortByCount(event) {
    event?.preventDefault();
    this.sortProperties = ["count:desc", "id"];
    this.sortedByCount = true;
    this.sortedByName = false;
  }
  sortById(event) {
    event?.preventDefault();
    this.sortProperties = ["id"];
    this.sortedByCount = false;
    this.sortedByName = true;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "tagsForUser", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "sortedByCount", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return true;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "sortedByName", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "sortProperties", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return ["count:desc", "id"];
  }
}), _applyDecoratedDescriptor(_class2.prototype, "sortByCount", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "sortByCount"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "sortById", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "sortById"), _class2.prototype)), _class2);
export { _class as default };