import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @closeModal={{@closeModal}}
  @title={{i18n "user.second_factor.security_key.add"}}
  {{did-insert this.securityKeyRequested}}
>
  <:body>
    <ConditionalLoadingSpinner @condition={{this.loading}}>
      {{#if this.errorMessage}}
        <div class="control-group">
          <div class="controls">
            <div class="alert alert-error">{{this.errorMessage}}</div>
          </div>
        </div>
      {{/if}}

      <div class="control-group">
        <div class="controls">
          {{html-safe
            (i18n "user.second_factor.enable_security_key_description")
          }}
        </div>
      </div>

      <div class="control-group">
        <div class="controls">
          <Input
            @value={{this.securityKeyName}}
            id="security-key-name"
            placeholder="security key name"
            maxlength={{this.maxSecondFactorNameLength}}
          />
        </div>
      </div>

      <div class="control-group">
        <div class="controls">
          {{#unless this.webauthnUnsupported}}
            <DButton
              class="btn-primary add-security-key"
              @action={{this.registerSecurityKey}}
              @label="user.second_factor.security_key.register"
            />
          {{/unless}}
        </div>
      </div>
    </ConditionalLoadingSpinner>
  </:body>
</DModal>
*/
{
  "id": "cuA70RP/",
  "block": "[[[8,[39,0],[[4,[38,2],[[30,0,[\"securityKeyRequested\"]]],null]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"user.second_factor.security_key.add\"],null]]],[[\"body\"],[[[[1,\"\\n    \"],[8,[39,3],null,[[\"@condition\"],[[30,0,[\"loading\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"errorMessage\"]],[[[1,\"        \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n          \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"alert alert-error\"],[12],[1,[30,0,[\"errorMessage\"]]],[13],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[1,[28,[35,5],[[28,[37,1],[\"user.second_factor.enable_security_key_description\"],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[39,6],[[24,1,\"security-key-name\"],[24,\"placeholder\",\"security key name\"],[16,\"maxlength\",[30,0,[\"maxSecondFactorNameLength\"]]]],[[\"@value\"],[[30,0,[\"securityKeyName\"]]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"webauthnUnsupported\"]]],[[[1,\"            \"],[8,[39,8],[[24,0,\"btn-primary add-security-key\"]],[[\"@action\",\"@label\"],[[30,0,[\"registerSecurityKey\"]],\"user.second_factor.security_key.register\"]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"did-insert\",\"conditional-loading-spinner\",\"if\",\"html-safe\",\"input\",\"unless\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/second-factor-add-security-key.hbs",
  "isStrictMode": false
});