import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{body-class "account-activation-page"}}
<div class="container invites-show">
  <div class="login-welcome-header">
    <h1 class="login-title">{{this.welcomeTitle}}</h1>
    <img src={{this.wavingHandURL}} alt="" class="waving-hand" />
  </div>

  <div class="ac-page">
    <div class="two-col">
      <div class="col-image">
        <img src={{this.envelopeImageUrl}} alt="" />
      </div>
      <div class="col-form">
        <div class="success-info">
          {{html-safe this.accountCreated.message}}
        </div>
        {{#if this.accountCreated.show_controls}}
          <ActivationControls
            @sendActivationEmail={{action "sendActivationEmail"}}
            @editActivationEmail={{action "editActivationEmail"}}
          />
        {{/if}}
      </div>
    </div>
  </div>
</div>
*/
{
  "id": "sYMQgSZu",
  "block": "[[[1,[28,[35,0],[\"account-activation-page\"],null]],[1,\"\\n\"],[10,0],[14,0,\"container invites-show\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"login-welcome-header\"],[12],[1,\"\\n    \"],[10,\"h1\"],[14,0,\"login-title\"],[12],[1,[30,0,[\"welcomeTitle\"]]],[13],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[30,0,[\"wavingHandURL\"]]],[14,\"alt\",\"\"],[14,0,\"waving-hand\"],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"ac-page\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"two-col\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"col-image\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,0,[\"envelopeImageUrl\"]]],[14,\"alt\",\"\"],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"col-form\"],[12],[1,\"\\n        \"],[10,0],[14,0,\"success-info\"],[12],[1,\"\\n          \"],[1,[28,[35,1],[[30,0,[\"accountCreated\",\"message\"]]],null]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"accountCreated\",\"show_controls\"]],[[[1,\"          \"],[8,[39,3],null,[[\"@sendActivationEmail\",\"@editActivationEmail\"],[[28,[37,4],[[30,0],\"sendActivationEmail\"],null],[28,[37,4],[[30,0],\"editActivationEmail\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"body-class\",\"html-safe\",\"if\",\"activation-controls\",\"action\"]]",
  "moduleName": "discourse/templates/account-created/index.hbs",
  "isStrictMode": false
});