import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './composer-container.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import TEMPLATE from "./composer-container.hbs";
import { setComponentTemplate } from "@ember/component";
let ComposerContainer = (_class = class ComposerContainer extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "composer", _descriptor, this);
    _initializerDefineProperty(this, "site", _descriptor2, this);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "composer", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { ComposerContainer as default };
setComponentTemplate(TEMPLATE, ComposerContainer);