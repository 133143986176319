import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './copy-button.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import discourseDebounce from "discourse-common/lib/debounce";
import { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./copy-button.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  tagName: "",
  copyIcon: "copy",
  copyClass: "btn-primary",
  _restoreButton() {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    this.set("copyIcon", "copy");
    this.set("copyClass", "btn-primary");
  },
  copy() {
    const target = document.querySelector(this.selector);
    target.select();
    target.setSelectionRange(0, target.value.length);
    try {
      document.execCommand("copy");
      if (this.copied) {
        this.copied();
      }
      this.set("copyIcon", "check");
      this.set("copyClass", "btn-primary ok");
      discourseDebounce(this._restoreButton, 3000);
    } catch (err) {}
  }
}, (_applyDecoratedDescriptor(_obj, "_restoreButton", [bind], Object.getOwnPropertyDescriptor(_obj, "_restoreButton"), _obj), _applyDecoratedDescriptor(_obj, "copy", [action], Object.getOwnPropertyDescriptor(_obj, "copy"), _obj)), _obj)));