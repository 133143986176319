import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.siteSettings.experimental_form_templates}}
  <div class="control-group">
    <DToggleSwitch
      class="toggle-template-type"
      @state={{this.showFormTemplate}}
      @label={{this.templateTypeToggleLabel}}
      {{on "click" this.toggleTemplateType}}
    />
  </div>

  {{#if this.showFormTemplate}}
    <div class="control-group">
      <FormTemplateChooser
        @value={{this.category.form_template_ids}}
        @onChange={{fn (mut this.category.form_template_ids)}}
        class="select-category-template"
      />

      <p class="select-category-template__info desc">
        {{#if this.currentUser.staff}}
          <LinkTo @route="adminCustomizeFormTemplates">
            {{i18n "admin.form_templates.edit_category.select_template_help"}}
          </LinkTo>
        {{/if}}
      </p>
    </div>
  {{else}}
    <DEditor
      @value={{this.category.topic_template}}
      @showLink={{this.showInsertLinkButton}}
    />
  {{/if}}
{{else}}
  <DEditor
    @value={{this.category.topic_template}}
    @showLink={{this.showInsertLinkButton}}
  />
{{/if}}
*/
{
  "id": "QiczNRt0",
  "block": "[[[41,[30,0,[\"siteSettings\",\"experimental_form_templates\"]],[[[1,\"  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[8,[39,1],[[24,0,\"toggle-template-type\"],[4,[38,2],[\"click\",[30,0,[\"toggleTemplateType\"]]],null]],[[\"@state\",\"@label\"],[[30,0,[\"showFormTemplate\"]],[30,0,[\"templateTypeToggleLabel\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showFormTemplate\"]],[[[1,\"    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,0,\"select-category-template\"]],[[\"@value\",\"@onChange\"],[[30,0,[\"category\",\"form_template_ids\"]],[28,[37,4],[[28,[37,5],[[30,0,[\"category\",\"form_template_ids\"]]],null]],null]]],null],[1,\"\\n\\n      \"],[10,2],[14,0,\"select-category-template__info desc\"],[12],[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"          \"],[8,[39,6],null,[[\"@route\"],[\"adminCustomizeFormTemplates\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[35,7],[\"admin.form_templates.edit_category.select_template_help\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,8],null,[[\"@value\",\"@showLink\"],[[30,0,[\"category\",\"topic_template\"]],[30,0,[\"showInsertLinkButton\"]]]],null],[1,\"\\n\"]],[]]]],[]],[[[1,\"  \"],[8,[39,8],null,[[\"@value\",\"@showLink\"],[[30,0,[\"category\",\"topic_template\"]],[30,0,[\"showInsertLinkButton\"]]]],null],[1,\"\\n\"]],[]]]],[],false,[\"if\",\"d-toggle-switch\",\"on\",\"form-template-chooser\",\"fn\",\"mut\",\"link-to\",\"i18n\",\"d-editor\"]]",
  "moduleName": "discourse/components/edit-category-topic-template.hbs",
  "isStrictMode": false
});