import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @bodyClass="change-ownership"
  @closeModal={{@closeModal}}
  @title={{i18n "topic.change_owner.title"}}
  @flash={{this.flash}}
  @flashType="error"
  class="change-ownership-modal"
>
  <:body>
    <span>
      {{html-safe
        (i18n
          (if
            this.selectedPostsUsername
            "topic.change_owner.instructions"
            "topic.change_owner.instructions_without_old_user"
          )
          count=this.selectedPostsCount
          old_user=this.selectedPostsUsername
        )
      }}
    </span>

    <form>
      <label></label>
      <EmailGroupUserChooser
        @value={{this.newOwner}}
        @autofocus={{true}}
        @onChange={{this.updateNewOwner}}
        @options={{hash
          maximum=1
          filterPlaceholder="topic.change_owner.placeholder"
        }}
      />
    </form>
  </:body>
  <:footer>
    <DButton
      {{on "click" this.changeOwnershipOfPosts}}
      @disabled={{this.buttonDisabled}}
      @label={{if this.saving "saving" "topic.change_owner.action"}}
      class="btn-primary"
    />
  </:footer>
</DModal>
*/
{
  "id": "wli/WPh2",
  "block": "[[[8,[39,0],[[24,0,\"change-ownership-modal\"]],[[\"@bodyClass\",\"@closeModal\",\"@title\",\"@flash\",\"@flashType\"],[\"change-ownership\",[30,1],[28,[37,1],[\"topic.change_owner.title\"],null],[30,0,[\"flash\"]],\"error\"]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,1],[12],[1,\"\\n      \"],[1,[28,[35,2],[[28,[37,1],[[52,[30,0,[\"selectedPostsUsername\"]],\"topic.change_owner.instructions\",\"topic.change_owner.instructions_without_old_user\"]],[[\"count\",\"old_user\"],[[30,0,[\"selectedPostsCount\"]],[30,0,[\"selectedPostsUsername\"]]]]]],null]],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,\"form\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[13],[1,\"\\n      \"],[8,[39,4],null,[[\"@value\",\"@autofocus\",\"@onChange\",\"@options\"],[[30,0,[\"newOwner\"]],true,[30,0,[\"updateNewOwner\"]],[28,[37,5],null,[[\"maximum\",\"filterPlaceholder\"],[1,\"topic.change_owner.placeholder\"]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,6],[[24,0,\"btn-primary\"],[4,[38,7],[\"click\",[30,0,[\"changeOwnershipOfPosts\"]]],null]],[[\"@disabled\",\"@label\"],[[30,0,[\"buttonDisabled\"]],[52,[30,0,[\"saving\"]],\"saving\",\"topic.change_owner.action\"]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"html-safe\",\"if\",\"email-group-user-chooser\",\"hash\",\"d-button\",\"on\"]]",
  "moduleName": "discourse/components/modal/change-owner.hbs",
  "isStrictMode": false
});