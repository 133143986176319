import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{d-icon "tag"}}
{{discourse-tag (or @result.id @result) tagName="span"}}
*/
{
  "id": "Ygddpubc",
  "block": "[[[1,[28,[35,0],[\"tag\"],null]],[1,\"\\n\"],[1,[28,[35,1],[[28,[37,2],[[30,1,[\"id\"]],[30,1]],null]],[[\"tagName\"],[\"span\"]]]]],[\"@result\"],false,[\"d-icon\",\"discourse-tag\",\"or\"]]",
  "moduleName": "discourse/components/search-menu/results/type/tag.hbs",
  "isStrictMode": false
});