import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @closeModal={{@closeModal}}
  @title={{i18n "log_in"}}
  class="not-activated-modal"
>
  <:body>
    {{html-safe (i18n "login.not_activated" sentTo=@model.sentTo)}}
  </:body>
  <:footer>
    <ActivationControls
      @sendActivationEmail={{this.sendActivationEmail}}
      @editActivationEmail={{this.editActivationEmail}}
    />
  </:footer>
</DModal>
*/
{
  "id": "IRbuoAJb",
  "block": "[[[8,[39,0],[[24,0,\"not-activated-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"log_in\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[28,[35,2],[[28,[37,1],[\"login.not_activated\"],[[\"sentTo\"],[[30,2,[\"sentTo\"]]]]]],null]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,3],null,[[\"@sendActivationEmail\",\"@editActivationEmail\"],[[30,0,[\"sendActivationEmail\"]],[30,0,[\"editActivationEmail\"]]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"html-safe\",\"activation-controls\"]]",
  "moduleName": "discourse/components/modal/not-activated.hbs",
  "isStrictMode": false
});