import { registerWidgetShim } from "discourse/widgets/render-glimmer";
import { createTemplateFactory } from "@ember/template-factory";
registerWidgetShim("before-header-panel-outlet", "div.before-header-panel-outlet", createTemplateFactory(
/*
  <PluginOutlet @name="before-header-panel" @outletArgs={{hash topic=@data.topic}} /> 
*/
{
  "id": "rkeZTl8e",
  "block": "[[[8,[39,0],null,[[\"@name\",\"@outletArgs\"],[\"before-header-panel\",[28,[37,1],null,[[\"topic\"],[[30,1,[\"topic\"]]]]]]],null],[1,\" \"]],[\"@data\"],false,[\"plugin-outlet\",\"hash\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/widgets/before-header-panel-outlet.js",
  "isStrictMode": false
}));