import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <section class="field category-name-fields">
  {{#unless this.category.isUncategorizedCategory}}
    <section class="field-item">
      <label>{{i18n "category.name"}}</label>
      <TextField
        @value={{this.category.name}}
        @placeholderKey="category.name_placeholder"
        @maxlength="50"
        class="category-name"
      />
    </section>
  {{/unless}}
  <section class="field-item">
    <label>{{i18n "category.slug"}}</label>
    <TextField
      @value={{this.category.slug}}
      @placeholderKey="category.slug_placeholder"
      @maxlength="255"
    />
  </section>
</section>
*/
{
  "id": "9ZeIQHrZ",
  "block": "[[[10,\"section\"],[14,0,\"field category-name-fields\"],[12],[1,\"\\n\"],[41,[51,[30,0,[\"category\",\"isUncategorizedCategory\"]]],[[[1,\"    \"],[10,\"section\"],[14,0,\"field-item\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,1],[\"category.name\"],null]],[13],[1,\"\\n      \"],[8,[39,2],[[24,0,\"category-name\"]],[[\"@value\",\"@placeholderKey\",\"@maxlength\"],[[30,0,[\"category\",\"name\"]],\"category.name_placeholder\",\"50\"]],null],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[10,\"section\"],[14,0,\"field-item\"],[12],[1,\"\\n    \"],[10,\"label\"],[12],[1,[28,[35,1],[\"category.slug\"],null]],[13],[1,\"\\n    \"],[8,[39,2],null,[[\"@value\",\"@placeholderKey\",\"@maxlength\"],[[30,0,[\"category\",\"slug\"]],\"category.slug_placeholder\",\"255\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"unless\",\"i18n\",\"text-field\"]]",
  "moduleName": "discourse/components/category-name-fields.hbs",
  "isStrictMode": false
});