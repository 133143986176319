import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal @title={{i18n "tagging.upload"}} @closeModal={{@closeModal}}>
  <:body>
    <TagsUploader
      @refresh={{route-action "triggerRefresh"}}
      @closeModal={{@closeModal}}
      @id="tags-uploader"
    />
  </:body>
</DModal>
*/
{
  "id": "qLVsm41d",
  "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"tagging.upload\"],null],[30,1]]],[[\"body\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@refresh\",\"@closeModal\",\"@id\"],[[28,[37,3],[\"triggerRefresh\"],null],[30,1],\"tags-uploader\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"i18n\",\"tags-uploader\",\"route-action\"]]",
  "moduleName": "discourse/components/modal/tag-upload.hbs",
  "isStrictMode": false
});