import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{d-icon this.icon}}
*/
{
  "id": "Y4OXmcyw",
  "block": "[[[1,[28,[35,0],[[30,0,[\"icon\"]]],null]]],[],false,[\"d-icon\"]]",
  "moduleName": "discourse/components/flat-button.hbs",
  "isStrictMode": false
});