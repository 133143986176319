import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
/* import __COLOCATED_TEMPLATE__ from './reviewable-claimed-topic.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./reviewable-claimed-topic.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_obj = {
  tagName: "",
  enabled() {
    return this.siteSettings.reviewable_claiming !== "disabled";
  },
  actions: {
    unclaim() {
      ajax(`/reviewable_claimed_topics/${this.topicId}`, {
        type: "DELETE"
      }).then(() => {
        this.set("claimedBy", null);
      });
    },
    claim() {
      let claim = this.store.createRecord("reviewable-claimed-topic");
      claim.save({
        topic_id: this.topicId
      }).then(() => {
        this.set("claimedBy", this.currentUser);
      }).catch(popupAjaxError);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "enabled", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "enabled"), _obj)), _obj)));