import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DButton
  @action={{@onGoBack}}
  @label="topic.timeline.back"
  @title="topic.timeline.back_description"
  class="btn-primary btn-small back-button"
/>
*/
{
  "id": "ENXTXQs6",
  "block": "[[[8,[39,0],[[24,0,\"btn-primary btn-small back-button\"]],[[\"@action\",\"@label\",\"@title\"],[[30,1],\"topic.timeline.back\",\"topic.timeline.back_description\"]],null]],[\"@onGoBack\"],false,[\"d-button\"]]",
  "moduleName": "discourse/components/topic-timeline/back-button.hbs",
  "isStrictMode": false
});