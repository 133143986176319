import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="login-welcome-header">
  <h1 class="login-title">{{i18n "login.header_title"}}</h1>
  <img src={{@wavingHandURL}} alt="" class="waving-hand" />
  <p class="login-subheader">{{i18n "login.subheader_title"}}</p>
  <PluginOutlet
    @name="login-header-bottom"
    @outletArgs={{hash createAccount=@createAccount}}
  />
</div>
*/
{
  "id": "unRlLxmk",
  "block": "[[[10,0],[14,0,\"login-welcome-header\"],[12],[1,\"\\n  \"],[10,\"h1\"],[14,0,\"login-title\"],[12],[1,[28,[35,0],[\"login.header_title\"],null]],[13],[1,\"\\n  \"],[10,\"img\"],[15,\"src\",[30,1]],[14,\"alt\",\"\"],[14,0,\"waving-hand\"],[12],[13],[1,\"\\n  \"],[10,2],[14,0,\"login-subheader\"],[12],[1,[28,[35,0],[\"login.subheader_title\"],null]],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@name\",\"@outletArgs\"],[\"login-header-bottom\",[28,[37,2],null,[[\"createAccount\"],[[30,2]]]]]],null],[1,\"\\n\"],[13]],[\"@wavingHandURL\",\"@createAccount\"],false,[\"i18n\",\"plugin-outlet\",\"hash\"]]",
  "moduleName": "discourse/components/modal/login/welcome-header.hbs",
  "isStrictMode": false
});