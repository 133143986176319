import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
/* import __COLOCATED_TEMPLATE__ from './topic-progress.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import { alias } from "@ember/object/computed";
import { scheduleOnce } from "@ember/runloop";
import { isTesting } from "discourse-common/config/environment";
import discourseLater from "discourse-common/lib/later";
import discourseComputed, { bind } from "discourse-common/utils/decorators";
import TEMPLATE from "./topic-progress.hbs";
const CSS_TRANSITION_DELAY = isTesting() ? 0 : 500;
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("postStream.loaded", "topic.currentPost", "postStream.filteredPostsCount"), _dec2 = discourseComputed("postStream.filteredPostsCount"), _dec3 = discourseComputed("progressPosition", "topic.last_read_post_id"), _dec4 = discourseComputed("_streamPercentage"), (_obj = {
  elementId: "topic-progress-wrapper",
  classNameBindings: ["docked", "withTransitions"],
  docked: false,
  withTransitions: null,
  progressPosition: null,
  postStream: alias("topic.postStream"),
  _streamPercentage: null,
  hideProgress(loaded, currentPost, filteredPostsCount) {
    const hideOnShortStream = this.site.desktopView && filteredPostsCount < 2;
    return !loaded || !currentPost || hideOnShortStream;
  },
  hugeNumberOfPosts(filteredPostsCount) {
    return filteredPostsCount >= this.siteSettings.short_progress_text_threshold;
  },
  showBackButton(position, lastReadId) {
    if (!lastReadId) {
      return;
    }
    const stream = this.get("postStream.stream");
    const readPos = stream.indexOf(lastReadId) || 0;
    return readPos < stream.length - 1 && readPos > position;
  },
  _topicScrolled(event) {
    if (this.docked) {
      this.setProperties({
        progressPosition: this.get("postStream.filteredPostsCount"),
        _streamPercentage: 100
      });
    } else {
      this.setProperties({
        progressPosition: event.postIndex,
        _streamPercentage: (event.percent * 100).toFixed(2)
      });
    }
  },
  progressStyle(_streamPercentage) {
    return `--progress-bg-width: ${_streamPercentage || 0}%`;
  },
  didInsertElement() {
    this._super(...arguments);
    this.appEvents.on("composer:resized", this, this._composerEvent).on("topic:current-post-scrolled", this, this._topicScrolled);
    if (this.prevEvent) {
      scheduleOnce("afterRender", this, this._topicScrolled, this.prevEvent);
    }
    scheduleOnce("afterRender", this, this._startObserver);

    // start CSS transitions a tiny bit later
    // to avoid jumpiness on initial topic load
    discourseLater(this._addCssTransitions, CSS_TRANSITION_DELAY);
  },
  willDestroyElement() {
    this._super(...arguments);
    this._topicBottomObserver?.disconnect();
    this.appEvents.off("composer:resized", this, this._composerEvent).off("topic:current-post-scrolled", this, this._topicScrolled);
  },
  _addCssTransitions() {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    this.set("withTransitions", true);
  },
  _startObserver() {
    if ("IntersectionObserver" in window) {
      this._topicBottomObserver = this._setupObserver();
      this._topicBottomObserver.observe(document.querySelector("#topic-bottom"));
    }
  },
  _setupObserver() {
    // minimum 50px here ensures element is not docked when
    // scrolling down quickly, it causes post stream refresh loop
    // on Android
    const bottomIntersectionMargin = document.querySelector("#reply-control")?.clientHeight || 50;
    return new IntersectionObserver(this._intersectionHandler, {
      threshold: 1,
      rootMargin: `0px 0px -${bottomIntersectionMargin}px 0px`
    });
  },
  _composerEvent() {
    // reinitializing needed to account for composer height
    // might be no longer necessary if IntersectionObserver API supports dynamic rootMargin
    // see https://github.com/w3c/IntersectionObserver/issues/428
    if ("IntersectionObserver" in window) {
      this._topicBottomObserver?.disconnect();
      this._startObserver();
    }
  },
  _intersectionHandler(entries) {
    if (!this.element || this.isDestroying || this.isDestroyed) {
      return;
    }
    const composerH = document.querySelector("#reply-control")?.clientHeight || 0;

    // on desktop, pin this element to the composer
    // otherwise the grid layout will change too much when toggling the composer
    // and jitter when the viewport is near the topic bottom
    if (this.site.desktopView && composerH) {
      this.set("docked", false);
      this.element.style.setProperty("bottom", `${composerH}px`);
      return;
    }
    if (entries[0].isIntersecting === true) {
      this.set("docked", true);
      this.element.style.removeProperty("bottom");
    } else {
      if (entries[0].boundingClientRect.top > 0) {
        this.set("docked", false);
        if (composerH === 0) {
          const filteredPostsHeight = document.querySelector(".posts-filtered-notice")?.clientHeight || 0;
          filteredPostsHeight === 0 ? this.element.style.removeProperty("bottom") : this.element.style.setProperty("bottom", `${filteredPostsHeight}px`);
        } else {
          this.element.style.setProperty("bottom", `${composerH}px`);
        }
      }
    }
  },
  click(e) {
    if (e.target.closest("#topic-progress")) {
      this.toggleProperty("expanded");
    }
  },
  goBack() {
    this.jumpToPost(this.get("topic.last_read_post_number"));
  }
}, (_applyDecoratedDescriptor(_obj, "hideProgress", [_dec], Object.getOwnPropertyDescriptor(_obj, "hideProgress"), _obj), _applyDecoratedDescriptor(_obj, "hugeNumberOfPosts", [_dec2], Object.getOwnPropertyDescriptor(_obj, "hugeNumberOfPosts"), _obj), _applyDecoratedDescriptor(_obj, "showBackButton", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showBackButton"), _obj), _applyDecoratedDescriptor(_obj, "progressStyle", [_dec4], Object.getOwnPropertyDescriptor(_obj, "progressStyle"), _obj), _applyDecoratedDescriptor(_obj, "_addCssTransitions", [bind], Object.getOwnPropertyDescriptor(_obj, "_addCssTransitions"), _obj), _applyDecoratedDescriptor(_obj, "_intersectionHandler", [bind], Object.getOwnPropertyDescriptor(_obj, "_intersectionHandler"), _obj), _applyDecoratedDescriptor(_obj, "goBack", [action], Object.getOwnPropertyDescriptor(_obj, "goBack"), _obj)), _obj))));