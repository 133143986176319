import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
import { tracked } from "@glimmer/tracking";
import { ajax } from "discourse/lib/ajax";
import { shortDateNoYear } from "discourse/lib/formatter";
import { cook } from "discourse/lib/text";
let TopicSummary = (_class = class TopicSummary {
  constructor() {
    _initializerDefineProperty(this, "text", _descriptor, this);
    _initializerDefineProperty(this, "summarizedOn", _descriptor2, this);
    _initializerDefineProperty(this, "summarizedBy", _descriptor3, this);
    _initializerDefineProperty(this, "newPostsSinceSummary", _descriptor4, this);
    _initializerDefineProperty(this, "outdated", _descriptor5, this);
    _initializerDefineProperty(this, "canRegenerate", _descriptor6, this);
    _initializerDefineProperty(this, "regenerated", _descriptor7, this);
    _initializerDefineProperty(this, "showSummaryBox", _descriptor8, this);
    _initializerDefineProperty(this, "canCollapseSummary", _descriptor9, this);
    _initializerDefineProperty(this, "loadingSummary", _descriptor10, this);
  }
  processUpdate(update) {
    const topicSummary = update.topic_summary;
    return cook(topicSummary.summarized_text).then(cooked => {
      this.text = cooked;
      this.loading = false;
    }).then(() => {
      if (update.done) {
        this.summarizedOn = shortDateNoYear(topicSummary.summarized_on);
        this.summarizedBy = topicSummary.algorithm;
        this.newPostsSinceSummary = topicSummary.new_posts_since_summary;
        this.outdated = topicSummary.outdated;
        this.newPostsSinceSummary = topicSummary.new_posts_since_summary;
        this.canRegenerate = topicSummary.outdated && topicSummary.can_regenerate;
      }
    });
  }
  collapse() {
    this.showSummaryBox = false;
    this.canCollapseSummary = false;
  }
  generateSummary(currentUser, topicId) {
    this.showSummaryBox = true;
    if (this.text && !this.canRegenerate) {
      this.canCollapseSummary = false;
      return;
    }
    let fetchURL = `/t/${topicId}/strategy-summary?`;
    if (currentUser) {
      fetchURL += `stream=true`;
      if (this.canRegenerate) {
        fetchURL += "&skip_age_check=true";
      }
    }
    this.loading = true;
    return ajax(fetchURL).then(data => {
      if (!currentUser) {
        data.done = true;
        this.processUpdate(data);
      }
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "text", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return "";
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "summarizedOn", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "summarizedBy", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "newPostsSinceSummary", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "outdated", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "canRegenerate", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "regenerated", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showSummaryBox", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "canCollapseSummary", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "loadingSummary", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
})), _class);
export { TopicSummary as default };