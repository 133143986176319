import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{yield (hash activeTile=this.activeTile)}}
*/
{
  "id": "Jm6vymle",
  "block": "[[[18,1,[[28,[37,1],null,[[\"activeTile\"],[[30,0,[\"activeTile\"]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\"]]",
  "moduleName": "discourse/components/tap-tile-grid.hbs",
  "isStrictMode": false
});