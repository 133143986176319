import { htmlSafe } from "@ember/template";
import { escapeExpression } from "discourse/lib/utilities";
import { iconHTML } from "discourse-common/lib/icon-library";
import I18n from "discourse-i18n";
export default function userStatus(user) {
  let {
    currentUser
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!user) {
    return;
  }
  const name = escapeExpression(user.name);
  if (user.admin && currentUser?.staff) {
    return htmlSafe(iconHTML("shield-alt", {
      label: I18n.t("user.admin", {
        user: name
      })
    }));
  }
  if (user.moderator) {
    return htmlSafe(iconHTML("shield-alt", {
      label: I18n.t("user.moderator", {
        user: name
      })
    }));
  }
}