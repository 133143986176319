import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
/* import __COLOCATED_TEMPLATE__ from './edit-topic-timer.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { TrackedObject } from "@ember-compat/tracked-built-ins";
import { popupAjaxError } from "discourse/lib/ajax-error";
import TopicTimer from "discourse/models/topic-timer";
import I18n from "discourse-i18n";
import { FORMAT } from "select-kit/components/future-date-input-selector";
import TEMPLATE from "./edit-topic-timer.hbs";
import { setComponentTemplate } from "@ember/component";
export const CLOSE_STATUS_TYPE = "close";
export const CLOSE_AFTER_LAST_POST_STATUS_TYPE = "close_after_last_post";
export const OPEN_STATUS_TYPE = "open";
export const PUBLISH_TO_CATEGORY_STATUS_TYPE = "publish_to_category";
export const DELETE_STATUS_TYPE = "delete";
export const BUMP_TYPE = "bump";
export const DELETE_REPLIES_TYPE = "delete_replies";
let EditTopicTimer = (_class = class EditTopicTimer extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "currentUser", _descriptor, this);
    _initializerDefineProperty(this, "topicTimer", _descriptor2, this);
    _initializerDefineProperty(this, "loading", _descriptor3, this);
    _initializerDefineProperty(this, "flash", _descriptor4, this);
  }
  get defaultStatusType() {
    return this.publicTimerTypes[0].id;
  }
  get publicTimerTypes() {
    const types = [];
    const {
      closed,
      category,
      isPrivateMessage,
      invisible
    } = this.args.model.topic;
    if (!closed) {
      types.push({
        id: CLOSE_STATUS_TYPE,
        name: I18n.t("topic.auto_close.title")
      });
      types.push({
        id: CLOSE_AFTER_LAST_POST_STATUS_TYPE,
        name: I18n.t("topic.auto_close_after_last_post.title")
      });
    }
    if (closed) {
      types.push({
        id: OPEN_STATUS_TYPE,
        name: I18n.t("topic.auto_reopen.title")
      });
    }
    if (this.args.model.topic.details.can_delete) {
      types.push({
        id: DELETE_STATUS_TYPE,
        name: I18n.t("topic.auto_delete.title")
      });
    }
    types.push({
      id: BUMP_TYPE,
      name: I18n.t("topic.auto_bump.title")
    });
    if (this.args.model.topic.details.can_delete) {
      types.push({
        id: DELETE_REPLIES_TYPE,
        name: I18n.t("topic.auto_delete_replies.title")
      });
    }
    if (closed) {
      types.push({
        id: CLOSE_STATUS_TYPE,
        name: I18n.t("topic.temp_open.title")
      });
    }
    if (!closed) {
      types.push({
        id: OPEN_STATUS_TYPE,
        name: I18n.t("topic.temp_close.title")
      });
    }
    if (category && category.read_restricted || isPrivateMessage || invisible) {
      types.push({
        id: PUBLISH_TO_CATEGORY_STATUS_TYPE,
        name: I18n.t("topic.publish_to_category.title")
      });
    }
    return types;
  }
  _setTimer(time, durationMinutes, statusType, basedOnLastPost, categoryId) {
    this.loading = true;
    TopicTimer.update(this.args.model.topic.id, time, basedOnLastPost, statusType, categoryId, durationMinutes).then(result => {
      if (time || durationMinutes) {
        this.args.model.updateTopicTimerProperty("execute_at", result.execute_at);
        this.args.model.updateTopicTimerProperty("duration_minutes", result.duration_minutes);
        this.args.model.updateTopicTimerProperty("category_id", result.category_id);
        this.args.model.updateTopicTimerProperty("closed", result.closed);
        this.args.closeModal();
      } else {
        const topicTimer = this.createDefaultTimer();
        this.topicTime = topicTimer;
        this.args.model.setTopicTimer(topicTimer);
        this.onChangeInput(null, null);
      }
    }).catch(popupAjaxError).finally(() => this.loading = false);
  }
  createDefaultTimer() {
    const defaultTimer = TopicTimer.create({
      status_type: this.defaultStatusType
    });
    this.args.model.setTopicTimer(defaultTimer);
    return defaultTimer;
  }
  onChangeStatusType(value) {
    const basedOnLastPost = CLOSE_AFTER_LAST_POST_STATUS_TYPE === value;
    this.topicTimer.based_on_last_post = basedOnLastPost;
    this.args.model.updateTopicTimerProperty("based_on_last_post", basedOnLastPost);
    this.topicTimer.status_type = value;
    this.args.model.updateTopicTimerProperty("status_type", value);
  }
  onChangeInput(_type, time) {
    if (moment.isMoment(time)) {
      time = time.format(FORMAT);
    }
    this.topicTimer.updateTime = time;
    this.args.model.updateTopicTimerProperty("updateTime", time);
  }
  async saveTimer() {
    this.flash = null;
    if (!this.topicTimer.updateTime && !this.topicTimer.duration_minutes) {
      this.flash = I18n.t("topic.topic_status_update.time_frame_required");
      return;
    }
    if (this.topicTimer.duration_minutes && !this.topicTimer.updateTime) {
      if (this.topicTimer.duration_minutes <= 0) {
        this.flash = I18n.t("topic.topic_status_update.min_duration");
        return;
      }

      // cannot be more than 20 years
      if (this.topicTimer.duration_minutes > 20 * 365 * 1440) {
        this.flash = I18n.t("topic.topic_status_update.max_duration");
        return;
      }
    }
    let statusType = this.topicTimer.status_type;
    if (statusType === CLOSE_AFTER_LAST_POST_STATUS_TYPE) {
      statusType = CLOSE_STATUS_TYPE;
    }
    await this._setTimer(this.topicTimer.updateTime, this.topicTimer.duration_minutes, statusType, this.topicTimer.based_on_last_post, this.topicTimer.category_id);
  }
  async removeTimer() {
    let statusType = this.topicTimer.status_type;
    if (statusType === CLOSE_AFTER_LAST_POST_STATUS_TYPE) {
      statusType = CLOSE_STATUS_TYPE;
    }
    await this._setTimer(null, null, statusType);
    // timer has been removed and we are removing `execute_at`
    // which will hide the remove timer button from the modal
    this.topicTimer.execute_at = null;
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "topicTimer", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return new TrackedObject(this.args.model.topic?.topic_timer || this.createDefaultTimer());
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loading", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "flash", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "createDefaultTimer", [action], Object.getOwnPropertyDescriptor(_class.prototype, "createDefaultTimer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeStatusType", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeStatusType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeInput", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveTimer", [action], Object.getOwnPropertyDescriptor(_class.prototype, "saveTimer"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeTimer", [action], Object.getOwnPropertyDescriptor(_class.prototype, "removeTimer"), _class.prototype)), _class);
export { EditTopicTimer as default };
setComponentTemplate(TEMPLATE, EditTopicTimer);