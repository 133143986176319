import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3;
/* import __COLOCATED_TEMPLATE__ from './change-post-notice.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { isEmpty } from "@ember/utils";
import { cook } from "discourse/lib/text";
import TEMPLATE from "./change-post-notice.hbs";
import { setComponentTemplate } from "@ember/component";
let ChangePostNoticeModal = (_class = class ChangePostNoticeModal extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "post", _descriptor, this);
    _initializerDefineProperty(this, "notice", _descriptor2, this);
    _initializerDefineProperty(this, "saving", _descriptor3, this);
    _defineProperty(this, "resolve", this.args.model.resolve);
    _defineProperty(this, "reject", this.args.model.reject);
  }
  get disabled() {
    return this.saving || isEmpty(this.notice) || this.notice === this.post.notice?.raw;
  }
  saveNotice() {
    this.setNotice(this.notice);
  }
  deleteNotice() {
    this.setNotice();
  }
  setNotice(notice) {
    const {
      resolve,
      reject
    } = this;
    this.saving = true;
    this.resolve = null;
    this.reject = null;
    this.post.updatePostField("notice", notice).then(() => {
      if (notice) {
        return cook(notice, {
          features: {
            onebox: false
          }
        });
      }
    }).then(cooked => this.post.set("notice", cooked ? {
      type: "custom",
      raw: notice,
      cooked: cooked.toString()
    } : null)).then(resolve, reject).finally(() => this.args.closeModal());
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "post", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.post;
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notice", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.model.post.notice?.raw ?? "";
  }
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "saving", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "saveNotice", [action], Object.getOwnPropertyDescriptor(_class.prototype, "saveNotice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteNotice", [action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteNotice"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setNotice", [action], Object.getOwnPropertyDescriptor(_class.prototype, "setNotice"), _class.prototype)), _class);
export { ChangePostNoticeModal as default };
setComponentTemplate(TEMPLATE, ChangePostNoticeModal);