export default (function () {
  var prefix = 'discourse';
  try {
    var metaName = prefix + '/config/environment';
    var rawConfig = document.querySelector('meta[name="' + metaName + '"]').getAttribute('content');
    var config = JSON.parse(decodeURIComponent(rawConfig));
    var exports = {
      'default': config
    };
    Object.defineProperty(exports, '__esModule', {
      value: true
    });
    return exports;
  } catch (err) {
    throw new Error('Could not read config from meta tag with name "' + metaName + '".');
  }
})().default;