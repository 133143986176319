import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _obj;
/* import __COLOCATED_TEMPLATE__ from './group-flair-inputs.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { action } from "@ember/object";
import $ from "jquery";
import { ajax } from "discourse/lib/ajax";
import discourseDebounce from "discourse-common/lib/debounce";
import getURL from "discourse-common/lib/get-url";
import { convertIconClass } from "discourse-common/lib/icon-library";
import discourseComputed, { observes, on } from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
import TEMPLATE from "./group-flair-inputs.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("model.flair_type"), _dec2 = discourseComputed("model.flair_icon"), _dec3 = on("didInsertElement"), _dec4 = observes("model.flair_icon"), _dec5 = discourseComputed("model.flair_type"), _dec6 = discourseComputed("model.flair_url"), _dec7 = discourseComputed("flairPreviewImage"), (_obj = {
  classNames: ["group-flair-inputs"],
  demoAvatarUrl() {
    return getURL("/images/avatar.png");
  },
  flairPreviewIcon(flairType) {
    return flairType && flairType === "icon";
  },
  flairPreviewIconUrl(flairIcon) {
    return flairIcon ? convertIconClass(flairIcon) : "";
  },
  _loadSVGIcon(flairIcon) {
    if (flairIcon) {
      discourseDebounce(this, this._loadIcon, 1000);
    }
  },
  _loadIcon() {
    if (!this.model.flair_icon) {
      return;
    }
    const icon = convertIconClass(this.model.flair_icon),
      c = "#svg-sprites",
      h = "ajax-icon-holder",
      singleIconEl = `${c} .${h}`;
    if (!icon) {
      return;
    }
    if (!$(`${c} symbol#${icon}`).length) {
      ajax(`/svg-sprite/search/${icon}`).then(function (data) {
        if ($(singleIconEl).length === 0) {
          $(c).append(`<div class="${h}">`);
        }
        $(singleIconEl).html(`<svg xmlns='http://www.w3.org/2000/svg' style='display: none;'>${data}</svg>`);
      });
    }
  },
  flairPreviewImage(flairType) {
    return flairType && flairType === "image";
  },
  flairImageUrl(flairUrl) {
    return flairUrl && flairUrl.includes("/") ? flairUrl : null;
  },
  flairPreviewLabel(flairPreviewImage) {
    const key = flairPreviewImage ? "image" : "icon";
    return I18n.t(`groups.flair_preview_${key}`);
  },
  setFlairImage(upload) {
    this.model.setProperties({
      flair_url: getURL(upload.url),
      flair_upload_id: upload.id
    });
  },
  removeFlairImage() {
    this.model.setProperties({
      flair_url: null,
      flair_upload_id: null
    });
  }
}, (_applyDecoratedDescriptor(_obj, "demoAvatarUrl", [discourseComputed], Object.getOwnPropertyDescriptor(_obj, "demoAvatarUrl"), _obj), _applyDecoratedDescriptor(_obj, "flairPreviewIcon", [_dec], Object.getOwnPropertyDescriptor(_obj, "flairPreviewIcon"), _obj), _applyDecoratedDescriptor(_obj, "flairPreviewIconUrl", [_dec2], Object.getOwnPropertyDescriptor(_obj, "flairPreviewIconUrl"), _obj), _applyDecoratedDescriptor(_obj, "_loadSVGIcon", [_dec3, _dec4], Object.getOwnPropertyDescriptor(_obj, "_loadSVGIcon"), _obj), _applyDecoratedDescriptor(_obj, "flairPreviewImage", [_dec5], Object.getOwnPropertyDescriptor(_obj, "flairPreviewImage"), _obj), _applyDecoratedDescriptor(_obj, "flairImageUrl", [_dec6], Object.getOwnPropertyDescriptor(_obj, "flairImageUrl"), _obj), _applyDecoratedDescriptor(_obj, "flairPreviewLabel", [_dec7], Object.getOwnPropertyDescriptor(_obj, "flairPreviewLabel"), _obj), _applyDecoratedDescriptor(_obj, "setFlairImage", [action], Object.getOwnPropertyDescriptor(_obj, "setFlairImage"), _obj), _applyDecoratedDescriptor(_obj, "removeFlairImage", [action], Object.getOwnPropertyDescriptor(_obj, "removeFlairImage"), _obj)), _obj))));