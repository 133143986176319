import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal @title={{i18n "post.quote_edit"}} @closeModal={{@closeModal}}>
  <FastEdit
    @newValue={{@model.newValue}}
    @initialValue={{@model.initialValue}}
    @post={{@model.post}}
    @close={{@closeModal}}
  />
</DModal>
*/
{
  "id": "OX9V+/2m",
  "block": "[[[8,[39,0],null,[[\"@title\",\"@closeModal\"],[[28,[37,1],[\"post.quote_edit\"],null],[30,1]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@newValue\",\"@initialValue\",\"@post\",\"@close\"],[[30,2,[\"newValue\"]],[30,2,[\"initialValue\"]],[30,2,[\"post\"]],[30,1]]],null],[1,\"\\n\"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"fast-edit\"]]",
  "moduleName": "discourse/components/modal/fast-edit.hbs",
  "isStrictMode": false
});