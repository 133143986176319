import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _class2, _descriptor, _descriptor2, _descriptor3, _descriptor4;
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import Service, { service } from "@ember/service";
import { CLOSE_INITIATED_BY_MODAL_SHOW } from "discourse/components/d-modal";
import { clearAllBodyScrollLocks } from "discourse/lib/body-scroll-lock";
import { disableImplicitInjections } from "discourse/lib/implicit-injections";
import { waitForClosedKeyboard } from "discourse/lib/wait-for-keyboard";
import deprecated from "discourse-common/lib/deprecated";
const LEGACY_OPTS = new Set(["admin", "templateName", "title", "titleTranslated", "modalClass", "titleAriaElementId", "panels"]);
let ModalService = disableImplicitInjections(_class = (_class2 = class ModalService extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "dialog", _descriptor, this);
    _initializerDefineProperty(this, "activeModal", _descriptor2, this);
    _initializerDefineProperty(this, "opts", _descriptor3, this);
    _initializerDefineProperty(this, "containerElement", _descriptor4, this);
  }
  setContainerElement(element) {
    this.containerElement = element;
  }

  /**
   * Render a modal
   *
   * @param {Component} modal - a reference to the component class for the modal
   * @param {Object} [options] - options
   * @param {string} [options.model] - An object which will be passed as the `@model` argument on the component
   *
   * @returns {Promise} A promise that resolves when the modal is closed, with any data passed to closeModal
   */
  async show(modal, opts) {
    if (typeof modal === "string") {
      this.dialog.alert(`Error: the '${modal}' modal needs updating to work with the latest version of Discourse. See https://meta.discourse.org/t/268057.`);
      deprecated(`Defining modals using a controller is no longer supported. Use the component-based API instead. (modal: ${modal})`, {
        id: "discourse.modal-controllers",
        since: "3.1",
        dropFrom: "3.2",
        url: "https://meta.discourse.org/t/268057",
        raiseError: true
      });
      return;
    }
    this.close({
      initiatedBy: CLOSE_INITIATED_BY_MODAL_SHOW
    });
    await waitForClosedKeyboard(this);
    let resolveShowPromise;
    const promise = new Promise(resolve => {
      resolveShowPromise = resolve;
    });
    this.opts = opts ??= {};
    this.activeModal = {
      component: modal,
      opts,
      resolveShowPromise
    };
    const unsupportedOpts = Object.keys(opts).filter(key => LEGACY_OPTS.has(key));
    if (unsupportedOpts.length > 0) {
      throw new Error(`${unsupportedOpts.join(", ")} are not supported in the component-based modal API. See https://meta.discourse.org/t/268057`);
    }
    return promise;
  }
  close(data) {
    clearAllBodyScrollLocks();
    this.activeModal?.resolveShowPromise?.(data);
    this.activeModal = null;
    this.opts = {};
  }
}, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "activeModal", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class2.prototype, "opts", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return {};
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class2.prototype, "containerElement", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class2.prototype, "setContainerElement", [action], Object.getOwnPropertyDescriptor(_class2.prototype, "setContainerElement"), _class2.prototype)), _class2)) || _class;
export { ModalService as default };