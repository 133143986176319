import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <a class="user-card-badge-link" href={{this.badgeUrl}}>
  <BadgeButton @badge={{@badge}}>
    {{#if this.showGrantCount}}
      <span class="count">&nbsp;(&times;{{@count}})</span>
    {{/if}}
  </BadgeButton>
</a>
*/
{
  "id": "dzTGBxDW",
  "block": "[[[10,3],[14,0,\"user-card-badge-link\"],[15,6,[30,0,[\"badgeUrl\"]]],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@badge\"],[[30,1]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"showGrantCount\"]],[[[1,\"      \"],[10,1],[14,0,\"count\"],[12],[1,\" (×\"],[1,[30,2]],[1,\")\"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"],[13]],[\"@badge\",\"@count\"],false,[\"badge-button\",\"if\"]]",
  "moduleName": "discourse/components/user-badge.hbs",
  "isStrictMode": false
});