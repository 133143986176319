import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <input
  type="text"
  placeholder={{this.placeholder}}
  name="badges"
  class="ember-text-field badge-names"
/>
*/
{
  "id": "H35wL86O",
  "block": "[[[10,\"input\"],[15,\"placeholder\",[30,0,[\"placeholder\"]]],[14,3,\"badges\"],[14,0,\"ember-text-field badge-names\"],[14,4,\"text\"],[12],[13]],[],false,[]]",
  "moduleName": "discourse/components/badge-selector.hbs",
  "isStrictMode": false
});