import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor;
import { tracked } from "@glimmer/tracking";
import Service from "@ember/service";
let AdminPostMenuButtons = (_class = class AdminPostMenuButtons extends Service {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "callbacks", _descriptor, this);
  }
  addButton(callback) {
    this.callbacks.push(callback);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "callbacks", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return [];
  }
})), _class);
export { AdminPostMenuButtons as default };