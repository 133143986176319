import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
import { getOwner } from "@ember/application";
import { service } from "@ember/service";
import DiscourseRoute from "discourse/routes/discourse";
let UserIndex = (_class = class UserIndex extends DiscourseRoute {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "router", _descriptor, this);
    _initializerDefineProperty(this, "site", _descriptor2, this);
    _initializerDefineProperty(this, "currentUser", _descriptor3, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor4, this);
  }
  get viewingOtherUserDefaultRoute() {
    let viewUserRoute = this.siteSettings.view_user_route;
    if (viewUserRoute === "activity") {
      viewUserRoute = "userActivity";
    } else {
      viewUserRoute = `user.${viewUserRoute}`;
    }
    if (getOwner(this).lookup(`route:${viewUserRoute}`)) {
      return viewUserRoute;
    } else {
      // eslint-disable-next-line no-console
      console.error(`Invalid value for view_user_route '${viewUserRoute}'. Falling back to 'summary'.`);
      return "user.summary";
    }
  }
  beforeModel() {
    const viewingMe = this.currentUser?.username === this.modelFor("user").username;
    let destination;
    if (viewingMe) {
      destination = "userActivity";
    } else {
      destination = this.viewingOtherUserDefaultRoute;
    }
    this.router.transitionTo(destination);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { UserIndex as default };