import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @flash={{this.flash}}
  @flashType={{this.flashType}}
  @closeModal={{@closeModal}}
  @title={{i18n
    "admin.site_settings.json_schema.modal_title"
    name=@model.settingName
  }}
  class="json-schema-editor-modal"
>
  <:body>
    <div
      id="json-editor-holder"
      {{did-insert this.buildJsonEditor}}
      {{will-destroy this.teardownJsonEditor}}
    ></div>
  </:body>

  <:footer>
    <DButton @action={{this.saveChanges}} @label="save" class="btn-primary" />
  </:footer>
</DModal>
*/
{
  "id": "1T505yng",
  "block": "[[[8,[39,0],[[24,0,\"json-schema-editor-modal\"]],[[\"@flash\",\"@flashType\",\"@closeModal\",\"@title\"],[[30,0,[\"flash\"]],[30,0,[\"flashType\"]],[30,1],[28,[37,1],[\"admin.site_settings.json_schema.modal_title\"],[[\"name\"],[[30,2,[\"settingName\"]]]]]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[11,0],[24,1,\"json-editor-holder\"],[4,[38,2],[[30,0,[\"buildJsonEditor\"]]],null],[4,[38,3],[[30,0,[\"teardownJsonEditor\"]]],null],[12],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,4],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"saveChanges\"]],\"save\"]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"did-insert\",\"will-destroy\",\"d-button\"]]",
  "moduleName": "discourse/components/modal/json-schema-editor.hbs",
  "isStrictMode": false
});