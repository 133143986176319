import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.src}}
  <img
    ...attributes
    src={{this.cdnSrc}}
    width={{this.width}}
    height={{this.height}}
    style={{this.style}}
    alt=""
  />
{{/if}}
*/
{
  "id": "ahC3O1KZ",
  "block": "[[[41,[30,0,[\"src\"]],[[[1,\"  \"],[11,\"img\"],[17,1],[16,\"src\",[30,0,[\"cdnSrc\"]]],[16,\"width\",[30,0,[\"width\"]]],[16,\"height\",[30,0,[\"height\"]]],[16,5,[30,0,[\"style\"]]],[24,\"alt\",\"\"],[12],[13],[1,\"\\n\"]],[]],null]],[\"&attrs\"],false,[\"if\"]]",
  "moduleName": "discourse/components/cdn-img.hbs",
  "isStrictMode": false
});