import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.privateGroupNameNotice}}
  <div class="row">
    <div class="alert alert-warning alert-private-group-name">
      {{this.privateGroupNameNotice}}
    </div>
  </div>
{{/if}}
<div class="control-group buttons group-manage-save-button">
  <DButton
    @action={{this.save}}
    @disabled={{or this.disabled this.saving}}
    @translatedLabel={{this.savingText}}
    class="btn-primary group-manage-save"
  />
  {{#if this.saved}}
    <span>{{i18n "saved"}}</span>
  {{/if}}
</div>
*/
{
  "id": "oqs346Xw",
  "block": "[[[41,[30,0,[\"privateGroupNameNotice\"]],[[[1,\"  \"],[10,0],[14,0,\"row\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"alert alert-warning alert-private-group-name\"],[12],[1,\"\\n      \"],[1,[30,0,[\"privateGroupNameNotice\"]]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[10,0],[14,0,\"control-group buttons group-manage-save-button\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"btn-primary group-manage-save\"]],[[\"@action\",\"@disabled\",\"@translatedLabel\"],[[30,0,[\"save\"]],[28,[37,2],[[30,0,[\"disabled\"]],[30,0,[\"saving\"]]],null],[30,0,[\"savingText\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"saved\"]],[[[1,\"    \"],[10,1],[12],[1,[28,[35,3],[\"saved\"],null]],[13],[1,\"\\n\"]],[]],null],[13]],[],false,[\"if\",\"d-button\",\"or\",\"i18n\"]]",
  "moduleName": "discourse/components/group-manage-save-button.hbs",
  "isStrictMode": false
});