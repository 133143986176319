/* import __COLOCATED_TEMPLATE__ from './link-to-input.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { schedule } from "@ember/runloop";
import $ from "jquery";
import TEMPLATE from "./link-to-input.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend({
  showInput: false,
  click() {
    this.onClick();
    schedule("afterRender", () => {
      $(this.element).find("input").focus();
    });
    return false;
  }
}));