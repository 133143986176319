import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _dec4, _obj;
/* import __COLOCATED_TEMPLATE__ from './edit-category-images.hbs'; */
import EmberObject from "@ember/object";
import { buildCategoryPanel } from "discourse/components/edit-category-panel";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./edit-category-images.hbs";
import { setComponentTemplate } from "@ember/component";
export default setComponentTemplate(TEMPLATE, buildCategoryPanel("images").extend((_dec = discourseComputed("category.uploaded_background.url"), _dec2 = discourseComputed("category.uploaded_background_dark.url"), _dec3 = discourseComputed("category.uploaded_logo.url"), _dec4 = discourseComputed("category.uploaded_logo_dark.url"), (_obj = {
  backgroundImageUrl(uploadedBackgroundUrl) {
    return uploadedBackgroundUrl || "";
  },
  backgroundDarkImageUrl(uploadedBackgroundDarkUrl) {
    return uploadedBackgroundDarkUrl || "";
  },
  logoImageUrl(uploadedLogoUrl) {
    return uploadedLogoUrl || "";
  },
  logoImageDarkUrl(uploadedLogoDarkUrl) {
    return uploadedLogoDarkUrl || "";
  },
  actions: {
    logoUploadDone(upload) {
      this._setFromUpload("category.uploaded_logo", upload);
    },
    logoUploadDeleted() {
      this._deleteUpload("category.uploaded_logo");
    },
    logoDarkUploadDone(upload) {
      this._setFromUpload("category.uploaded_logo_dark", upload);
    },
    logoDarkUploadDeleted() {
      this._deleteUpload("category.uploaded_logo_dark");
    },
    backgroundUploadDone(upload) {
      this._setFromUpload("category.uploaded_background", upload);
    },
    backgroundUploadDeleted() {
      this._deleteUpload("category.uploaded_background");
    },
    backgroundDarkUploadDone(upload) {
      this._setFromUpload("category.uploaded_background_dark", upload);
    },
    backgroundDarkUploadDeleted() {
      this._deleteUpload("category.uploaded_background_dark");
    }
  },
  _deleteUpload(path) {
    this.set(path, EmberObject.create({
      id: null,
      url: null
    }));
  },
  _setFromUpload(path, upload) {
    this.set(path, EmberObject.create({
      url: upload.url,
      id: upload.id
    }));
  }
}, (_applyDecoratedDescriptor(_obj, "backgroundImageUrl", [_dec], Object.getOwnPropertyDescriptor(_obj, "backgroundImageUrl"), _obj), _applyDecoratedDescriptor(_obj, "backgroundDarkImageUrl", [_dec2], Object.getOwnPropertyDescriptor(_obj, "backgroundDarkImageUrl"), _obj), _applyDecoratedDescriptor(_obj, "logoImageUrl", [_dec3], Object.getOwnPropertyDescriptor(_obj, "logoImageUrl"), _obj), _applyDecoratedDescriptor(_obj, "logoImageDarkUrl", [_dec4], Object.getOwnPropertyDescriptor(_obj, "logoImageDarkUrl"), _obj)), _obj))));