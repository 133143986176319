import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#each this.categories as |c|}}
  <div
    data-notification-level={{c.notificationLevelString}}
    style={{category-color-variable c.color}}
    class="category category-box category-box-{{c.slug}}
      {{if c.isMuted 'muted'}}"
  >
    <div class="category-box-inner">
      <div class="category-box-heading">
        <a class="parent-box-link" href={{c.url}}>
          {{#unless c.isMuted}}
            {{#if c.uploaded_logo.url}}
              <CategoryLogo @category={{c}} />
            {{/if}}
          {{/unless}}

          <h3>
            <CategoryTitleBefore @category={{c}} />
            {{#if c.read_restricted}}
              {{d-icon this.lockIcon}}
            {{/if}}
            {{c.name}}
          </h3>
        </a>
      </div>

      {{#unless c.isMuted}}
        <div class="featured-topics">
          {{#if c.topics}}
            <ul>
              {{#each c.topics as |topic|}}
                <CategoriesBoxesTopic @topic={{topic}} />
              {{/each}}
            </ul>
          {{/if}}
        </div>
      {{/unless}}

      <PluginOutlet
        @name="category-box-below-each-category"
        @outletArgs={{hash category=c}}
      />
    </div>
  </div>
{{/each}}
*/
{
  "id": "zRz84fk0",
  "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"categories\"]]],null]],null],null,[[[1,\"  \"],[10,0],[15,\"data-notification-level\",[30,1,[\"notificationLevelString\"]]],[15,5,[28,[37,2],[[30,1,[\"color\"]]],null]],[15,0,[29,[\"category category-box category-box-\",[30,1,[\"slug\"]],\"\\n      \",[52,[30,1,[\"isMuted\"]],\"muted\"]]]],[12],[1,\"\\n    \"],[10,0],[14,0,\"category-box-inner\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"category-box-heading\"],[12],[1,\"\\n        \"],[10,3],[14,0,\"parent-box-link\"],[15,6,[30,1,[\"url\"]]],[12],[1,\"\\n\"],[41,[51,[30,1,[\"isMuted\"]]],[[[41,[30,1,[\"uploaded_logo\",\"url\"]],[[[1,\"              \"],[8,[39,5],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n          \"],[10,\"h3\"],[12],[1,\"\\n            \"],[8,[39,6],null,[[\"@category\"],[[30,1]]],null],[1,\"\\n\"],[41,[30,1,[\"read_restricted\"]],[[[1,\"              \"],[1,[28,[35,7],[[30,0,[\"lockIcon\"]]],null]],[1,\"\\n\"]],[]],null],[1,\"            \"],[1,[30,1,[\"name\"]]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\\n\"],[41,[51,[30,1,[\"isMuted\"]]],[[[1,\"        \"],[10,0],[14,0,\"featured-topics\"],[12],[1,\"\\n\"],[41,[30,1,[\"topics\"]],[[[1,\"            \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,1,[\"topics\"]]],null]],null],null,[[[1,\"                \"],[8,[39,8],null,[[\"@topic\"],[[30,2]]],null],[1,\"\\n\"]],[2]],null],[1,\"            \"],[13],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[39,9],null,[[\"@name\",\"@outletArgs\"],[\"category-box-below-each-category\",[28,[37,10],null,[[\"category\"],[[30,1]]]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[1]],null]],[\"c\",\"topic\"],false,[\"each\",\"-track-array\",\"category-color-variable\",\"if\",\"unless\",\"category-logo\",\"category-title-before\",\"d-icon\",\"categories-boxes-topic\",\"plugin-outlet\",\"hash\"]]",
  "moduleName": "discourse/components/categories-boxes-with-topics.hbs",
  "isStrictMode": false
});