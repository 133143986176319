import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{this.title}}
*/
{
  "id": "ud88QZyP",
  "block": "[[[1,[30,0,[\"title\"]]]],[],false,[]]",
  "moduleName": "discourse/components/modal-tab.hbs",
  "isStrictMode": false
});