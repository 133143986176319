import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
import { action } from "@ember/object";
import { next } from "@ember/runloop";
import { service } from "@ember/service";
import AssociateAccountConfirm from "discourse/components/modal/associate-account-confirm";
import { ajax } from "discourse/lib/ajax";
import { popupAjaxError } from "discourse/lib/ajax-error";
import cookie from "discourse/lib/cookie";
import DiscourseRoute from "discourse/routes/discourse";
export default DiscourseRoute.extend((_obj = {
  router: service(),
  currentUser: service(),
  modal: service(),
  beforeModel(transition) {
    if (!this.currentUser) {
      cookie("destination_url", transition.intent.url);
      return this.router.replaceWith("login");
    }
    const params = this.paramsFor("associate-account");
    this.redirectToAccount(params);
  },
  async redirectToAccount(params) {
    await this.router.replaceWith(`preferences.account`, this.currentUser).followRedirects();
    next(() => this.showAssociateAccount(params));
  },
  async showAssociateAccount(params) {
    try {
      const model = await ajax(`/associate/${encodeURIComponent(params.token)}.json`);
      this.modal.show(AssociateAccountConfirm, {
        model
      });
    } catch (e) {
      popupAjaxError(e);
    }
  }
}, (_applyDecoratedDescriptor(_obj, "redirectToAccount", [action], Object.getOwnPropertyDescriptor(_obj, "redirectToAccount"), _obj), _applyDecoratedDescriptor(_obj, "showAssociateAccount", [action], Object.getOwnPropertyDescriptor(_obj, "showAssociateAccount"), _obj)), _obj));