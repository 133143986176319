import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <PluginOutlet @name="custom-homepage">
  {{#if this.currentUser.admin}}
    <p class="alert alert-info">
      {{i18n "custom_homepage.admin_message"}}
    </p>
  {{/if}}
</PluginOutlet>
*/
{
  "id": "VykinReJ",
  "block": "[[[8,[39,0],null,[[\"@name\"],[\"custom-homepage\"]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"    \"],[10,2],[14,0,\"alert alert-info\"],[12],[1,\"\\n      \"],[1,[28,[35,2],[\"custom_homepage.admin_message\"],null]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null]],[]]]]]],[],false,[\"plugin-outlet\",\"if\",\"i18n\"]]",
  "moduleName": "discourse/templates/discovery/custom.hbs",
  "isStrictMode": false
});