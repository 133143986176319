import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Input
  @type={{this.inputType}}
  class="date-picker"
  placeholder={{this.placeholder}}
  @value={{readonly this.value}}
  id={{this.inputId}}
  {{on "input" (action "onChangeDate")}}
/>

{{#unless this.useGlobalPickerContainer}}
  <div class="picker-container"></div>
{{/unless}}
*/
{
  "id": "1XRLewO+",
  "block": "[[[8,[39,0],[[24,0,\"date-picker\"],[16,\"placeholder\",[30,0,[\"placeholder\"]]],[16,1,[30,0,[\"inputId\"]]],[4,[38,2],[\"input\",[28,[37,3],[[30,0],\"onChangeDate\"],null]],null]],[[\"@type\",\"@value\"],[[30,0,[\"inputType\"]],[28,[37,1],[[30,0,[\"value\"]]],null]]],null],[1,\"\\n\\n\"],[41,[51,[30,0,[\"useGlobalPickerContainer\"]]],[[[1,\"  \"],[10,0],[14,0,\"picker-container\"],[12],[13],[1,\"\\n\"]],[]],null]],[],false,[\"input\",\"readonly\",\"on\",\"action\",\"unless\"]]",
  "moduleName": "discourse/components/date-input.hbs",
  "isStrictMode": false
});