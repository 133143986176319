import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#unless this.isMuted}}
  {{category-link this.category}}
{{/unless}}
*/
{
  "id": "Vp+8lcfr",
  "block": "[[[41,[51,[30,0,[\"isMuted\"]]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"category\"]]],null]],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"category-link\"]]",
  "moduleName": "discourse/templates/mobile/components/sub-category-item.hbs",
  "isStrictMode": false
});