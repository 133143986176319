import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="tag-group-content">
  <h3>{{i18n "tagging.groups.edit_title"}}</h3>
  <TagGroupsForm @model={{this.model}} @onDestroy={{action "onDestroy"}} />
</div>
*/
{
  "id": "4bVtO7Wg",
  "block": "[[[10,0],[14,0,\"tag-group-content\"],[12],[1,\"\\n  \"],[10,\"h3\"],[12],[1,[28,[35,0],[\"tagging.groups.edit_title\"],null]],[13],[1,\"\\n  \"],[8,[39,1],null,[[\"@model\",\"@onDestroy\"],[[30,0,[\"model\"]],[28,[37,2],[[30,0],\"onDestroy\"],null]]],null],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"tag-groups-form\",\"action\"]]",
  "moduleName": "discourse/templates/tag-groups-edit.hbs",
  "isStrictMode": false
});