import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="ignored-list">
  {{#each this.items as |item|}}
    <IgnoredUserListItem
      @item={{item}}
      @onRemoveIgnoredUser={{action "removeIgnoredUser"}}
    />
  {{else}}
    {{i18n "user.user_notifications.ignore_no_users"}}
  {{/each}}
</div>
<div class="instructions">{{i18n "user.ignored_users_instructions"}}</div>
<div><DButton
    @action={{action "newIgnoredUser"}}
    @icon="plus"
    @label="user.user_notifications.add_ignored_user"
    class="btn-default"
  /></div>
*/
{
  "id": "aYvtZBmh",
  "block": "[[[10,0],[14,0,\"ignored-list\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"items\"]]],null]],null],null,[[[1,\"    \"],[8,[39,2],null,[[\"@item\",\"@onRemoveIgnoredUser\"],[[30,1],[28,[37,3],[[30,0],\"removeIgnoredUser\"],null]]],null],[1,\"\\n\"]],[1]],[[[1,\"    \"],[1,[28,[35,4],[\"user.user_notifications.ignore_no_users\"],null]],[1,\"\\n\"]],[]]],[13],[1,\"\\n\"],[10,0],[14,0,\"instructions\"],[12],[1,[28,[35,4],[\"user.ignored_users_instructions\"],null]],[13],[1,\"\\n\"],[10,0],[12],[8,[39,5],[[24,0,\"btn-default\"]],[[\"@action\",\"@icon\",\"@label\"],[[28,[37,3],[[30,0],\"newIgnoredUser\"],null],\"plus\",\"user.user_notifications.add_ignored_user\"]],null],[13]],[\"item\"],false,[\"each\",\"-track-array\",\"ignored-user-list-item\",\"action\",\"i18n\",\"d-button\"]]",
  "moduleName": "discourse/components/ignored-user-list.hbs",
  "isStrictMode": false
});