import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Discovery::Layout @model={{this.model}}>
  <:navigation>
    <Discovery::FilterNavigation
      @queryString={{this.q}}
      @updateTopicsListQueryParams={{this.updateTopicsListQueryParams}}
    />
  </:navigation>
  <:list>
    <Discovery::Topics
      @period={{this.period}}
      @expandAllPinned={{this.expandAllPinned}}
      @expandAllGloballyPinned={{this.expandAllGloballyPinned}}
      @model={{this.model}}
      @canBulkSelect={{this.canBulkSelect}}
      @bulkSelectHelper={{this.bulkSelectHelper}}
    />
  </:list>
</Discovery::Layout>
*/
{
  "id": "khlC2AqX",
  "block": "[[[8,[39,0],null,[[\"@model\"],[[30,0,[\"model\"]]]],[[\"navigation\",\"list\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@queryString\",\"@updateTopicsListQueryParams\"],[[30,0,[\"q\"]],[30,0,[\"updateTopicsListQueryParams\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,2],null,[[\"@period\",\"@expandAllPinned\",\"@expandAllGloballyPinned\",\"@model\",\"@canBulkSelect\",\"@bulkSelectHelper\"],[[30,0,[\"period\"]],[30,0,[\"expandAllPinned\"]],[30,0,[\"expandAllGloballyPinned\"]],[30,0,[\"model\"]],[30,0,[\"canBulkSelect\"]],[30,0,[\"bulkSelectHelper\"]]]],null],[1,\"\\n  \"]],[]]]]]],[],false,[\"discovery/layout\",\"discovery/filter-navigation\",\"discovery/topics\"]]",
  "moduleName": "discourse/templates/discovery/filter.hbs",
  "isStrictMode": false
});