import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div id="simple-container">
  <div class="confirm-old-email">
    <h2>{{i18n "user.change_email.authorizing_old.title"}}</h2>
    <p>
      {{#if this.model.old_email}}
        {{i18n "user.change_email.authorizing_old.description"}}
      {{else}}
        {{i18n "user.change_email.authorizing_old.description_add"}}
      {{/if}}
    </p>
    {{#if this.model.old_email}}
      <p>
        {{i18n
          "user.change_email.authorizing_old.old_email"
          email=this.model.old_email
        }}
      </p>
    {{/if}}
    <p>
      {{i18n
        "user.change_email.authorizing_old.new_email"
        email=this.model.new_email
      }}
    </p>
    <DButton
      @translatedLabel={{i18n "user.change_email.confirm"}}
      class="btn-primary"
      @action={{this.confirm}}
    />
  </div>
</div>
*/
{
  "id": "Z/XnDROi",
  "block": "[[[10,0],[14,1,\"simple-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"confirm-old-email\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,[28,[35,0],[\"user.change_email.authorizing_old.title\"],null]],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"old_email\"]],[[[1,\"        \"],[1,[28,[35,0],[\"user.change_email.authorizing_old.description\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,0],[\"user.change_email.authorizing_old.description_add\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"],[41,[30,0,[\"model\",\"old_email\"]],[[[1,\"      \"],[10,2],[12],[1,\"\\n        \"],[1,[28,[35,0],[\"user.change_email.authorizing_old.old_email\"],[[\"email\"],[[30,0,[\"model\",\"old_email\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,2],[12],[1,\"\\n      \"],[1,[28,[35,0],[\"user.change_email.authorizing_old.new_email\"],[[\"email\"],[[30,0,[\"model\",\"new_email\"]]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@action\"],[[28,[37,0],[\"user.change_email.confirm\"],null],[30,0,[\"confirm\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"if\",\"d-button\"]]",
  "moduleName": "discourse/templates/confirm-old-email.hbs",
  "isStrictMode": false
});