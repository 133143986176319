import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
/* import __COLOCATED_TEMPLATE__ from './groups-info.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import discourseComputed from "discourse-common/utils/decorators";
import TEMPLATE from "./groups-info.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend((_dec = discourseComputed("group.full_name"), (_obj = {
  tagName: "span",
  classNames: ["group-info-details"],
  showFullName(fullName) {
    return fullName && fullName.length;
  }
}, (_applyDecoratedDescriptor(_obj, "showFullName", [_dec], Object.getOwnPropertyDescriptor(_obj, "showFullName"), _obj)), _obj))));