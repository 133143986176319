import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <MiniTagChooser
  @value={{this.value}}
  @onChange={{action "onChange"}}
  @options={{hash categoryId=this.tagCategoryId}}
/>
*/
{
  "id": "JKyUkA0b",
  "block": "[[[8,[39,0],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,0,[\"value\"]],[28,[37,1],[[30,0],\"onChange\"],null],[28,[37,2],null,[[\"categoryId\"],[[30,0,[\"tagCategoryId\"]]]]]]],null]],[],false,[\"mini-tag-chooser\",\"action\",\"hash\"]]",
  "moduleName": "discourse/components/reviewable-field-tags.hbs",
  "isStrictMode": false
});