import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _class, _descriptor, _descriptor2;
/* import __COLOCATED_TEMPLATE__ from './suggested-topics.hbs'; */
import Component from "@glimmer/component";
import { action, computed } from "@ember/object";
import { service } from "@ember/service";
import I18n from "discourse-i18n";
import TEMPLATE from "./suggested-topics.hbs";
import { setComponentTemplate } from "@ember/component";
let SuggestedTopics = (_dec = computed("moreTopicsPreferenceTracking.selectedTab"), (_class = class SuggestedTopics extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "moreTopicsPreferenceTracking", _descriptor, this);
    _initializerDefineProperty(this, "currentUser", _descriptor2, this);
    _defineProperty(this, "listId", "suggested-topics");
  }
  get suggestedTitleLabel() {
    const href = this.currentUser && this.currentUser.pmPath(this.args.topic);
    if (this.args.topic.isPrivateMessage && href) {
      return "suggested_topics.pm_title";
    } else {
      return "suggested_topics.title";
    }
  }
  get hidden() {
    return this.moreTopicsPreferenceTracking.selectedTab !== this.listId;
  }
  registerList() {
    this.moreTopicsPreferenceTracking.registerTopicList({
      name: I18n.t("suggested_topics.pill"),
      id: this.listId
    });
  }
  removeList() {
    this.moreTopicsPreferenceTracking.removeTopicList(this.listId);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "moreTopicsPreferenceTracking", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "currentUser", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "hidden", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "hidden"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "registerList", [action], Object.getOwnPropertyDescriptor(_class.prototype, "registerList"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeList", [action], Object.getOwnPropertyDescriptor(_class.prototype, "removeList"), _class.prototype)), _class));
export { SuggestedTopics as default };
setComponentTemplate(TEMPLATE, SuggestedTopics);