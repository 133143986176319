import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <TextField
  @value={{this.composer.title}}
  @id="reply-title"
  @maxLength={{this.titleMaxLength}}
  @placeholderKey={{this.composer.titlePlaceholder}}
  @aria-label={{i18n this.composer.titlePlaceholder}}
  @disabled={{this.disabled}}
  @autocomplete="off"
/>

<PluginOutlet
  @name="after-composer-title-input"
  @connectorTagName="div"
  @outletArgs={{hash composer=this.composer}}
/>

<PopupInputTip @validation={{this.validation}} />
*/
{
  "id": "IetttjVL",
  "block": "[[[8,[39,0],null,[[\"@value\",\"@id\",\"@maxLength\",\"@placeholderKey\",\"@aria-label\",\"@disabled\",\"@autocomplete\"],[[30,0,[\"composer\",\"title\"]],\"reply-title\",[30,0,[\"titleMaxLength\"]],[30,0,[\"composer\",\"titlePlaceholder\"]],[28,[37,1],[[30,0,[\"composer\",\"titlePlaceholder\"]]],null],[30,0,[\"disabled\"]],\"off\"]],null],[1,\"\\n\\n\"],[8,[39,2],null,[[\"@name\",\"@connectorTagName\",\"@outletArgs\"],[\"after-composer-title-input\",\"div\",[28,[37,3],null,[[\"composer\"],[[30,0,[\"composer\"]]]]]]],null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@validation\"],[[30,0,[\"validation\"]]]],null]],[],false,[\"text-field\",\"i18n\",\"plugin-outlet\",\"hash\",\"popup-input-tip\"]]",
  "moduleName": "discourse/components/composer-title.hbs",
  "isStrictMode": false
});