import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <Textarea
  @value={{this.value}}
  {{on "change" this.valueChanged}}
  class="reviewable-input-textarea"
/>
*/
{
  "id": "qWCiGgyG",
  "block": "[[[8,[39,0],[[24,0,\"reviewable-input-textarea\"],[4,[38,1],[\"change\",[30,0,[\"valueChanged\"]]],null]],[[\"@value\"],[[30,0,[\"value\"]]]],null]],[],false,[\"textarea\",\"on\"]]",
  "moduleName": "discourse/components/reviewable-field-textarea.hbs",
  "isStrictMode": false
});