import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import Modifier from "ember-modifier";
export default class AutoFocusModifier extends Modifier {
  constructor() {
    super(...arguments);
    _defineProperty(this, "didFocus", false);
  }
  modify(element, _, _ref) {
    let {
      selectText
    } = _ref;
    if (!this.didFocus) {
      element.autofocus = true;
      element.focus();
      if (selectText) {
        element.select();
      }
      this.didFocus = true;
    }
  }
}