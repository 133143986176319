import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11;
/* import __COLOCATED_TEMPLATE__ from './username-preference.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { empty, or } from "@ember/object/computed";
import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import { popupAjaxError } from "discourse/lib/ajax-error";
import { setting } from "discourse/lib/computed";
import DiscourseURL, { userPath } from "discourse/lib/url";
import User from "discourse/models/user";
import I18n from "discourse-i18n";
import TEMPLATE from "./username-preference.hbs";
import { setComponentTemplate } from "@ember/component";
let UsernamePreference = (_dec = setting("max_username_length"), _dec2 = setting("min_username_length"), _dec3 = empty("newUsername"), _dec4 = or("saving", "newUsernameEmpty", "taken", "unchanged", "errorMessage"), (_class = class UsernamePreference extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "siteSettings", _descriptor, this);
    _initializerDefineProperty(this, "dialog", _descriptor2, this);
    _initializerDefineProperty(this, "editing", _descriptor3, this);
    _initializerDefineProperty(this, "newUsername", _descriptor4, this);
    _initializerDefineProperty(this, "errorMessage", _descriptor5, this);
    _initializerDefineProperty(this, "saving", _descriptor6, this);
    _initializerDefineProperty(this, "taken", _descriptor7, this);
    _initializerDefineProperty(this, "maxLength", _descriptor8, this);
    _initializerDefineProperty(this, "minLength", _descriptor9, this);
    _initializerDefineProperty(this, "newUsernameEmpty", _descriptor10, this);
    _initializerDefineProperty(this, "saveDisabled", _descriptor11, this);
  }
  get unchanged() {
    return this.newUsername === this.args.user.username;
  }
  get saveButtonText() {
    return this.saving ? I18n.t("saving") : I18n.t("user.change");
  }
  toggleEditing() {
    this.editing = !this.editing;
    this.newUsername = this.args.user.username;
    this.errorMessage = null;
    this.saving = false;
    this.taken = false;
  }
  async onInput(event) {
    this.newUsername = event.target.value;
    this.taken = false;
    this.errorMessage = null;
    if (isEmpty(this.newUsername)) {
      return;
    }
    if (this.newUsername === this.args.user.username) {
      return;
    }
    if (this.newUsername.length < this.minLength) {
      this.errorMessage = I18n.t("user.name.too_short");
      return;
    }
    const result = await User.checkUsername(this.newUsername, undefined, this.args.user.id);
    if (result.errors) {
      this.errorMessage = result.errors.join(" ");
    } else if (result.available === false) {
      this.taken = true;
    }
  }
  changeUsername() {
    return this.dialog.yesNoConfirm({
      title: I18n.t("user.change_username.confirm"),
      didConfirm: async () => {
        this.saving = true;
        try {
          await this.args.user.changeUsername(this.newUsername);
          DiscourseURL.redirectTo(userPath(this.newUsername.toLowerCase() + "/preferences"));
        } catch (e) {
          popupAjaxError(e);
        } finally {
          this.saving = false;
        }
      }
    });
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "dialog", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "editing", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "newUsername", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return this.args.user.username;
  }
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return null;
  }
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "saving", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "taken", [tracked], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "maxLength", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "minLength", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "newUsernameEmpty", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "saveDisabled", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "toggleEditing", [action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleEditing"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onInput", [action], Object.getOwnPropertyDescriptor(_class.prototype, "onInput"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeUsername", [action], Object.getOwnPropertyDescriptor(_class.prototype, "changeUsername"), _class.prototype)), _class));
export { UsernamePreference as default };
setComponentTemplate(TEMPLATE, UsernamePreference);