import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _dec2, _dec3, _obj;
import Component from "@ember/component";
import { htmlSafe } from "@ember/template";
import discourseComputed from "discourse-common/utils/decorators";
import I18n from "discourse-i18n";
export default Component.extend((_dec = discourseComputed("color", "usedColors"), _dec2 = discourseComputed("isUsed"), _dec3 = discourseComputed("color"), (_obj = {
  tagName: "button",
  attributeBindings: ["style", "title"],
  classNameBindings: [":colorpicker", "isUsed:used-color:unused-color"],
  isUsed(color, usedColors) {
    return (usedColors || []).includes(color.toUpperCase());
  },
  title(isUsed) {
    return isUsed ? I18n.t("category.already_used") : null;
  },
  style(color) {
    return htmlSafe(`background-color: #${color};`);
  },
  click(e) {
    e.preventDefault();
    this.selectColor(this.color);
  }
}, (_applyDecoratedDescriptor(_obj, "isUsed", [_dec], Object.getOwnPropertyDescriptor(_obj, "isUsed"), _obj), _applyDecoratedDescriptor(_obj, "title", [_dec2], Object.getOwnPropertyDescriptor(_obj, "title"), _obj), _applyDecoratedDescriptor(_obj, "style", [_dec3], Object.getOwnPropertyDescriptor(_obj, "style"), _obj)), _obj)));