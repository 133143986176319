import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{body-class "user-messages-page"}}

<PluginOutlet
  @name="user-messages-above-navigation"
  @outletArgs={{hash model=this.model}}
/>

<div class="user-navigation user-navigation-secondary">
  <ol class="category-breadcrumb">
    <li>
      <UserNav::MessagesDropdown
        @content={{this.messagesDropdownContent}}
        @value={{this.messagesDropdownValue}}
        @onChange={{this.onMessagesDropdownChange}}
      />
    </li>
  </ol>

  <HorizontalOverflowNav
    @ariaLabel="User secondary - messages"
    id="user-navigation-secondary__horizontal-nav"
    class="messages-nav"
  />

  <div class="navigation-controls">
    {{#if this.site.mobileView}}
      {{#if this.currentUser.admin}}
        <BulkSelectToggle @bulkSelectHelper={{this.bulkSelectHelper}} />
      {{/if}}
    {{/if}}

    <span id="navigation-controls__button"></span>

    {{#if this.showNewPM}}
      <DButton
        @action={{route-action "composePrivateMessage"}}
        @icon="envelope"
        @label="user.new_private_message"
        class="btn-primary new-private-message"
      />
    {{/if}}
    <PluginOutlet
      @name="user-messages-controls-bottom"
      @outletArgs={{hash showNewPM=this.showNewPM}}
    />
  </div>
</div>

<section class="user-content" id="user-content">
  {{outlet}}
</section>
*/
{
  "id": "L4dcg+C5",
  "block": "[[[1,[28,[35,0],[\"user-messages-page\"],null]],[1,\"\\n\\n\"],[8,[39,1],null,[[\"@name\",\"@outletArgs\"],[\"user-messages-above-navigation\",[28,[37,2],null,[[\"model\"],[[30,0,[\"model\"]]]]]]],null],[1,\"\\n\\n\"],[10,0],[14,0,\"user-navigation user-navigation-secondary\"],[12],[1,\"\\n  \"],[10,\"ol\"],[14,0,\"category-breadcrumb\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,3],null,[[\"@content\",\"@value\",\"@onChange\"],[[30,0,[\"messagesDropdownContent\"]],[30,0,[\"messagesDropdownValue\"]],[30,0,[\"onMessagesDropdownChange\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\\n  \"],[8,[39,4],[[24,1,\"user-navigation-secondary__horizontal-nav\"],[24,0,\"messages-nav\"]],[[\"@ariaLabel\"],[\"User secondary - messages\"]],null],[1,\"\\n\\n  \"],[10,0],[14,0,\"navigation-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"site\",\"mobileView\"]],[[[41,[30,0,[\"currentUser\",\"admin\"]],[[[1,\"        \"],[8,[39,6],null,[[\"@bulkSelectHelper\"],[[30,0,[\"bulkSelectHelper\"]]]],null],[1,\"\\n\"]],[]],null]],[]],null],[1,\"\\n    \"],[10,1],[14,1,\"navigation-controls__button\"],[12],[13],[1,\"\\n\\n\"],[41,[30,0,[\"showNewPM\"]],[[[1,\"      \"],[8,[39,7],[[24,0,\"btn-primary new-private-message\"]],[[\"@action\",\"@icon\",\"@label\"],[[28,[37,8],[\"composePrivateMessage\"],null],\"envelope\",\"user.new_private_message\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,1],null,[[\"@name\",\"@outletArgs\"],[\"user-messages-controls-bottom\",[28,[37,2],null,[[\"showNewPM\"],[[30,0,[\"showNewPM\"]]]]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,\"section\"],[14,0,\"user-content\"],[14,1,\"user-content\"],[12],[1,\"\\n  \"],[46,[28,[37,10],null,null],null,null,null],[1,\"\\n\"],[13]],[],false,[\"body-class\",\"plugin-outlet\",\"hash\",\"user-nav/messages-dropdown\",\"horizontal-overflow-nav\",\"if\",\"bulk-select-toggle\",\"d-button\",\"route-action\",\"component\",\"-outlet\"]]",
  "moduleName": "discourse/templates/user/messages.hbs",
  "isStrictMode": false
});