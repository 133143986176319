import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _obj;
import { action } from "@ember/object";
import { service } from "@ember/service";
import CreateInvite from "discourse/components/modal/create-invite";
import GroupAddMembersModal from "discourse/components/modal/group-add-members";
import DiscourseRoute from "discourse/routes/discourse";
import I18n from "discourse-i18n";
export default DiscourseRoute.extend((_obj = {
  modal: service(),
  titleToken() {
    return I18n.t("groups.members.title");
  },
  model(params) {
    this._params = params;
    return this.modelFor("group");
  },
  setupController(controller, model) {
    controller.setProperties({
      model,
      filterInput: this._params.filter,
      showing: "members"
    });
    controller.reloadMembers(true);
  },
  showAddMembersModal() {
    this.modal.show(GroupAddMembersModal, {
      model: this.modelFor("group")
    });
  },
  showInviteModal() {
    const group = this.modelFor("group");
    this.modal.show(CreateInvite, {
      model: {
        groupIds: [group.id]
      }
    });
  },
  didTransition() {
    this.controllerFor("group-index").set("filterInput", this._params.filter);
    return true;
  }
}, (_applyDecoratedDescriptor(_obj, "showAddMembersModal", [action], Object.getOwnPropertyDescriptor(_obj, "showAddMembersModal"), _obj), _applyDecoratedDescriptor(_obj, "showInviteModal", [action], Object.getOwnPropertyDescriptor(_obj, "showInviteModal"), _obj), _applyDecoratedDescriptor(_obj, "didTransition", [action], Object.getOwnPropertyDescriptor(_obj, "didTransition"), _obj)), _obj));