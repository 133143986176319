/* import __COLOCATED_TEMPLATE__ from './directory-item.hbs'; */
import Component, { setComponentTemplate } from "@ember/component";
import { propertyEqual } from "discourse/lib/computed";
import TEMPLATE from "./directory-item.hbs";
export default setComponentTemplate(TEMPLATE, Component.extend({
  tagName: "div",
  classNames: ["directory-table__row"],
  classNameBindings: ["me"],
  me: propertyEqual("item.user.id", "currentUser.id"),
  columns: null
}));