import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="shared-draft-controls">
  {{#if this.publishing}}
    {{i18n "shared_drafts.publishing"}}
  {{else}}
    {{i18n "shared_drafts.notice"}}

    <div class="publish-field">
      <label>{{i18n "shared_drafts.destination_category"}}</label>
      <CategoryChooser
        @value={{this.topic.destination_category_id}}
        @onChange={{action "updateDestinationCategory"}}
      />
    </div>

    <div class="publish-field">
      {{#if this.validCategory}}
        <DButton
          @action={{action "publish"}}
          @label="shared_drafts.publish"
          @icon="far-clipboard"
          class="btn-primary publish-shared-draft"
        />
      {{/if}}
    </div>
  {{/if}}
</div>
*/
{
  "id": "dqlGT5Ik",
  "block": "[[[10,0],[14,0,\"shared-draft-controls\"],[12],[1,\"\\n\"],[41,[30,0,[\"publishing\"]],[[[1,\"    \"],[1,[28,[35,1],[\"shared_drafts.publishing\"],null]],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,1],[\"shared_drafts.notice\"],null]],[1,\"\\n\\n    \"],[10,0],[14,0,\"publish-field\"],[12],[1,\"\\n      \"],[10,\"label\"],[12],[1,[28,[35,1],[\"shared_drafts.destination_category\"],null]],[13],[1,\"\\n      \"],[8,[39,2],null,[[\"@value\",\"@onChange\"],[[30,0,[\"topic\",\"destination_category_id\"]],[28,[37,3],[[30,0],\"updateDestinationCategory\"],null]]],null],[1,\"\\n    \"],[13],[1,\"\\n\\n    \"],[10,0],[14,0,\"publish-field\"],[12],[1,\"\\n\"],[41,[30,0,[\"validCategory\"]],[[[1,\"        \"],[8,[39,4],[[24,0,\"btn-primary publish-shared-draft\"]],[[\"@action\",\"@label\",\"@icon\"],[[28,[37,3],[[30,0],\"publish\"],null],\"shared_drafts.publish\",\"far-clipboard\"]],null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]]],[13]],[],false,[\"if\",\"i18n\",\"category-chooser\",\"action\",\"d-button\"]]",
  "moduleName": "discourse/components/shared-draft-controls.hbs",
  "isStrictMode": false
});