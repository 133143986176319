import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#if this.icon}}
  {{d-icon this.icon}}
{{/if}}
{{yield}}
*/
{
  "id": "YzzhQB97",
  "block": "[[[41,[30,0,[\"icon\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,0,[\"icon\"]]],null]],[1,\"\\n\"]],[]],null],[18,1,null]],[\"&default\"],false,[\"if\",\"d-icon\",\"yield\"]]",
  "moduleName": "discourse/components/tap-tile.hbs",
  "isStrictMode": false
});