import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div class="ac-message">
  <ActivationEmailForm
    @email={{this.newEmail}}
    @updateNewEmail={{this.updateNewEmail}}
  />
</div>

<div class="activation-controls">
  <DButton
    @action={{this.changeEmail}}
    @label="login.submit_new_email"
    @disabled={{this.submitDisabled}}
    class="btn-primary"
  />
  <DButton @action={{this.cancel}} @label="cancel" class="edit-cancel" />
</div>
*/
{
  "id": "PZO8Nq3J",
  "block": "[[[10,0],[14,0,\"ac-message\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@email\",\"@updateNewEmail\"],[[30,0,[\"newEmail\"]],[30,0,[\"updateNewEmail\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"activation-controls\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[30,0,[\"changeEmail\"]],\"login.submit_new_email\",[30,0,[\"submitDisabled\"]]]],null],[1,\"\\n  \"],[8,[39,1],[[24,0,\"edit-cancel\"]],[[\"@action\",\"@label\"],[[30,0,[\"cancel\"]],\"cancel\"]],null],[1,\"\\n\"],[13]],[],false,[\"activation-email-form\",\"d-button\"]]",
  "moduleName": "discourse/templates/account-created/edit-email.hbs",
  "isStrictMode": false
});