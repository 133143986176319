import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <DModal
  @closeModal={{@closeModal}}
  @title={{i18n "admin.customize.bulk_delete"}}
>
  <:body>
    {{i18n (concat "admin.customize.bulk_" @model.type "_delete_confirm")}}
    <ul>
      {{#each @model.selectedThemesOrComponents as |theme|}}
        <li>{{theme.name}}</li>
      {{/each}}
    </ul>

  </:body>
  <:footer>
    <DButton class="btn-primary" @action={{this.delete}} @label="yes_value" />
    <DModalCancel @close={{@closeModal}} />
  </:footer>
</DModal>
*/
{
  "id": "VgVfKQe+",
  "block": "[[[8,[39,0],null,[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"admin.customize.bulk_delete\"],null]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[28,[35,1],[[28,[37,2],[\"admin.customize.bulk_\",[30,2,[\"type\"]],\"_delete_confirm\"],null]],null]],[1,\"\\n    \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,2,[\"selectedThemesOrComponents\"]]],null]],null],null,[[[1,\"        \"],[10,\"li\"],[12],[1,[30,3,[\"name\"]]],[13],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,5],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[30,0,[\"delete\"]],\"yes_value\"]],null],[1,\"\\n    \"],[8,[39,6],null,[[\"@close\"],[[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\",\"theme\"],false,[\"d-modal\",\"i18n\",\"concat\",\"each\",\"-track-array\",\"d-button\",\"d-modal-cancel\"]]",
  "moduleName": "discourse/components/modal/delete-themes-confirm.hbs",
  "isStrictMode": false
});