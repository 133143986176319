import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
var _dec, _obj;
import Controller, { inject as controller } from "@ember/controller";
import { action } from "@ember/object";
import { alias, filterBy, sort } from "@ember/object/computed";
import discourseComputed from "discourse-common/utils/decorators";
export default Controller.extend((_dec = discourseComputed("favoriteBadges.length"), (_obj = {
  user: controller(),
  username: alias("user.model.username_lower"),
  sortedBadges: sort("model", "badgeSortOrder"),
  favoriteBadges: filterBy("model", "is_favorite", true),
  canFavoriteMoreBadges(favoriteBadgesCount) {
    return favoriteBadgesCount < this.siteSettings.max_favorite_badges;
  },
  init() {
    this._super(...arguments);
    this.badgeSortOrder = ["badge.badge_type.sort_order:desc", "badge.name"];
  },
  favorite(badge) {
    return badge.favorite();
  }
}, (_applyDecoratedDescriptor(_obj, "canFavoriteMoreBadges", [_dec], Object.getOwnPropertyDescriptor(_obj, "canFavoriteMoreBadges"), _obj), _applyDecoratedDescriptor(_obj, "favorite", [action], Object.getOwnPropertyDescriptor(_obj, "favorite"), _obj)), _obj)));