import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  <div id="simple-container">
  <div class="confirm-new-email">
    <h2>{{i18n "user.change_email.title"}}</h2>
    <p>
      {{#if this.model.old_email}}
        {{i18n "user.change_email.authorizing_new.description"}}
      {{else}}
        {{i18n "user.change_email.authorizing_new.description_add"}}
      {{/if}}
    </p>
    <p>{{this.model.new_email}}</p>
    <DButton
      @translatedLabel={{i18n "user.change_email.confirm"}}
      class="btn-primary"
      @action={{this.confirm}}
    />
  </div>
</div>
*/
{
  "id": "bJqk0xpQ",
  "block": "[[[10,0],[14,1,\"simple-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"confirm-new-email\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,[28,[35,0],[\"user.change_email.title\"],null]],[13],[1,\"\\n    \"],[10,2],[12],[1,\"\\n\"],[41,[30,0,[\"model\",\"old_email\"]],[[[1,\"        \"],[1,[28,[35,0],[\"user.change_email.authorizing_new.description\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[1,[28,[35,0],[\"user.change_email.authorizing_new.description_add\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,[30,0,[\"model\",\"new_email\"]]],[13],[1,\"\\n    \"],[8,[39,2],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@action\"],[[28,[37,0],[\"user.change_email.confirm\"],null],[30,0,[\"confirm\"]]]],null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"i18n\",\"if\",\"d-button\"]]",
  "moduleName": "discourse/templates/confirm-new-email.hbs",
  "isStrictMode": false
});