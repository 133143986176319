import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{#unless this.timeFirst}}
  <DateInput
    @date={{this.date}}
    @placeholder={{this.placeholder}}
    @relativeDate={{this.relativeDate}}
    @onChange={{action "onChangeDate"}}
    @useGlobalPickerContainer={{this.useGlobalPickerContainer}}
  />
{{/unless}}

{{#if this.showTime}}
  <TimeInput
    @date={{this.date}}
    @relativeDate={{this.relativeDate}}
    @onChange={{action "onChangeTime"}}
  />
{{/if}}

{{#if this.timeFirst}}
  <DateInput
    @date={{this.date}}
    @placeholder={{this.placeholder}}
    @relativeDate={{this.relativeDate}}
    @onChange={{action "onChangeDate"}}
    @useGlobalPickerContainer={{this.useGlobalPickerContainer}}
  />
{{/if}}

{{#if this.clearable}}
  <DButton
    @icon="times"
    @action={{this.onClear}}
    class="btn-default clear-date-time"
  />
{{/if}}
*/
{
  "id": "cH9xoPRR",
  "block": "[[[41,[51,[30,0,[\"timeFirst\"]]],[[[1,\"  \"],[8,[39,1],null,[[\"@date\",\"@placeholder\",\"@relativeDate\",\"@onChange\",\"@useGlobalPickerContainer\"],[[30,0,[\"date\"]],[30,0,[\"placeholder\"]],[30,0,[\"relativeDate\"]],[28,[37,2],[[30,0],\"onChangeDate\"],null],[30,0,[\"useGlobalPickerContainer\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"showTime\"]],[[[1,\"  \"],[8,[39,4],null,[[\"@date\",\"@relativeDate\",\"@onChange\"],[[30,0,[\"date\"]],[30,0,[\"relativeDate\"]],[28,[37,2],[[30,0],\"onChangeTime\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"timeFirst\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@date\",\"@placeholder\",\"@relativeDate\",\"@onChange\",\"@useGlobalPickerContainer\"],[[30,0,[\"date\"]],[30,0,[\"placeholder\"]],[30,0,[\"relativeDate\"]],[28,[37,2],[[30,0],\"onChangeDate\"],null],[30,0,[\"useGlobalPickerContainer\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[30,0,[\"clearable\"]],[[[1,\"  \"],[8,[39,5],[[24,0,\"btn-default clear-date-time\"]],[[\"@icon\",\"@action\"],[\"times\",[30,0,[\"onClear\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"unless\",\"date-input\",\"action\",\"if\",\"time-input\",\"d-button\"]]",
  "moduleName": "discourse/components/date-time-input.hbs",
  "isStrictMode": false
});