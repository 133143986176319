import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{this.tagId}}
*/
{
  "id": "vzIVmx5d",
  "block": "[[[1,[30,0,[\"tagId\"]]]],[],false,[]]",
  "moduleName": "discourse/components/tag-drop-link.hbs",
  "isStrictMode": false
});