import { createTemplateFactory } from "@ember/template-factory";
export default createTemplateFactory(
/*
  {{d-icon this.topicStatusIcon}}

<a href={{this.topic.lastUnreadUrl}} class="title">
  {{html-safe this.topic.fancyTitle}}
</a>
*/
{
  "id": "A/xiz0vg",
  "block": "[[[1,[28,[35,0],[[30,0,[\"topicStatusIcon\"]]],null]],[1,\"\\n\\n\"],[10,3],[15,6,[30,0,[\"topic\",\"lastUnreadUrl\"]]],[14,0,\"title\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[[30,0,[\"topic\",\"fancyTitle\"]]],null]],[1,\"\\n\"],[13]],[],false,[\"d-icon\",\"html-safe\"]]",
  "moduleName": "discourse/components/categories-boxes-topic.hbs",
  "isStrictMode": false
});